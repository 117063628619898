import {
  FormControl,
  FormControlLabel,
  Radio,
  MenuItem,
  Box,
  Skeleton,
  Typography,
  RadioGroup
} from '@mui/material';
import { LoadingButton } from 'components';
import { ValidatedTextField } from 'feature/forms';
import { useState } from 'react';
import {
  offerBookingDataCy,
  getOfferBookingAppointment,
  getOfferBookingEndTime
} from '.';
import { useGetEventSuggestions } from 'feature/hooks';
import { type UseFormSetValue, type FieldValues } from 'react-hook-form';
import { getTextIn } from 'localization';
import { TimeRange, useBooking } from 'context/booking';
import { UseFormSetValueValidating } from 'feature/forms/use-form';
import dayjs from 'dayjs';

const getOfferBookingTimeSlotsText = getTextIn('offer-booking-timeslots');
interface Props {
  offerIds?: string[];
  offerDuration?: string | undefined;
  timeRange: TimeRange;
  setValue: UseFormSetValue<FieldValues>;
  setValueValidating: UseFormSetValueValidating<FieldValues>;
}

const OfferBookingTimeSlots = ({
  offerIds = [],
  offerDuration,
  timeRange,
  setValue,
  setValueValidating
}: Props) => {
  const { requestedGeoAreaId, setSelectedTimeRange } = useBooking();

  const [newSuggestionsAfter, setNewSuggestionsAfter] = useState<
    Date | undefined
  >(undefined);
  const [showPrevButton, setShowPrevButton] = useState(false);

  const {
    data: possibleTimeSlots,
    isLoading: isPossibleTimeSlotsLoading,
    isError
  } = useGetEventSuggestions(
    offerIds.map((id) => ({
      offerId: id,
      geoAreaId: requestedGeoAreaId ?? '',
      startTime: timeRange.startTime,
      endTime: timeRange.endTime
    })),
    0,
    newSuggestionsAfter,
    timeRange !== undefined
  );

  const resetSelectedTimeRange = () =>
    setSelectedTimeRange({
      startTime: undefined,
      endTime: undefined
    });

  return (
    <ValidatedTextField
      name="startTime"
      render={({ field, props }) => {
        const { errorMessage, error } = props;
        return (
          <FormControl
            error={error}
            sx={{
              width: '100%'
            }}
          >
            <RadioGroup
              data-cy={`${offerBookingDataCy}-timeSlots-selection`}
              value={field.value ?? ''}
              onChange={(event) => {
                if (event.target.value) {
                  setValueValidating('startTime', event.target.value);
                  setValueValidating(
                    'endTime',
                    getOfferBookingEndTime(event.target.value, offerDuration)
                  );
                  setSelectedTimeRange({
                    startTime: dayjs(event.target.value).toDate(),
                    endTime: getOfferBookingEndTime(
                      event.target.value,
                      offerDuration
                    )
                  });
                }
              }}
            >
              {possibleTimeSlots === undefined && !isPossibleTimeSlotsLoading
                ? getOfferBookingTimeSlotsText('noAppointmentsAnymore')
                : possibleTimeSlots?.map((timeSlot, index) => (
                    <MenuItem
                      key={index}
                      data-cy={`${offerBookingDataCy}-${index}-timeSlot`}
                    >
                      <FormControlLabel
                        value={timeSlot.startTime?.toString()}
                        checked={
                          field.value?.toString() ===
                          timeSlot.startTime?.toString()
                        }
                        control={<Radio size="small" />}
                        label={getOfferBookingAppointment(timeSlot.startTime)}
                      />
                    </MenuItem>
                  ))}
              {isPossibleTimeSlotsLoading && (
                <MenuItem disabled sx={{ width: 237 }}>
                  <Skeleton animation="wave" width="100%" />
                </MenuItem>
              )}

              {(possibleTimeSlots?.length === 3 ||
                isPossibleTimeSlotsLoading) && (
                <LoadingButton
                  variant="outlined"
                  dataCy={`${offerBookingDataCy}-request-suggestions`}
                  label={getOfferBookingTimeSlotsText('requestNext')}
                  disabled={isPossibleTimeSlotsLoading}
                  loading={isPossibleTimeSlotsLoading}
                  onClick={() => {
                    setNewSuggestionsAfter(possibleTimeSlots?.at(-1)?.endTime);
                    setShowPrevButton(true);
                    setValue('startTime', undefined);
                    setValue('endTime', undefined);
                    resetSelectedTimeRange();
                  }}
                  size="small"
                  sx={{ alignSelf: 'flex-end', mt: 2 }}
                />
              )}
              {showPrevButton && (
                <LoadingButton
                  variant="text"
                  dataCy={`${offerBookingDataCy}-request-suggestions`}
                  label={getOfferBookingTimeSlotsText('requestPrev')}
                  disabled={isPossibleTimeSlotsLoading}
                  loading={isPossibleTimeSlotsLoading}
                  onClick={() => {
                    setNewSuggestionsAfter(timeRange.startTime);
                    setShowPrevButton(false);
                    setValue('startTime', undefined);
                    setValue('endTime', undefined);
                    resetSelectedTimeRange();
                  }}
                  size="small"
                  sx={{ alignSelf: 'flex-end', mt: 2 }}
                />
              )}
              {possibleTimeSlots?.length === 0 &&
                !isPossibleTimeSlotsLoading && (
                  <Box
                    py={1}
                    sx={{
                      width: 237,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography>
                      {getOfferBookingTimeSlotsText('noAppointments')}
                    </Typography>
                  </Box>
                )}
            </RadioGroup>
            <Typography color="error" sx={{ pt: 1 }}>
              {errorMessage}
            </Typography>
            {isError && (
              <Typography>
                {getOfferBookingTimeSlotsText('fetchTimeslotsError')}
              </Typography>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default OfferBookingTimeSlots;
