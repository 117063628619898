import { Card, CardActionArea, CardActions } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type ClickableCardProps = {
  href: string;
  minWidthPercent?: number;
  children: ReactElement[];
  cardActions?: ReactNode;
};

const CustomCard = ({
  children,
  href,
  minWidthPercent,
  cardActions
}: ClickableCardProps) => {
  // Note: the custom card call from offer-card does not use the parameter minWidthPercent, as it uses a grid
  return (
    <Card
      sx={{
        minWidth: minWidthPercent
          ? { xs: 'unset', sm: `${minWidthPercent}%`, xl: '23%' }
          : 'unset',
        maxWidth: minWidthPercent ? 358 : 'unset',
        flexGrow: minWidthPercent ? 1 : 'unset'
      }}
    >
      <CardActionArea component={RouterLink} to={href} sx={{ height: '100%' }}>
        {children}
      </CardActionArea>
      {cardActions && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          {cardActions}
        </CardActions>
      )}
    </Card>
  );
};

export default CustomCard;
