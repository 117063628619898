import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { type ArticleUpdate } from '@SLR/solution3-sdk';
import { useSetDocumentTitle } from 'context/project';
import { useUser } from 'context/user';
import { ContentWrapper } from 'feature';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { EditToggle } from 'feature/editor';
import { useUpdateArticle, useGetArticle } from 'feature/hooks/compass';
import {
  notifyMutationError,
  notifyMutationSuccess,
  RequestErrorFallback
} from 'feature/error';
import { Article } from './model';
import ArticleForm from './article-form';
import ArticleDeleteButton from './article-delete-button';
import PreviewOffers from './preview-offers';
import { convertArticlePayload } from './utils';
import { getTextIn, getPath } from 'localization';
import { isEmptyArray } from 'utils/helper';

const getTextCompass = getTextIn('compass');

const getCategoryPath = (pathname: string, id?: string) => {
  const parts = pathname.split('/');
  if (id) {
    parts[2] = id;
    parts.length = 3;
  }
  return parts.join('/');
};

const CompassArticle = () => {
  const { pathname } = useLocation();

  const { categoryId = '', articleId = '' } = useParams();
  const { isEditModeOn: editMode, toggleEditMode } = useUser();

  const article = useGetArticle({ articleId });
  const articleData = article?.data;

  const title = articleData?.title ?? '';
  const content = articleData?.content ?? '';
  const categoryName =
    articleData?.category?.name ?? getTextCompass('category');
  const keywords = articleData?.keywords ?? [];

  const articleParams = { categoryId, title, content, keywords };
  const pageTitle = editMode ? getTextCompass('editArticle') : title;

  const categoryPath = getCategoryPath(pathname, articleData?.category?.id);

  useSetDocumentTitle(pageTitle);

  const navigate = useNavigate();
  const updateArticle = useUpdateArticle();

  const handleConfirm = useCallback(
    (updatedArticle: ArticleUpdate) => {
      const articleUpdate = convertArticlePayload(
        updatedArticle as unknown as Article
      );

      updateArticle.mutate(
        { articleUpdate, articleId },
        {
          onSuccess: () => {
            notifyMutationSuccess();
            toggleEditMode();
          },
          onError: () => {
            notifyMutationError();
          }
        }
      );
    },
    [articleId, toggleEditMode, updateArticle]
  );

  return (
    <RequestErrorFallback queryResult={article}>
      <ContentWrapper breadcrumbOnly>
        <Stack
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <EditToggle />
          {editMode && (
            <ArticleDeleteButton
              article={articleParams}
              articleId={articleId}
            />
          )}
        </Stack>

        <BreadcrumbNavigation
          customItem={{
            2: { title: categoryName, path: categoryPath },
            3: { title: pageTitle }
          }}
        />

        <Typography variant="h1" align="left">
          {pageTitle}
        </Typography>
      </ContentWrapper>

      <ArticleForm
        categoryId={categoryId}
        article={articleParams}
        inProgress={updateArticle.isLoading}
        onConfirm={handleConfirm}
        onCancel={() => navigate(`/${getPath('compass')}/${categoryId}`)}
        editMode={editMode}
      />

      {!isEmptyArray(keywords) && !editMode && (
        <PreviewOffers keywords={keywords} />
      )}
    </RequestErrorFallback>
  );
};

export default CompassArticle;
