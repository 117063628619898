import { useQuery } from '@tanstack/react-query';
import { useKeycloakAuth } from 'auth';
import useUsersApi from './useUsersApi';
import { useGetProjectId } from 'context/project';

const useGetCurrentUsersOrganizations = () => {
  const { getCurrentUserOrganizations } = useUsersApi();
  const { isAuthenticated } = useKeycloakAuth();
  const projectId = useGetProjectId();

  return useQuery(
    ['currentUsersOrganizations', { projectId }],
    () => {
      return getCurrentUserOrganizations({ projectId: projectId ?? '' });
    },
    {
      enabled: !!projectId && isAuthenticated
    }
  );
};

export default useGetCurrentUsersOrganizations;
