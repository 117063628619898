import React from 'react';
import { Box } from '@mui/material';
import ProcessElement from './process-element';
import { useTheme } from '@mui/material/styles';
import { type ProcessVizLabels } from './process-wrapper';

const TRANSFORM_LINEAR = {
  xs: 'translate(1.7rem)',
  sm: 'translate(0, -5rem)'
};

export const CIRCLE_DIAMETER = 50;

type ProcessVizContent = ProcessVizLabels & {
  icon: JSX.Element;
};

type ProcessVizProps = {
  content: Array<ProcessVizContent>;
};

const ProcessViz = ({ content }: ProcessVizProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderWidth: '0.4rem',
        borderBottomStyle: { xs: 'none', lg: 'solid' },
        borderRightStyle: { xs: 'solid', sm: 'none', lg: 'solid' },
        borderTopStyle: { xs: 'none', sm: 'solid', lg: 'solid' },
        borderLeftStyle: 'none',
        borderColor: theme.palette.primary.main,
        borderRadius: { xs: 0, lg: '100%' },
        px: { xs: 0, lg: 1.5 },
        py: { xs: 6, lg: 1.5 },
        width: {
          xs: '3.5rem',
          sm: 'unset',
          lg: `${CIRCLE_DIAMETER}rem`,
          xxl: `${CIRCLE_DIAMETER + 10}rem`
        },
        height: {
          xs: 'unset',
          sm: '3rem',
          lg: `${CIRCLE_DIAMETER}rem`,
          xxl: `${CIRCLE_DIAMETER + 10}rem`
        },
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row', lg: 'column' },
        justifyContent: 'center',
        alignItems: { xs: 'center', sm: 'flex-start', lg: 'flex-end' },
        gap: { xs: 3, sm: 4, md: 8, lg: 4 },
        zIndex: 0,
        mt: { sm: 6, lg: 'unset' }
      }}
    >
      <ProcessElement
        {...content[0]}
        additionalBoxSx={{
          transform: {
            ...TRANSFORM_LINEAR,
            lg: 'translate(6.25rem)',
            xxl: 'translate(-3rem, -1rem)'
          },
          flexDirection: { lg: 'row', xxl: 'row-reverse' },
          maxWidth: { lg: '15rem', xxl: '24rem' }
        }}
        additionalLabelSx={{
          textAlign: { xs: 'center', lg: 'left', xxl: 'right' }
        }}
      />
      <ProcessElement
        {...content[1]}
        additionalBoxSx={{
          transform: {
            ...TRANSFORM_LINEAR,
            lg: 'translate(0.5rem, -0.5rem)',
            xxl: 'translate(1rem, -1rem)'
          }
        }}
      />
      <ProcessElement
        {...content[2]}
        additionalBoxSx={{
          transform: {
            ...TRANSFORM_LINEAR,
            lg: 'translate(3rem, -0.25rem)'
          }
        }}
      />
      <ProcessElement
        {...content[3]}
        additionalBoxSx={{
          transform: {
            ...TRANSFORM_LINEAR,
            lg: 'translate(3rem, 0.25rem)'
          }
        }}
      />
      <ProcessElement
        {...content[4]}
        additionalBoxSx={{
          transform: {
            ...TRANSFORM_LINEAR,
            lg: 'translate(0.5rem, 0.5rem)',
            xxl: 'translate(1rem, 1rem)'
          }
        }}
      />
      <ProcessElement
        {...content[5]}
        additionalBoxSx={{
          transform: {
            ...TRANSFORM_LINEAR,
            lg: 'translate(6.25rem)',
            xxl: 'translate(-3rem, 1rem)'
          },
          flexDirection: { lg: 'row', xxl: 'row-reverse' },
          maxWidth: { lg: '15rem', xxl: '24rem' }
        }}
        additionalLabelSx={{
          textAlign: { xs: 'center', lg: 'left', xxl: 'right' }
        }}
      />
    </Box>
  );
};

export default ProcessViz;
