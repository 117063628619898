import { MouseEvent } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useUser } from 'context/user';
import { UserAvatar } from 'feature';

type NavUserAvatarProps = {
  onUserAvatarClick: (event: MouseEvent<HTMLElement>) => void;
};

const NavUserAvatar = ({ onUserAvatarClick }: NavUserAvatarProps) => {
  const { perspective } = useUser();

  const { label } = perspective;
  const cursor = 'pointer';
  const mr = { xs: 0, lg: -2.25 };
  const size = { xs: 42, md: 61 };
  const display = { xs: 'none', xl: 'inherit' };

  return (
    <Box
      key="user-avatar"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        cursor
      }}
      onClick={onUserAvatarClick}
    >
      <UserAvatar sx={{ mr, width: size, height: size }} />

      {label ? (
        <Chip
          variant="filled"
          deleteIcon={<KeyboardArrowDownIcon />}
          // onDelete is a hack for the arrow icon, as in the Chip componenent an icon is not intended
          onDelete={onUserAvatarClick}
          data-cy="user-chip"
          sx={{
            display,
            pl: 2.5,
            minWidth: label ? 'unset' : 132,
            maxWidth: 230,
            height: 36,
            fontSize: 16,
            cursor,
            color: 'primary.contrastText',
            bgcolor: 'primary.main',
            borderRadius: 0.75,
            '.MuiChip-deleteIcon, .MuiChip-deleteIcon:hover': {
              color: 'primary.contrastText',
              position: label ? 'unset' : 'absolute',
              right: 1
            }
          }}
          label={label}
        />
      ) : (
        <Skeleton
          width={132}
          height={36}
          sx={{ display, borderRadius: 0.75 }}
        />
      )}
    </Box>
  );
};
export default NavUserAvatar;
