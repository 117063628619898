import { Section } from '@SLR/solution3-sdk';
import { EditToggle } from 'feature/editor';
import { ProviderPreviewSection } from 'feature';
import { InfoPageHeroWrapper, InfoSection } from 'feature/info';
import {
  ProcessWrapper,
  useVizLabelsPatient
} from 'feature/process-visualization';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { useSetDocumentTitle } from 'context/project';
import { transformToFragment } from 'utils/fragments-utils';
import { getTextIn } from 'localization';

const getInfoText = getTextIn('info');

const PatientInfoPage = () => {
  useSetDocumentTitle(getInfoText('patientInfo'));
  const patientVizLabels = useVizLabelsPatient();

  return (
    <>
      <InfoPageHeroWrapper section={Section.PatientInformation} />

      <ProcessWrapper
        processSection={{
          title: getInfoText('patientHelpTitle'),
          section: Section.PatientHelp,
          id: transformToFragment(getInfoText('patientHelpTitle'))
        }}
        vizLabels={patientVizLabels}
      >
        <>
          <EditToggle />
          <BreadcrumbNavigation />

          <InfoSection
            title={getInfoText('patientInfo')}
            headingType="h1"
            section={Section.PatientInformation}
          />
        </>

        <InfoSection
          title={getInfoText('patientServicesTitle')}
          section={Section.PatientServices}
        />
        <ProviderPreviewSection
          coloredBackground
          title={getInfoText('patientProvidersText')}
        />
        <InfoSection
          title={getInfoText('patientParticipate')}
          section={Section.PatientParticipate}
        />
        <InfoSection
          title={getInfoText('patientMore')}
          section={Section.PatientMore}
        />
      </ProcessWrapper>
    </>
  );
};

export default PatientInfoPage;
