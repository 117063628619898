import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type UpdateArticleRequest } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { useArticlesApi } from './use-compass-api';
import { GET_ARTICLE } from '.';

type UpdateArticle = Omit<UpdateArticleRequest, 'projectId'>;

const useUpdateArticle = () => {
  const queryClient = useQueryClient();
  const projectId = useGetProjectId();
  const { updateArticle } = useArticlesApi();

  return useMutation(
    (params: UpdateArticle) => {
      return updateArticle({
        ...params,
        projectId: projectId ?? ''
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_ARTICLE]);
      }
    }
  );
};

export default useUpdateArticle;
