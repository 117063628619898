import { useCallback, useState } from 'react';
import { type UseQueryResult } from '@tanstack/react-query';
import { Button, Stack } from '@mui/material';
import {
  type PagedModelEntityModelKeyword,
  type EntityModelKeyword
} from '@SLR/solution3-sdk';
import { useListKeywords } from 'feature/hooks';
import SearchFieldAutocomplete from 'components/search-field-autocomplete';
import { type Keyword } from './model';

const getSelectableKeywords = (
  listKeywords: UseQueryResult<PagedModelEntityModelKeyword, unknown>,
  keywordsToExclude: Keyword[]
) =>
  (listKeywords?.data?.embedded?.keywords ?? []).filter(
    (keyword) =>
      !keywordsToExclude.some(
        (keywordToExclude) => keywordToExclude.id === keyword.id
      )
  );

interface Props {
  label: string;
  selectCaption?: string;
  excludedOptions?: Keyword[];
  error?: boolean;
  errorMessage?: string;
  onSelectOption: (option: Keyword | string) => void;
}

const ArticleKeywordsSelector = ({
  label,
  selectCaption,
  error,
  errorMessage,
  excludedOptions = [],
  onSelectOption
}: Props) => {
  const [query, setQuery] = useState<string>('');
  const listKeywords = useListKeywords(query);
  const [selectedKeyword, setSelectedKeyword] = useState<
    EntityModelKeyword | string
  >();

  const selectableOptions = getSelectableKeywords(
    listKeywords,
    excludedOptions
  );

  const { isFetching } = listKeywords;

  const handleSelect = useCallback(() => {
    if (selectedKeyword) {
      onSelectOption(selectedKeyword);
    }

    // Reset search field
    setQuery('');
    setSelectedKeyword(undefined);
  }, [onSelectOption, selectedKeyword]);

  return (
    <Stack direction="row" spacing={2} alignItems="stretch">
      {SearchFieldAutocomplete<EntityModelKeyword>({
        label,
        query,
        selectableOptions,
        isFetching,
        error,
        errorMessage,
        onQueryChanged: setQuery,
        selectedOptionName:
          typeof selectedKeyword === 'string'
            ? selectedKeyword
            : selectedKeyword?.name ?? '',
        onOptionSelectedNew: setSelectedKeyword,
        onAccept: handleSelect
      })}

      <Button
        variant="outlined"
        disabled={!selectedKeyword}
        onClick={handleSelect}
        sx={{ height: 56 }}
        data-cy="keywordselector-select-button"
      >
        {selectCaption}
      </Button>
    </Stack>
  );
};

export default ArticleKeywordsSelector;
