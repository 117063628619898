import { CATEGORIES, TARGET_GROUPS } from 'utils/url-param';

import { useListSegments } from 'feature/hooks';

const useGetVariableCheckboxValues = () => {
  const { data: categories } = useListSegments(CATEGORIES);
  const { data: targetGroups } = useListSegments(TARGET_GROUPS);

  return {
    [CATEGORIES]: categories,
    [TARGET_GROUPS]: targetGroups
  };
};

export { useGetVariableCheckboxValues };
