import {
  Visibility,
  type EntityModelProject,
  Registration
} from '@SLR/solution3-sdk';
import { useKeycloakAuth } from 'auth';
import { useUser, type Perspective } from 'context/user';
import { useProject } from 'context/project';
import {
  IS_ADMIN,
  PROVIDER_ORGANIZATIONS_VISIBILITY,
  SEEKER_ORGANIZATIONS_VISIBILITY,
  OFFER_VISIBILITY,
  OFFERS_BOOKABLE_ORGANIZATION,
  OFFERS_BOOKABLE_SEEKER,
  HAS_TARGET_GROUPS,
  HAS_KNOWLEDGE_BASE
} from './constants';

type Protect = boolean | string;
type PerspectiveProp = { perspective: Perspective };
type ShowBookableContent = { showBookableContent: boolean };
type MayBookOffers = { mayBookOffers: boolean };
type ProjectAndIsAuthenticated = {
  project?: EntityModelProject;
  isAuthenticated: boolean;
};
type Project = {
  project?: EntityModelProject;
};

const CONFIG = {
  [IS_ADMIN]: ({ perspective }: PerspectiveProp) =>
    !!perspective.roles?.isAdmin,

  [PROVIDER_ORGANIZATIONS_VISIBILITY]: ({
    project,
    isAuthenticated
  }: ProjectAndIsAuthenticated) =>
    project?.providerOrganizationsVisibility !== Visibility.OnlyRegistered ||
    isAuthenticated,

  [SEEKER_ORGANIZATIONS_VISIBILITY]: ({
    project,
    isAuthenticated
  }: ProjectAndIsAuthenticated) =>
    project?.seekerOrganizationsRegistration !== Registration.None &&
    (project?.seekerOrganizationsVisibility !== Visibility.OnlyRegistered ||
      isAuthenticated),

  [OFFER_VISIBILITY]: ({
    project,
    isAuthenticated
  }: ProjectAndIsAuthenticated) =>
    project?.offersVisibility !== Visibility.OnlyRegistered || isAuthenticated,

  [OFFERS_BOOKABLE_ORGANIZATION]: ({
    showBookableContent
  }: ShowBookableContent) => showBookableContent,

  [OFFERS_BOOKABLE_SEEKER]: ({ mayBookOffers }: MayBookOffers) => mayBookOffers,

  [HAS_TARGET_GROUPS]: ({ project }: Project) =>
    project?.features?.targetGroups ?? false,

  [HAS_KNOWLEDGE_BASE]: ({ project }: Project) =>
    project?.features?.knowledgeBase ?? false
};

// this is written as a component to access the hooks in a loop but turns back only a boolean
//  has to be called as a function, not as a component
const ShowProtectedLink = ({ protect }: { protect?: Protect }) => {
  const { isAuthenticated } = useKeycloakAuth();
  const { perspective, showBookableContent, mayBookOffers } = useUser();
  const { project } = useProject();

  // eslint-disable-next-line no-negated-condition
  return !protect
    ? true
    : typeof protect === 'boolean'
    ? isAuthenticated
    : typeof protect === 'string' &&
      CONFIG[protect as keyof typeof CONFIG]?.({
        perspective,
        project,
        isAuthenticated,
        showBookableContent,
        mayBookOffers
      });
};

export default ShowProtectedLink;
