import { useState, useEffect, PropsWithChildren, ReactNode } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { type ErrorInformation } from '@SLR/solution3-sdk';
import useProjectNavigate from 'utils/useProjectNavigate';
import { getErrorText } from 'localization';

interface Props {
  title?: string;
  description?: string | ReactNode;
  response?: Response | null;
}

const RequestFailed = ({
  title = getErrorText('somethingWentWrong'),
  description = (
    <>
      {getErrorText('errorOnPage')}
      <br />
      {getErrorText('tryAgain')}
    </>
  ),
  response
}: PropsWithChildren<Props>) => {
  const { navigateBack, navigateToHome } = useProjectNavigate();
  const [errorInfo, setErrorInfo] = useState<ErrorInformation>();

  useEffect(() => {
    if (!response?.bodyUsed) {
      response?.json().then((data: ErrorInformation) => {
        setErrorInfo(data);
      });
    }
  }, [response]);

  return (
    <Stack alignItems="center" spacing={2} mt={4} mb={4} data-cy="error">
      <Typography variant="h1">{getErrorText('oops')}</Typography>
      <Typography variant="h2" textAlign="center">
        {title}
      </Typography>
      <Typography textAlign="center">{description}</Typography>

      {errorInfo?.trace && (
        <Typography color="primary">
          {getErrorText('errorId')}: {errorInfo.trace}
        </Typography>
      )}

      <Stack direction="row" spacing={2} pt={4}>
        <Button variant="outlined" onClick={navigateBack}>
          {getErrorText('back')}
        </Button>
        <Button variant="contained" onClick={navigateToHome}>
          {getErrorText('toHome')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default RequestFailed;
