import { type ListEventsRequest } from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useEventsApi from './useEventsApi';
import { useUser } from 'context/user';

type EventParams = {
  from: Date;
  to: Date;
};

const useListEvents = (params: EventParams) => {
  const { listEvents } = useEventsApi();
  const projectId = useGetProjectId();
  const { perspective } = useUser();

  const organizationId = perspective?.isOrganization
    ? perspective.id
    : undefined;

  const eventRequestParameters: ListEventsRequest = {
    projectId: projectId ?? '',
    organizationId: organizationId ?? '',
    ...params,
    includeReferenced: true
  };

  return useQuery(
    ['events', eventRequestParameters],
    () => {
      return listEvents(eventRequestParameters);
    },
    {
      staleTime: Infinity,
      enabled: !!projectId && !!organizationId,
      select: (data) => data?.embedded?.events
    }
  );
};

export default useListEvents;
