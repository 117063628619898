import { type EntityModelEvent } from '@SLR/solution3-sdk';
import { getOfferBookingText, offerBookingDataCy } from '.';
import { useCrypto } from 'context/crypto';
import useGetOrganizationSpecificKey from 'context/crypto/hooks/useGetOrganizationSpecificKey';
import { useUser } from 'context/user';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getText } from 'localization';
import { DialogGeneric } from 'feature';
import join from 'lodash/join';
import compact from 'lodash/compact';
import { formatAsDate, formatAsTime } from 'utils/date';

interface Props {
  showSuccessDialog: boolean;
  encryptedEvent: EntityModelEvent;
  onClose: () => void;
  isHomevisit: boolean;
}

const BookingSuccessDialog = ({
  showSuccessDialog,
  encryptedEvent,
  onClose,
  isHomevisit
}: Props) => {
  const { perspective } = useUser();
  const { decryptEventDetails, selectedOrganizationPrivateKey } = useCrypto();
  const { enqueueSnackbar } = useSnackbar();
  const { data: encryptedSymMsgKey } = useGetOrganizationSpecificKey(
    perspective.id,
    encryptedEvent.id
  );
  const [decryptedEvent, setDecryptedEvent] =
    useState<EntityModelEvent>(encryptedEvent);

  const startTime = decryptedEvent.timeSlot.startTime ?? new Date();
  const location = decryptedEvent.eventDetails.fixedLocation;

  useEffect(() => {
    if (encryptedSymMsgKey && selectedOrganizationPrivateKey) {
      decryptEventDetails(
        encryptedEvent,
        encryptedSymMsgKey,
        selectedOrganizationPrivateKey
      )
        .then(setDecryptedEvent)
        .catch((reason) =>
          enqueueSnackbar(
            `${getText('decryptionError', 'crypto')}: ${reason}`,
            {
              variant: 'error'
            }
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encryptedSymMsgKey, selectedOrganizationPrivateKey]);

  return (
    <DialogGeneric
      open={showSuccessDialog}
      onClose={onClose}
      title={getOfferBookingText('successTitle')}
      prefix={offerBookingDataCy}
      hasActionButtons={false}
      maxWidth="md"
    >
      {getOfferBookingText('getNodeSuccessDescription', {
        date: formatAsDate(startTime),
        time: formatAsTime(startTime),
        isHomevisit,
        location:
          location.description === ''
            ? join(compact([location.street, location.houseNumber]), ' ')
            : location.description
      })}
    </DialogGeneric>
  );
};

export default BookingSuccessDialog;
