import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Section } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { useReplaceProjectSectionText } from 'feature/hooks';
import EditWrapper from './edit-wrapper';
import TextEditor from './text-editor';
import ReadOnlyTextEditor from './read-only-editor';
import TextSectionPlaceholder from './text-section-placeholder';
import { EMPTY_HTML, isNotEmptyHtml } from 'utils/editor';
import { getTextIn } from 'localization';

const getEditText = getTextIn('edit');

type EditSectionProps = {
  section: Section;
  sectionText?: string;
};

const EditSection = ({ section, sectionText }: EditSectionProps) => {
  const [showFields, setShowFields] = useState(false);
  const initialContent = sectionText ?? '';
  const [content, setContent] = useState<string>(initialContent);
  const projectId = useGetProjectId();
  const replaceProjectSectionText = useReplaceProjectSectionText(section);
  const { enqueueSnackbar } = useSnackbar();

  const onSaveClicked = () => {
    replaceProjectSectionText.mutate(
      {
        projectId: projectId ?? '',
        section,
        text: content || EMPTY_HTML
      },
      {
        onError: () => {
          enqueueSnackbar(getEditText('editTextError'), {
            variant: 'error'
          });
        },
        onSuccess: () => {
          enqueueSnackbar(getEditText('editSuccess'), {
            variant: 'success'
          });
          setShowFields(false);
        }
      }
    );
  };

  const onCancelClicked = () => {
    setShowFields(false);
    setTimeout(() => setContent(initialContent), 300);
  };

  return (
    <EditWrapper
      showFields={showFields}
      setShowFields={setShowFields}
      onCancelClicked={onCancelClicked}
      onSaveClicked={onSaveClicked}
      loading={replaceProjectSectionText.isLoading}
      disabled={initialContent === content}
      dataCyPostfix={`${section}-text`}
    >
      {showFields ? (
        <TextEditor
          placeholderText={getEditText('editorPlaceholder')}
          content={content}
          onEditorChange={setContent}
        />
      ) : initialContent && isNotEmptyHtml(initialContent) ? (
        <ReadOnlyTextEditor content={initialContent} />
      ) : (
        <TextSectionPlaceholder loading={false} />
      )}
    </EditWrapper>
  );
};

export default EditSection;
