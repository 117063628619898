import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type DropdownButtonProps = {
  dataCy?: string;
  open: boolean;
  text?: string;
  onClick: VoidFunction;
};

const DropdownButton = ({
  dataCy,
  open = false,
  text = '',
  onClick
}: DropdownButtonProps) => {
  const Arrow = open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

  return (
    <Button
      data-cy={dataCy}
      variant="outlined"
      endIcon={<Arrow />}
      sx={{
        fontSize: 14,
        px: 2.75,
        py: 0.75
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default DropdownButton;
