import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Add from '@mui/icons-material/Add';
import { useSetDocumentTitle } from 'context/project';
import { useIsAdmin } from 'context/user';
import { ContentWrapper, BreadcrumbNavigation } from 'feature';
import { useArticleCategories } from 'feature/hooks/compass';
import CategoriesGrid from './categories-grid';
import CategoryDialog from './category-dialog';
import { getTextIn } from 'localization';

const getTextCompass = getTextIn('compass');

const CategoriesList = () => {
  const isAdmin = useIsAdmin();
  const { articleCategories, isLoading } = useArticleCategories();
  const [isOpen, setIsOpen] = useState(false);
  const listTitle = getTextCompass('listTitle');

  useSetDocumentTitle(listTitle);

  return (
    <ContentWrapper>
      <BreadcrumbNavigation />
      <Typography variant="h1">{listTitle}</Typography>

      {isAdmin && (
        <>
          <Box
            sx={{
              textAlign: { xs: 'center', sm: 'right' },
              mx: 0.75,
              mt: { sm: -5.5, md: -8 },
              mb: 3.75
            }}
          >
            <Button
              startIcon={<Add />}
              onClick={() => setIsOpen(true)}
              data-cy="compass-list-create"
              sx={{ width: 'auto', whiteSpace: 'nowrap' }}
            >
              {getTextCompass('createCategory')}
            </Button>
          </Box>

          {isOpen && (
            <CategoryDialog
              isOpen={isOpen}
              handleClose={() => {
                setIsOpen(false);
              }}
            />
          )}
        </>
      )}

      <CategoriesGrid
        articleCategories={articleCategories}
        isLoading={isLoading}
      />
    </ContentWrapper>
  );
};

export default CategoriesList;
