import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';
import {
  type CreateEventRequest,
  EventsApi,
  type ListEventsRequest,
  type DeleteEventRequest
} from '@SLR/solution3-sdk';
import { useUser } from 'context/user';

const useEventsApi = () => {
  const { perspective } = useUser();
  const currentOrgId = perspective.isOrganization ? perspective.id : undefined;

  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.events, currentOrgId) as EventsApi;
  }, [currentOrgId]);

  const createEvent = useCallback(
    async (requestParameters: CreateEventRequest) => {
      try {
        return await apiInstance.createEvent(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const listEvents = useCallback(
    async (requestParameters: ListEventsRequest) => {
      try {
        return await apiInstance.listEvents(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const deleteEvent = useCallback(
    async (requestParameters: DeleteEventRequest) => {
      try {
        return await apiInstance.deleteEvent(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    createEvent,
    listEvents,
    deleteEvent
  };
};

export default useEventsApi;
