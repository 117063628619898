import { Box } from '@mui/material';
import { Section } from '@SLR/solution3-sdk';
import { ProviderPreviewSection } from 'feature';
import { EditToggle } from 'feature/editor';
import { InfoPageHeroWrapper, InfoSection, StaticSection } from 'feature/info';
import {
  ProcessWrapper,
  useVizLabelsProvider
} from 'feature/process-visualization';
import { getPath, getTextIn } from 'localization';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { useSetDocumentTitle } from 'context/project';
import { transformToFragment } from 'utils/fragments-utils';
import { ContactButton } from 'components';

const getInfoText = getTextIn('info');
const getOfferText = getTextIn('offer-details');

const ProviderInfoPage = () => {
  useSetDocumentTitle(getInfoText('providerInfo'));
  const providerVizLabels = useVizLabelsProvider(getPath('providerInfo'));

  return (
    <>
      <InfoPageHeroWrapper section={Section.ProviderInformation} />

      <ProcessWrapper
        processSection={{
          title: getInfoText('servicesTitle'),
          section: Section.ProviderGains,
          id: transformToFragment(getInfoText('servicesTitle'))
        }}
        vizLabels={providerVizLabels}
      >
        <>
          <EditToggle />
          <BreadcrumbNavigation />

          <InfoSection
            title={getInfoText('providerInfo')}
            headingType="h1"
            section={Section.ProviderInformation}
          />
        </>
        <InfoSection
          title={getInfoText('howToParticipateTitle')}
          section={Section.ProviderParticipate}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 3 }}>
            <ContactButton data-cy="provider-info-participate-contact-button" />
          </Box>
        </InfoSection>
        <ProviderPreviewSection coloredBackground={true} />
        <InfoSection
          title={getOfferText('additionalInformation')}
          section={Section.ProviderMore}
        />
        <StaticSection
          title={getInfoText('helpAndContact')}
          sectionText={getInfoText('helpAndContactInfo')}
          coloredBackground={true}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 3 }}>
            <ContactButton data-cy="provider-info-contact-button" />
          </Box>
        </StaticSection>
      </ProcessWrapper>
    </>
  );
};

export default ProviderInfoPage;
