import { Stack, Typography } from '@mui/material';
import { ParagraphSkeleton } from 'components';
import { ReactNode } from 'react';
import { ReadOnlyTextEditor } from './editor';
import { isEmptyHtml } from 'utils/editor';

interface GenericContentSectionProps {
  title?: ReactNode;
  content?: ReactNode;
  placeholder?: ReactNode;
  empty?: boolean;
  hideEmpty?: boolean;
  loading?: boolean;
}

const GenericContentSection = ({
  title,
  content,
  placeholder,
  hideEmpty = true,
  empty = false,
  loading = false
}: GenericContentSectionProps) => {
  if (loading && placeholder) {
    return (
      <Stack spacing={1} sx={{ display: 'block' }}>
        {title}
        {placeholder}
      </Stack>
    );
  }

  if (hideEmpty && empty) {
    return <></>;
  }

  return (
    <Stack spacing={1} sx={{ display: 'block' }}>
      {title}
      {content}
    </Stack>
  );
};

interface TextContentSectionProps {
  title?: ReactNode;
  content?: string;
  placeholderLines?: number;
  hideEmpty?: boolean;
  loading?: boolean;
  dataCyPostfix?: string;
}

const TextContentSection = ({
  title,
  content,
  placeholderLines = 3,
  hideEmpty = true,
  loading = false,
  dataCyPostfix
}: TextContentSectionProps) => {
  return (
    <GenericContentSection
      title={title}
      content={
        <Typography
          sx={{ whiteSpace: 'pre-wrap' }}
          data-cy={dataCyPostfix ? `textcontent-${dataCyPostfix}` : undefined}
        >
          {content}
        </Typography>
      }
      placeholder={<ParagraphSkeleton lines={placeholderLines} pulse />}
      hideEmpty={hideEmpty}
      empty={!content}
      loading={loading}
    />
  );
};

interface HtmlContentSectionProps {
  title?: ReactNode;
  content?: string;
  placeholderLines?: number;
  hideEmpty?: boolean;
  loading?: boolean;
  dataCyPostfix?: string;
}

const HtmlContentSection = ({
  title,
  content,
  placeholderLines = 3,
  hideEmpty = true,
  loading = false,
  dataCyPostfix
}: HtmlContentSectionProps) => {
  return (
    <GenericContentSection
      title={title}
      content={
        <ReadOnlyTextEditor
          content={content ?? ''}
          dataCy={dataCyPostfix ? `htmlcontent-${dataCyPostfix}` : undefined}
        />
      }
      placeholder={<ParagraphSkeleton lines={placeholderLines} pulse />}
      hideEmpty={hideEmpty}
      empty={isEmptyHtml(content)}
      loading={loading}
    />
  );
};

export { GenericContentSection, TextContentSection, HtmlContentSection };
export type { GenericContentSectionProps };
