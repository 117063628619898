import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useEventsApi from './useEventsApi';

const useDeleteEvent = () => {
  const queryClient = useQueryClient();
  const { deleteEvent } = useEventsApi();
  const projectId = useGetProjectId();

  return useMutation<void, Error, string, () => void>(
    (eventId: string) =>
      deleteEvent({
        projectId: projectId ?? '',
        eventId
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['events']);
      }
    }
  );
};

export default useDeleteEvent;
