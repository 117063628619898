import { Dispatch, SetStateAction, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

type RemoveHideButtonProps = {
  text?: string;
  setHideButtons: Dispatch<SetStateAction<boolean>>;
};

const RemoveHideButton = ({ text, setHideButtons }: RemoveHideButtonProps) => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen((isOpen) => !isOpen);
    setHideButtons((isOpen) => !isOpen);
  };

  const Arrow = open ? KeyboardArrowUp : KeyboardArrowDown;

  return (
    <Box sx={{ ml: 1, display: { xs: 'initial', md: 'none' } }}>
      <Button
        variant="text"
        endIcon={<Arrow />}
        aria-label="toggle-navigation-menu"
        onClick={toggleMenu}
        disableRipple
        sx={{
          fontSize: 16,
          color: open ? 'primary.main' : 'initial',
          width: '100%',
          minWidth: open ? 36 : 'unset',
          height: 36,
          justifyContent: 'space-between',
          px: 1,
          pl: open ? 0 : 1,
          '& svg.MuiSvgIcon-fontSizeMedium': {
            fontSize: 24
          }
        }}
      >
        {text}
      </Button>
    </Box>
  );
};

export default RemoveHideButton;
