import type { EntityModelArticleCategory } from '@SLR/solution3-sdk';
import { Article } from '../model';

const findCategory = (
  articleCategories: EntityModelArticleCategory[],
  value: string,
  key = 'id'
) =>
  articleCategories.find(
    (category) => category[key as keyof typeof category] === value
  );

const convertArticlePayload = (updatedArticle: Article) => {
  const keywordsInternal = updatedArticle.keywords;

  const keywords = keywordsInternal?.map((elem) => {
    return typeof elem === 'string' ? elem : elem?.keyword.name;
  });

  return { ...updatedArticle, keywords };
};

export { findCategory, convertArticlePayload };
