import React from 'react';
import { Box, CircularProgress, type SxProps, type Theme } from '@mui/material';

type LoadingSpinnerProps = {
  dataCy: string;
  color?: 'primary' | 'secondary' | 'inherit';
  sx?: SxProps<Theme>;
  size?: number | string;
};

const LoadingSpinner = ({
  dataCy,
  color = 'primary',
  sx = [],
  size
}: LoadingSpinnerProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        py: 6,
        width: '100%'
      }}
    >
      <CircularProgress
        data-cy={`${dataCy}-loadingspinner`}
        color={color}
        sx={sx}
        size={size}
      />
    </Box>
  );
};

export default LoadingSpinner;
