import { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSetDocumentTitle } from 'context/project';
import { ContentWrapper } from 'feature';
import {
  BreadcrumbNavigation,
  useAutoBreadcrumbItems
} from 'feature/breadcrumbs';
import MenuMobile from './menu-mobile';
import SidebarMenu from './menu-sidebar';
import theme from 'theme/theme';

type SidebarPageWrapperProps = {
  routeLinksName?: string;
  children: ReactElement;
};

const SidebarPageWrapperContent = ({
  routeLinksName = 'settingsLinks',
  isMdDown,
  children
}: SidebarPageWrapperProps & { isMdDown: boolean }) => {
  const breadcrumbItems = useAutoBreadcrumbItems();
  useSetDocumentTitle(breadcrumbItems[breadcrumbItems.length - 1].title);

  return (
    <Grid container sx={{ flexWrap: 'nowrap' }}>
      {!isMdDown && (
        <Box sx={{ display: 'flex', pr: 10 }}>
          <SidebarMenu routeLinksName={routeLinksName} />

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: 'primary.main',
              borderWidth: 1
            }}
          />
        </Box>
      )}

      <Grid item xs={12} md={8}>
        <Box>
          {isMdDown && (
            <Box>
              <ContentWrapper breadcrumbOnly>
                <BreadcrumbNavigation />
              </ContentWrapper>

              <MenuMobile routeLinksName={routeLinksName} />
            </Box>
          )}

          <>
            {isMdDown ? (
              <ContentWrapper>{children}</ContentWrapper>
            ) : (
              <>
                <BreadcrumbNavigation />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {children}
                </Box>
              </>
            )}
          </>
        </Box>
      </Grid>
    </Grid>
  );
};

const SidebarPageWrapper = ({
  routeLinksName,
  children
}: SidebarPageWrapperProps) => {
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return isMdDown ? (
    <SidebarPageWrapperContent
      routeLinksName={routeLinksName}
      isMdDown={isMdDown}
    >
      {children}
    </SidebarPageWrapperContent>
  ) : (
    <ContentWrapper additionalPadding>
      <SidebarPageWrapperContent
        routeLinksName={routeLinksName}
        isMdDown={isMdDown}
      >
        {children}
      </SidebarPageWrapperContent>
    </ContentWrapper>
  );
};

export default SidebarPageWrapper;
