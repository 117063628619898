import { useState, ChangeEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { type UpdateProjectRequest } from '@SLR/solution3-sdk';
import { SidebarPageWrapper } from 'feature';
import { useValidation } from 'feature/hooks';
import { useSnackbar } from 'notistack';
import CtrlButtons from './ctrl-buttons';
import { useProject } from 'context/project';
import { useUpdateProject } from 'context/project/hooks';
import { getTextIn } from 'localization';

const getSettingsText = getTextIn('settings-general');

type TitleSwitchProps = {
  value: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const TitleSwitch = ({ value, onChange }: TitleSwitchProps) => {
  return (
    <FormControlLabel
      control={
        <Switch
          name="showTitle"
          checked={!value}
          onChange={onChange}
          inputProps={{
            'aria-label': getSettingsText('hideTitleToggleLabel')
          }}
        />
      }
      label={getSettingsText('hideTitleToggleLabel')}
      data-cy="toggle-hide-title"
    />
  );
};

type TitleInputProps = {
  value?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  maxLength: number;
  isNotValid: boolean;
};

const TitleInput = ({
  value,
  onChange,
  maxLength,
  isNotValid
}: TitleInputProps) => {
  return (
    <FormControl sx={{ my: 3 }} component="fieldset" variant="standard">
      <TextField
        value={value}
        variant="outlined"
        label={getSettingsText('title')}
        onChange={onChange}
        error={isNotValid}
        helperText={getSettingsText(
          isNotValid ? 'titleHelperError' : 'titleHelperText'
        )}
        inputProps={{ maxLength }}
      />
    </FormControl>
  );
};

const SettingsGeneral = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { project } = useProject();
  const title = project?.name ?? '';
  const showTitle = project?.showTitle ?? true;
  const updateProject = useUpdateProject();
  const [newTitle, setNewTitle] = useState(title);
  const [newShowTitle, setNewShowTitle] = useState(showTitle);

  const disabled =
    newTitle.trim() === '' ||
    (newTitle === title && newShowTitle === showTitle);

  const onCancelClick = () => {
    setNewTitle(title);
    setNewShowTitle(showTitle);
  };

  const onConfirmClick = () => {
    const requestParameters: UpdateProjectRequest = {
      projectId: project?.id ?? '',
      projectUpdate: {
        name: newTitle,
        showTitle: newShowTitle
      }
    };

    updateProject.mutate(requestParameters, {
      onSuccess: () => {
        enqueueSnackbar(getSettingsText('changeTitleSuccess'), {
          variant: 'success'
        });
      },
      onError: () => {
        enqueueSnackbar(getSettingsText('changeTitleError'), {
          variant: 'error'
        });
      }
    });
  };

  const onSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked }
    } = event;

    setNewShowTitle(!checked);
  };

  const { onChange, onConfirm, onCancel } = useValidation({
    handleChange: setNewTitle,
    handleConfirm: onConfirmClick,
    handleCancel: onCancelClick
  });

  return (
    <SidebarPageWrapper>
      <>
        <Typography variant="h1">
          {getSettingsText('generalSettings')}
        </Typography>

        <TitleSwitch
          value={newShowTitle ?? showTitle}
          onChange={onSwitchChange}
        />

        <TitleInput
          value={newTitle ?? title}
          onChange={onChange}
          maxLength={50}
          isNotValid={newTitle?.trim() === ''}
        />

        <CtrlButtons
          disabled={disabled}
          isLoading={updateProject.isLoading}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </>
    </SidebarPageWrapper>
  );
};

export default SettingsGeneral;
