import { ChangeEvent, MouseEvent, useEffect } from 'react';
import {
  Container,
  Pagination as MuiPagination,
  TablePagination,
  useMediaQuery
} from '@mui/material';
import theme from 'theme/theme';
import { getText } from 'localization';

const ROWS_PER_PAGE_OPTIONS_NR = 4;

const fillPaginationOptions = (
  rowsPerPageOptionsVariants = 1,
  elemNumberPerPage = 1
) =>
  Array.from(Array(rowsPerPageOptionsVariants), (_, index) => {
    return (index + 1) * elemNumberPerPage;
  });

type AutoPaginationProps = {
  count: number | undefined;
  page: number;
  setPage: (newPage: number) => void;
  setRowsPerPage: (value: number) => void;
  rowsPerPage?: number;
  labelRowsPerPage?: string;
};

const Pagination = ({
  count = 0,
  page = 0,
  setPage,
  setRowsPerPage,
  rowsPerPage = 0,
  labelRowsPerPage = getText('numberPerPage')
}: AutoPaginationProps) => {
  const mobilePagination = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isBreakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const pageOffset = mobilePagination ? -1 : 0;
  const elemNumberPerPage = isBreakpointDownMd ? 10 : isBreakpointUpLg ? 12 : 9;

  useEffect(() => {
    setRowsPerPage(elemNumberPerPage);
  }, [elemNumberPerPage, setRowsPerPage]);

  // get the elements per page options
  const rowsPerPageOptions = fillPaginationOptions(
    ROWS_PER_PAGE_OPTIONS_NR,
    elemNumberPerPage
  );

  // necessary for a change in the rows per page due to a responsive screen change
  const rowsPerPageChecked = rowsPerPageOptions.includes(rowsPerPage)
    ? rowsPerPage
    : rowsPerPageOptions[0];

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | ChangeEvent<unknown> | null,
    newPage: number
  ) => {
    setPage(newPage + pageOffset);
    window.scrollTo(0, 0);
  };

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = Number(event.target.value);
    // get page that fits in the new rows per page interval
    const newPage = Math.floor((rowsPerPage / newRowsPerPage) * page);

    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  return count ? (
    mobilePagination ? (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <MuiPagination
          count={Math.ceil(count / elemNumberPerPage)}
          page={page + 1}
          onChange={onChangePage}
          color="primary"
        />
      </Container>
    ) : (
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={onChangePage}
        rowsPerPage={rowsPerPageChecked}
        onRowsPerPageChange={onRowsPerPageChange}
        labelRowsPerPage={labelRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        sx={{ '.MuiTablePagination-select': { fontSize: 14 } }}
      />
    )
  ) : null;
};

export default Pagination;
