import Typography from '@mui/material/Typography';
import { useSetDocumentTitle } from 'context/project';
import { ContentWrapper, Pagination, ParticipantGrid } from 'feature';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { RequestErrorFallback } from 'feature/error';
import { useListParticipantsInfo } from 'feature/hooks';
import { getText } from 'localization';
import { useEffect, useState } from 'react';
import {
  SearchParams,
  setSearchParamWithPageSetbackFor,
  useUrlParams
} from 'utils/url-param';
import { Role } from 'utils/roles';

type ParticipantListType = {
  role: Role;
};

const ParticipantListPage = ({ role }: ParticipantListType) => {
  const title =
    role === 'seeker'
      ? getText('seekers', 'role')
      : getText('providers', 'role');
  useSetDocumentTitle(title);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [search, setSearch] = useUrlParams();

  const page = Number(search[SearchParams.page] ?? 0);

  const setSearchParamWithPageSetback = setSearchParamWithPageSetbackFor(
    search,
    setSearch
  );

  const handlePageClick = (newPage: number) => {
    // remove page value for page 0
    setSearchParamWithPageSetback(
      Boolean(newPage) ? { [SearchParams.page]: String(newPage) } : {},
      Boolean(newPage)
    );
  };

  const participants = useListParticipantsInfo({
    page,
    size: rowsPerPage,
    sort: ['name,asc'],
    showProviders: role === 'provider',
    showSeekers: role === 'seeker'
  });

  const totalElements = participants?.data?.page?.totalElements;
  const totalPages = participants?.data?.page?.totalPages;

  // when exceeding the page number, make empty call with page setback
  useEffect(() => {
    if (totalPages && totalPages <= page) {
      setSearchParamWithPageSetback({});
    }
  }, [page, setSearchParamWithPageSetback, totalPages]);

  return (
    <RequestErrorFallback queryResult={participants}>
      <ContentWrapper
        additionalSx={{ maxWidth: { lg: 1140 }, px: { lg: '0 !important' } }}
      >
        <BreadcrumbNavigation />
        <Typography variant="h1">{title}</Typography>
        <>
          <ParticipantGrid
            participantInfos={participants.data?.embedded?.participantInfos}
            isLoading={participants.isLoading}
            role={role}
          />
        </>

        <Pagination
          count={totalElements}
          page={page}
          setPage={handlePageClick}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </ContentWrapper>
    </RequestErrorFallback>
  );
};

export default ParticipantListPage;
