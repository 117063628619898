import { SegmentsGrid } from 'feature/forms';
import { TARGET_GROUPS } from 'feature/hooks';
import { getTextIn } from 'localization';

const getTargetGroupsText = getTextIn('settings-targetGroups');

const SettingsTargetGroups = () => {
  return (
    <SegmentsGrid
      segmentType={TARGET_GROUPS}
      dataCyPrefix="settings-target-groups"
      getSpecificText={getTargetGroupsText}
    />
  );
};

export default SettingsTargetGroups;
