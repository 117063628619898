import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { ManageData } from 'pages/account';
import { Restricted } from './restricted-route';
import { useUser } from 'context/user';
import { getPath } from 'localization';

const AccountRoutes = () => {
  const { perspective } = useUser();

  return (
    <>
      <Routes>
        {/* navigate to data only until edit profile will be added */}
        <Route index element={<Navigate replace to={getPath('data')} />} />
        <Route
          path={getPath('data')}
          element={
            <Restricted
              isVisible={perspective?.roles?.isSolutionUser}
              forceLogin={true}
            >
              <ManageData />
            </Restricted>
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};

export default AccountRoutes;
