import { Box, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';

export type LinkButtonProps = {
  text: string;
  linkTo: string;
  hide?: boolean;
};

type LinkButtonListProps = {
  linkButtons: LinkButtonProps[];
};

const LinkButtonList = ({ linkButtons }: LinkButtonListProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: { sm: 'wrap', lg: 'nowrap' },
        justifyContent: { xs: 'center' },
        gap: { xs: 1, sm: 2, lg: 4, xl: 10 },
        pt: 2
      }}
    >
      {linkButtons.map((button: LinkButtonProps) => {
        if (button.hide == undefined || !button.hide) {
          return (
            <Button
              variant="text"
              component={RouterLink}
              to={button.linkTo}
              endIcon={<NavigateNextIcon />}
              key={button.linkTo}
              data-cy={button.linkTo}
            >
              {button.text}
            </Button>
          );
        }
      })}
    </Box>
  );
};
export default LinkButtonList;
