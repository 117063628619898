import { useQuery } from '@tanstack/react-query';
import { type ListKeywordsRequest } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { useKeywordsApi } from './use-compass-api';

const useListKeywords = (name: string) => {
  const projectId = useGetProjectId();
  const { listKeywords } = useKeywordsApi();

  const requestParameters: ListKeywordsRequest = {
    projectId: projectId ?? '',
    name
  };

  return useQuery(
    ['keywords', { name }],
    () => {
      return listKeywords(requestParameters);
    },
    {
      enabled: name.length > 2
    }
  );
};

export default useListKeywords;
