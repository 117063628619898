// TinyMCE so the global var exists
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tinymce, { Editor as TinyMCEEditor } from 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/quickbars';

import './de';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
/* eslint import/no-unresolved: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

const PLUGINS = [
  'advlist',
  'autoresize',
  'autolink',
  'lists',
  'link',
  'image',
  'quickbars'
];

export { PLUGINS, contentCss, contentUiCss, TinyMCEEditor };
