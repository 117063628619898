import { ReactElement } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';

interface RadioTimeProps {
  name: string;
  value: string;
  checked: boolean;
  onCheck: () => void;
  title: string;
  prefixCy?: string;
  children: (disabled: boolean) => ReactElement;
}

const RadioTime = ({
  name,
  value,
  checked = false,
  onCheck,
  title,
  prefixCy = name,
  children
}: RadioTimeProps) => {
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'start', md: 'center' }}
      justifyContent="space-between"
    >
      <FormControlLabel
        control={
          <Radio
            checked={checked}
            onChange={onCheck}
            name={name}
            value={value}
            data-cy={`${prefixCy}-radio`}
          />
        }
        label={title}
      />
      {children(!checked)}
    </Stack>
  );
};

export default RadioTime;
