import { type EntityModelImage } from '@SLR/solution3-sdk';
import { useMutation } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useImagesApi from './useImagesApi';

const useCreateImage = () => {
  const projectId = useGetProjectId();
  const { createProjectImage } = useImagesApi();

  return useMutation<EntityModelImage, Response, Blob>((image: Blob) => {
    return createProjectImage({
      projectId: projectId ?? '',
      image
    });
  });
};

export default useCreateImage;
