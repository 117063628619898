import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import { notifyMutationError, notifyMutationSuccess } from 'feature/error';
import { OFFERS_LIST_KEY, OFFER_KEY } from 'feature/hooks/offers/useOffersApi';
import useCategoriesApi from './useCategoriesApi';
import useTargetGroupsApi from './useTargetGroupsApi';
import { CATEGORIES, TARGET_GROUPS, type SegmentType } from './constants';
import { getSegmentListType } from './helper';
import { getText, getTextIn } from 'localization';

const getSegmentsText = getTextIn('settings-segments');

const useDeleteSegment = (segmentType: SegmentType) => {
  const queryClient = useQueryClient();
  const { deleteCategory } = useCategoriesApi();
  const { deleteTargetGroup } = useTargetGroupsApi();
  const projectId = useGetProjectId() ?? '';

  return useMutation(
    (id: string): Promise<void> => {
      switch (segmentType) {
        case CATEGORIES: {
          return deleteCategory({
            projectId,
            categoryId: id
          });
        }
        case TARGET_GROUPS: {
          return deleteTargetGroup({
            projectId,
            targetGroupId: id
          });
        }
      }
    },
    {
      onSuccess: () => {
        notifyMutationSuccess(
          getSegmentsText('getSuccessAction', {
            singular: getText('segment', `settings-${segmentType}`),
            action: getSegmentsText('delete')
          })
        );

        queryClient.refetchQueries([getSegmentListType(segmentType)]);
        queryClient.refetchQueries([OFFERS_LIST_KEY]);
        queryClient.refetchQueries([OFFER_KEY]);
      },
      onError: () =>
        notifyMutationError(
          getSegmentsText('getErrorAction', {
            singular: getText('segment', `settings-${segmentType}`),
            action: getSegmentsText('delete')
          })
        )
    }
  );
};

export default useDeleteSegment;
