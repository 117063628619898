import { type ListBookableOffersRequest } from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import { useProject } from 'context/project';
import useOffersApi, { OFFERS_LIST_KEY } from './useOffersApi';
import { useIsSeeker } from 'context/user';
import { useBooking } from 'context/booking';
import { getMaxExpired } from 'utils/duration';

type OffersListFilters = Omit<ListBookableOffersRequest, 'projectId'>;

const useGetOffers = (params: OffersListFilters) => {
  const { getOffers, getBookableOffers } = useOffersApi();
  const { project } = useProject();
  const { isOffersBookable, offers } = useBooking();
  const projectId = project?.id;

  const isSeeker = useIsSeeker();

  const shouldUseBookingEndpoint = !!(
    isOffersBookable &&
    isSeeker &&
    params.locationTypes &&
    params.geoAreaId &&
    (params.fromDate || params.toDate)
  );

  const offerIdsList = offers.map((o) => o.id);

  const offerParams = {
    ...params,
    projectId: projectId ?? '',
    includeReferenced: true,
    maxExpired: getMaxExpired(),
    offerIds: offerIdsList.length > 0 ? new Set(offerIdsList) : undefined
  };

  return useQuery(
    [
      OFFERS_LIST_KEY,
      offerParams,
      shouldUseBookingEndpoint ? offerIdsList : null
    ],
    () => {
      return (shouldUseBookingEndpoint ? getBookableOffers : getOffers)(
        offerParams
      );
    },
    {
      enabled: !!projectId
    }
  );
};

export default useGetOffers;
