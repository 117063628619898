import React from 'react';
import { Box, Button, useMediaQuery } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
import { type Image } from '@SLR/solution3-sdk';
import { useUser } from 'context/user';
import HeroTitle from './hero-title';
import { SearchBar } from 'feature';
import HeroImageDialog from './hero-image-dialog';
import { getText } from 'localization';
import theme from 'theme/theme';
import { HERO_HEIGHTS } from './hero';
import { noop } from 'utils/helper';

type EditableHeroSectionProps = {
  image?: Image;
  updatedImage?: Image;
  handleAddImage: (newImage: Image) => void;
  handleAltTextChange: (newText: string) => void;
  handleRemoveImage: VoidFunction;
  slogan?: string;
  handleSloganChange?: (newText: string) => void;
  handleSave: VoidFunction;
  onCancel: VoidFunction;
  isHeroImageDialogOpen: boolean;
  openHeroImageDialog: VoidFunction;
  isSloganFieldVisible?: boolean;
  showHeroContent?: boolean;
  setIsSloganFieldVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdateLoading: boolean;
};

const EditableHeroSection = ({
  image,
  updatedImage,
  handleAddImage,
  handleAltTextChange,
  handleRemoveImage,
  slogan,
  handleSloganChange,
  handleSave,
  onCancel,
  isHeroImageDialogOpen,
  openHeroImageDialog,
  isSloganFieldVisible = false,
  showHeroContent = false,
  setIsSloganFieldVisible = noop,
  isUpdateLoading
}: EditableHeroSectionProps) => {
  const { isEditModeOn } = useUser();
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const hasImageToBlur = !!image?.urls.large && showHeroContent;

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${image?.urls.large})`,
          backgroundColor: hasImageToBlur ? '#707070' : 'primary.background',
          backgroundBlendMode: hasImageToBlur ? 'screen' : 'unset',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          py: {
            xs: 4,
            lg: 6,
            xxl: 9
          },
          minHeight: HERO_HEIGHTS,
          position: 'relative'
        }}
      >
        <Box
          sx={{
            mx: 'auto',
            px: {
              xs: 2,
              sm: 5,
              lg: 12
            },
            maxWidth: { xl: '66vw' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: {
              xs: 2,
              lg: 6,
              xxl: 9
            }
          }}
        >
          {showHeroContent && (
            <>
              <SearchBar morePadding height={isLgDown ? 42 : 60} />
              <HeroTitle
                handleSave={handleSave}
                slogan={slogan ?? ''}
                handleSloganChange={handleSloganChange}
                onCancel={onCancel}
                isSloganFieldVisible={isSloganFieldVisible}
                setIsSloganFieldVisible={setIsSloganFieldVisible}
                isUpdateLoading={isUpdateLoading}
              />
            </>
          )}
        </Box>
        {isEditModeOn && (
          <Box
            sx={{
              mx: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2,
              maxWidth: { xs: 1440, xl: 2116 },
              px: { xs: 2, sm: 5, md: 12 }
            }}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<CameraAlt />}
              onClick={openHeroImageDialog}
              data-cy="edit-hero-image"
            >
              {getText('title', 'edit-editHeroImage')}
            </Button>
          </Box>
        )}
      </Box>
      <HeroImageDialog
        isOpen={isHeroImageDialogOpen}
        handleClose={onCancel}
        image={updatedImage}
        handleConfirm={handleSave}
        handleAddImage={handleAddImage}
        handleAltTextChange={handleAltTextChange}
        isUpdateLoading={isUpdateLoading}
        handleDelete={handleRemoveImage}
      />
    </>
  );
};

export default EditableHeroSection;
