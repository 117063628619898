import dayjs, { duration } from 'dayjs';
import { getTextIn } from 'localization';

const getOfferBookingText = getTextIn('offer-booking');

const offerBookingDataCy = 'offer-booking-page';

const getOfferBookingAppointment = (requestStartTime?: Date | null) =>
  requestStartTime
    ? `${dayjs(requestStartTime).format('DD.MM.YYYY - HH:mm')} Uhr`
    : '';

const getOfferBookingEndTime = (
  startTime?: string | Date | null,
  dur?: string
) =>
  dayjs(startTime ?? '')
    .add(duration(dur ?? ''))
    .toDate();

export {
  offerBookingDataCy,
  getOfferBookingText,
  getOfferBookingEndTime,
  getOfferBookingAppointment
};
