import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Section,
  type Image,
  type GalleryEntryUpdate,
  type EntityModelGalleryEntry
} from '@SLR/solution3-sdk';
import {
  useGetProjectSectionGalleryEntry,
  useUpdateProjectGalleryEntries
} from 'feature/hooks';
import { getTextIn } from 'localization';
import { filterImageChange } from 'utils/image-utils';
import EditableHeroSection from './editable-hero';

const getEditModalText = getTextIn('edit-editHeroImage');

export type GalleryEntryReplaceObject = {
  [key: string]: GalleryEntryUpdate;
};

const generateGalleryUpdate = (
  updatedImage: Image | undefined,
  section: Section,
  fetchedEntry?: EntityModelGalleryEntry
) => {
  if (fetchedEntry?.image) {
    const filteredImage = filterImageChange(updatedImage, fetchedEntry.image);
    if (filteredImage) {
      const replaceObj: GalleryEntryReplaceObject = {};
      replaceObj[fetchedEntry.id] = {
        image: filteredImage,
        section
      };
      return {
        replace: replaceObj
      };
    }
    if (updatedImage == undefined) {
      return {
        _delete: [fetchedEntry.id]
      };
    }
  }

  if (updatedImage) {
    return {
      create: [
        {
          image: {
            imageId: updatedImage.id,
            alternativeText:
              updatedImage.alternativeText ??
              getEditModalText('altTextFallback')
          },
          section
        }
      ]
    };
  }
};

type InfoPageHeroWrapperProps = {
  section: Section;
};

const InfoPageHeroWrapper = ({ section }: InfoPageHeroWrapperProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: heroImageEntry } = useGetProjectSectionGalleryEntry(section);
  const [isHeroImageOpen, setHeroImageOpen] = useState(false);
  const [updatedHeroImage, setUpdatedHeroImage] = useState<Image>();
  const updateProjectGalleryEntries = useUpdateProjectGalleryEntries();

  const openHeroImageDialog = () => setHeroImageOpen(true);
  const closeHeroImageDialog = () => setHeroImageOpen(false);

  const handleAddImage = (image: Image) => setUpdatedHeroImage(image);
  const handleRemoveImage = () => setUpdatedHeroImage(undefined);

  const handleAltTextChange = (text: string) =>
    setUpdatedHeroImage((prevImage) => {
      if (prevImage) {
        return { ...prevImage, alternativeText: text };
      }
    });

  const handleCancelClick = () => {
    closeHeroImageDialog();
    setUpdatedHeroImage(heroImageEntry?.image);
  };

  const handleSave = () => {
    const update = generateGalleryUpdate(
      updatedHeroImage,
      section,
      heroImageEntry
    );
    if (update) {
      updateProjectGalleryEntries.mutate(update, {
        onSuccess: () => {
          enqueueSnackbar(getEditModalText('savingSuccess'), {
            variant: 'success'
          });
          closeHeroImageDialog();
        },
        onError: () => {
          enqueueSnackbar(getEditModalText('savingFail'), {
            variant: 'error'
          });
        }
      });
    }
  };

  useEffect(() => {
    if (heroImageEntry?.image) {
      setUpdatedHeroImage(heroImageEntry.image);
    }
  }, [heroImageEntry?.image]);

  return (
    <EditableHeroSection
      image={heroImageEntry?.image}
      updatedImage={updatedHeroImage}
      handleAddImage={handleAddImage}
      handleAltTextChange={handleAltTextChange}
      handleRemoveImage={handleRemoveImage}
      handleSave={handleSave}
      onCancel={handleCancelClick}
      isHeroImageDialogOpen={isHeroImageOpen}
      openHeroImageDialog={openHeroImageDialog}
      isUpdateLoading={updateProjectGalleryEntries.isLoading}
    />
  );
};

export default InfoPageHeroWrapper;
