import { Box } from '@mui/material';

type LegalTextBoxProps = {
  legalHtml: string;
};

const LegalTextBox = ({ legalHtml }: LegalTextBoxProps) => {
  return (
    <Box
      sx={{
        p: 5,
        pt: 0,
        table: {
          border: '1px solid black',
          borderCollapse: 'collapse',
          td: {
            border: '1px solid black',
            borderCollapse: 'collapse',
            padding: '0.5rem'
          }
        }
      }}
      dangerouslySetInnerHTML={{ __html: legalHtml ?? '' }}
    ></Box>
  );
};

export default LegalTextBox;
