import Stack from '@mui/material/Stack';
import NavigationLinks from './nav-links';
import { NavLinksBasicProps } from './types';

const NavMainbarLinks = ({
  mainRoute,
  navigationLinks
}: NavLinksBasicProps) => {
  return (
    <Stack
      direction="row"
      spacing={{ md: 3, xl: 6 }}
      sx={{
        height: '100%',
        mx: { md: 2, xl: 4 },
        display: { xs: 'none', md: 'flex' },
        alignItems: 'stretch',
        font: '18px/32px Roboto',
        fontWeight: 'bold'
      }}
    >
      <NavigationLinks
        navigationLinks={navigationLinks}
        mainRoute={mainRoute}
        variant="mainbar"
      />
    </Stack>
  );
};

export default NavMainbarLinks;
