import { type CreateReportRequest, ReportsApi } from '@SLR/solution3-sdk';
import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';

const useReportsApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.reports) as ReportsApi;
  }, []);

  const createReport = useCallback(
    async (requestParameters: CreateReportRequest) => {
      try {
        return await apiInstance.createReport(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return { createReport };
};

export default useReportsApi;
