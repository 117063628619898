import { Routes, Route } from 'react-router-dom';
import {
  OffersPage,
  OfferCreatePage,
  OfferEditPage,
  OfferPage
} from 'pages/offer';
import { useProject } from 'context/project';
import { RestrictedVisibilityRoute } from './restricted-route';
import { getPath } from 'localization';

const OffersRoutes = () => {
  const { project } = useProject();
  const visibility = project?.offersVisibility;

  return (
    <RestrictedVisibilityRoute visibility={visibility} forceLogin>
      <Routes>
        <Route index element={<OffersPage />} />
        <Route path=":category" element={<OffersPage />} />
        <Route path={getPath('create')} element={<OfferCreatePage />} />
        <Route
          path={`:offerId/${getPath('edit')}`}
          element={<OfferEditPage />}
        />
        <Route path=":category/:offerId" element={<OfferPage />} />
        <Route path=":offerId" element={<OfferPage />} />
      </Routes>
    </RestrictedVisibilityRoute>
  );
};

export default OffersRoutes;
