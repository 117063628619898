import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, List, ListItem } from '@mui/material';
import { useKeycloakAuth } from 'context/auth';
import FooterItem from '../footer-item';
import { getText, getTextIn, getTextFx, getPath } from 'localization';
import { useProject } from 'context/project';
import { Registration, Visibility } from '@SLR/solution3-sdk';
import { RANGE_STRING_SEPARATOR } from 'utils/date';

const getFooterText = getTextIn('footer');
const getInfoText = getTextIn('info');

const UsefulLinks = () => {
  const { isAuthenticated, login, logout } = useKeycloakAuth();
  const { isProjectNew, project } = useProject();

  const pageTitle = document.title.split(RANGE_STRING_SEPARATOR)[0];
  const url = window.location.href;

  const footerPages = useMemo(
    () => [
      {
        title: getText('reportAccessibility', 'contact'),
        path: `${getPath('reportAccessibility')}${getTextFx(
          'getAccessibilityParams',
          'contact'
        )({ pageTitle, url })}`,
        hide: !project?.features?.accessibility
      },
      {
        title: getText('privacy'),
        path: getPath('privacy')
      },
      {
        title: getText('imprint'),
        path: getPath('imprint')
      },
      {
        title: getText('terms'),
        path: getPath('terms')
      },
      {
        title: getText('accessibility'),
        path: getPath('accessibility'),
        hide: !project?.features?.accessibility
      },
      {
        title: getText('home'),
        path: getPath('home')
      },
      {
        title: getInfoText('patientInfoShort'),
        path: getPath('patientInfo')
      },
      {
        title: getInfoText('providerInfoShort'),
        path: getPath('providerInfo'),
        hide: isProjectNew
      },
      {
        title: getInfoText('doctorInfoShort'),
        path: getPath('doctorInfo')
      },
      {
        title: getText('offers'),
        path: getPath('offers'),
        hide: !(
          project?.offersVisibility !== Visibility.OnlyRegistered ||
          isAuthenticated
        )
      },
      {
        title: getText('allProviders'),
        path: getPath('providers'),
        hide: !(
          project?.providerOrganizationsVisibility !==
            Visibility.OnlyRegistered || isAuthenticated
        )
      },
      {
        title: getText('allSeekers'),
        path: getPath('seekers'),
        hide: !(
          project?.seekerOrganizationsRegistration !== Registration.None &&
          (project?.seekerOrganizationsVisibility !==
            Visibility.OnlyRegistered ||
            isAuthenticated)
        )
      },
      {
        title: getText('listTitle', 'compass'),
        path: getPath('compass'),
        hide: !(project?.features?.knowledgeBase ?? false)
      }
    ],
    [
      pageTitle,
      url,
      isProjectNew,
      project?.offersVisibility,
      project?.providerOrganizationsVisibility,
      project?.seekerOrganizationsRegistration,
      project?.seekerOrganizationsVisibility,
      project?.features?.knowledgeBase,
      project?.features?.accessibility,
      isAuthenticated
    ]
  );

  return (
    <FooterItem title={getFooterText('linksTitle')}>
      <List
        dense
        sx={{
          pt: 0,
          li: {
            p: 0
          },
          a: {
            color: 'white',
            width: 'fit-content',
            textDecoration: 'none'
          },
          'a:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
          }
        }}
      >
        <>
          {isAuthenticated ? (
            <ListItem key="logout">
              <Link onClick={logout} data-cy="logout-link">
                {getText('logout')}
              </Link>
            </ListItem>
          ) : (
            <ListItem key="login" data-cy="login-link">
              <Link onClick={login}>{getText('login')}</Link>
            </ListItem>
          )}
          {footerPages.map(
            (page: { title: string; path: string; hide: boolean }) => {
              if (page.hide == undefined || !page.hide) {
                return (
                  <ListItem key={page.title}>
                    <Link to={page.path} component={RouterLink}>
                      {page.title}
                    </Link>
                  </ListItem>
                );
              }
            }
          )}
          <ListItem key="support">
            <a href={'https://support.landkreise.digital/'} target="_self">
              {getFooterText('support')}
            </a>
          </ListItem>
        </>
      </List>
    </FooterItem>
  );
};

export default UsefulLinks;
