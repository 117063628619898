import { useMutation } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import { useArticlesApi } from './use-compass-api';

const useDeleteArticle = () => {
  const projectId = useGetProjectId();
  const { deleteArticle } = useArticlesApi();

  return useMutation((articleId: string) => {
    return deleteArticle({
      projectId: projectId ?? '',
      articleId
    });
  });
};

export default useDeleteArticle;
