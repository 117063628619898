import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { getPath, getText, getTextIn } from 'localization';
import {
  SchemaProvider,
  FormWrapper,
  ValidatedSwitch,
  ValidatedTextField
} from 'feature/forms';
import {
  type AnonymousMessage,
  type EntityModelUser
} from '@SLR/solution3-sdk';
import {
  AnonymousMessageSchema,
  EMPTY_ANONYMOUSMESSAGE,
  ExtendedAnonymousMessage,
  MAX_CONTENT_LENGTH,
  MAX_SUBJECT_LENGTH
} from './model';
import { Link } from 'react-router-dom';
import theme from 'theme/theme';
import LoadingButton from 'components/loading-button';

const getContactText = getTextIn('contact');

interface Props {
  currentUser?: EntityModelUser;
  isLoading?: boolean;
  subject?: string;
  content?: string;
  onConfirm: (anonymousMessage: AnonymousMessage) => void;
}

const ContactForm = ({
  currentUser,
  isLoading = false,
  subject = '',
  content = '',
  onConfirm
}: Props) => {
  return (
    <SchemaProvider<ExtendedAnonymousMessage>
      defaultValues={EMPTY_ANONYMOUSMESSAGE}
      values={{
        firstName: currentUser?.firstName ?? '',
        lastName: currentUser?.lastName ?? '',
        email: currentUser?.email ?? '',
        privacyPolicy: currentUser !== undefined,
        subject,
        content
      }}
      schema={AnonymousMessageSchema}
    >
      {({ handleSubmit }) => {
        return (
          <FormWrapper>
            <Stack spacing={1.5}>
              <Stack
                spacing={{ xs: 1.5, sm: 2 }}
                sx={{ width: '100%' }}
                direction={{ xs: 'column', sm: 'row' }}
              >
                <ValidatedTextField
                  name="firstName"
                  render={({ field, props }) => {
                    const { errorMessage, ...fieldProps } = props;
                    return (
                      <TextField
                        sx={{ width: 'inherit' }}
                        label={`${getContactText('firstName')}*`}
                        helperText={errorMessage ?? ' '}
                        inputProps={{
                          'data-cy': 'contact-form-first-name'
                        }}
                        {...field}
                        {...fieldProps}
                      />
                    );
                  }}
                />

                <ValidatedTextField
                  name="lastName"
                  render={({ field, props }) => {
                    const { errorMessage, ...fieldProps } = props;
                    return (
                      <TextField
                        sx={{ width: 'inherit' }}
                        label={`${getContactText('lastName')}*`}
                        helperText={errorMessage ?? ' '}
                        inputProps={{
                          'data-cy': 'contact-form-last-name'
                        }}
                        {...field}
                        {...fieldProps}
                      />
                    );
                  }}
                />
              </Stack>

              <ValidatedTextField
                name="email"
                render={({ field, props }) => {
                  const { errorMessage, ...fieldProps } = props;
                  return (
                    <TextField
                      label={`${getContactText('email')}*`}
                      helperText={errorMessage ?? ' '}
                      inputProps={{
                        'data-cy': 'contact-form-email'
                      }}
                      {...field}
                      {...fieldProps}
                    />
                  );
                }}
              />

              <ValidatedTextField
                name="subject"
                render={({ field, props }) => {
                  const { value } = field;
                  const { errorMessage, ...fieldProps } = props;
                  return (
                    <TextField
                      label={`${getContactText('subject')}*`}
                      helperText={
                        errorMessage ??
                        (value
                          ? `${
                              value?.toString().length
                            } / ${MAX_SUBJECT_LENGTH}`
                          : ' ')
                      }
                      inputProps={{
                        maxLength: MAX_SUBJECT_LENGTH,
                        'data-cy': 'contact-form-subject'
                      }}
                      {...field}
                      {...fieldProps}
                    />
                  );
                }}
              />

              <ValidatedTextField
                name="content"
                render={({ field, props }) => {
                  const { value } = field;
                  const { errorMessage, ...fieldProps } = props;
                  return (
                    <TextField
                      multiline
                      minRows={4}
                      label={`${getContactText('content')}*`}
                      helperText={
                        errorMessage ??
                        (value
                          ? `${
                              value?.toString().length
                            } / ${MAX_CONTENT_LENGTH}`
                          : ' ')
                      }
                      inputProps={{
                        maxLength: MAX_CONTENT_LENGTH,
                        'data-cy': 'contact-form-content'
                      }}
                      {...field}
                      {...fieldProps}
                    />
                  );
                }}
              />

              {!currentUser && (
                <ValidatedSwitch
                  checkedValue={false}
                  uncheckedValue={false}
                  name="privacyPolicy"
                  render={({ field, formState }) => {
                    const { value } = field;
                    const error = formState.submitCount > 0 && !value;
                    return (
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            data-cy="contact-form-privacy-policy-check"
                            checked={value}
                            {...field}
                          />
                        }
                        label={
                          <Typography
                            fontSize={'1rem !important'}
                            color={error ? 'error' : undefined}
                          >
                            {getContactText('acceptPrivacyPolicy')}{' '}
                            <Link
                              target="_blank"
                              to={'/' + getPath('privacy')}
                              style={{ color: theme.palette.primary.main }}
                            >
                              {getContactText('privacyPolicy')}
                            </Link>
                          </Typography>
                        }
                      />
                    );
                  }}
                />
              )}

              <FormControl>
                <FormHelperText>{getText('requiredFields')}</FormHelperText>
              </FormControl>
            </Stack>

            <LoadingButton
              sx={{ mt: 3, mb: 6 }}
              dataCy="contact-form-submit"
              label={getContactText('send')}
              disabled={isLoading}
              loading={isLoading}
              onClick={handleSubmit(onConfirm)}
              size="medium"
            />
          </FormWrapper>
        );
      }}
    </SchemaProvider>
  );
};

export default ContactForm;
