import { useMutation, useQueryClient } from '@tanstack/react-query';
import useProjectSectionsApi from './useProjectSectionsApi';
import {
  type ReplaceProjectSectionTextRequest,
  Section
} from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';

const useReplaceProjectSectionText = (section: Section) => {
  const queryClient = useQueryClient();
  const projectId = useGetProjectId();
  const { replaceProjectSectionText } = useProjectSectionsApi();

  return useMutation(
    (requestParameters: ReplaceProjectSectionTextRequest): Promise<void> => {
      return replaceProjectSectionText({
        ...requestParameters,
        text: new Blob([requestParameters.text], {
          type: 'text/html'
        }) as unknown as string
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'sectionText',
          {
            projectId,
            section
          }
        ]);
      }
    }
  );
};

export default useReplaceProjectSectionText;
