import { useNavigate } from 'react-router-dom';
import { notifyMutationSuccess } from 'feature/error';
import { useDeleteArticleCategory } from 'feature/hooks/compass';
import { getTextIn, getPath } from 'localization';

const getTextCompass = getTextIn('compass');

const useDeleteCategoryMutate = ({
  id,
  handleClose
}: {
  id: string;
  handleClose: VoidFunction;
}) => {
  const navigate = useNavigate();
  const deleteArticleCategory = useDeleteArticleCategory();

  const onDeleteConfirm = () => {
    if (id) {
      deleteArticleCategory.mutate(id, {
        onSuccess: () => {
          notifyMutationSuccess(getTextCompass('deleteCompassCategorySuccess'));
          handleClose();
          navigate(`/${getPath('compass')}`, {
            replace: true
          });
        },
        onError: () => {
          notifyMutationSuccess(getTextCompass('deleteCompassCategoryError'));
        }
      });
    }

    handleClose();
  };

  return { onDeleteConfirm };
};

export default useDeleteCategoryMutate;
