import useOrganizationApi from './useOrganizationsApi';
import { useQuery } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';

const useListOrganizations = (name: string) => {
  const { listOrganizations } = useOrganizationApi();
  const projectId = useGetProjectId() ?? '';

  return useQuery(
    ['organizationSearchHints', { projectId, name }],
    () => {
      return listOrganizations({
        projectId,
        query: name
      });
    },
    {
      staleTime: Infinity,
      enabled: !!projectId && name.length > 2
    }
  );
};

export default useListOrganizations;
