import { FormControlLabel, Radio, Stack } from '@mui/material';
import { InfoSymbol } from 'feature/forms';
import SubFormWrapper from './subform-wrapper';

type RadioSubFormProps = {
  name: string;
  value: string;
  checked: boolean;
  onChange: VoidFunction;
  title: string;
  info: string;
  prefixCy?: string;
  children?: React.ReactNode;
};

const RadioSubForm = ({
  name,
  value,
  checked = false,
  onChange,
  title,
  info,
  prefixCy = name,
  children
}: RadioSubFormProps) => {
  return (
    <>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Radio
              checked={checked}
              onChange={onChange}
              name={name}
              value={value}
              data-cy={`${prefixCy}-radio`}
            />
          }
          label={title}
        />
        <InfoSymbol info={info} />
      </Stack>

      {children && checked && <SubFormWrapper>{children}</SubFormWrapper>}
    </>
  );
};

export default RadioSubForm;
