import { type RequestCurrentUserDataRequest } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';

import { useMutation } from '@tanstack/react-query';
import useUsersApi from 'context/user/hooks/useUsersApi';

const useRequestUserData = () => {
  const projectId = useGetProjectId() ?? '';
  const { requestUserData } = useUsersApi();

  const requestParameters: RequestCurrentUserDataRequest = {
    projectId
  };

  return useMutation<void, Error, string, VoidFunction>(() =>
    requestUserData(requestParameters)
  );
};

export default useRequestUserData;
