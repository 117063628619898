import type { RadioOption } from 'components';
import { cloneObjectDeepJson } from 'utils/helper';

const GEO_AREA_STEP = 5;

const sortOptions: RadioOption = {
  key: 'sort-options',
  options: [
    {
      value: 'created,desc',
      label: 'Neueste zuerst'
    },
    {
      value: 'title',
      label: 'Alphabetisch (A-Z)'
    }
  ]
};

const additionalOptionForQuery = {
  value: '',
  label: 'Nach Relevanz'
};

const sortOptionsWithAdditionalQueryOption = (() => {
  const extendedSortOptions = cloneObjectDeepJson(sortOptions);
  extendedSortOptions.options.push(additionalOptionForQuery);
  return extendedSortOptions;
})();

export { sortOptions, sortOptionsWithAdditionalQueryOption, GEO_AREA_STEP };
