import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from 'components';
import { withoutBackdropClick } from 'utils/helper';
import type {
  FilterAndSortUnitParamsType,
  FilterParamsKeyType
} from 'utils/url-param';
import { getText } from 'localization';

type CombinedFilterParamsType =
  | FilterAndSortUnitParamsType
  | FilterParamsKeyType;

type FilterDialogMobileProps = {
  getOpen: (key: CombinedFilterParamsType) => boolean;
  setOpen: (key: CombinedFilterParamsType, value: boolean) => void;
  title: string;
  totalElements?: number;
  showElementCountButton?: boolean;
  filterParamName: CombinedFilterParamsType;
  onClose: VoidFunction;
  children: React.ReactElement;
};

const FilterDialogMobile = ({
  getOpen,
  setOpen,
  title,
  totalElements,
  showElementCountButton = false,
  filterParamName,
  onClose,
  children
}: FilterDialogMobileProps) => {
  const closeFilter = () => {
    onClose();
    setOpen(filterParamName, false);
  };

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      open={getOpen(filterParamName)}
      onClose={withoutBackdropClick(onClose)}
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: '70%' },
          height: '100%',
          position: 'absolute',
          right: 0
        }
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 1.75,
          py: 1,
          mt: 1.75,
          mb: 2.25
        }}
      >
        <IconButton
          aria-label="back"
          onClick={() => setOpen(filterParamName, false)}
          sx={{
            color: 'primary.main'
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <span>{title}</span>

        <IconButton
          aria-label="close"
          onClick={closeFilter}
          sx={{
            color: 'primary.main'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl
          sx={{
            m: '9px 18px',
            width: 'calc(100% - 48px)',
            height: 'calc(100% - 18px)'
          }}
          component="fieldset"
          variant="standard"
        >
          {children}
        </FormControl>
      </DialogContent>
      {showElementCountButton && (
        <DialogActions sx={{ justifyContent: 'center', mb: 3.75 }}>
          <LoadingButton
            dataCy="mobile-filter-apply"
            label={`${getText('apply')}${
              // eslint-disable-next-line no-negated-condition
              totalElements !== undefined ? ` (${totalElements ?? 0})` : ``
            } `}
            variant="contained"
            loading={totalElements === undefined}
            sx={{
              order: {
                xs: 3,
                sm: 3
              }
            }}
            onClick={closeFilter}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default FilterDialogMobile;
