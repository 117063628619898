import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';

interface Props {
  info: string;
}

const InfoSymbol = ({ info }: Props) => {
  return (
    <Tooltip title={info} enterTouchDelay={0} leaveTouchDelay={4500}>
      <InfoOutlinedIcon fontSize="small" color="primary" />
    </Tooltip>
  );
};

export default InfoSymbol;
