import { useQuery } from '@tanstack/react-query';
import { type GetOrganizationSettingsRequest } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import useOrganizationSettingsApi from './use-organization-settings-api';

type UseOrganizationSettings = Omit<
  GetOrganizationSettingsRequest,
  'projectId'
>;

const useOrganizationSettings = ({
  organizationId
}: UseOrganizationSettings) => {
  const { getOrganizationSettings } = useOrganizationSettingsApi();
  const projectId = useGetProjectId();

  return useQuery(
    ['organizationSettings', { projectId, organizationId }],
    () => {
      return getOrganizationSettings({
        projectId: projectId ?? '',
        organizationId
      });
    },

    {
      staleTime: Infinity,
      enabled: !!projectId && organizationId !== ''
    }
  );
};

export default useOrganizationSettings;
