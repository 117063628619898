import useOrganizationKeysApi from './useOrganizationKeysApi';
import { useGetProjectId } from 'context/project';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export type ReplaceOrganizationSpecificKeyType = {
  organizationId: string;
  keyId: string;
  key: string;
};

const useReplaceOrganizationSpecificKey = () => {
  const projectId = useGetProjectId();
  const queryClient = useQueryClient();
  const { replaceOrganizationSpecificKey } = useOrganizationKeysApi();

  return useMutation(
    (requestParameters: ReplaceOrganizationSpecificKeyType) => {
      return replaceOrganizationSpecificKey({
        projectId: projectId ?? '',
        ...requestParameters,
        key: new Blob([requestParameters.key], {
          type: 'text/plain'
        }) as unknown as string
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'organizationSpecificKey',
          {
            projectId
          }
        ]);
      }
    }
  );
};

export default useReplaceOrganizationSpecificKey;
