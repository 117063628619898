import { Typography } from '@mui/material';
import { ContentWrapper } from 'feature';
import { getText } from 'localization';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import useGetProjectTermsOfUseText from 'feature/hooks/legal/useGetProjectTermsOfUseText';
import LegalTextBox from './LegalTexts/LegalTextBox';
import { useSetDocumentTitle } from 'context/project';

const TermsPage = () => {
  const terms = getText('terms');
  useSetDocumentTitle(terms);

  const { data } = useGetProjectTermsOfUseText();

  return (
    <ContentWrapper>
      <BreadcrumbNavigation />
      <Typography variant="h1" textAlign="center">
        {terms}
      </Typography>
      <LegalTextBox legalHtml={data ?? ''} />
    </ContentWrapper>
  );
};

export default TermsPage;
