import { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useUser } from 'context/user';
import { DialogGeneric } from 'feature';
import { useRequestUserData } from 'feature/hooks';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import { getTextIn, getNode } from 'localization';

const getTextRequestData = getTextIn('account-data-requestData');

const RequestUserDataDialog = ({
  open,
  onClose,
  onConfirm
}: {
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}) => {
  const { currentUser } = useUser();
  const requestDataText = getTextRequestData('action');

  return (
    <DialogGeneric
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={requestDataText}
      confirmText={requestDataText}
      maxWidth="sm"
      prefix="request-user-data-dialog"
    >
      {getNode(
        'getDialogQuestion',
        'account-data-requestData'
      )({
        children: (
          <Typography variant="formLabel" color="primary">
            {currentUser?.email ?? ''}
          </Typography>
        )
      })}
    </DialogGeneric>
  );
};

const RequestUserData = () => {
  const [open, setOpen] = useState(false);
  const requestData = useRequestUserData();

  const handleConfirm = useCallback(() => {
    requestData.mutate('', {
      onSuccess: () => {
        notifyMutationSuccess(getTextRequestData('success'));
      },
      onError: () => {
        notifyMutationError(getTextRequestData('error'));
      }
    });

    setOpen(false);
  }, [requestData]);

  return (
    <>
      <Grid item>
        <Typography component="div" variant="sectionTitle" sx={{ mb: 0.75 }}>
          {getTextRequestData('requestCopy')}
        </Typography>

        <Typography>{getTextRequestData('info')}</Typography>

        <Button
          variant="outlined"
          sx={{ mt: 2.5 }}
          onClick={() => setOpen(true)}
        >
          {getTextRequestData('action')}
        </Button>
      </Grid>

      <RequestUserDataDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default RequestUserData;
