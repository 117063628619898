import { type GetProjectAccessibilityDeclarationTextRequest } from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import useLegalApi from './useLegalApi';
import { useGetProjectId } from 'context/project';

const useGetProjectPrivacyPolicy = () => {
  const projectId = useGetProjectId();
  const { getProjectAccessibilityDeclarationText } = useLegalApi();

  const requestParameters: GetProjectAccessibilityDeclarationTextRequest = {
    projectId: projectId ?? ''
  };
  return useQuery(
    ['accessibilityText', { projectId }],
    () => {
      return getProjectAccessibilityDeclarationText(requestParameters);
    },
    {
      staleTime: 259200000,
      enabled: !!projectId
    }
  );
};
export default useGetProjectPrivacyPolicy;
