import { Divider } from '@mui/material';

const MenuDivider = () => {
  return (
    <Divider
      component="li"
      sx={{ mx: 2.5, mt: '0 !important', mb: '0 !important' }}
    />
  );
};

export default MenuDivider;
