import ListItemText from '@mui/material/ListItemText';
import type { TypographyProps } from '@mui/material/Typography';

type ItemTextProps = {
  text: string;
  color?: string;
  fontSize?: TypographyProps['fontSize'];
  fontWeight?: TypographyProps['fontWeight'];
};

const ItemText = ({
  text,
  color = 'primary.main',
  fontSize = '1rem !important',
  fontWeight = 400
}: ItemTextProps) => {
  return (
    <ListItemText
      primaryTypographyProps={{
        sx: {
          color,
          fontSize,
          fontWeight,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }
      }}
    >
      {text}
    </ListItemText>
  );
};

export default ItemText;
