import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { TextField, Box, useTheme, IconButton } from '@mui/material';
import {
  Search as SearchIcon,
  CancelRounded as CancelRoundedIcon
} from '@mui/icons-material';
import { SearchParams, getTrimmedSearchTerm } from 'utils/url-param';
import { getText, getPath } from 'localization';

type SearchBarProps = {
  callback?: VoidFunction;
  height?: number;
  width?: number;
  morePadding?: boolean;
  placeholder?: string;
};

const SearchBar = ({
  callback,
  height = 42,
  width,
  morePadding = false,
  placeholder = getText('searchBarPlaceholder', 'offer')
}: SearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  const theme = useTheme();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const onSearch = () => {
    const trimmedSearchTerm = getTrimmedSearchTerm(searchTerm);

    if (trimmedSearchTerm) {
      navigate({
        pathname: getPath('offers'),
        search: createSearchParams({
          [SearchParams.query]: trimmedSearchTerm
        }).toString()
      });
    }

    clearSearchTerm();
    callback?.();
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearch();
    }

    if (event.key === 'Escape') {
      clearSearchTerm();
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        borderRadius: 1.75,
        bgcolor: 'common.white',
        '&:hover': {
          bgcolor: alpha(theme.palette.common.white, 0.95)
        },
        ml: 0,
        width: width ?? { xs: '100%', sm: 'auto' },
        height
      }}
    >
      <TextField
        value={searchTerm}
        variant="standard"
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        autoFocus
        sx={{
          width: '100%',
          '& .MuiInputBase-input': {
            p: 0,
            pl: morePadding ? 3 : 1,
            color: 'secondary.main',
            transition: theme.transitions.create('width'),
            width: '100%'
          }
        }}
        InputProps={{
          'aria-label': 'search',
          disableUnderline: true,
          sx: { height: '100%' },

          endAdornment: (
            <>
              {searchTerm && (
                <IconButton
                  aria-label="clear search input"
                  sx={{
                    p: '0',
                    height,
                    width: height,
                    minWidth: 'unset'
                  }}
                  onClick={clearSearchTerm}
                >
                  <CancelRoundedIcon sx={{ color: 'secondary.main' }} />
                </IconButton>
              )}
              <Box
                sx={{
                  verticalAlign: 'middle',
                  display: 'inline-flex',
                  borderRadius: '0 7px 7px 0',
                  borderLeft: '2px solid',
                  borderLeftColor: 'secondary.main',
                  bgcolor: 'secondary.main',
                  ml: 0.25,
                  height,
                  width: height,
                  justifyContent: 'center'
                }}
              >
                <IconButton
                  aria-label="search"
                  onClick={onSearch}
                  disabled={!searchTerm}
                >
                  <SearchIcon sx={{ color: 'white' }} />
                </IconButton>
              </Box>
            </>
          )
        }}
      />
    </Box>
  );
};
export default SearchBar;
