import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from 'components/loading-button';
import { getText } from 'localization';

type CtrlButtonsProps = {
  disabled: boolean;
  isLoading: boolean;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
};

const CtrlButtons = ({
  disabled,
  isLoading,
  onConfirm,
  onCancel
}: CtrlButtonsProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={{ xs: 2, sm: 7.25 }}
      sx={{
        my: 8,
        flexWrap: 'wrap'
      }}
    >
      <Button variant="text" onClick={onCancel}>
        {getText('cancel')}
      </Button>

      <LoadingButton
        dataCy="project-settings"
        label={getText('save')}
        loading={isLoading}
        disabled={disabled}
        onClick={onConfirm}
      />
    </Stack>
  );
};

export default CtrlButtons;
