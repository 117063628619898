import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { type ArticleCreate } from '@SLR/solution3-sdk';
import { DialogGeneric } from 'feature';
import { TITLE, CATEGORY_ID } from './model';
import { useDeleteArticleMutate } from './hooks';
import { getTextIn, getNode } from 'localization';

const getTextCompass = getTextIn('compass');

const ArticleDeleteButton = ({
  article,
  articleId = ''
}: {
  article: ArticleCreate;
  articleId?: string;
}) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const categoryId = article[CATEGORY_ID];
  const articleName = article[TITLE];

  const handleClose = () => setIsOpenDelete(false);

  const { onDeleteConfirm } = useDeleteArticleMutate({
    id: articleId,
    handleClose,
    categoryId
  });

  return (
    <Box
      sx={{
        textAlign: { xs: 'center', sm: 'unset' },
        mt: { xs: 1, sm: 'unset' }
      }}
    >
      <Button
        variant="outlined"
        size="small"
        startIcon={<DeleteIcon />}
        color="error"
        onClick={() => setIsOpenDelete(true)}
        sx={{ width: 'auto', alignSelf: 'end' }}
        data-cy="article-actions-delete"
      >
        {getTextCompass('deleteArticle')}
      </Button>

      <DialogGeneric
        open={isOpenDelete}
        title={getTextCompass('deleteCompassArticle')}
        confirmText={getTextCompass('deleteArticle')}
        onClose={handleClose}
        onConfirm={onDeleteConfirm}
        prefix="delete-article-dialog"
        confirmColor="error"
        maxWidth="sm"
      >
        {getNode('getNodeDeleteArticleQuestion', 'compass')({ articleName })}
      </DialogGeneric>
    </Box>
  );
};

export default ArticleDeleteButton;
