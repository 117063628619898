import {
  type EntityModelProject,
  type UpdateProjectRequest
} from '@SLR/solution3-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useProjectsApi from './useProjectsApi';

const useUpdateProject = () => {
  const queryClient = useQueryClient();
  const { updateProject } = useProjectsApi();

  return useMutation(
    (requestParameters: UpdateProjectRequest): Promise<EntityModelProject> => {
      return updateProject(requestParameters);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['currentProject']);
      }
    }
  );
};

export default useUpdateProject;
