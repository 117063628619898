import { Routes, Route } from 'react-router-dom';
import { CategoriesList } from 'pages/compass';
import CompassCategoryRoutes from './compass-category-routes';
import { Restricted } from './restricted-route';
import { useProject } from 'context/project';

const CompassRoutes = () => {
  const { project } = useProject();
  return (
    <Restricted
      isVisible={project?.features?.knowledgeBase ?? false}
      forceLogin={false}
    >
      <Routes>
        <Route index element={<CategoriesList />} />
        <Route path=":categoryId/*" element={<CompassCategoryRoutes />} />
      </Routes>
    </Restricted>
  );
};

export default CompassRoutes;
