import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getText } from 'localization';
import { ParagraphSkeleton } from 'components';

export type TextSectionPlaceholderProps = {
  loading?: boolean;
};

const TextSectionPlaceholder = ({
  loading = true
}: TextSectionPlaceholderProps) => {
  return (
    <Box>
      {!loading && (
        <Typography
          sx={{
            pb: 3
          }}
        >
          {getText('noTextYet', 'edit')}
        </Typography>
      )}
      <ParagraphSkeleton pulse={loading} lines={loading ? 8 : 6} />
    </Box>
  );
};

export default TextSectionPlaceholder;
