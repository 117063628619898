import { Registration } from '@SLR/solution3-sdk';
import { getTextIn } from 'localization';
import { useProject } from '..';

export type UserType = 'user' | 'organization';

const useGetAvailableRoles = (type: UserType) => {
  const { project } = useProject();
  const getRoleText = getTextIn('role');

  const options = [];

  const ROLE_RADIO_OPTION = {
    PROVIDER: { value: 'provider', label: getRoleText('provider') },
    SEEKER: { value: 'seeker', label: getRoleText('seeker') }
  };

  switch (type) {
    case 'user': {
      if (project?.seekerUsersRegistration !== Registration.None) {
        options.push(ROLE_RADIO_OPTION.SEEKER);
      }
      if (project?.providerUsersRegistration !== Registration.None) {
        options.push(ROLE_RADIO_OPTION.PROVIDER);
      }
      break;
    }
    case 'organization': {
      if (project?.seekerOrganizationsRegistration !== Registration.None) {
        options.push(ROLE_RADIO_OPTION.SEEKER);
      }
      if (project?.providerOrganizationsRegistration !== Registration.None) {
        options.push(ROLE_RADIO_OPTION.PROVIDER);
      }
      break;
    }
  }
  return {
    key: 'role',
    options
  };
};

export default useGetAvailableRoles;
