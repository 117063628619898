import { useKeycloakAuth } from 'auth';
import LoadingSpinner from 'components/loading-spinner';
import { useProject, useSetDocumentTitle } from 'context/project';
import { RequestFailed } from 'feature/error';
import { getErrorText } from 'localization';

type ErrorPageNotFoundProps = {
  documentTitleKey?: string;
  titleKey?: string;
  descriptionKey?: string;
};

const ErrorPageNotFound = ({
  documentTitleKey = 'oops',
  titleKey = 'pageNotFound',
  descriptionKey = 'pageNotFoundDescription'
}: ErrorPageNotFoundProps) => {
  useSetDocumentTitle(getErrorText(documentTitleKey));
  const { isLoading: isLoadingProject } = useProject();
  const { isReady } = useKeycloakAuth();

  return isLoadingProject || !isReady ? (
    <LoadingSpinner dataCy="error-page-not-found" />
  ) : (
    <RequestFailed
      title={getErrorText(titleKey)}
      description={getErrorText(descriptionKey)}
    />
  );
};

export default ErrorPageNotFound;
