import useProjectGalleryApi from './useProjectGalleryApi';
import { type GalleryEntriesUpdate } from '@SLR/solution3-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';

const useUpdateProjectGalleryEntries = () => {
  const queryClient = useQueryClient();
  const { updateProjectGalleryEntries } = useProjectGalleryApi();
  const projectId = useGetProjectId();

  return useMutation<void, Error, GalleryEntriesUpdate>(
    (galleryEntriesUpdate: GalleryEntriesUpdate) => {
      return updateProjectGalleryEntries({
        projectId: projectId ?? '',
        galleryEntriesUpdate
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sectionImage']);
      }
    }
  );
};

export default useUpdateProjectGalleryEntries;
