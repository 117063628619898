import { useState } from 'react';
import { Box } from '@mui/material';
import ImageGalleryDialog from 'feature/gallery/image-gallery-dialog';
import { type Image } from '@SLR/solution3-sdk';

export const HERO_HEIGHTS = {
  xs: '200px',
  sm: '250px',
  lg: '300px',
  xxl: '400px'
};

type HeroSectionProps = {
  image?: Image;
};

const HeroSection = ({ image }: HeroSectionProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleImageClick = () => {
    if (image) {
      setOpenDialog(true);
    }
  };

  return (
    <>
      <Box
        data-cy="hero-section-image"
        sx={{
          backgroundImage: `url(${image?.urls.large})`,
          backgroundColor: 'primary.background',
          backgroundBlendMode: 'unset',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          minHeight: HERO_HEIGHTS,
          cursor: image ? 'pointer' : 'default' // Change cursor based on image presence
        }}
        onClick={handleImageClick} // Only attach onClick handler if image is available
      ></Box>
      {image && (
        <ImageGalleryDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          image={image}
        />
      )}
    </>
  );
};

export default HeroSection;
