import ContactPage from './contact-page';
import { useUrlParams, SearchParams } from 'utils/url-param';
import { getText, getTextFx } from 'localization';

const AccessibilityPage = () => {
  const [search] = useUrlParams();
  const subject = search[SearchParams.subject];
  const content = getTextFx(
    'getAccessibilityBody',
    'contact'
  )({ url: search[SearchParams.body] });

  return (
    <ContactPage
      subject={subject}
      content={content}
      title={getText('reportAccessibility', 'contact')}
    />
  );
};

export default AccessibilityPage;
