import { Typography } from '@mui/material';
import { ParagraphSkeleton } from 'components';
import { Loadable } from 'types';
import useHtmlAsPlainText from './use-html-as-plain-text';

interface Props {
  html?: string;
}

const CardDescription = ({ html, loading }: Loadable<Props>) => {
  const plainText = useHtmlAsPlainText(html);
  return (
    <Typography
      variant="customCardDescription"
      sx={{ whiteSpace: 'pre-wrap', minHeight: 64 }}
    >
      {loading ? <ParagraphSkeleton lines={3} pulse /> : plainText}
    </Typography>
  );
};

export default CardDescription;
