import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { ContentWrapper, OfferGrid, Pagination } from 'feature';
import { useGetOffers, useGetOrganizationProfile } from 'feature/hooks';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';

import {
  useUrlParams,
  SearchParams,
  setSearchParamWithPageSetbackFor
} from 'utils/url-param';
import { getText, getTextIn } from 'localization';
import { useSetDocumentTitle } from 'context/project';

const SORT_BY = ['created,desc'];

const getOfferText = getTextIn('offer');

const ProviderOffersPage = () => {
  useSetDocumentTitle(getOfferText('offers'));
  const { providerId } = useParams();
  const profile = useGetOrganizationProfile(providerId);

  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [search, setSearch] = useUrlParams();

  const customItem = {
    2: {
      title: profile.data?.name ?? getText('profilePage')
    }
  };

  const setSearchParamWithPageSetback = setSearchParamWithPageSetbackFor(
    search,
    setSearch
  );

  const handlePageClick = (newPage: number) => {
    // remove page search param for page 0
    setSearchParamWithPageSetback(
      Boolean(newPage) ? { [SearchParams.page]: String(newPage) } : {},
      Boolean(newPage)
    );
  };

  const page = Number(search[SearchParams.page] ?? 0);

  const {
    data: offers,
    isLoading,
    isError
  } = useGetOffers({
    organization: providerId,
    page,
    size: rowsPerPage,
    sort: SORT_BY
  });

  const totalElements = offers?.page?.totalElements;
  const totalPages = offers?.page?.totalPages;

  // when exceeding the page number, make empty call with page setback
  useEffect(() => {
    if (totalPages && totalPages <= page) {
      setSearchParamWithPageSetback({});
    }
  }, [page, setSearchParamWithPageSetback, totalPages]);

  return (
    <ContentWrapper
      additionalSx={{ maxWidth: { lg: 1140 }, px: { lg: '0 !important' } }}
    >
      <BreadcrumbNavigation customItem={customItem} />
      <Typography variant="h1">
        {profile.data?.name
          ? `${profile.data?.name}s ${getOfferText('offers')}`
          : getOfferText('offers')}
      </Typography>

      <OfferGrid
        isError={isError}
        isLoading={isLoading}
        offers={offers?.embedded?.offers}
        totalElements={totalElements}
      />

      <Pagination
        count={totalElements}
        page={page}
        setPage={handlePageClick}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </ContentWrapper>
  );
};

export default ProviderOffersPage;
