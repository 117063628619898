import dayjs, { Dayjs } from 'dayjs';
import { RRule, type Options, Weekday } from 'rrule';
import { sunSatWeekdayToMonSun } from './date';
import { getTextIn } from 'localization';

enum Frequency {
  ONCE = 'once',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  EVERY_SECOND_WEEK = 'everySecondWeek',
  MONTHLY = 'monthly'
}

const getFrequency = (rule: RRule): Frequency => {
  switch (rule.options.freq) {
    case RRule.DAILY:
      return Frequency.DAILY;
    case RRule.WEEKLY:
      if (rule.options.interval === 2) {
        return Frequency.EVERY_SECOND_WEEK;
      } else {
        return Frequency.WEEKLY;
      }
    case RRule.MONTHLY:
      return Frequency.MONTHLY;
    default:
      return Frequency.ONCE;
  }
};

const getRecurrenceText = getTextIn('recurrence');

const getFrequencyText = (frequency: Frequency) => {
  return getRecurrenceText(frequency);
};

const getWeekInMonth = (dayOfMonth: number): number => {
  return Math.ceil(dayOfMonth / 7.0);
};

const createRRule = (
  frequency: Frequency,
  until: Dayjs | null,
  start: Date | null
): RRule | null => {
  const getRuleOptions = (): Partial<Options> | undefined => {
    switch (frequency) {
      case Frequency.DAILY:
        return { freq: RRule.DAILY };
      case Frequency.WEEKLY:
        return { freq: RRule.WEEKLY };
      case Frequency.EVERY_SECOND_WEEK:
        return { freq: RRule.WEEKLY, interval: 2 };
      case Frequency.MONTHLY: {
        if (start) {
          const s = dayjs(start);
          const weekInMonth = getWeekInMonth(s.date());

          return {
            freq: RRule.MONTHLY,
            byweekday: new Weekday(sunSatWeekdayToMonSun(s.day())),
            bysetpos: weekInMonth > 4 ? -1 : weekInMonth
          };
        } else {
          return { freq: RRule.MONTHLY };
        }
      }
    }
  };

  const options = getRuleOptions();

  if (options) {
    if (until) {
      const untilDate = until.toDate();
      untilDate.setHours(23, 59, 0, 0);
      options.until = untilDate;
    }
    return new RRule(options);
  }
  return null;
};

const createRRuleString = (
  frequency: Frequency,
  until: Dayjs | null,
  start: Date | null
): string | null => {
  const rule = createRRule(frequency, until, start);

  // remove possible RRULE: at the start
  return rule == null ? rule : rule.toString().split('RRULE:').join('');
};

export { Frequency, getFrequency, getFrequencyText, createRRuleString };
