import React from 'react';
import { Badge } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useBooking } from 'context/booking';

const BookingBadge = () => {
  const { offers } = useBooking();

  return (
    <Badge
      badgeContent={offers.length}
      color="secondary"
      sx={{
        mx: 'auto',
        '& .MuiBadge-badge': {
          height: 16,
          width: 16,
          right: -1,
          top: 8
        }
      }}
    >
      <AssignmentIcon sx={{ color: 'primary.contrastText' }} />
    </Badge>
  );
};

export default BookingBadge;
