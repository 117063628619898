import { Typography } from '@mui/material';
import { ContentWrapper } from 'feature';
import { getText } from 'localization';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import useGetProjectImprintText from 'feature/hooks/legal/useGetProjectImprintText';
import LegalTextBox from './LegalTexts/LegalTextBox';
import { useSetDocumentTitle } from 'context/project';

const ImprintPage = () => {
  const imprint = getText('imprint');
  useSetDocumentTitle(imprint);

  const { data } = useGetProjectImprintText();

  return (
    <ContentWrapper>
      <BreadcrumbNavigation />
      <Typography variant="h1" textAlign="center">
        {imprint}
      </Typography>
      <LegalTextBox legalHtml={data ?? ''} />
    </ContentWrapper>
  );
};

export default ImprintPage;
