import { type EntityModelArticleCategory } from '@SLR/solution3-sdk';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import {
  useSchemaValidationFormContext,
  ValidatedTextField
} from 'feature/forms';
import { useArticleCategories } from 'feature/hooks/compass';
import { Article, CATEGORY_ID } from './model';
import { findCategory } from './utils';
import { getTextIn, required } from 'localization';

const getTextCompass = getTextIn('compass');

const ArticleFormCategoryField = ({ categoryId }: { categoryId: string }) => {
  const { articleCategories = [] } = useArticleCategories();
  const { setValueValidating } = useSchemaValidationFormContext<Article>();

  return (
    <ValidatedTextField
      name={CATEGORY_ID}
      render={({ field, props }) => {
        const { errorMessage, error } = props;
        const defaultValue = findCategory(articleCategories, categoryId);

        return (
          <FormControl error={error}>
            {defaultValue && (
              <Autocomplete
                disableClearable
                getOptionLabel={(option) => option?.name ?? ''}
                defaultValue={defaultValue}
                value={findCategory(articleCategories, field.value)}
                onChange={(_, newValue: EntityModelArticleCategory) => {
                  if (newValue) {
                    setValueValidating(CATEGORY_ID, newValue.id);
                  }
                }}
                options={articleCategories}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${required(getTextCompass('category'))}`}
                  />
                )}
              />
            )}
            <FormHelperText sx={{ m: 0 }}>{errorMessage}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default ArticleFormCategoryField;
