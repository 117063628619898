import { useCallback } from 'react';
import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue
} from 'react-hook-form';
import { useSchemaValidationFormContext } from './form-provider';
import dayjs, { Dayjs } from 'dayjs';
import {
  DatePicker,
  DateTimePicker,
  type DatePickerProps,
  type DateTimePickerProps
} from '@mui/x-date-pickers';

import { type StandardTextFieldProps } from '@mui/material';

interface RenderProps {
  props: {
    value: Dayjs | null;
    onChange: (value: Dayjs) => void;
    error: boolean;
    errorMessage?: string;
  };
}

interface Props<T extends FieldValues = FieldValues> {
  name: Path<T>;
  render: (props: RenderProps) => React.ReactElement;
}

/**
 * Since the `DatePicker` uses `Dayjs` and the `yup` scheme together with react-hooks-form `Date` for the date value,
 * the value is converted back and forth here in both directions.
 *
 * Note: The component can only be used within an {@link SchemaValidationFormProvider}.
 */
const ValidatedDatePicker = <T extends FieldValues = FieldValues>({
  name,
  render
}: Props<T>) => {
  const { control, setValueValidating } = useSchemaValidationFormContext<T>();

  const handleOnChange = useCallback(
    (value: Dayjs): void => {
      setValueValidating(
        name,
        (value ? value.toDate() : null) as PathValue<T, Path<T>>
      );
    },
    [name, setValueValidating]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const props = {
          value: dayjs(field.value),
          onChange: handleOnChange,
          error: !!fieldState.error,
          errorMessage: fieldState.error?.message
        };
        return render({ props });
      }}
    />
  );
};

const DefaultDateTimePicker = ({
  props,
  slotProps,
  ...pickerProps
}: DateTimePickerProps<Dayjs> & RenderProps) => {
  const { error, errorMessage, ...controllerProps } = props;
  const helperText =
    errorMessage ??
    (slotProps?.textField as StandardTextFieldProps)?.helperText;
  return (
    <DateTimePicker
      slotProps={{
        ...slotProps,
        textField: { ...slotProps?.textField, error, helperText }
      }}
      {...controllerProps}
      {...pickerProps}
    />
  );
};

const DefaultDatePicker = ({
  props,
  slotProps,
  ...pickerProps
}: DatePickerProps<Dayjs> & RenderProps) => {
  const { error, errorMessage, ...controllerProps } = props;
  const helperText =
    errorMessage ??
    (slotProps?.textField as StandardTextFieldProps)?.helperText;
  return (
    <DatePicker
      slotProps={{
        ...slotProps,
        textField: { ...slotProps?.textField, error, helperText }
      }}
      {...controllerProps}
      {...pickerProps}
    />
  );
};

export default ValidatedDatePicker;
export { DefaultDateTimePicker, DefaultDatePicker };
