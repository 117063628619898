import { type GetProjectPrivacyPolicyTextRequest } from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import useLegalApi from './useLegalApi';
import { useGetProjectId } from 'context/project';

const useGetProjectPrivacyPolicy = () => {
  const projectId = useGetProjectId();
  const { getProjectPrivacyPolicyText } = useLegalApi();

  const requestParameters: GetProjectPrivacyPolicyTextRequest = {
    projectId: projectId ?? ''
  };
  return useQuery(
    ['privacyPolicyText', { projectId }],
    () => {
      return getProjectPrivacyPolicyText(requestParameters);
    },
    {
      staleTime: 259200000,
      enabled: !!projectId
    }
  );
};
export default useGetProjectPrivacyPolicy;
