import { getTextIn } from 'localization';
import { isEmptyOrNull } from './helper';

export type Role = 'seeker' | 'provider' | '';

const booleanToRole = (seeker?: boolean, provider?: boolean): Role => {
  if (seeker) {
    return 'seeker';
  } else if (provider) {
    return 'provider';
  } else {
    return '';
  }
};

const getRoleText = getTextIn('role');

const booleanRoleToString = (seeker?: boolean, provider?: boolean): string => {
  if (seeker && provider) {
    return `${getRoleText('provider')}, ${getRoleText('seeker')}`;
  } else if (seeker) {
    return getRoleText('seeker');
  } else if (provider) {
    return getRoleText('provider');
  } else {
    return '';
  }
};

const roleToBoolean = (role?: Role): { seeker: boolean; provider: boolean } => {
  if (isEmptyOrNull(role)) {
    return { seeker: false, provider: false };
  } else if (role === 'seeker') {
    return { seeker: true, provider: false };
  } else if (role === 'provider') {
    return { seeker: false, provider: true };
  } else {
    return { seeker: true, provider: true };
  }
};

export { booleanToRole, booleanRoleToString, roleToBoolean };
