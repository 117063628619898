import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import type { SxProps, Theme } from '@mui/material';
import ItemText from 'feature/item-text';

type MenuItemLinkProps = {
  itemText: string;
  linkTo: string;
  padding?: number | string;
  sx?: SxProps<Theme>;
  icon?: JSX.Element;
};

const MenuItemLink = ({ itemText, linkTo, sx, icon }: MenuItemLinkProps) => {
  return (
    <MenuItem sx={{ px: 2.5, py: 1.25, height: 44, ...sx }}>
      {icon}
      <Link
        data-cy={`menuitemlink-${itemText}`}
        component={RouterLink}
        to={linkTo}
        sx={{
          textDecoration: 'none',
          width: '100%',
          height: '100%'
        }}
      >
        <ItemText text={itemText} />
      </Link>
    </MenuItem>
  );
};

export default MenuItemLink;
