import { useOnboarding } from 'context/onboarding';

const DONE_FLAG = -1;

enum BitMaskOnboarding {
  profile = 0b001, // 2^0
  key = 0b010, // 2^1
  offer = 0b100 // 2^2
}

type BitMaskOnboardingType = keyof typeof BitMaskOnboarding;

const addFlagToStatus = (status: number, flag: number) => {
  return status | flag;
};

const hasFlagForStatus = (status: number, flag: number) => {
  return (status & flag) === flag;
};

const checkAllFlagsSet = (
  status: number,
  excludeFlag?: BitMaskOnboardingType
) => {
  return Object.keys(BitMaskOnboarding)
    .filter((value) => {
      return isNaN(Number(value)) && (!excludeFlag || value !== excludeFlag);
    })
    .every((value) => {
      return hasFlagForStatus(
        status,
        BitMaskOnboarding[value as keyof typeof BitMaskOnboarding]
      );
    });
};

const useOnboardingLogic = () => {
  const {
    onboardingState,
    changeOnboardingState,
    setSuccessMode,
    type,
    setType
  } = useOnboarding();

  const getAllStepsDone = (excludeFlag?: BitMaskOnboardingType) =>
    checkAllFlagsSet(onboardingState, excludeFlag);

  const onboardingDone = onboardingState === DONE_FLAG;

  const hasFlag = (bitMaskType: BitMaskOnboardingType) => {
    return hasFlagForStatus(onboardingState, BitMaskOnboarding[bitMaskType]);
  };

  type AddFlagByNameOptions = {
    delay?: number | null;
    noModeChange?: boolean;
  };

  // delay null to keep the call in the stack
  const addFlag = (
    bitMaskType: BitMaskOnboardingType,
    { delay, noModeChange = false }: AddFlagByNameOptions = {}
  ) => {
    if (!hasFlag(bitMaskType)) {
      // set the dialog type, if it has not been set by a click from dialog/panel before
      if (type !== bitMaskType) {
        setType(bitMaskType);
      }

      if (!noModeChange) {
        setSuccessMode(true);
      }

      const changeStateWithNewFlag = () =>
        changeOnboardingState(
          addFlagToStatus(onboardingState, BitMaskOnboarding[bitMaskType])
        );

      if (delay === null) {
        changeStateWithNewFlag();
      } else {
        setTimeout(changeStateWithNewFlag, delay ?? 1000);
      }
    }
  };

  return {
    getAllStepsDone,
    onboardingDone,
    addFlag,
    hasFlag
  };
};

const useOnboardingDone = (name: BitMaskOnboardingType) => {
  const { hasFlag } = useOnboardingLogic();
  return hasFlag(name);
};

export { useOnboardingLogic, useOnboardingDone, DONE_FLAG };

export type { BitMaskOnboardingType };
