import React, { PropsWithChildren } from 'react';
import { type FieldValues } from 'react-hook-form';
import { type UseSchemaValidationFormReturn } from './use-form';

const HookSchemaValidationFormContext =
  React.createContext<UseSchemaValidationFormReturn | null>(null);

export const useSchemaValidationFormContext = <
  TFieldValues extends FieldValues,
  TransformedValues extends FieldValues | undefined = undefined
>(): UseSchemaValidationFormReturn<TFieldValues> =>
  React.useContext(
    HookSchemaValidationFormContext
  ) as UseSchemaValidationFormReturn<TFieldValues, TransformedValues>;

type Props<
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any
> = UseSchemaValidationFormReturn<TFieldValues, TContext>;

const SchemaValidationFormProvider = <
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any
>({
  children,
  ...data
}: PropsWithChildren<Props<TFieldValues, TContext>>) => {
  return (
    <HookSchemaValidationFormContext.Provider
      value={data as UseSchemaValidationFormReturn}
    >
      {children}
    </HookSchemaValidationFormContext.Provider>
  );
};

export default SchemaValidationFormProvider;
