import { Loadable } from 'types';
import GenericParticipantCard, { toAvatar } from './generic-participant-card';
import { type EntityModelParticipantInfo } from '@SLR/solution3-sdk';
import { getProviderProfileUrl, getSeekerProfileUrl } from 'routes';
import { Role } from 'utils/roles';

interface Props {
  participantInfo: EntityModelParticipantInfo;
  role: Role;
}

const ParticipantCard = ({
  participantInfo,
  role,
  loading = false
}: Loadable<Props>) => {
  const title = participantInfo?.name ?? '';
  const avatarProps = toAvatar(participantInfo?.picture);
  const description = participantInfo?.description ?? '';
  const url =
    role === 'provider'
      ? getProviderProfileUrl(participantInfo?.participantId)
      : role === 'seeker'
      ? getSeekerProfileUrl(participantInfo?.participantId)
      : '';

  return (
    <GenericParticipantCard
      title={title}
      avatar={avatarProps}
      description={description}
      address=""
      url={url}
      loading={loading}
      isOrganization={!participantInfo?.user}
    />
  );
};

export default ParticipantCard;
