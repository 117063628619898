import { useMemo } from 'react';
import getConfigRouteLinks, { type RouteLinks } from './config';

const useRouteLinks = (routeLinksName: keyof RouteLinks) => {
  const links = useMemo(
    () =>
      getConfigRouteLinks()[routeLinksName as keyof typeof getConfigRouteLinks],
    [routeLinksName]
  );

  return Array.isArray(links) ? links : [];
};

export default useRouteLinks;
