import { type EntityModelArticle } from '@SLR/solution3-sdk';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArticleCard from './article-card';
import { getText } from 'localization';

const ArticleGrid = ({
  articles,
  isLoading
}: {
  articles?: EntityModelArticle[];
  isLoading: boolean;
}) => {
  const previewNumber = 6;

  if (!isLoading && articles === undefined) {
    return <Typography>{getText('noArticlesYet', 'compass')}</Typography>;
  }

  return (
    <Grid
      container
      spacing={2}
      sx={[
        {
          mb: 2
        }
      ]}
    >
      {(articles ?? Array(previewNumber).fill(null))?.map(
        (article: EntityModelArticle, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              xl={3}
              key={index}
              sx={{ justifyContent: 'center', display: 'flex' }}
            >
              <ArticleCard
                key={index}
                title={article?.title}
                content={article?.content}
                loading={isLoading}
                url={article?.id}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default ArticleGrid;
