export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL as string;
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL as string;
export const POST_LOGIN_REDIRECT_URL = process.env
  .REACT_APP_POST_LOGIN_REDIRECT_URL as string;
export const NODE_ENV = process.env.NODE_ENV as string;

export const KEYCLOAK_BASE_URL = process.env
  .REACT_APP_KEYCLOAK_BASE_URL as string;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM as string;
export const KEYCLOAK_CLIENT_ID = process.env
  .REACT_APP_KEYCLOAK_CLIENT_ID as string;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

export const USERPORTAL_URL = process.env.REACT_APP_USERPORTAL_URL as string;

export const DIGITALERLANDKREIS_URL = process.env
  .REACT_APP_DIGITALERLANDKREIS_URL as string;

export const IS_DEVELOPMENT: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
