const replacerTable: Record<string, string> = {
  '\u00df': 'ss',
  '\u00e4': 'ae',
  '\u00f6': 'oe',
  '\u00fc': 'ue',
  '\u00c4': 'Ae',
  '\u00d6': 'Oe',
  '\u00dc': 'Ue'
};

/**
 * Escape umlauts in the given text
 * @param text Text (optionally including umlauts) to escape
 * @returns The escaped text
 */
export function escapeUmlauts(text: string) {
  return text
    .split('')
    .map((char) => replacerTable[char] ?? char)
    .join('');
}

const fragmentSpecialCharactersRegex = /[^a-zA-Z0-9\s\-#_]/g;
const wordDelimiterRegex = /[\s\-#_]+/g;

export function transformToFragment(text: string) {
  return escapeUmlauts(text)
    .replace(fragmentSpecialCharactersRegex, '')
    .trim()
    .replace(wordDelimiterRegex, '-')
    .toLowerCase();
}
