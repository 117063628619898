import { useState, useReducer, Reducer } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  type EntityModelProfileGalleryEntry,
  type ProfileGalleryEntriesUpdate,
  type EntityModelGalleryEntry
} from '@SLR/solution3-sdk';
import { produce } from 'immer';
import { useSnackbar } from 'notistack';
import { GalleryGrid } from 'feature';
import { useUpdateProfileGalleryEntries } from 'feature/gallery';
import {
  GalleryEditDialog,
  ADD,
  REMOVE,
  ALT,
  DESC,
  CLEAR,
  type Action
} from './gallery';
import { isEmptyObject } from 'utils/helper';
import { PartiallyOptional } from 'types';
import { getTextIn } from 'localization';

type EntityModelGalleryEntryUpload = PartiallyOptional<
  EntityModelGalleryEntry,
  'created'
>;

const getProfileText = getTextIn('profile');
const getEditText = getTextIn('edit');

const updateText = ({
  state,
  id,
  imageId,
  text,
  entry,
  isDescription
}: {
  state: ProfileGalleryEntriesUpdate;
  id: string;
  imageId?: string;
  text?: string;
  entry?: EntityModelGalleryEntryUpload;
  isDescription?: boolean;
}) => {
  let newState = state;

  if (text !== undefined) {
    let hasCreateUpdated = false;

    if (state.create) {
      newState = produce(state, (draft: ProfileGalleryEntriesUpdate) => {
        hasCreateUpdated =
          draft.create?.reduce((acc, elem) => {
            if (elem.image.imageId === imageId) {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              isDescription
                ? (elem.description = text)
                : (elem.image.alternativeText = text);

              // eslint-disable-next-line no-param-reassign
              acc = true;
            }
            return acc;
          }, false) ?? false;
      });
    }

    if (!hasCreateUpdated) {
      newState = produce(state, (draft: ProfileGalleryEntriesUpdate) => {
        const replace = draft.replace ?? (draft.replace = {});
        const replaceEntry = replace[id] ?? (replace[id] = { image: {} });

        if (isDescription) {
          replaceEntry.description = text;
        } else {
          replaceEntry.image.alternativeText = text;

          // resend value for description as required by the PATCH request
          replaceEntry.description = entry?.description;
        }
      });
    }
  }

  return newState;
};

const uploadReducer = (state: ProfileGalleryEntriesUpdate, action: Action) => {
  switch (action.type) {
    case ADD:
      const create = state.create ? [...state.create] : [];

      if (action.imageId) {
        create.push({
          image: {
            imageId: action.imageId,
            alternativeText: ''
          }
        });
      }

      return { ...state, create };

    case REMOVE: {
      const createIndex =
        state.create?.reduce((acc, entry, index) => {
          if (entry.image.imageId === action.imageId) {
            return index;
          }
          return acc;
        }, -1) ?? -1;

      // is a new created img
      if (createIndex > -1 && state.create) {
        const newCreate = [...state.create];
        newCreate.splice(createIndex, 1);
        return { ...state, create: newCreate };
      }

      // is an existing img
      const remove = state._delete ? [...state._delete] : [];
      remove.push(action.id);

      return { ...state, _delete: remove };
    }

    case ALT:
      return updateText({
        state,
        id: action.id,
        imageId: action.imageId,
        entry: action.entry,
        text: action.alt
      });

    case DESC:
      return updateText({
        state,
        id: action.id,
        imageId: action.imageId,
        text: action.desc,
        isDescription: true
      });

    case CLEAR:
      return {};

    default:
      throw new Error();
  }
};

const ProfileGallery = ({
  galleryEntries,
  isOwner = false,
  isSmUp
}: {
  galleryEntries: EntityModelProfileGalleryEntry[];
  isOwner: boolean;
  isSmUp: boolean;
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [activeImageId, setActiveImageId] = useState<string>('');

  const [state, dispatchAction] = useReducer<
    Reducer<ProfileGalleryEntriesUpdate, Action>
  >(uploadReducer, {});

  const updateProfileGalleryEntries = useUpdateProfileGalleryEntries();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenDialog(false);
    dispatchAction({ type: CLEAR, id: '' });
    setActiveImageId('');
  };

  const handleConfirm = () => {
    if (!isEmptyObject(state)) {
      updateProfileGalleryEntries.mutate(state, {
        onSuccess: () => {
          enqueueSnackbar(getEditText('editSuccess'), {
            variant: 'success'
          });
        },
        onError: () => {
          enqueueSnackbar(getProfileText('galleryUpdateError'), {
            variant: 'error',
            persist: true
          });
        }
      });
    }

    setActiveImageId('');
    handleClose();
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <GalleryGrid
          galleryEntries={galleryEntries}
          showMoreButton={!isOwner}
          isSmUp={isSmUp}
        />

        {isOwner && (
          <>
            <Button
              variant="outlined"
              size="small"
              sx={{
                alignSelf: { xs: 'center', sm: 'end' },
                mt: { xs: 0, sm: 2 }
              }}
              startIcon={<ModeEditIcon />}
              data-cy="profileform-edit-imagegallery"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              {getProfileText('editImageGallery')}
            </Button>

            <GalleryEditDialog
              openDialog={openDialog}
              handleClose={handleClose}
              handleConfirm={handleConfirm}
              galleryEntries={galleryEntries}
              state={state}
              dispatchAction={dispatchAction}
              activeImageId={activeImageId}
              setActiveImageId={setActiveImageId}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default ProfileGallery;
