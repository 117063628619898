import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';
import {
  OrganizationProfileGalleryApi,
  type PagedModelEntityModelGalleryEntry,
  type ListProfileGalleryEntriesRequest,
  type UpdateProfileGalleryEntriesRequest
} from '@SLR/solution3-sdk';

const useProfileGalleryApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(
      APINames.organizationProfileGallery
    ) as OrganizationProfileGalleryApi;
  }, []);

  const listProfileGalleryEntries = useCallback(
    async (requestParameters: ListProfileGalleryEntriesRequest) => {
      try {
        return await apiInstance.listProfileGalleryEntries(requestParameters);
      } catch {
        return {} as PagedModelEntityModelGalleryEntry;
      }
    },
    [apiInstance]
  );

  const updateProfileGalleryEntries = useCallback(
    async (requestParameters: UpdateProfileGalleryEntriesRequest) => {
      try {
        return await apiInstance.updateProfileGalleryEntries(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    listProfileGalleryEntries,
    updateProfileGalleryEntries
  };
};

export default useProfileGalleryApi;
