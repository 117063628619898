import { useEffect, useState } from 'react';
import { Avatar, Box } from '@mui/material';
import type { EntityModelParticipantInfo } from '@SLR/solution3-sdk';
import {
  DataGrid,
  GridActionsCellItem,
  type GridSortModel,
  type GridColDef
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getText, getTextIn } from 'localization';
import { useListParticipantsInfo } from 'feature/hooks';
import EditParticipantForm from './edit-participant-form';
import DeleteParticipantDialog from './delete-participant-dialog';
import { formatAsDate } from 'utils/date';
import { booleanRoleToString } from 'utils/roles';
import { AvatarFallback } from 'feature/user-avatar';
import { Link as RouterLink } from 'react-router-dom';
import { getProviderProfileUrl, getSeekerProfileUrl } from 'routes';

const getParticipantsText = getTextIn('settings-participants');

type NameWithAvatarProps = {
  avatarSrc: string;
  name: string;
  id: string;
  isSeeker: boolean;
  isOrganization: boolean;
};

const NameWithAvatar = ({
  avatarSrc,
  name,
  id,
  isSeeker,
  isOrganization
}: NameWithAvatarProps) => {
  return (
    <Box
      component={RouterLink}
      to={isSeeker ? getSeekerProfileUrl(id) : getProviderProfileUrl(id)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        overflow: 'hidden',
        color: 'inherit',
        textDecoration: 'none',
        cursor: 'pointer'
      }}
    >
      <Avatar
        alt={`${getText('profilePicOfAlt')} ${name}`}
        src={avatarSrc}
        sx={{ width: 24, height: 24 }}
      >
        <AvatarFallback isOrganization={isOrganization} />
      </Avatar>
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {name}
      </span>
    </Box>
  );
};

const ParticipantsTable = () => {
  const [querySortModel, setQuerySortModel] = useState<GridSortModel>();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const participantsRequest = useListParticipantsInfo({
    page: paginationModel.page,
    size: paginationModel.pageSize,
    sort: querySortModel?.map(
      (sortQuery) => `${sortQuery.field},${sortQuery.sort}`
    )
  });

  const [editParticipant, setEditParticipant] =
    useState<EntityModelParticipantInfo>();
  const [deleteParticipant, setDeleteParticipant] =
    useState<EntityModelParticipantInfo>();

  const participantsTotal = participantsRequest.data?.page?.totalElements;
  const participants =
    participantsRequest?.data?.embedded?.participantInfos ?? [];

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(participantsTotal || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      participantsTotal === undefined ? prevRowCountState : participantsTotal
    );
  }, [participantsTotal, setRowCountState]);

  const detailColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: getParticipantsText('name'),
      width: 220,
      disableColumnMenu: true,
      renderCell: (params) => (
        <NameWithAvatar
          avatarSrc={params.row.picture?.urls?.small ?? ''}
          name={params.value}
          id={params.row.participantId}
          isSeeker={params.row.seeker}
          isOrganization={!params.row.user}
        />
      )
    },
    {
      field: 'user',
      headerName: getParticipantsText('type'),
      width: 130,
      disableColumnMenu: true,
      sortable: false, // does not yield sorted response yet
      valueGetter: (params) => (params.value ? 'Nutzer' : 'Organisation')
    },
    {
      field: 'provider',
      headerName: getParticipantsText('roles'),
      width: 260,
      disableColumnMenu: true,
      sortable: false, // does not yield sorted response yet
      valueGetter: (params) =>
        booleanRoleToString(params.row.seeker, params.row.provider)
    },
    {
      field: 'created',
      headerName: getParticipantsText('created'),
      width: 110,
      disableColumnMenu: true,
      valueGetter: (params) => formatAsDate(new Date(params.value))
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: getParticipantsText('actions'),
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const participantSelected = participants?.filter(
          (p) => p.participantId === id
        )[0];
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={getText('edit', 'edit')}
            className="textPrimary"
            onClick={() => setEditParticipant(participantSelected)}
            color="inherit"
            disabled={participantSelected.user}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={getText('delete')}
            onClick={() => setDeleteParticipant(participantSelected)}
            color="inherit"
          />
        ];
      }
    }
  ];

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={participants}
          columns={detailColumns}
          getRowId={(row: EntityModelParticipantInfo) => row.participantId}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              color: 'primary.main'
            },
            '.MuiDataGrid-virtualScroller': {
              minHeight: 100
            }
          }}
          rowCount={rowCountState}
          loading={participantsRequest.isLoading}
          pageSizeOptions={[10, 25, 50]}
          paginationModel={paginationModel}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          onSortModelChange={setQuerySortModel}
        />
      </Box>

      <EditParticipantForm
        open={!!editParticipant}
        onClose={() => {
          setEditParticipant(undefined);
        }}
        participantSelected={editParticipant}
      />
      <DeleteParticipantDialog
        open={!!deleteParticipant}
        onClose={() => setDeleteParticipant(undefined)}
        participantSelected={deleteParticipant}
      />
    </>
  );
};

export default ParticipantsTable;
