import TimeFormBlock from './time/time-form-block';
import { EMPTY_DATE_FILTER } from './time/model';
import {
  SearchParams,
  type UrlParams,
  type OnClickDate,
  type OnRemoveFilter
} from 'utils/url-param';

type DateFiltersProps = {
  search: UrlParams;
  onClick: OnClickDate;
  onCancel: OnRemoveFilter;
};

const DateFilters = ({ search, onClick, onCancel }: DateFiltersProps) => {
  const timeRange = search[SearchParams.timeRange];
  const time = search[SearchParams.time];

  const [fromRange, toRange] = timeRange ?? [];

  const onRemoveEveryFilter = () => {
    if (timeRange) {
      onCancel(SearchParams.timeRange, '')();
    }

    if (time) {
      onCancel(SearchParams.time, '')();
    }
  };

  const dateFilter = { ...EMPTY_DATE_FILTER };

  if (fromRange) {
    dateFilter.rangeFrom = new Date(fromRange);
  }

  if (toRange) {
    dateFilter.rangeTo = new Date(toRange);
  }

  if (time) {
    dateFilter.time = new Date(time ?? '');
  }

  return (
    <TimeFormBlock
      defaultFilter={EMPTY_DATE_FILTER}
      dateFilter={dateFilter}
      onConfirm={onClick}
      onCancel={onRemoveEveryFilter}
    />
  );
};

export default DateFilters;
