import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetDocumentTitle } from 'context/project';
import { useCreateInvitationApproval, useGetInvitation } from 'feature/hooks';
import { getPath, getTextIn } from 'localization';
import { ContentWrapper } from 'feature';
import LoadingSpinner from 'components/loading-spinner';
import { RequestFailed, notifyMutationSuccess } from 'feature/error';
import { unwrapErrorResponse } from 'feature/error/request-error-fallback';

const getInvitationText = getTextIn('invitation');

const InvitationApprovePage: FC = () => {
  const navigate = useNavigate();
  const { invitationId } = useParams();
  const {
    data: invitation,
    isLoading,
    error: fetchInvitationError
  } = useGetInvitation(invitationId);
  const approveInvitation = useCreateInvitationApproval();

  useSetDocumentTitle(getInvitationText('approveTitle'));

  /**
   * approve invitation
   */
  useEffect(() => {
    if (invitation) {
      approveInvitation.mutate(invitation.id, {
        onSuccess: () => {
          navigate(
            getPath('getSettingSubpagePath', { subpage: 'participants' }),
            {
              replace: true
            }
          );
          notifyMutationSuccess(getInvitationText('approveSuccess'));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation]);

  return (
    <ContentWrapper>
      {isLoading || approveInvitation.isLoading ? (
        <LoadingSpinner dataCy="invitationApprovePage" />
      ) : (
        (approveInvitation.error || fetchInvitationError) && (
          <RequestFailed
            title={getInvitationText('approveFail')}
            response={
              approveInvitation.error
                ? unwrapErrorResponse(approveInvitation.error)
                : fetchInvitationError
                ? unwrapErrorResponse(fetchInvitationError)
                : undefined
            }
          />
        )
      )}
    </ContentWrapper>
  );
};

export default InvitationApprovePage;
