/* eslint-disable no-negated-condition */
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { DialogGeneric } from 'feature';
import { getText, getTextIn } from 'localization';
import { type EntityModelEvent } from '@SLR/solution3-sdk';
import Address from 'components/address';
import {
  formatAsDateTime,
  formatAsDateTimeExtended,
  formatAsTime,
  getFormattedRangeString
} from 'utils/date';
import Link from '@mui/material/Link';
import { getProviderProfileUrl, getSeekerProfileUrl } from 'routes';
import { useCrypto } from 'context/crypto';
import useGetOrganizationSpecificKey from 'context/crypto/hooks/useGetOrganizationSpecificKey';
import { useSnackbar } from 'notistack';
import { useUser } from 'context/user';
import { useEffect, useState } from 'react';
import { AvatarFallback } from 'feature/user-avatar';

const getCalendarText = getTextIn('calendar');

type LinkWithAvatarProps = {
  avatarSrc: string;
  name: string;
  url: string;
  isOrganization: boolean;
};

const LinkWithAvatar = ({
  avatarSrc,
  name,
  url,
  isOrganization
}: LinkWithAvatarProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Avatar
        alt={`${getText('profileImage', 'profile')} ${name}`}
        src={avatarSrc}
        sx={{ width: 24, height: 24 }}
      >
        <AvatarFallback isOrganization={isOrganization} />
      </Avatar>
      <Link href={url} target="_blank" color="primary">
        {name}
      </Link>
    </Box>
  );
};

type EventDetailsDialogProps = {
  event: EntityModelEvent;
  showDialog: boolean;
  closeDialog: () => void;
  onDeleteClick: () => void;
};

type EventDetail = {
  content: JSX.Element | string;
  dataCy: string;
  icon?: JSX.Element;
  postfix?: JSX.Element;
};

const EventDetailsDialog = ({
  event,
  showDialog,
  closeDialog,
  onDeleteClick
}: EventDetailsDialogProps) => {
  const { perspective } = useUser();
  const { decryptEventDetails, selectedOrganizationPrivateKey } = useCrypto();
  const { enqueueSnackbar } = useSnackbar();
  const { data: encryptedSymMsgKey } = useGetOrganizationSpecificKey(
    perspective.id,
    event.id
  );
  const [decryptedEvent, setDecryptedEvent] = useState<EntityModelEvent>(event);

  useEffect(() => {
    if (encryptedSymMsgKey && selectedOrganizationPrivateKey) {
      decryptEventDetails(
        event,
        encryptedSymMsgKey,
        selectedOrganizationPrivateKey
      )
        .then(setDecryptedEvent)
        .catch((reason) => {
          enqueueSnackbar(
            `${getText('decryptionError', 'crypto')}: ${reason}`,
            {
              variant: 'error'
            }
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encryptedSymMsgKey, selectedOrganizationPrivateKey]);

  const REQUEST_START_TIME = 'requestStartTime';
  const REQUEST_END_TIME = 'requestEndTime';
  const INVALID_DATE = 'Invalid Date';

  const getTimeString = (timeKey: keyof EntityModelEvent) =>
    `${formatAsDateTime(event[timeKey] as Date)} ${getCalendarText('oClock')}`;

  const details: Array<EventDetail | undefined> = [
    event.timeSlot.startTime &&
      event.timeSlot.endTime && {
        icon: <EventIcon color="secondary" />,
        content: `${getFormattedRangeString(
          formatAsDateTimeExtended(event.timeSlot.startTime),
          formatAsTime(event.timeSlot.endTime)
        )} ${getCalendarText('oClock')}`,
        dataCy: 'event-date-time'
      },
    {
      icon: <LocationOnIcon color="secondary" />,
      content: (
        <Address address={decryptedEvent.eventDetails.fixedLocation} asString />
      ),
      dataCy: 'event-location'
    },
    event.online
      ? {
          icon: <VideoCameraFrontIcon color="secondary" />,
          content: getCalendarText('withVideo'),
          dataCy: 'event-online'
        }
      : undefined,
    {
      icon: <PeopleIcon color="secondary" />,
      content: `${decryptedEvent.eventDetails.firstName} ${
        decryptedEvent.eventDetails.lastName
      }, ${getCalendarText('bookedBy')}:`,

      postfix: event.booker && (
        <LinkWithAvatar
          avatarSrc={event.booker?.logo?.urls?.small ?? ''}
          name={event.booker?.name}
          url={getSeekerProfileUrl(event.booker?.id)}
          isOrganization
        />
      ),
      dataCy: 'event-stakeholders'
    },
    ...event.offers.map((offer, idx) => ({
      icon: idx === 0 ? <AssignmentIcon color="secondary" /> : undefined,
      content: `${offer.offer?.title}, ${getCalendarText('offeredBy')}:`,
      postfix: event.offerer && (
        <LinkWithAvatar
          avatarSrc={event.offerer?.logo?.urls?.small ?? ''}
          name={event.offerer?.name}
          url={getProviderProfileUrl(event.offerer?.id)}
          isOrganization
        />
      ),
      dataCy: `event-offer-${idx + 1}`
    })),
    {
      content:
        `${getCalendarText('requestedTimeframe')}: ` +
        (String(event[REQUEST_END_TIME]) !== INVALID_DATE
          ? String(event[REQUEST_START_TIME]) !== INVALID_DATE
            ? String(event[REQUEST_START_TIME]) !==
              String(event[REQUEST_END_TIME])
              ? getFormattedRangeString(
                  getTimeString(REQUEST_START_TIME),
                  getTimeString(REQUEST_END_TIME)
                )
              : getTimeString(REQUEST_START_TIME)
            : `${getText('until', 'filter-time')} ${getTimeString(
                REQUEST_END_TIME
              )}`
          : `${getText('asOf', 'filter-time')} ${getTimeString(
              REQUEST_START_TIME
            )}`),

      dataCy: ' event-requested-time-range'
    }
  ];

  return (
    <DialogGeneric
      open={showDialog}
      title={event?.title}
      onConfirm={onDeleteClick}
      onClose={closeDialog}
      confirmText={getCalendarText('cancelAppointment')}
      confirmColor="error"
      prefix="event-details-dialog"
      fullWidth
    >
      <Stack
        direction="column"
        data-cy="event-details"
        spacing={2}
        sx={{ py: 2 }}
      >
        {details.map((detail) => {
          if (detail) {
            return (
              <Stack
                direction="row"
                spacing={1}
                key={detail.dataCy}
                data-cy={detail.dataCy}
              >
                {detail?.icon}
                <Typography sx={{ pl: detail?.icon ? 'inherit' : 4 }}>
                  {detail.content}
                </Typography>
                {detail.postfix}
              </Stack>
            );
          }
        })}
      </Stack>
    </DialogGeneric>
  );
};

export default EventDetailsDialog;
export { LinkWithAvatar, type EventDetailsDialogProps };
