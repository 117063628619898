import { useCallback, useMemo } from 'react';
import { getApiInstance, APINames } from 'utils/api-utils';
import {
  LegalApi,
  type GetProjectImprintTextRequest,
  type GetProjectPrivacyPolicyTextRequest,
  type GetProjectTermsOfUseTextRequest,
  type GetProjectAccessibilityDeclarationTextRequest
} from '@SLR/solution3-sdk';

const useLegalApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.legal) as LegalApi;
  }, []);

  const getProjectPrivacyPolicyText = useCallback(
    async (requestParameters: GetProjectPrivacyPolicyTextRequest) => {
      try {
        return await apiInstance.getProjectPrivacyPolicyText(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getProjectImprintText = useCallback(
    async (requestParameters: GetProjectImprintTextRequest) => {
      try {
        return await apiInstance.getProjectImprintText(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getProjectTermsOfUseText = useCallback(
    async (requestParameters: GetProjectTermsOfUseTextRequest) => {
      try {
        return await apiInstance.getProjectTermsOfUseText(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getProjectAccessibilityDeclarationText = useCallback(
    async (
      requestParameters: GetProjectAccessibilityDeclarationTextRequest
    ) => {
      try {
        return await apiInstance.getProjectAccessibilityDeclarationText(
          requestParameters
        );
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getProjectPrivacyPolicyText,
    getProjectImprintText,
    getProjectTermsOfUseText,
    getProjectAccessibilityDeclarationText
  };
};

export default useLegalApi;
