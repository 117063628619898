import React from 'react';
import Container, { type ContainerProps } from '@mui/material/Container';

const FormWrapper = ({
  maxWidth = 'md',
  children
}: {
  maxWidth?: ContainerProps['maxWidth'];
  children: React.ReactNode;
}) => {
  return <Container maxWidth={maxWidth}>{children}</Container>;
};

export default FormWrapper;
