import { Grid, Typography, Button } from '@mui/material';
import { useUser, useGetPortalOrganizationProfileHref } from 'context/user';
import { getTextIn } from 'localization';

const getTextChangeData = getTextIn('account-data-changeData');

const ChangeData = () => {
  const { perspective } = useUser();
  const organizationId = perspective?.id ?? '';
  const userPortalHref = useGetPortalOrganizationProfileHref(
    organizationId,
    '&showOrgEdit'
  );

  const changeDataText = getTextChangeData('action');

  return (
    <Grid item>
      <Typography component="div" variant="sectionTitle" sx={{ mb: 0.75 }}>
        {changeDataText}
      </Typography>

      <Typography>{getTextChangeData('info')}</Typography>

      <Button
        variant="outlined"
        sx={{ mt: 2.5 }}
        target="_blank"
        href={userPortalHref}
        data-cy="accountdata-gotouserportal"
      >
        {changeDataText}
      </Button>
    </Grid>
  );
};

export default ChangeData;
