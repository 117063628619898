import { Typography } from '@mui/material';
import { useBooking } from 'context/booking';
import { DialogGeneric } from 'feature';
import { getTextIn } from 'localization';

const getFilterText = getTextIn('filter');

const AlterFilterDialog = ({
  showDialog,
  onClose,
  alterFilters
}: {
  showDialog: boolean;
  onClose: () => void;
  alterFilters: () => void;
}) => {
  const { clearBookingList } = useBooking();
  return (
    <DialogGeneric
      open={showDialog}
      onClose={onClose}
      onConfirm={() => {
        clearBookingList();
        alterFilters();
      }}
      confirmText={getFilterText('alter')}
      confirmColor="error"
      title={getFilterText('alterFilterDialogTitle')}
      prefix="alter-filters"
      maxWidth="md"
    >
      <Typography>{getFilterText('alterFiltersDialogText')}</Typography>
    </DialogGeneric>
  );
};

export default AlterFilterDialog;
