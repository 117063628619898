import { Grid } from '@mui/material';
import { ContentWrapper } from 'feature';
import { FooterItemLeft, FooterItemRight, FooterItemMiddle } from './items';

const Footer = () => {
  return (
    <footer>
      <ContentWrapper backgroundColor="primary.main" bgColorFullWidth={true}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          gap={{ xs: 5, md: 1 }}
          sx={{ pt: 4 }}
        >
          <Grid item md={3}>
            <FooterItemLeft />
          </Grid>
          <Grid item md={3}>
            <FooterItemMiddle />
          </Grid>
          <Grid item md={3}>
            <FooterItemRight />
          </Grid>
        </Grid>
      </ContentWrapper>
    </footer>
  );
};

export default Footer;
