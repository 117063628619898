/* eslint-disable no-negated-condition */
import { useState, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Bookable } from '@SLR/solution3-sdk';
import { useProject } from 'context/project';
import {
  CheckboxStateValues,
  RadioBlock,
  RemoveFilterButton
} from 'components';
import FilterMobileDialog from './filter-dialog-mobile';
import FilterCheckboxes from './filter-checkboxes';
import TimeRange from './date';
import Duration from './duration';
import { withoutBackdropClick, isEmptyArray } from 'utils/helper';
import {
  SORT,
  TIME_RANGE,
  DURATION,
  GEO_AREA,
  LOCATION_TYPES,
  CATEGORIES,
  TARGET_GROUPS,
  SearchParams,
  FilterParams,
  getFilterButtonLabel,
  getSortOptions,
  getSortParam,
  useGetFilterButtons,
  FilterParamsCheckbox,
  type UrlParams,
  type SearchParamType,
  type VariableCheckboxValues,
  type FilterParamsKeyType,
  type FilterAndSortUnitParamsType,
  type FilterHandler,
  type FilterGeoAreaCtrl,
  type OnRemoveFilter
} from 'utils/url-param';

import FilterGeoArea from './geo-area';
import { getText, getTextIn } from 'localization';
import { useGetVariableCheckboxValues } from './hooks';

const getFilterText = getTextIn('filter');

const MENU_LIST_ORDER = [
  SORT,
  TIME_RANGE,
  DURATION,
  GEO_AREA,
  LOCATION_TYPES,
  CATEGORIES,
  TARGET_GROUPS
];

// a hash for divergent localization names from the menu list order names
const textName: { [key in SearchParamType]: string } = {
  [SORT]: 'sortBy',
  [GEO_AREA]: 'location',
  [LOCATION_TYPES]: 'locationType',
  [CATEGORIES]: 'category',
  [TARGET_GROUPS]: 'targetGroup'
};

// the time SearchParam will be addressed also by the timeRange menu button
const SUBSUMED_TYPE_KEYS = { time: TIME_RANGE };

const getAppliedFilterNames = (
  filterButtons: string[],
  type: string,
  checkboxValues?: VariableCheckboxValues
) => {
  const getFilterButtonLabelKeyName = getFilterButtonLabel(checkboxValues);

  const accGetfilterButtonsForType: string[] = [];

  const getfilterNameArray = filterButtons.reduce((acc, filterButton) => {
    const [key, name] = filterButton.split('_');

    if (
      key === SearchParams[type as keyof typeof SearchParams] ||
      SUBSUMED_TYPE_KEYS[key as keyof typeof SUBSUMED_TYPE_KEYS] === type
    ) {
      const typedKey = key as FilterParamsKeyType;
      acc.push(getFilterButtonLabelKeyName(typedKey, name) ?? '');
    }
    return acc;
  }, accGetfilterButtonsForType);

  return getfilterNameArray.join(', ');
};

const getSortName = (search: UrlParams, isQuerySet: boolean) => {
  const sortParam = getSortParam(search);
  const sortOptions = getSortOptions(isQuerySet);

  const sortLabel = sortOptions.options.find(
    (option) => sortParam === option.value
  )?.label;

  return sortLabel;
};

type FilterMenuMobileProps = FilterHandler &
  FilterGeoAreaCtrl & {
    open: boolean;
    onClose: VoidFunction;
    search: UrlParams;
    totalElements?: number;
    title: string;
    hideCategoryFilter?: boolean;
    hasTargetGroup?: boolean;
    onRemoveFilter: OnRemoveFilter;
    onRemoveAllFilters: VoidFunction;
  };

const FilterMenuMobile = ({
  open,
  onClose,
  search,
  totalElements,
  title,
  geoAreaData,
  hideCategoryFilter,
  hasTargetGroup,
  onRemoveFilter,
  onRemoveAllFilters,
  onClickApplyCheckbox,
  onClickApplyGeoArea,
  onClickApplyDate,
  onClickApplyDuration,
  onChangeSort
}: FilterMenuMobileProps) => {
  const { project } = useProject();

  const [dialogsOpen, setDialogsOpen] = useState({
    [FilterParams.locationTypes]: false,
    [FilterParams.categories]: false,
    [FilterParams.targetGroups]: false,
    [FilterParams.timeRange]: false,
    [FilterParams.time]: false,
    [FilterParams.duration]: false,
    [FilterParams.geoAreaId]: false,
    [SearchParams.sort]: false
  });

  const getOpen = (key: FilterAndSortUnitParamsType) => {
    return dialogsOpen[key];
  };

  const setOpen = (key: FilterAndSortUnitParamsType, value: boolean) => {
    setDialogsOpen({ ...dialogsOpen, [key]: value });
  };

  const filterButtons = useGetFilterButtons(search);
  const variableCheckboxValues = useGetVariableCheckboxValues();

  // special case sort
  const isQuerySet = !!search[SearchParams.query];

  const MENU_BUTTON_CONFIG = useMemo(
    () =>
      MENU_LIST_ORDER.map((type) => {
        return { title: getFilterText(textName[type] || type), type };
      }),
    []
  );

  const ctrls = {
    getOpen,
    setOpen,
    onClose
  };

  const onClickCheckbox =
    (filterParam: FilterParamsCheckbox) =>
    (...props: CheckboxStateValues[]) => {
      onClickApplyCheckbox(filterParam)(...props);
      setOpen(filterParam, false);
    };

  return (
    <>
      <Dialog
        fullScreen
        disableEscapeKeyDown
        open={open}
        onClose={withoutBackdropClick(onClose)}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '70%' },
            height: '100%',
            position: 'absolute',
            right: 0
          }
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 1.75,
            py: 1,
            mt: 1.75,
            mb: 2.25
          }}
        >
          {/* hidden element for center position of title */}
          <CloseIcon sx={{ visibility: 'hidden' }} />
          <span>{title}</span>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: 'primary.main'
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 0, color: 'unset' }}>
          <List sx={{ p: 0 }}>
            {MENU_BUTTON_CONFIG.filter((button) =>
              button.type === TARGET_GROUPS
                ? hasTargetGroup
                : button.type === CATEGORIES
                ? !hideCategoryFilter
                : true
            ).map(({ title: dialogTitle, type }) => {
              const filterNames =
                type === SearchParams.sort
                  ? getSortName(search, isQuerySet)
                  : getAppliedFilterNames(
                      filterButtons,
                      type,
                      variableCheckboxValues
                    );

              return (
                <ListItemButton
                  key={`${dialogTitle}-${type}-mobile`}
                  sx={{
                    pl: 2.5,
                    pr: 2.25,
                    height: 60,
                    '&.Mui-selected': { bgcolor: 'primary.background' }
                  }}
                  onClick={() => {
                    setOpen(
                      SearchParams[
                        type as keyof typeof SearchParams
                      ] as FilterAndSortUnitParamsType,
                      open
                    );
                  }}
                >
                  <ListItemText
                    primary={dialogTitle}
                    primaryTypographyProps={{
                      sx: { color: 'initial', textTransform: 'none' }
                    }}
                  />
                  {filterNames && (
                    <ListItemText
                      primary={filterNames}
                      primaryTypographyProps={{
                        sx: {
                          color: 'placeholder.dark',
                          fontSize: '13px',
                          ml: 4,
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }
                      }}
                      sx={{ mr: 2, flexGrow: 0 }}
                    />
                  )}
                  <ListItemIcon
                    sx={{
                      minWidth: 'auto',
                      color: 'initial'
                    }}
                  >
                    <ArrowForwardIcon />
                  </ListItemIcon>
                </ListItemButton>
              );
            })}
          </List>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center', mb: 3.75 }}>
          <RemoveFilterButton
            text={getText('deleteAll')}
            onClick={onRemoveAllFilters}
          />
        </DialogActions>
      </Dialog>

      {!hideCategoryFilter && (
        <FilterMobileDialog
          title={getFilterText('category')}
          filterParamName={SearchParams.categories}
          totalElements={totalElements}
          {...ctrls}
        >
          <FilterCheckboxes
            search={search}
            options={variableCheckboxValues[CATEGORIES]}
            filterParam={SearchParams.categories}
            onClick={onClickCheckbox(SearchParams.categories)}
          />
        </FilterMobileDialog>
      )}

      {hasTargetGroup &&
        !isEmptyArray(variableCheckboxValues[TARGET_GROUPS]) && (
          <FilterMobileDialog
            title={getFilterText('targetGroup')}
            filterParamName={SearchParams.targetGroups}
            totalElements={totalElements}
            {...ctrls}
          >
            <FilterCheckboxes
              search={search}
              options={variableCheckboxValues[TARGET_GROUPS]}
              filterParam={SearchParams.targetGroups}
              onClick={onClickCheckbox(SearchParams.targetGroups)}
            />
          </FilterMobileDialog>
        )}

      <FilterMobileDialog
        title={getFilterText('locationType')}
        filterParamName={SearchParams.locationTypes}
        totalElements={totalElements}
        {...ctrls}
      >
        <FilterCheckboxes
          search={search}
          filterParam={SearchParams.locationTypes}
          disableUnchecked={project?.offersBookable === Bookable.Multi}
          onClick={onClickCheckbox(SearchParams.locationTypes)}
        />
      </FilterMobileDialog>

      <FilterMobileDialog
        title={getFilterText('timeRange')}
        filterParamName={SearchParams.timeRange}
        {...ctrls}
      >
        <TimeRange
          search={search}
          onClick={(...props) => {
            onClickApplyDate(...props);
            setOpen(SearchParams.timeRange, false);
          }}
          onCancel={onRemoveFilter}
        />
      </FilterMobileDialog>

      <FilterMobileDialog
        title={getFilterText('duration')}
        filterParamName={SearchParams.duration}
        {...ctrls}
      >
        <Duration
          search={search}
          onClick={(...props) => {
            onClickApplyDuration(...props);
            setOpen(SearchParams.duration, false);
          }}
        />
      </FilterMobileDialog>

      <FilterMobileDialog
        title={getFilterText('location')}
        filterParamName={SearchParams.geoAreaId}
        {...ctrls}
      >
        <FilterGeoArea
          geoAreaData={geoAreaData}
          distanceValue={search[SearchParams.distance]}
          onClick={(...props) => {
            onClickApplyGeoArea(...props);
            setOpen(
              SearchParams.geoAreaId as unknown as FilterAndSortUnitParamsType,
              false
            );
          }}
          buttonSx={{ alignSelf: 'center', px: 8, width: '100%' }}
        />
      </FilterMobileDialog>

      <FilterMobileDialog
        title={getFilterText('sortBy')}
        filterParamName={
          SearchParams.sort as unknown as FilterAndSortUnitParamsType
        }
        {...ctrls}
      >
        <RadioBlock
          row={false}
          radioOption={getSortOptions(isQuerySet)}
          controlledValue={getSortParam(search)}
          onValueChange={(...props) => {
            onChangeSort(...props);
            setOpen(
              SearchParams.sort as unknown as FilterAndSortUnitParamsType,
              false
            );
          }}
        />
      </FilterMobileDialog>
    </>
  );
};

export default FilterMenuMobile;
