import { Button, Typography } from '@mui/material';
import React from 'react';
import EmojiPeopleTwoToneIcon from '@mui/icons-material/EmojiPeopleTwoTone';
import { useProject } from 'context/project';
import FeedbackScreen from './feedback-screen';
import { useGetPortalAddOrganizationHref } from 'context/user';
import { getNode, getTextIn } from 'localization';

const getInvitationText = getTextIn('invitation');

const RegisterOrganizationScreen = ({
  organizationName
}: {
  organizationName: string;
}) => {
  const { project } = useProject();
  const icon = <EmojiPeopleTwoToneIcon sx={{ flexGrow: 1 }} />;
  const text = (
    <Typography component="span">
      {getNode(
        'getNodeRegisterText',
        'invitation'
      )({
        orgName: <strong>{organizationName}</strong>,
        projectName: project?.name
      })}
    </Typography>
  );

  const button = (
    <Button
      variant="contained"
      href={useGetPortalAddOrganizationHref(organizationName)}
    >
      {getInvitationText('createOrganization')}
    </Button>
  );

  return (
    <FeedbackScreen
      title={getInvitationText('success')}
      icon={icon}
      text={text}
      hasHomePageButton={false}
      actionButton={button}
      dataCy="callforregisterorganizationscreen"
    />
  );
};

export default RegisterOrganizationScreen;
