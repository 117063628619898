import {
  type EventSuggestionCreate,
  type CreateEventSuggestionsRequest
} from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useEventSuggestionsApi from './useEventSuggestionApi';

const useGetEventSuggestions = (
  params: EventSuggestionCreate[],
  index: number,
  newSuggestionsAfter?: Date,
  enabled = false
) => {
  const { getEventSuggestions } = useEventSuggestionsApi();
  const projectId = useGetProjectId();

  const eventRequestParameters: CreateEventSuggestionsRequest = {
    projectId: projectId ?? '',
    eventRequests: params,
    generationTime: newSuggestionsAfter
  };
  return useQuery(
    ['eventSuggestions', eventRequestParameters],
    () => {
      return getEventSuggestions(eventRequestParameters);
    },
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: !!projectId && enabled,
      select: (data) => {
        if (data?.embedded?.eventSuggestions?.[index])
          return data.embedded.eventSuggestions[index]?.possibleTimeSlots;
        else return [];
      }
    }
  );
};

export default useGetEventSuggestions;
