import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import {
  SettingsAppearance,
  SettingsGeneral,
  SettingsVisibility,
  SettingsParticipants,
  SettingsCategories,
  SettingsTargetGroups
} from 'pages/settings';
import { Restricted } from 'routes';
import { useIsAdmin } from 'context/user';
import { getPath } from 'localization';
import { useProject } from 'context/project';

const SettingsRoutes = () => {
  const isAdmin = useIsAdmin();
  const { project } = useProject();
  return (
    <Restricted isVisible={isAdmin} forceLogin={true}>
      <Routes>
        <Route index element={<Navigate replace to={getPath('general')} />} />
        <Route path={getPath('general')} element={<SettingsGeneral />} />
        <Route path={getPath('appearance')} element={<SettingsAppearance />} />
        <Route path={getPath('visibility')} element={<SettingsVisibility />} />
        <Route
          path={getPath('participants')}
          element={<SettingsParticipants />}
        />
        <Route path={getPath('categories')} element={<SettingsCategories />} />
        <Route
          path={getPath('targetGroups')}
          element={
            <Restricted isVisible={project?.features?.targetGroups ?? false}>
              <SettingsTargetGroups />
            </Restricted>
          }
        />
      </Routes>

      <Outlet />
    </Restricted>
  );
};

export default SettingsRoutes;
