import { type OfferUpdate, type EntityModelOffer } from '@SLR/solution3-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useOffersApi, {
  OFFERS_LIST_KEY,
  OFFER_KEY,
  OFFER_LOCATIONS_KEY
} from './useOffersApi';

const useUpdateOffer = (offerId: string) => {
  const queryClient = useQueryClient();
  const { updateOffer } = useOffersApi();
  const projectId = useGetProjectId();

  return useMutation<EntityModelOffer, Error, OfferUpdate>(
    (offerUpdate: OfferUpdate) =>
      updateOffer({ projectId: projectId ?? '', offerId, offerUpdate }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([OFFER_KEY, { projectId, offerId }]);
        queryClient.invalidateQueries([
          OFFER_LOCATIONS_KEY,
          { projectId, offerId }
        ]);
        queryClient.invalidateQueries([OFFERS_LIST_KEY]);
      }
    }
  );
};

export default useUpdateOffer;
