import type { InputBaseComponentProps } from '@mui/material/InputBase';
import type { SxProps } from '@mui/material';
import { type Dayjs } from 'dayjs';
import ValidatedDatePicker, {
  DefaultDateTimePicker
} from './validated-date-picker';

/**
 *
 * @param name  Form name
 * @param label  Label
 * @param minDateTime  Optional minimum date time for pickers
 * @param maxDateTime  Optional maximum date time for pickers
 * @param inputProps  Optional input props, e.g.
 *    inputProps={{
 *      'data-cy': 'filter-timerange-start',
 *      sx: {...}
 *    }}
 * @param pickerSx  Optional styling sx for the picker
 * @returns ValidatedDatePicker element
 */
const ConfigurableDatePicker = ({
  name,
  label,
  minDateTime,
  maxDateTime,
  disabled,
  inputProps,
  pickerSx
}: {
  name: string;
  label: string;
  minDateTime?: Dayjs;
  maxDateTime?: Dayjs;
  disabled?: boolean;
  inputProps?: InputBaseComponentProps;
  pickerSx?: SxProps;
}) => {
  return (
    <ValidatedDatePicker
      name={name}
      render={(props) => (
        <DefaultDateTimePicker
          label={label}
          disablePast
          slotProps={{
            textField: {
              fullWidth: true,
              inputProps
            }
          }}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
          disabled={disabled}
          sx={{ ...pickerSx }}
          {...props}
        />
      )}
    />
  );
};

export default ConfigurableDatePicker;
