import { useMutation } from '@tanstack/react-query';
import { type ArticleCreate } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { useArticlesApi } from './use-compass-api';

const useCreateArticle = () => {
  const projectId = useGetProjectId();
  const { createArticle } = useArticlesApi();

  return useMutation((articleCreate: ArticleCreate) => {
    return createArticle({
      projectId: projectId ?? '',
      articleCreate
    });
  });
};

export default useCreateArticle;
