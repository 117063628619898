import { Paper } from '@mui/material';

const SubFormWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Paper variant="outlined" sx={{ p: 4 }}>
      {children}
    </Paper>
  );
};

export default SubFormWrapper;
