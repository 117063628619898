import { type EntityModelArticleCategory } from '@SLR/solution3-sdk';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CompassCategoryCard from './category-card';
import { getText } from 'localization';

const CategoriesGrid = ({
  articleCategories,
  isLoading
}: {
  articleCategories?: EntityModelArticleCategory[];
  isLoading: boolean;
}) => {
  const previewNumber = 6;

  return !isLoading && !articleCategories ? (
    <Typography>{getText('noCategoriesYet', 'compass')}</Typography>
  ) : (
    <Grid
      container
      spacing={2}
      sx={[
        {
          mb: 2
        }
      ]}
    >
      {(articleCategories ?? Array(previewNumber).fill(null))?.map(
        (articleCategory: EntityModelArticleCategory, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              xl={3}
              key={index}
              sx={{ justifyContent: 'center', display: 'flex' }}
            >
              <CompassCategoryCard
                key={index}
                title={articleCategory?.name}
                image={articleCategory?.picture}
                loading={isLoading}
                url={articleCategory?.id}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default CategoriesGrid;
