import { Typography } from '@mui/material';
import { useBooking } from 'context/booking';
import { DialogGeneric } from 'feature';
import { getTextIn } from 'localization';

const getFilterText = getTextIn('filter');

const RemoveFilterDialog = ({
  showDialog,
  onClose,
  removeFilters,
  bodyText
}: {
  showDialog: boolean;
  onClose: () => void;
  removeFilters: () => void;
  bodyText: string;
}) => {
  const { clearBookingList } = useBooking();
  return (
    <DialogGeneric
      open={showDialog}
      onClose={onClose}
      onConfirm={() => {
        clearBookingList();
        removeFilters();
      }}
      confirmText={getFilterText('delete')}
      confirmColor="error"
      title={getFilterText('removeFilterDialogTitle')}
      prefix="remove-filters"
      maxWidth="md"
    >
      <Typography>{bodyText}</Typography>
    </DialogGeneric>
  );
};

export default RemoveFilterDialog;
