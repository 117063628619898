import { useState, useEffect } from 'react';
import { type Image, type ImageUrls } from '@SLR/solution3-sdk';

const useImageLoader = ({
  image,
  srcFormat = '',
  size = 'small'
}: {
  image?: Image;
  srcFormat?: string;
  size?: keyof ImageUrls;
}) => {
  const [loadedSrc, setLoadedSrc] = useState('');
  const orgSrc =
    image && `${image.urls?.[size]}${srcFormat ? `?${srcFormat}` : ''}`;

  useEffect(() => {
    if (orgSrc) {
      const img = new Image();
      img.src = orgSrc;
      img.onload = () => {
        setLoadedSrc(orgSrc);
      };
    }
  }, [orgSrc]);

  return { loadedSrc };
};

export default useImageLoader;
