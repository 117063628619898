import { type Image } from '@SLR/solution3-sdk';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Loadable } from 'types';
import { CustomCard } from 'feature/cards';
import { Skeleton } from '@mui/material';

type CompassCategoryCardProps = {
  title: string;
  image?: Image;
  url: string;
};

const CompassCategoryCard = ({
  title,
  loading,
  image,
  url
}: Loadable<CompassCategoryCardProps>) => {
  return (
    <CustomCard href={loading ? '' : url} minWidthPercent={31}>
      <CardContent key="description" sx={{ p: 0 }}>
        {loading ? (
          <Skeleton animation="wave" variant="rectangular" height={183} />
        ) : (
          <Box
            sx={{
              backgroundImage: `url(${image?.urls.small})`,
              backgroundColor: image?.urls.small
                ? 'unset'
                : 'primary.background',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              py: {
                xs: 4,
                lg: 6,
                xxl: 9
              },
              height: 183,
              position: 'relative'
            }}
          />
        )}
      </CardContent>

      <CardHeader
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '& .MuiCardHeader-content': {
            width: '100%'
          }
        }}
        disableTypography
        title={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {loading ? (
              <Skeleton animation="wave" />
            ) : (
              <Typography
                variant="customCardTitle"
                sx={{
                  fontSize: 24,
                  mb: 0,
                  height: 32,
                  minHeight: 'unset',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  display: 'block'
                }}
              >
                {title}
              </Typography>
            )}
          </Box>
        }
      />
    </CustomCard>
  );
};

export default CompassCategoryCard;
