export const isObject = (value: unknown) =>
  !!value && typeof value === 'object' && value.constructor === Object;

export const isEmptyObject = (obj: unknown) =>
  isObject(obj) && Object.keys(obj as object).length === 0;

// with array check - if value is no array, it also returns false
export const isEmptyArray = (value: unknown) =>
  Array.isArray(value) ? !!!value.length : false;

/**
 * Checks if the given string input is null or empty (whitespace)
 * @param input input to be checked.
 * @returns True if the input is null or empty, false otherwise
 */
export const isEmptyOrNull = (input: string | undefined | null): boolean => {
  return !input?.trim() as boolean;
};

/**
 * A deep object/array clone with JSON methods
 *
 *    - very quick for deep cloning of standard object, array, number, string, boolean values
 *    - do NOT use with  functions, Date variables or other complex types such as ImageDatas,
 *          Blobs, FileLists, Maps, etc.
 */
export const cloneObjectDeepJson = <T>(object: T) =>
  JSON.parse(JSON.stringify(object)) as T;

export const compareObjectDeepJson = <T>(object1: T, object2: T) =>
  JSON.stringify(object1) === JSON.stringify(object2);

export const stripDashes = (str: string) => str.split('-').join('');

// a no operating function
export const noop = () => {
  return;
};

export const limitValueRange = (value: number, max = 100, min = 0) => {
  return Math.max(min, Math.min(value, max));
};

interface ApplyStep {
  step?: number;
  max?: number;
  value: string | number;
  always?: boolean;
}

interface LimitValueRangeWithStep extends ApplyStep {
  min?: number;
}

export const applyStep = ({
  step = 1,
  max = 100,
  value,
  always = false
}: ApplyStep) => {
  return !always &&
    value.toString().replace(/^0/, '').length < max.toString().length - 1
    ? Number(value)
    : Math.ceil(Number(value) / step) * step;
};

export const limitValueRangeWithStep = ({
  step = 1,
  max,
  min,
  value,
  always
}: LimitValueRangeWithStep) => {
  return limitValueRange(applyStep({ step, max, value, always }), max, min);
};

export const withoutBackdropClick =
  (onClose: VoidFunction) => (_: React.ReactEventHandler, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

export const EMAIL_REGEX =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const ZIPCODE_REGEX = /^\d{5}$/;

export const getIsValidZipCode = (zipCode: string) =>
  ZIPCODE_REGEX.test(zipCode);

export const getIsValidEmail = (email: string) => EMAIL_REGEX.test(email);

export const isNotNegativeInteger = (value: number) =>
  Number.isInteger(value) && value > -1;
