import { useProject } from 'context/project';
import { leastRestrictiveVisibility, useIsVisible } from 'feature/permissions';
import { EditProfilePage, ParticipantListPage, ProfilePage } from 'pages';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Restricted, RestrictedVisibilityRoute } from './restricted-route';
import { getPath } from 'localization';
import { Registration } from '@SLR/solution3-sdk';

const SeekerRoutes = () => {
  const { project } = useProject();
  const visibility = leastRestrictiveVisibility(
    project?.seekerOrganizationsVisibility,
    project?.seekerUsersVisibility
  );
  const isVisible = useIsVisible(visibility);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <Restricted
              isVisible={
                project?.seekerOrganizationsRegistration !==
                  Registration.None && isVisible
              }
            >
              <ParticipantListPage role="seeker" />
            </Restricted>
          }
        />
        <Route
          path={`:profileId/${getPath('edit')}`}
          element={
            <RestrictedVisibilityRoute visibility={visibility}>
              <EditProfilePage isSeeker />
            </RestrictedVisibilityRoute>
          }
        />
        <Route
          path=":profileId"
          element={
            <RestrictedVisibilityRoute visibility={visibility}>
              <ProfilePage />
            </RestrictedVisibilityRoute>
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};

export default SeekerRoutes;
