import { ReactElement } from 'react';
import { Box } from '@mui/material';
import Snackbar from 'feature/snackbar';
import { NavBar } from './navbar';
import Footer from './Footer';
import { useUser } from 'context/user';
import { useProject } from 'context/project';
import TwoFaErrorFallback from 'feature/error/2fa-error-fallback';
import { useKeycloakAuth } from 'auth';
import { useLocation } from 'react-router-dom';
import { getPath } from 'localization';

const Layout = ({ children }: { children: ReactElement }) => {
  const location = useLocation();
  const { isAuthenticated } = useKeycloakAuth();
  const { isProjectNew } = useProject();
  const { hasTwoFaEnabled } = useUser();
  const { pathname } = location;

  const pathWhiteList = [
    getPath('contact'),
    getPath('imprint'),
    getPath('privacy'),
    getPath('terms')
  ];

  return (
    <>
      <Snackbar>
        <NavBar />
        <main>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'flex-start'
            }}
          >
            {isAuthenticated &&
            isProjectNew &&
            !hasTwoFaEnabled &&
            !pathWhiteList.includes(pathname.replace('/', '')) ? (
              <TwoFaErrorFallback />
            ) : (
              children
            )}
          </Box>
        </main>
        <Footer />
      </Snackbar>
    </>
  );
};

export default Layout;
