import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { ContentWrapper, LinkWithAvatar, PrivateKeyDialog } from 'feature';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { getText, getTextIn } from 'localization';
import { useSetDocumentTitle } from 'context/project';
import { useBooking } from 'context/booking';
import { useCrypto, useSendEncryptedEvent } from 'context/crypto';
import { useUser } from 'context/user';
import {
  SchemaProvider,
  ValidatedSwitch,
  ValidatedTextField
} from 'feature/forms';
import {
  EMPTY_EVENT_DETAILS,
  EMPTY_OFFER_BOOKING_EVENT,
  OfferBookingEvent,
  OfferBookingEventSchema
} from 'feature/offers/booking/model';
import { offerBookingDataCy } from 'feature/offers/booking';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { FixedLocationForm } from 'feature/offers';
import { LoadingButton } from 'components';
import Address from 'components/address';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import OfferBookingTimeSlots from 'feature/offers/booking/offer-booking-timeslots';
import { formatAsDurationString } from 'utils/duration';
import { getProviderProfileUrl } from 'routes/utils';
import BookingSuccessDialog from 'feature/offers/booking/booking-success-dialog';
import dayjs from 'dayjs';
import { ListOffersLocationTypesEnum } from '@SLR/solution3-sdk';

const getOfferBookingText = getTextIn('offer-booking');

const OfferBookingPage = () => {
  useSetDocumentTitle(getOfferBookingText('bookingList'));
  const {
    offers,
    removeOfferFromList,
    requestedLocationType,
    requestedGeoAreaId,
    requestedTimeRange,
    fixedLocation,
    withOnlineParticipation,
    setWithOnlineParticipation,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    selectedTimeRange
  } = useBooking();

  const navigate = useNavigate();
  const { perspective } = useUser();
  const { showPrivateKeyDialog } = useCrypto();

  const organizationId = perspective.id;

  const { create, createEvent } = useSendEncryptedEvent(
    organizationId,
    offers[0]?.organizationId ?? ''
  );

  const [showSuccessDialog, setShowSuccessDialog] = useState(
    createEvent.isSuccess
  );

  const totalDuration = offers
    .reduce(
      (acc, offer) => acc.add(dayjs.duration(offer.duration ?? '')),
      dayjs.duration(0)
    )
    .toISOString();

  useEffect(() => {
    setShowSuccessDialog(createEvent.isSuccess);
  }, [createEvent.isSuccess]);

  const isAtProviderLocation =
    requestedLocationType === ListOffersLocationTypesEnum.Fixed;

  const offerBookingEventValues = useMemo(() => {
    return {
      ...EMPTY_OFFER_BOOKING_EVENT,
      eventDetails: {
        ...EMPTY_EVENT_DETAILS,
        fixedLocation,
        firstName,
        lastName
      },
      offers: offers.map((offer) => ({
        offerId: offer.id,
        online: withOnlineParticipation
      })),
      startTime: selectedTimeRange?.startTime ?? undefined,
      endTime: selectedTimeRange?.endTime ?? undefined,
      requestStartTime: requestedTimeRange?.startTime || undefined,
      requestEndTime: requestedTimeRange?.endTime || undefined,
      geoAreaId: requestedGeoAreaId,
      organizationId
    };
  }, [
    fixedLocation,
    firstName,
    lastName,
    offers,
    selectedTimeRange?.startTime,
    selectedTimeRange?.endTime,
    requestedTimeRange?.startTime,
    requestedTimeRange?.endTime,
    requestedGeoAreaId,
    organizationId,
    withOnlineParticipation
  ]);

  if (offers.length === 0) {
    return (
      <ContentWrapper>
        <BreadcrumbNavigation />
        <Typography variant="h1">
          {getOfferBookingText('bookingList')}
        </Typography>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          sx={{ py: 9 }}
        >
          <Typography variant="h5" fontWeight="600" color="primary.light">
            {getOfferBookingText('bookingListEmpty')}
          </Typography>

          <ContentPasteIcon
            sx={{ color: 'primary.light', width: 125, height: 140 }}
          />
        </Stack>
        {createEvent.data && (
          <BookingSuccessDialog
            showSuccessDialog={showSuccessDialog}
            encryptedEvent={createEvent.data}
            onClose={() => setShowSuccessDialog(false)}
            isHomevisit={
              requestedLocationType === ListOffersLocationTypesEnum.Mobile
            }
          />
        )}
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <PrivateKeyDialog
        showDialog={showPrivateKeyDialog}
        organizationId={organizationId}
        closeDialog={() => {
          navigate(-1);
        }}
        isOrganizationAdmin={perspective.data?.canConfigureEncryption ?? false}
      />
      <BreadcrumbNavigation />
      <SchemaProvider<OfferBookingEvent>
        defaultValues={EMPTY_OFFER_BOOKING_EVENT}
        values={offerBookingEventValues}
        schema={OfferBookingEventSchema}
      >
        {({ handleSubmit, getValues, setValue, setValueValidating }) => {
          // instead of undefined getValue() returns an empty string, but TS does not notice that
          const valueRangeStart = getValues('requestStartTime') || undefined;
          const valueRangeEnd = getValues('requestEndTime') || undefined;

          const appointmentSelection = (
            <OfferBookingTimeSlots
              offerIds={offers.map((offer) => offer.id)}
              offerDuration={totalDuration}
              timeRange={{
                startTime: valueRangeStart,
                endTime: valueRangeEnd
              }}
              setValue={setValue}
              setValueValidating={setValueValidating}
            />
          );

          return (
            <Grid container spacing={{ md: 8, xl: 16 }}>
              <Grid item xs={12} md={7}>
                <Stack gap={4}>
                  <Typography variant="h1">
                    {getOfferBookingText('bookingList')}
                  </Typography>
                  <Box>
                    <Typography variant="bookingSectionHeading">
                      {getOfferBookingText('offers')}
                    </Typography>
                    <List dense={false}>
                      <Divider />
                      {offers.map((offer, idx) => (
                        <React.Fragment key={`offer-fragment-${offer.id}`}>
                          <ListItem
                            key={`offer-${offer.id}`}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => removeOfferFromList(offer.id)}
                                key={`offer-${offer.id}-delete-button`}
                              >
                                <DeleteIcon
                                  key={`offer-${offer.id}-delete-icon`}
                                />
                              </IconButton>
                            }
                          >
                            <ListItemText
                              key={`offer-${offer.id}-title`}
                              primary={offer.title}
                              secondary={
                                offer.duration
                                  ? formatAsDurationString(offer.duration)
                                  : ''
                              }
                            />
                          </ListItem>
                          <Divider key={`offer-divider-${idx + 1}`} />
                        </React.Fragment>
                      ))}
                    </List>
                  </Box>
                  <ValidatedSwitch
                    checkedValue={true}
                    uncheckedValue={false}
                    name="offers[0].online"
                    render={({ field }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={withOnlineParticipation}
                              {...field}
                              data-cy={`${offerBookingDataCy}-video-participation-switch`}
                              onChange={(event) =>
                                setWithOnlineParticipation(event.target.checked)
                              }
                            />
                          }
                          label={getOfferBookingText('videoParticipation')}
                        />
                      );
                    }}
                  />
                  <Box>
                    <Typography variant="bookingSectionHeading">
                      {getOfferBookingText('patientData')}
                    </Typography>
                    <Stack
                      spacing={{ xs: 1.5, sm: 2 }}
                      sx={{ mt: 2 }}
                      direction={{ xs: 'column', sm: 'row' }}
                    >
                      <ValidatedTextField
                        name="eventDetails.firstName"
                        render={({ field, props }) => {
                          const { errorMessage, ...fieldProps } = props;
                          return (
                            <TextField
                              fullWidth
                              label={`${getOfferBookingText('firstName')}*`}
                              helperText={errorMessage}
                              inputProps={{
                                'data-cy': `${offerBookingDataCy}-first-name`
                              }}
                              {...field}
                              {...fieldProps}
                              onBlur={(event) =>
                                setFirstName(event.target.value)
                              }
                            />
                          );
                        }}
                      />

                      <ValidatedTextField
                        name="eventDetails.lastName"
                        render={({ field, props }) => {
                          const { errorMessage, ...fieldProps } = props;
                          return (
                            <TextField
                              fullWidth
                              label={`${getOfferBookingText('lastName')}*`}
                              helperText={errorMessage}
                              inputProps={{
                                'data-cy': `${offerBookingDataCy}-last-name`
                              }}
                              {...field}
                              {...fieldProps}
                              onBlur={(event) =>
                                setLastName(event.target.value)
                              }
                            />
                          );
                        }}
                      />
                    </Stack>
                  </Box>
                  {isAtProviderLocation ? (
                    offers[0].fixedLocation && (
                      <Box>
                        <Typography
                          variant="bookingSectionHeading"
                          component="h2"
                          sx={{ mb: 2 }}
                        >
                          {getOfferBookingText('location')}
                        </Typography>
                        <Address address={offers[0].fixedLocation} />
                      </Box>
                    )
                  ) : (
                    <FixedLocationForm
                      prefix="eventDetails."
                      dataCy={offerBookingDataCy}
                      spacing={2}
                    />
                  )}
                  <FormControl>
                    <FormHelperText>{getText('requiredFields')}</FormHelperText>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack gap={4}>
                  <Paper sx={{ p: 3 }} key="summary">
                    <Typography variant="bookingSectionHeading">
                      {getOfferBookingText('overview')}
                    </Typography>
                    <List dense={false}>
                      <ListItem disablePadding key="overview-offer-sum">
                        <ListItemIcon>
                          <AssignmentIcon sx={{ color: 'secondary.main' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${offers.length} ${
                            offers.length > 1
                              ? getOfferBookingText('offers')
                              : getOfferBookingText('offer')
                          }`}
                        />
                      </ListItem>
                      <ListItem disablePadding key="overview-duration-sum">
                        <ListItemIcon>
                          <AccessTimeFilledIcon
                            sx={{ color: 'secondary.main' }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={formatAsDurationString(totalDuration)}
                        />
                      </ListItem>

                      {getValues('offers[0].online') && (
                        <ListItem disablePadding key="overview-is-video">
                          <ListItemIcon>
                            <VideoCameraFrontIcon
                              sx={{ color: 'secondary.main' }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={getOfferBookingText('withVideo')}
                          />
                        </ListItem>
                      )}

                      <ListItem disablePadding key="overview-location-type">
                        <ListItemIcon>
                          <PlaceIcon sx={{ color: 'secondary.main' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            isAtProviderLocation
                              ? getOfferBookingText('providerLocation')
                              : getText('title', 'offer-details-mobileLocation')
                          }
                        />
                      </ListItem>

                      <ListItem disablePadding key="overview-offerer">
                        <ListItemIcon>
                          <PersonIcon sx={{ color: 'secondary.main' }} />
                        </ListItemIcon>
                        <Stack
                          direction={{ xs: 'column', xl: 'row' }}
                          sx={{ gap: 1 }}
                        >
                          <ListItemText
                            primary={getOfferBookingText('personLabel')}
                          />
                          {offers[0].organization && (
                            <LinkWithAvatar
                              avatarSrc={
                                offers[0].organization?.logo?.urls.small ?? ''
                              }
                              name={offers[0].organization?.name}
                              url={getProviderProfileUrl(
                                offers[0].organizationId
                              )}
                              isOrganization
                            />
                          )}
                        </Stack>
                      </ListItem>
                    </List>
                  </Paper>
                  <Paper sx={{ p: 3 }} key="timeslot">
                    <Typography variant="bookingSectionHeading">
                      {getOfferBookingText('chooseTimeslot')}
                    </Typography>
                    {appointmentSelection}
                  </Paper>
                  <LoadingButton
                    dataCy={`${offerBookingDataCy}-submit`}
                    label={getOfferBookingText('confirm')}
                    disabled={createEvent.isLoading}
                    loading={createEvent.isLoading}
                    onClick={handleSubmit(create, (data) => console.log(data))}
                  />
                </Stack>
              </Grid>
            </Grid>
          );
        }}
      </SchemaProvider>
    </ContentWrapper>
  );
};

export default OfferBookingPage;
