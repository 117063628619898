import { useState, useEffect, useRef, ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  SearchParams,
  getTrimmedSearchTerm,
  type UrlParams,
  type SetSearchParamWithPageSetback
} from 'utils/url-param';
import { getText } from 'localization';

type SearchBarFilterProps = {
  search: UrlParams;
  setSearchParamWithPageSetback: SetSearchParamWithPageSetback;
};

const SearchBarFilter = ({
  search,
  setSearchParamWithPageSetback
}: SearchBarFilterProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const passedQueryTerm = search[SearchParams.query] ?? '';

  const [searchTerm, setSearchTerm] = useState(passedQueryTerm);

  // necessary when the navigation comes from the search bar
  useEffect(() => {
    setSearchTerm(passedQueryTerm);
  }, [passedQueryTerm]);

  const handleChangeSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    const trimmedSearchTerm = getTrimmedSearchTerm(searchTerm);

    if (trimmedSearchTerm) {
      setSearchParamWithPageSetback({
        [SearchParams.query]: trimmedSearchTerm
      });

      // force blur to close a virtual keyboard
      setTimeout(() => ref.current?.blur(), 0);
    }
  };

  const handleClearSearchClick = () => {
    if (passedQueryTerm) {
      setSearchParamWithPageSetback({ [SearchParams.query]: '' });
    } else {
      setSearchTerm('');
    }
  };

  return (
    <TextField
      inputRef={ref}
      sx={{
        width: { xs: '100%', md: '70%' },
        '& .MuiOutlinedInput-root:hover': {
          '& > fieldset': {
            borderColor: 'secondary.main'
          }
        },
        '& .MuiOutlinedInput-root': {
          height: 42
        }
      }}
      variant="outlined"
      placeholder={getText('searchBarPlaceholder', 'offer')}
      color="secondary"
      value={searchTerm}
      onChange={handleChangeSearchTerm}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          handleSearchClick();
        }
        if (event.key === 'Escape') {
          handleClearSearchClick();
        }
      }}
      inputProps={{ inputMode: 'search' }}
      InputProps={{
        sx: {
          pr: '0 !important',
          borderRadius: 1.75,
          '& input': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }
        },
        endAdornment: (
          <>
            {searchTerm && (
              <IconButton
                aria-label="clear search input"
                onClick={handleClearSearchClick}
              >
                <CancelRoundedIcon sx={{ color: 'secondary.main' }} />
              </IconButton>
            )}
            <Button
              aria-label="search"
              onClick={handleSearchClick}
              variant="contained"
              color="secondary"
              disableElevation
              sx={{
                alignItems: 'center',
                borderRadius: '0 7px 7px 0',
                ml: 0.5,
                width: 5.25,
                minWidth: 'unset',
                height: 42
              }}
            >
              <SearchIcon fontSize="medium" />
            </Button>
          </>
        )
      }}
    />
  );
};

export default SearchBarFilter;
