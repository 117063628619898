import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { useProject } from 'context/project';
import {
  useGetPortalOrganizationProfileHref,
  useIsProvider,
  useUser
} from 'context/user';
import { useOnboarding } from 'context/onboarding';
import { stripDashes } from 'utils/helper';
import { getProviderProfileEditUrl, getSeekerProfileEditUrl } from 'routes';
import IconItem from './icon-item';
import {
  INIT,
  PROFILE,
  KEY,
  OFFER,
  type StepType,
  type SetType
} from './config';
import { useOnboardingLogic } from 'feature/onboarding';

import { getNode, getText, getTextFx, getPath } from 'localization';
import { ReactElement } from 'react';

const Content = ({
  showKeyStep,
  setType
}: {
  showKeyStep: boolean;
  setType: SetType;
}) => {
  const { project } = useProject();

  return (
    <Stack sx={{ alignItems: 'center' }}>
      <WavingHandIcon sx={{ color: 'primary.main', fontSize: '3.5rem' }} />

      <Box
        sx={{
          fontSize: 35,
          pt: 2,
          px: 3
        }}
      >
        <Typography
          variant="h2"
          align="center"
          sx={{ fontSize: '35px !important' }}
        >
          {getTextFx(
            'titleStart',
            'onboarding-dialog'
          )({ projectName: project?.name })}
        </Typography>
      </Box>

      <Box sx={{ fontSize: 16, textAlign: 'center' }}>
        {getTextFx(
          'getStepsDescriptionStart',
          'onboarding-dialog'
        )({
          stepsNumber: getText(
            `stepsNumber${showKeyStep ? 3 : 2}`,
            'onboarding-dialog'
          )
        })}
      </Box>

      <Stack direction="row" pt={6} pb={8} px={2}>
        <IconItem name={PROFILE} setType={setType} />
        {showKeyStep && <IconItem name={KEY} setType={setType} />}
        <IconItem name={OFFER} setType={setType} />
      </Stack>
    </Stack>
  );
};

type OnClose = (reason: string) => void;
type OnCloseProp = { onClose: VoidFunction };

type ContentStepProps = {
  name: string;
  children: ReactElement;
};

const ContentStep = ({ name, children }: ContentStepProps) => {
  const { successMode } = useOnboarding();

  return (
    <Stack sx={{ alignItems: 'center' }}>
      <IconItem name={name} success={successMode} noFixWidth disabled />

      {!successMode && (
        <Box
          sx={{
            pt: 5,
            pb: 10,
            px: 3
          }}
        >
          {children}
        </Box>
      )}
    </Stack>
  );
};

const ContentStepProfile = ({ onClose }: OnCloseProp) => {
  const { perspective } = useUser();
  const isProvider = useIsProvider();

  return (
    <>
      {getNode(
        'getNodeProfile',
        'onboarding-dialog'
      )({
        profileLink: (text: string) => (
          <Link
            component={RouterLink}
            to={
              isProvider
                ? getProviderProfileEditUrl(perspective.id)
                : getSeekerProfileEditUrl(perspective.id)
            }
            onClick={onClose}
          >
            {text}
          </Link>
        )
      })}
    </>
  );
};

const ContentStepKey = ({ onClose }: OnCloseProp) => {
  const { project } = useProject();
  const { perspective } = useUser();
  const projectName = project?.name;

  const isOrgAdmin = perspective.data?.canConfigureEncryption ?? false;
  const userPortalHref = useGetPortalOrganizationProfileHref(
    perspective.id,
    '&showEncryption'
  );

  const keyLink = (text: string) => (
    <Link component={RouterLink} to={getPath('events')} onClick={onClose}>
      {text}
    </Link>
  );

  return (
    <>
      {isOrgAdmin
        ? getNode(
            'getNodeKeyAdmin',
            'onboarding-dialog'
          )({
            projectName,
            portalLink: (text: string) => (
              <Link
                component={RouterLink}
                to={userPortalHref}
                onClick={onClose}
              >
                {text}
              </Link>
            ),
            keyLink
          })
        : getNode(
            'getNodeKey',
            'onboarding-dialog'
          )({
            projectName,
            keyLink
          })}
    </>
  );
};

const ContentStepOffer = ({ onClose }: OnCloseProp) => {
  const { perspective } = useUser();
  const { addFlag } = useOnboardingLogic();

  const isSeeker = perspective.roles?.isSeeker;

  return (
    <>
      {getNode(
        isSeeker ? 'getNodeOfferSeeker' : 'getNodeOffer',
        'onboarding-dialog'
      )({
        offerLink: (text: string) => (
          <Link
            component={RouterLink}
            to={`/${getPath('offers')}/`}
            onClick={() => {
              onClose();

              if (isSeeker) {
                setTimeout(() => addFlag(OFFER), 600);
              }
            }}
          >
            {text}
          </Link>
        )
      })}
    </>
  );
};

const contentSteps = {
  [PROFILE]: ContentStepProfile,
  [KEY]: ContentStepKey,
  [OFFER]: ContentStepOffer
};

interface OnboardingDialogProps {
  open: boolean;
  type: StepType;
  onClose: OnClose;
  showKeyStep: boolean;
  setType: SetType;
}

const OnboardingDialog = ({
  open,
  type,
  onClose,
  showKeyStep,
  setType
}: OnboardingDialogProps) => {
  const prefix = 'start-onboarding';
  const prefixCy = stripDashes(prefix);
  const maxWidth = 'sm';

  const onClickClose = () => onClose('buttonClick');
  let ContentText;

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => onClose(reason)}
      aria-labelledby={`${prefix}-title`}
      aria-describedby={`${prefix}-description`}
      data-cy={prefixCy}
      maxWidth={maxWidth}
      disableEscapeKeyDown
    >
      <DialogTitle
        id={`${prefix}-title`}
        data-cy={`${prefixCy}-title`}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          pl: 3,
          pr: 1,
          pt: 1,
          pb: 0
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClickClose}
          sx={{
            color: 'black'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {type === INIT ? (
          <Content showKeyStep={showKeyStep} setType={setType} />
        ) : (
          <ContentStep name={type}>
            {(ContentText = contentSteps[type]) && (
              <ContentText onClose={onClickClose} />
            )}
          </ContentStep>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OnboardingDialog;
