import { Box, Button, Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getPath, getText } from 'localization';
import { ContentWrapper } from 'feature';

const ICON_SIZE = { xs: '5rem', md: '10rem' };

export type FeedbackScreenProps = {
  title: string;
  icon: ReactElement;
  text: ReactElement;
  hasHomePageButton?: boolean;
  actionButton?: ReactElement;
  dataCy: string;
};

const FeedbackScreen = ({
  title,
  icon,
  text,
  hasHomePageButton = false,
  actionButton,
  dataCy
}: FeedbackScreenProps) => {
  return (
    <ContentWrapper>
      <Stack
        data-cy={`${dataCy}-feedbackscreen`}
        alignItems="center"
        sx={{ my: { xs: 5, md: 10 } }}
      >
        <Typography variant="h1" data-cy={`${dataCy}-feedbackscreen`}>
          {title}
        </Typography>
        <Box
          sx={{
            svg: {
              width: ICON_SIZE,
              height: ICON_SIZE,
              color: 'primary.main'
            }
          }}
        >
          {icon}
        </Box>
        <Box
          sx={{
            pt: 3,
            maxWidth: 500
          }}
        >
          {text}
        </Box>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={4}
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 10 }}
        >
          {hasHomePageButton && (
            <Button
              variant="outlined"
              component={RouterLink}
              to={getPath('home')}
            >
              {getText('home')}
            </Button>
          )}
          {actionButton}
        </Stack>
      </Stack>
    </ContentWrapper>
  );
};

export default FeedbackScreen;
