import { useQuery } from '@tanstack/react-query';
import { Section } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import useProjectGalleryApi from './useProjectGalleryApi';

const useGetProjectSectionGalleryEntry = (section: Section) => {
  const { getProjectSectionGalleryEntry } = useProjectGalleryApi();
  const projectId = useGetProjectId();

  return useQuery(
    ['sectionImage', { projectId, section }],
    () => {
      return getProjectSectionGalleryEntry({
        projectId: projectId ?? '',
        section
      });
    },

    {
      staleTime: Infinity,
      enabled: !!projectId
    }
  );
};

export default useGetProjectSectionGalleryEntry;
