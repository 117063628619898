import { useCallback, useMemo } from 'react';
import {
  type GetOrganizationProfileRequest,
  OrganizationProfilesApi,
  type ReplaceOrganizationProfileRequest,
  type UpdateOrganizationProfileRequest
} from '@SLR/solution3-sdk';
import { APINames, getApiInstance } from 'utils/api-utils';

const useOrganizationProfilesApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(
      APINames.organizationProfiles
    ) as OrganizationProfilesApi;
  }, []);

  const getOrganizationProfile = useCallback(
    async (requestParameters: GetOrganizationProfileRequest) => {
      try {
        return await apiInstance.getOrganizationProfile(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const replaceOrganizationProfile = useCallback(
    async (requestParameters: ReplaceOrganizationProfileRequest) => {
      try {
        return await apiInstance.replaceOrganizationProfile(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const updateOrganizationProfile = useCallback(
    async (requestParameters: UpdateOrganizationProfileRequest) => {
      try {
        return await apiInstance.updateOrganizationProfile(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getOrganizationProfile,
    replaceOrganizationProfile,
    updateOrganizationProfile
  };
};

export default useOrganizationProfilesApi;
