import { useCallback, useMemo } from 'react';
import {
  type EntityModelGeoArea,
  GeoAreasApi,
  type GetGeoAreaRequest,
  type ListGeoAreasRequest
} from '@SLR/solution3-sdk';
import { APINames, getApiInstance } from 'utils/api-utils';

/**
 * Subset of the only used properties of {@link EntityModelGeoArea}.
 * Should be preferred over {@link EntityModelGeoArea}.
 */
export type GeoArea = Pick<EntityModelGeoArea, 'id' | 'name'>;

const useGeoAreasApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.geoAreas) as GeoAreasApi;
  }, []);

  const getGeoArea = useCallback(
    async (requestParameters: GetGeoAreaRequest) => {
      try {
        return await apiInstance.getGeoArea(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const listGeoAreas = useCallback(
    async (requestParameters: ListGeoAreasRequest) => {
      try {
        return await apiInstance.listGeoAreas(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getGeoArea,
    listGeoAreas
  };
};

export default useGeoAreasApi;
