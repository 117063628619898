import {
  getErrorText,
  getTextGeneric,
  generateGenitive,
  globalConfigKey,
  type GetTextFx,
  type GetNode,
  type GetTextFxParams
} from './utils';
import type { Config, Locales } from './types';
import { setLocale } from 'yup';
import { DE } from './yup-locale';

setLocale(DE);

// get a text leaf as a string with a passed localization object -
//    necessary for cypress as the access to the global config object is delayed
const getTextPreProject = (
  config: Config,
  key: string,
  path?: string
): string => {
  return getTextGeneric(config, key, path) as string;
};

// get a text leaf from the global localization
const getText = (key: string, path?: string): string => {
  return getTextGeneric(
    globalThis[globalConfigKey].config,
    key,
    path
  ) as string;
};

// get a function leaf from the localization to invoke in a second step with params
const getTextFx = (key: string, path?: string): GetTextFx =>
  getTextGeneric(globalThis[globalConfigKey].config, key, path) as GetTextFx;

// get a function leaf from the localization to invoke in a second step with params
const getNode = (key: string, path?: string): GetNode =>
  getTextFx(key, path) as GetNode;

//
/**
 * A multi utility function with switched arguments as a higher order function for a preloaded path
 *
 * @param path The path string
 * @param key The key string
 * @param params If params are defined, the call applies a function call with the passed params,
 *                without params, it returns the string leaf
 * @returns
 */
const getTextIn =
  (path: string) =>
  (key: string, params?: GetTextFxParams): string =>
    // eslint-disable-next-line no-negated-condition
    !params ? getText(key, path) : getTextFx(key, path)(params); // standard case first

/**
 * To get a path string, invoke getPath(key),
 *    for a path function, invoke getPath(getKey, {fxParams}), with the same signature as defined in config ./paths
 */
const getPath = getTextIn('paths');

const getPathSplat = (key: string) => `${getPath(key)}/*`;

const required = (text: string) => `${text} *`;

export {
  generateGenitive,
  getErrorText,
  getText,
  getTextPreProject,
  getTextFx,
  getTextIn,
  getPath,
  getPathSplat,
  getNode,
  required,
  globalConfigKey
};
export type { Config, Locales, GetTextFxParams };
