import { useMemo } from 'react';
import { compile } from 'html-to-text';

const compiledConvert = compile({
  limits: {
    maxInputLength: 10_000
  },
  selectors: [
    // singleNewLineParagraphs
    { selector: 'p', options: { leadingLineBreaks: 1, trailingLineBreaks: 1 } },
    {
      selector: 'pre',
      options: { leadingLineBreaks: 1, trailingLineBreaks: 1 }
    },
    // unorderedListItemPrefix
    { selector: 'ul', options: { itemPrefix: ' - ' } },
    // ignoreHref
    { selector: 'a', options: { ignoreHref: true } },
    { selector: 'img', format: 'skip' }
  ],
  wordwrap: null
});

const useHtmlAsPlainText = (html?: string): string => {
  return useMemo(() => {
    if (!html) {
      return '';
    }
    return compiledConvert(html);
  }, [html]);
};

export default useHtmlAsPlainText;
