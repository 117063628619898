import { type EntityModelOrganizationProfile } from '@SLR/solution3-sdk';
import { useUser } from 'context/user';

const useIsOrganizationOwner = (
  profileData?: EntityModelOrganizationProfile
) => {
  const { perspective } = useUser();
  const organizationId = profileData?.organizationId ?? '';
  const isOwner =
    perspective.isOrganization && organizationId === perspective.id;

  return isOwner;
};

export default useIsOrganizationOwner;
