import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Link, Typography } from '@mui/material';
import LogoMark from './logo-mark';
import { useProject } from 'context/project';

type CombinationMarkProps = {
  addRightMargin?: boolean;
};

const LogoCombined = memo(({ addRightMargin }: CombinationMarkProps) => {
  const { project } = useProject();
  const title = project?.name ?? '';
  const logo = project?.logo;
  const showTitle = project?.showTitle;

  return (
    <Stack
      alignItems="center"
      sx={{
        justifyContent: { xs: 'center', md: 'flex-start' },
        mr: { xs: addRightMargin ? 5.5 : 0, md: 0 },
        maxWidth: { md: 300, lg: 'unset' },
        minWidth: { lg: 330 }
      }}
    >
      <Link
        sx={{
          ml: { md: 0 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none'
        }}
        to="/"
        component={RouterLink}
      >
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          sx={{ height: { xs: 54, md: 70 } }}
        >
          <LogoMark logo={logo} />
          {showTitle && title && (
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 'bold',
                wordBreak: 'normal',
                textAlign: 'left !important',
                display: { xs: 'none', lg: '-webkit-box' },
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 220,
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
              }}
            >
              {title}
            </Typography>
          )}
        </Stack>
      </Link>
    </Stack>
  );
});

export default LogoCombined;
