import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type UpdateArticleCategoryRequest } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { useArticleCategoriesApi } from './use-compass-api';
import { GET_ARTICLE_CATEGORY } from '.';

type UpdateArticleCategory = Omit<UpdateArticleCategoryRequest, 'projectId'>;

const useUpdateArticleCategory = () => {
  const queryClient = useQueryClient();
  const projectId = useGetProjectId();
  const { updateArticleCategory } = useArticleCategoriesApi();

  return useMutation(
    (params: UpdateArticleCategory) => {
      return updateArticleCategory({
        ...params,
        projectId: projectId ?? ''
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_ARTICLE_CATEGORY]);
      }
    }
  );
};

export default useUpdateArticleCategory;
