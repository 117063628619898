import { type CreateProjectImageRequest, ImagesApi } from '@SLR/solution3-sdk';
import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';

const useImagesApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.images) as ImagesApi;
  }, []);

  const createProjectImage = useCallback(
    async (requestParameters: CreateProjectImageRequest) => {
      try {
        return await apiInstance.createProjectImage(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return { createProjectImage };
};

export default useImagesApi;
