import { useState } from 'react';
import {
  Box,
  Container,
  Stack,
  FormLabel,
  useMediaQuery,
  useTheme,
  Chip
} from '@mui/material';
import RemoveButtons from './remove-buttons';
import RemoveHideButton from './remove-hide-button';
import { RemoveFilterButton } from 'components';
import { type UrlParams, type FilterParamsKeyType } from 'utils/url-param';
import { getTextIn } from 'localization';
import { useBooking } from 'context/booking';
import { DispatchBooleanStateAction } from 'types';

const BUTTON_NUMBER_TO_SHOW_SMALL_SCREENS = 1;

const getFilterText = getTextIn('filter');

type RemoveFilterBlockProps = {
  search: UrlParams;
  onRemoveFilter: (key: FilterParamsKeyType, name: string) => VoidFunction;
  onRemoveAllFilters: VoidFunction;
  setDropDownsShouldClose: DispatchBooleanStateAction;
};

const RemoveFilterBlock = ({
  search,
  onRemoveFilter,
  onRemoveAllFilters,
  setDropDownsShouldClose
}: RemoveFilterBlockProps) => {
  const { selectedProvider } = useBooking();
  const [filterButtonsCount, setFilterButtonsCount] = useState<
    number | undefined
  >();

  const [hideFilterChips, setHideFilterChips] = useState(true);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const numberToShow =
    isSmallScreen && hideFilterChips
      ? BUTTON_NUMBER_TO_SHOW_SMALL_SCREENS
      : undefined;

  const numberHiddenButtons = (filterButtonsCount ?? 0) - (numberToShow ?? 0);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mt: { xs: '30px !important', sm: '22px !important', md: 'unset' }
        }}
      >
        <Box
          sx={{
            float: 'right'
          }}
        >
          <Stack direction="row">
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                mt: { xs: 0.5, sm: 0.75, md: 1 },
                display: {
                  xs: numberHiddenButtons ? 'unset' : 'none',
                  md: 'none'
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  height: { xs: '2.25rem', md: 'auto' },
                  justifyContent: 'flex-end'
                }}
              >
                <RemoveHideButton
                  text={hideFilterChips ? `+${numberHiddenButtons}` : ''}
                  setHideButtons={setHideFilterChips}
                />
              </Box>
            </Stack>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                mt: { xs: 0.5, sm: 0.75, md: 1 },
                justifyContent: 'flex-end'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  height: 36,
                  justifyContent: 'flex-end'
                }}
              >
                <RemoveFilterButton
                  text={getFilterText('delete')}
                  onClick={onRemoveAllFilters}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box>
          <Container
            sx={{
              p: '0 !important',
              mx: { xs: -0.25, sm: -0.625, md: -1.25 }
            }}
          >
            <FormLabel
              sx={{
                display: { xs: 'none', sm: 'inline-block' },
                m: 1.25,
                mt: 1.5,
                fontWeight: 500,
                whiteSpace: 'nowrap',
                alignSelf: 'center'
              }}
            >
              {getFilterText('selected')}:
            </FormLabel>

            <RemoveButtons
              search={search}
              onRemoveFilter={onRemoveFilter}
              numberToShow={numberToShow}
              setFilterButtonsCount={setFilterButtonsCount}
              setDropDownsShouldClose={setDropDownsShouldClose}
            />

            {selectedProvider && (
              <Chip
                label={selectedProvider.name ?? getFilterText('provider')}
                variant="outlined"
                sx={{
                  color: 'white',
                  bgcolor: 'primary.main',
                  height: 24,
                  mb: { xs: 'inherit', md: 0.5 },
                  ml: { xs: 0.5, md: 1 },
                  '&.MuiChip-root:hover': {
                    bgcolor: 'primary.dark'
                  },
                  '& .MuiChip-deleteIcon': {
                    color: 'neutral.main',
                    height: 16,
                    width: 16
                  },
                  '&:hover .MuiChip-deleteIcon': {
                    color: 'neutral.dark'
                  }
                }}
              />
            )}
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default RemoveFilterBlock;
