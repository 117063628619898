import { useMemo } from 'react';
import { Section } from '@SLR/solution3-sdk';
import { OfferGrid } from 'feature';
import { EditToggle } from 'feature/editor';
import { HomePageHeroWrapper, InfoSection } from 'feature/info';
import {
  ProcessWrapper,
  useVizLabelsPatient
} from 'feature/process-visualization';
import { PreviewSection, useCardPreviewNumber } from 'feature/cards';
import { useGetOffers } from 'feature/hooks';
import { useIsVisible } from 'feature/permissions';
import { useProject, useSetDocumentTitle } from 'context/project';
import { getText, getTextIn, getPath } from 'localization';

const getInfoText = getTextIn('info');

const SORT_BY = ['created,desc'];

const Home = () => {
  useSetDocumentTitle(getText('home'));
  const { project, isProjectNew } = useProject();
  const offersVisible = useIsVisible(project?.offersVisibility);
  const previewNumber = useCardPreviewNumber();
  const patientVizLabels = useVizLabelsPatient();

  const {
    data: offers,
    isLoading,
    isError
  } = useGetOffers({
    size: 4,
    sort: SORT_BY
  });

  const infoPagesLinkButtons = useMemo(
    () => [
      {
        text: getInfoText('patientInfoShort'),
        linkTo: getPath('patientInfo')
      },
      {
        text: getInfoText('providerInfoShort'),
        linkTo: getPath('providerInfo'),
        hide: isProjectNew
      },
      {
        text: getInfoText('doctorInfoShort'),
        linkTo: getPath('doctorInfo')
      }
    ],
    [isProjectNew]
  );

  return (
    <>
      <HomePageHeroWrapper />

      <ProcessWrapper
        processSection={{
          title: getInfoText('howItWorksSectionTitle'),
          section: Section.HowItWorks,
          linkButtons: infoPagesLinkButtons
        }}
        vizLabels={patientVizLabels}
      >
        <>
          <EditToggle />
          <InfoSection section={Section.Introduction} />
        </>
        <InfoSection
          title={getInfoText('whatToAchieveSectionTitle')}
          section={Section.WhatToAchieve}
        />
        {offersVisible ? (
          <PreviewSection
            title={getText('newOffers', 'offer')}
            showMoreButtonConfig={{
              to: getPath('offers'),
              text: getText('moreOffers', 'offer')
            }}
            coloredBackground={true}
          >
            <OfferGrid
              isError={isError}
              isLoading={isLoading}
              offers={offers?.embedded?.offers?.slice(0, previewNumber)}
              isPreview={true}
            />
          </PreviewSection>
        ) : undefined}
      </ProcessWrapper>
    </>
  );
};

export default Home;
