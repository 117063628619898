import {
  Configuration,
  ParticipantsApi,
  ProjectsApi,
  ProjectSectionsApi,
  ProjectGalleryApi,
  ImagesApi,
  UsersApi,
  OrganizationsApi,
  OrganizationProfilesApi,
  OrganizationProfileGalleryApi,
  OrganizationSettingsApi,
  OffersApi,
  ReportsApi,
  GeoAreasApi,
  MessagesApi,
  LegalApi,
  EventsApi,
  EventSuggestionsApi,
  InvitationsApi,
  BusinessHoursApi,
  CategoriesApi,
  OrganizationKeysApi,
  TargetGroupsApi,
  ArticleCategoriesApi,
  ArticlesApi,
  KeywordsApi
} from '@SLR/solution3-sdk';
import { ConfigurationParameters } from '@SLR/solution3-sdk/src/runtime';
import { API_BASE_URL } from 'auth/settings';
import { getCurrentAccessToken } from 'context/auth';

export enum APINames {
  projects = 'projects',
  projectSections = 'projectSections',
  projectGallery = 'projectGallery',
  users = 'users',
  organizations = 'organizations',
  images = 'images',
  participants = 'participants',
  organizationProfiles = 'organizationProfiles',
  organizationProfileGallery = 'organizationProfileGallery',
  organizationSettings = 'organizationSettings',
  offers = 'offers',
  geoAreas = 'geoAreas',
  message = 'message',
  reports = 'reports',
  legal = 'legal',
  events = 'events',
  eventSuggestion = 'eventSuggestion',
  invitations = 'invitations',
  businessHours = 'businessHours',
  categories = 'categories',
  organizationKeys = 'organizationKeys',
  targetGroups = 'targetGroups',
  articleCategories = 'articleCategories',
  articles = 'articles',
  keywords = 'keywords'
}

export const getApiConfig = (basePath: string, currentOrgId?: string) => {
  const configOptions: ConfigurationParameters = {
    basePath,
    accessToken: getCurrentAccessToken
  };

  if (currentOrgId) {
    configOptions.headers = { 'Current-Organization-ID': currentOrgId };
  }

  return new Configuration(configOptions);
};

export const getApiInstance = (apiName: APINames, currentOrgId?: string) => {
  const apiConfig = getApiConfig(API_BASE_URL, currentOrgId);
  switch (apiName) {
    case APINames.projects:
      return new ProjectsApi(apiConfig);
    case APINames.projectSections:
      return new ProjectSectionsApi(apiConfig);
    case APINames.projectGallery:
      return new ProjectGalleryApi(apiConfig);
    case APINames.users:
      return new UsersApi(apiConfig);
    case APINames.organizations:
      return new OrganizationsApi(apiConfig);
    case APINames.participants:
      return new ParticipantsApi(apiConfig);
    case APINames.organizationProfiles:
      return new OrganizationProfilesApi(apiConfig);
    case APINames.organizationProfileGallery:
      return new OrganizationProfileGalleryApi(apiConfig);
    case APINames.organizationSettings:
      return new OrganizationSettingsApi(apiConfig);
    case APINames.images:
      return new ImagesApi(apiConfig);
    case APINames.offers:
      return new OffersApi(apiConfig);
    case APINames.geoAreas:
      return new GeoAreasApi(apiConfig);
    case APINames.message:
      return new MessagesApi(apiConfig);
    case APINames.reports:
      return new ReportsApi(apiConfig);
    case APINames.legal:
      return new LegalApi(apiConfig);
    case APINames.events:
      return new EventsApi(apiConfig);
    case APINames.eventSuggestion:
      return new EventSuggestionsApi(apiConfig);
    case APINames.invitations:
      return new InvitationsApi(apiConfig);
    case APINames.businessHours:
      return new BusinessHoursApi(apiConfig);
    case APINames.categories:
      return new CategoriesApi(apiConfig);
    case APINames.organizationKeys:
      return new OrganizationKeysApi(apiConfig);
    case APINames.targetGroups:
      return new TargetGroupsApi(apiConfig);
    case APINames.articleCategories:
      return new ArticleCategoriesApi(apiConfig);
    case APINames.articles:
      return new ArticlesApi(apiConfig);
    case APINames.keywords:
      return new KeywordsApi(apiConfig);
    default:
      throw new Error('Invalid APIName');
  }
};
