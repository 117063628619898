import { getTextIn } from 'localization';
import { SegmentsGrid } from 'feature/forms';
import { CATEGORIES } from 'feature/hooks';

const getCategoriesText = getTextIn('settings-categories');

const SettingsCategories = () => {
  return (
    <SegmentsGrid
      segmentType={CATEGORIES}
      dataCyPrefix="settings-categories"
      getSpecificText={getCategoriesText}
    />
  );
};

export default SettingsCategories;
