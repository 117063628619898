import {
  type EventCreate,
  type UserInfo,
  type GpsPosition,
  type EventRelatedOffer
} from '@SLR/solution3-sdk';
import { ObjectSchema, mixed, date, object, string } from 'yup';
import {
  EMPTY_FIXED_LOCATION,
  FixedLocationSchema,
  EMPTY_MOBILE_LOCATION,
  EMPTY_ONLINE_LOCATION,
  EMPTY_OFFER
} from '../model';
import { getTextIn } from 'localization';
import { ChangeTypeOfKeys } from 'types';

const getOfferText = getTextIn('offer-details');

type CreateEvent = { [P in keyof EventCreate]?: EventCreate[P] | null };
type OfferBookingEvent = ChangeTypeOfKeys<
  CreateEvent,
  'eventDetails',
  Omit<UserInfo, 'initializationVector'>
>;

const EMPTY_EVENT_DETAILS: UserInfo = Object.freeze({
  firstName: '',
  lastName: '',
  gpsPosition: {
    latitude: 0,
    longitude: 0
  },
  fixedLocation: EMPTY_FIXED_LOCATION,
  initializationVector: ''
});

const EMPTY_OFFER_BOOKING_EVENT: OfferBookingEvent = Object.freeze({
  eventDetails: EMPTY_EVENT_DETAILS,
  geoAreaId: '',
  offers: [],
  requestStartTime: undefined,
  requestEndTime: undefined,
  organizationId: ''
});

const EventDetailsSchema = (): ObjectSchema<
  Omit<UserInfo, 'initializationVector'>
> =>
  object().shape({
    firstName: string().required().min(1).label(getOfferText('firstName')),
    lastName: string().required().min(1).label(getOfferText('lastName')),
    gpsPosition: mixed<GpsPosition>().required(),
    fixedLocation: FixedLocationSchema().defined().required()
  });

const OfferBookingEventSchema = (): ObjectSchema<OfferBookingEvent> =>
  object().shape({
    eventDetails: EventDetailsSchema().defined().required(),
    geoAreaId: string().required(),
    offers: mixed<EventRelatedOffer[]>().required(),
    startTime: date().defined().required(),
    endTime: date().defined().required(),
    requestStartTime: date().defined().notRequired(),
    requestEndTime: date().defined().notRequired(),
    organizationId: string().required()
  });

export {
  type OfferBookingEvent,
  OfferBookingEventSchema,
  EMPTY_MOBILE_LOCATION,
  EMPTY_FIXED_LOCATION,
  EMPTY_ONLINE_LOCATION,
  EMPTY_OFFER,
  EMPTY_OFFER_BOOKING_EVENT,
  EMPTY_EVENT_DETAILS
};
