import { Typography } from '@mui/material';
import { DialogGeneric } from 'feature';
import { getText, getTextIn } from 'localization';

const getOfferDeleteText = getTextIn('offer-details-delete');

interface Props {
  open: boolean;
  progress: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const OfferDeleteDialog = ({ open, progress, onConfirm, onCancel }: Props) => {
  const deleteOfferText = getText('deleteOffer', 'offer');
  return (
    <DialogGeneric
      open={open}
      title={deleteOfferText}
      onConfirm={onConfirm}
      onClose={onCancel}
      confirmColor="error"
      confirmText={deleteOfferText}
      prefix="delete-offer-alert-dialog"
      prefixCy="offerdeletedialog"
      isLoading={progress}
    >
      <Typography>{getOfferDeleteText('question')}</Typography>
    </DialogGeneric>
  );
};

export default OfferDeleteDialog;
