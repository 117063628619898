import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Chip, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import type { CheckboxStateValues } from 'components';
import {
  ContentWrapper,
  Filter,
  OfferGrid,
  Pagination,
  SearchBarFilter,
  type OnClickGeoArea
} from 'feature';
import { useGetOffers, useListSegments } from 'feature/hooks';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import {
  FilterTimeType,
  type DateFilter,
  type DurationFilter,
  AlterFilterDialog
} from 'feature/filter';
import {
  useGetPortalOrganizationProfileHref,
  useIsSeeker,
  useUser
} from 'context/user';
import {
  CATEGORIES,
  TARGET_GROUPS,
  OTHER,
  WITHOUT,
  useUrlParams,
  SearchParams,
  getSortParam,
  setSearchParamWithPageSetbackFor,
  convertCheckBoxesStateToUrlParamString,
  convertSearchToQueryParams,
  FilterParamsCheckbox,
  type FilterParamsKeyType,
  type SearchParamType,
  type KeyCheckboxParam,
  getFilterCheckboxValues
} from 'utils/url-param';
import { customDurationToIso, addMissingLeadingZero } from 'utils/duration';
import { KeyValueString } from 'types';
import { getPath, getTextIn } from 'localization';
import { useSetDocumentTitle, useProject } from 'context/project';
import { transformToFragment } from 'utils/fragments-utils';
import { useBooking } from 'context/booking';
import { isEmptyArray } from 'utils/helper';
import { ListOffersLocationTypesEnum } from '@SLR/solution3-sdk';

// conversion url search params to query (backend call signature) params
const SEARCH_PARAMS_CONVERSION_TABLE = {
  [SearchParams.page]: 'page',
  [SearchParams.query]: 'query',
  [SearchParams.locationTypes]: 'locationTypes',
  [SearchParams.categories]: 'categories',
  [SearchParams.targetGroups]: 'targetGroups',
  [SearchParams.geoAreaId]: 'geoAreaId',
  [SearchParams.distance]: 'distance',
  [SearchParams.time]: 'time',
  [SearchParams.timeRange]: 'timeRange',
  [SearchParams.duration]: 'duration'
};

const getOfferText = getTextIn('offer');
const getSegmentsText = getTextIn('settings-segments');

const convertFilterDuration = (queryParams: SearchParamType) => {
  const { duration } = queryParams;

  if (duration) {
    const [fromDuration, toDuration] = duration;
    const lowestDuration = customDurationToIso(fromDuration);
    const highestDuration = customDurationToIso(toDuration);
    delete queryParams.duration;

    if (lowestDuration) {
      queryParams.lowestDuration = lowestDuration;
    }

    if (highestDuration) {
      queryParams.highestDuration = highestDuration;
    }
  }
};

const convertFilterTime = (queryParams: SearchParamType) => {
  const { timeRange, time } = queryParams;

  if (timeRange) {
    const [fromDate, toDate] = timeRange;
    delete queryParams.timeRange;

    if (fromDate) {
      queryParams.fromDate = fromDate;
    }

    if (toDate) {
      queryParams.toDate = toDate;
    }
  } else if (time) {
    queryParams.fromDate = time;
    queryParams.toDate = time;
    delete queryParams.time;
  }
};

type ConvertedValuesAcc = { filtered: string[]; hasOther: boolean };

const getConvertedValues = (values: string[]) =>
  values.reduce(
    (acc: ConvertedValuesAcc, elem: string) => {
      if (elem === OTHER) {
        acc.hasOther = true;
      } else {
        acc.filtered.push(elem);
      }
      return acc;
    },
    {
      filtered: [],
      hasOther: false
    }
  );

const convertFilterVariableCheckboxes = (
  queryParams: SearchParamType,
  key: KeyCheckboxParam,
  fixPathId = ''
) => {
  const { [SearchParams[key]]: queryParamsValues } = queryParams;
  const values = fixPathId ? [fixPathId] : queryParamsValues;

  delete queryParams[SearchParams[key]];

  if (values) {
    const convertedValues = getConvertedValues(values);

    if (!isEmptyArray(convertedValues.filtered)) {
      queryParams[key] = convertedValues.filtered;
    }

    if (convertedValues.hasOther) {
      queryParams[WITHOUT[key]] = convertedValues.hasOther;
    }
  }
};

const OffersPage = () => {
  useSetDocumentTitle(getOfferText('offers'));

  const { project } = useProject();
  const { mayCreateOffers, mayBookOffers, showCreateOffersHint, perspective } =
    useUser();

  const userPortalHref = useGetPortalOrganizationProfileHref(
    perspective.id,
    '&showEncryption'
  );
  const { offers: bookinglist, savedQueryParams } = useBooking();
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [showBookingHint, setShowBookingHint] = useState(
    localStorage.getItem('showBookingHint') !== 'false'
  );
  const [search, setSearch] = useUrlParams();
  const [alterFilterCallback, setAlterFilterCallback] = useState<
    VoidFunction | undefined
  >();
  const isSeeker = useIsSeeker();
  const bookingInProgress = bookinglist.length > 0 && isSeeker;
  const { userId, organizationId, category } = useParams();
  const navigate = useNavigate();

  const { data: categories } = useListSegments(CATEGORIES);
  const { data: targetGroups } = useListSegments(TARGET_GROUPS);

  const pathCategory = useMemo(
    () => categories?.find((c) => transformToFragment(c.name) === category),
    [categories, category]
  );

  // Navigate back to offers page and replace the history in case the path includes a category that is not found in the existing categories
  useEffect(() => {
    if (category && !pathCategory) {
      navigate('/' + getPath('offers'), { replace: true });
    }
  }, [category, navigate, pathCategory]);

  const setSearchParamWithPageSetback = setSearchParamWithPageSetbackFor(
    search,
    setSearch
  );

  const handlePageClick = (newPage: number) => {
    // remove page search param for page 0
    setSearchParamWithPageSetback(
      Boolean(newPage) ? { [SearchParams.page]: String(newPage) } : {},
      Boolean(newPage)
    );
  };

  const queryParams = convertSearchToQueryParams(
    search,
    SEARCH_PARAMS_CONVERSION_TABLE
  );

  const { page } = queryParams;

  // Note: the convertFilter functions are not pure functions, the calls change the queryParams object
  convertFilterTime(queryParams);
  convertFilterDuration(queryParams);
  convertFilterVariableCheckboxes(queryParams, TARGET_GROUPS);
  convertFilterVariableCheckboxes(queryParams, CATEGORIES, pathCategory?.id);

  const {
    data: offers,
    isLoading,
    isError
  } = useGetOffers({
    ...queryParams,
    user: userId,
    organization: organizationId,
    size: rowsPerPage,
    sort: getSortParam(search)
  });

  const totalElements = offers?.page?.totalElements;
  const totalPages = offers?.page?.totalPages;

  // when exceeding the page number, make empty call with page setback
  useEffect(() => {
    if (totalPages && totalPages <= page) {
      setSearchParamWithPageSetback({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, totalPages]);

  const restoreSearchParamsFromContext = (storedParams: SearchParamType) =>
    setSearchParamWithPageSetback({
      ...storedParams,
      [SearchParams.timeRange]:
        storedParams.timeRange
          ?.map((rangePoint: Date | string) =>
            typeof rangePoint === 'string'
              ? rangePoint
              : new Date(rangePoint).toISOString()
          )
          .join(',') ?? '',
      [SearchParams.duration]: storedParams.duration?.join(',') ?? '',
      [SearchParams.categories]: storedParams.categories?.join(',') ?? '',
      [SearchParams.loadFilter]: ''
    });

  useEffect(() => {
    if (savedQueryParams && SearchParams.loadFilter) {
      restoreSearchParamsFromContext(savedQueryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams.loadFilter]);

  const onChangeSort = (_: string, value?: string) => {
    setSearchParamWithPageSetback({ [SearchParams.sort]: value || '' });
  };

  const protectMultipleBookingLogic = (fireChange: VoidFunction) => {
    if (bookingInProgress) {
      setAlterFilterCallback(() => fireChange);
    } else {
      fireChange();
    }
  };

  const onClickApplyGeoArea: OnClickGeoArea = (location, distance) => {
    const options: KeyValueString = {};

    if (location) {
      options[SearchParams.geoAreaId] = location.id;
    } else if (location === undefined) {
      options[SearchParams.geoAreaId] = '';
    }

    // set distance or set back distance
    if (distance || search[SearchParams.distance]) {
      options[SearchParams.distance] = distance ? String(distance) : '';
    }

    protectMultipleBookingLogic(() => setSearchParamWithPageSetback(options));
  };

  const onClickApplyCheckbox =
    (filterType: FilterParamsCheckbox) =>
    (checkboxValues: CheckboxStateValues) => {
      const urlString = convertCheckBoxesStateToUrlParamString(checkboxValues);
      if (
        filterType === FilterParamsCheckbox.categories ||
        filterType === FilterParamsCheckbox.targetGroups
      ) {
        setSearchParamWithPageSetback({ [filterType]: urlString });
      } else {
        protectMultipleBookingLogic(() =>
          setSearchParamWithPageSetback({ [filterType]: urlString })
        );
      }
    };

  const onClickApplyDate = (dateFilter: DateFilter) => {
    const options: KeyValueString = {};

    const { rangeFrom, rangeTo, time, timeType } = dateFilter;

    const isTimeFilter = timeType === FilterTimeType.fixedTime;
    const timeRange: Array<string | null> = [null, null];

    if (isTimeFilter) {
      if (time) {
        options[SearchParams.time] = time.toISOString();
      }

      if (search[SearchParams.timeRange]) {
        options[SearchParams.timeRange] = '';
      }
    } else {
      if (rangeFrom || rangeTo) {
        if (rangeFrom) {
          timeRange[0] = rangeFrom.toISOString();
        }

        if (rangeTo) {
          timeRange[1] = rangeTo.toISOString();
        }
        options[SearchParams.timeRange] = timeRange.join(',');
      }

      if (search[SearchParams.time]) {
        options[SearchParams.time] = '';
      }
    }

    protectMultipleBookingLogic(() => setSearchParamWithPageSetback(options));
  };

  const onClickApplyDuration = (durationFilter: DurationFilter) => {
    const options: KeyValueString = {};
    const { durationFrom, durationTo } = durationFilter;

    if (durationFrom || durationTo) {
      options[SearchParams.duration] = [
        addMissingLeadingZero(durationFrom),
        addMissingLeadingZero(durationTo)
      ].join(',');
    }

    setSearchParamWithPageSetback(options);
  };

  const removeFilterCheckbox = (
    filterType: FilterParamsKeyType,
    dropdownValues: CheckboxStateValues,
    name: string,
    checked: boolean
  ) => {
    const updatedStateLevel = {
      ...dropdownValues,
      [name]: checked
    };
    const urlString = convertCheckBoxesStateToUrlParamString(updatedStateLevel);

    setSearchParamWithPageSetback({ [filterType]: urlString });
  };

  const onRemoveFilter =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (filterParam: FilterParamsKeyType, name: string) => () => {
      if (filterParam === SearchParams.geoAreaId) {
        const options: KeyValueString = {
          [SearchParams.geoAreaId]: ''
        };

        if (search.distance) {
          options[SearchParams.distance] = '';
        }

        setSearchParamWithPageSetback(options);
      } else if (filterParam === SearchParams.duration) {
        setSearchParamWithPageSetback({ [SearchParams.duration]: '' });
      } else if (filterParam === SearchParams.timeRange) {
        setSearchParamWithPageSetback({
          [SearchParams.timeRange]: ''
        });
      } else if (filterParam === SearchParams.time) {
        setSearchParamWithPageSetback({
          [SearchParams.time]: ''
        });
      } else {
        removeFilterCheckbox(
          filterParam,
          getFilterCheckboxValues(search, filterParam, {
            categories,
            targetGroups
          }),
          name,
          false
        );
      }
    };

  const isBookingEnabled = Boolean(
    search[SearchParams.geoAreaId] &&
      (search[SearchParams.time] || search[SearchParams.timeRange]) &&
      search[SearchParams.locationTypes] &&
      // remove possibility to book offer as online event
      search[SearchParams.locationTypes] != ListOffersLocationTypesEnum.Online
  );

  const showBookingFeatures = mayBookOffers;

  return (
    <ContentWrapper
      additionalSx={{ maxWidth: { lg: 1140 }, px: { lg: '0 !important' } }}
    >
      <BreadcrumbNavigation
        customItem={
          category
            ? {
                2: {
                  title: pathCategory?.name ?? getSegmentsText('other')
                }
              }
            : undefined
        }
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Typography
          variant="h1"
          sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
        >
          {getOfferText('offers')}
          {!!pathCategory && <Chip color="primary" label={pathCategory.name} />}
        </Typography>
        {showBookingFeatures && showBookingHint && (
          <Alert
            severity="info"
            onClose={() => {
              localStorage.setItem('showBookingHint', 'false');
              setShowBookingHint(false);
            }}
            sx={{
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText'
            }}
          >
            {getOfferText('bookingHint')}
          </Alert>
        )}
      </Stack>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={{ xs: 4, md: 2 }}
        sx={{ pr: { xs: 0 } }}
      >
        {/* search bar/create button */}
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={{ xs: 4, sm: 2 }}
          width="100%"
          sx={{
            pb: { xs: 3, sm: 0 }
          }}
        >
          {!userId && (
            <SearchBarFilter
              search={search}
              setSearchParamWithPageSetback={setSearchParamWithPageSetback}
            />
          )}

          {mayCreateOffers && (
            <Button
              key="create"
              startIcon={<Add />}
              component={Link}
              to={getPath('create')}
              data-cy="offerspage-create"
              sx={{ minWidth: 218, whiteSpace: 'nowrap' }}
            >
              {getOfferText('createOffer')}
            </Button>
          )}
          {showCreateOffersHint && (
            <Alert
              severity="info"
              sx={{
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText'
              }}
            >
              {getOfferText('createOffersHint')}
              {perspective.data?.canConfigureEncryption ? (
                <Link to={userPortalHref}>
                  {getOfferText('createOffersHintAdmin')}
                </Link>
              ) : (
                getOfferText('createOffersHintMember')
              )}
            </Alert>
          )}
        </Stack>

        <Filter
          search={search}
          totalElements={totalElements}
          categories={categories}
          targetGroups={targetGroups}
          setSearchParamWithPageSetback={setSearchParamWithPageSetback}
          onRemoveFilter={onRemoveFilter}
          onClickApplyGeoArea={onClickApplyGeoArea}
          onClickApplyDate={onClickApplyDate}
          onClickApplyDuration={onClickApplyDuration}
          onClickApplyCheckbox={onClickApplyCheckbox}
          onChangeSort={onChangeSort}
          hideCategoryFilter={
            (categories && categories.length === 1) || !!pathCategory
          }
          hasTargetGroup={project?.features?.targetGroups ?? false}
        />
      </Stack>
      <AlterFilterDialog
        showDialog={!!alterFilterCallback}
        onClose={() => {
          setAlterFilterCallback(undefined);
        }}
        alterFilters={() => {
          alterFilterCallback?.();
          setAlterFilterCallback(undefined);
        }}
      />

      <OfferGrid
        isError={isError}
        isLoading={isLoading}
        showBookingButton={showBookingFeatures}
        isBookingEnabled={isBookingEnabled}
        offers={offers?.embedded?.offers}
        query={queryParams.query}
        totalElements={totalElements}
        sx={{ mt: 2 }}
      />

      <Pagination
        count={totalElements}
        page={page}
        setPage={handlePageClick}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </ContentWrapper>
  );
};

export default OffersPage;
export { SEARCH_PARAMS_CONVERSION_TABLE };
