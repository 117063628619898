import type { EntityModelOffer } from '@SLR/solution3-sdk';
import { Button } from '@mui/material';
import AssignmentAddIcon from './assignment-add-icon';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useBooking } from 'context/booking';
import { getTextIn } from 'localization';
import { convertSearchToQueryParams, useUrlParams } from 'utils/url-param';
import { SEARCH_PARAMS_CONVERSION_TABLE } from 'pages/offer/offers-page';

const getOfferText = getTextIn('offer-booking');

const AddBookingListButton = ({
  offer,
  isBookingEnabled
}: {
  offer: EntityModelOffer;
  isBookingEnabled?: boolean;
}) => {
  const [search] = useUrlParams();
  const {
    addOfferToList,
    removeOfferFromList,
    offers: bookingList
  } = useBooking();
  const isOnList = bookingList.some((ofr) => ofr.id === offer.id);

  const queryParams = convertSearchToQueryParams(
    search,
    SEARCH_PARAMS_CONVERSION_TABLE
  );

  return isOnList ? (
    <Button
      disabled={!isBookingEnabled}
      variant="contained"
      size="small"
      data-cy="offer-on-bookinglist-button"
      onClick={() => {
        removeOfferFromList(offer.id);
      }}
      startIcon={<AssignmentTurnedInIcon />}
    >
      {getOfferText('added')}
    </Button>
  ) : (
    <Button
      disabled={!isBookingEnabled}
      variant="outlined"
      size="small"
      data-cy="offer-add-to-bookinglist-button"
      onClick={() => {
        addOfferToList(offer, queryParams);
      }}
      startIcon={<AssignmentAddIcon />}
    >
      {getOfferText('toBookingList')}
    </Button>
  );
};

export default AddBookingListButton;
