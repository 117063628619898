import { type CreateReportRequest } from '@SLR/solution3-sdk';
import { useMutation } from '@tanstack/react-query';
import useReportsApi from './use-reports-api';

const useCreateReport = () => {
  const { createReport } = useReportsApi();

  return useMutation(
    (requestParameters: CreateReportRequest): Promise<void> => {
      return createReport(requestParameters);
    }
  );
};

export default useCreateReport;
