import AssignmentIcon from '@mui/icons-material/Assignment';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const AssignmentAddIcon = () => {
  return (
    <>
      <AssignmentIcon />
      <AddCircleIcon
        sx={{
          width: 12,
          height: 12,
          alignSelf: 'flex-end',
          ml: '-8px',
          mb: '-1px',
          bgcolor: 'white',
          borderRadius: 2
        }}
      />
    </>
  );
};

export default AssignmentAddIcon;
