import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { useSetDocumentTitle } from 'context/project';
import { useIsAdmin } from 'context/user';
import { ContentWrapper, BreadcrumbNavigation, DialogGeneric } from 'feature';
import { useGetArticleCategory, useListArticles } from 'feature/hooks/compass';
import { HeroSection } from 'feature/info';
import { RequestErrorFallback } from 'feature/error';
import ArticleGrid from './article-grid';
import PreviewOffers from './preview-offers';
import CategoryDialog from './category-dialog';
import { useDeleteCategoryMutate } from './hooks';
import { isEmptyArray } from 'utils/helper';
import { getText, getTextIn, getPath, getNode } from 'localization';

const getTextCompass = getTextIn('compass');

const CompassArticleList = () => {
  const isAdmin = useIsAdmin();
  const { categoryId = '' } = useParams();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [title, setTitle] = useState(getTextCompass('categoryDefaultTitle'));

  const articleCategory = useGetArticleCategory({ categoryId });
  const categoryData = articleCategory?.data;
  const categoryName = categoryData?.name;
  const keywords = categoryData?.keywords ?? [];

  useSetDocumentTitle(title);

  // articles
  const { data, isLoading } =
    useListArticles({
      categoryId
    }) ?? {};

  const articles = data?.embedded?.articles;

  useEffect(() => {
    if (categoryName) {
      setTitle(categoryName);
    }
  }, [categoryName]);

  const handleCloseDelete = () => setIsOpenDelete(false);

  const { onDeleteConfirm } = useDeleteCategoryMutate({
    id: categoryId,
    handleClose: handleCloseDelete
  });

  return (
    <RequestErrorFallback queryResult={articleCategory}>
      <HeroSection image={categoryData?.picture} />

      <ContentWrapper>
        {/* title only xs */}
        <Typography variant="h1" sx={{ display: { xs: 'block', sm: 'none' } }}>
          {title}
        </Typography>

        {/* edit + delete category */}
        {isAdmin && (
          <Stack
            direction="row"
            gap={1.25}
            sx={{
              justifyContent: {
                xs: 'center',
                sm: 'end'
              },
              flexWrap: 'wrap',
              mb: { xs: 3, sm: 1 },
              mr: { lg: 0.75 }
            }}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<Edit />}
              onClick={() => setIsOpenEdit(true)}
              sx={{ width: 'auto' }}
              data-cy="compass-action-edit"
            >
              {getTextCompass('editCategory')}
            </Button>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Delete />}
              color="error"
              onClick={() => setIsOpenDelete(true)}
              sx={{ width: 'auto' }}
              data-cy="compass-action-delete"
            >
              {getTextCompass('deleteCategory')}
            </Button>

            {isOpenEdit && (
              <CategoryDialog
                isOpen={isOpenEdit}
                categoryId={categoryId}
                data={categoryData}
                title={getTextCompass('editCompassCategory')}
                setTitle={setTitle}
                confirmText={getText('save')}
                handleClose={() => {
                  setIsOpenEdit(false);
                }}
              />
            )}

            {isOpenDelete && (
              <DialogGeneric
                open={isOpenDelete}
                title={getTextCompass('deleteCompassCategory')}
                confirmText={getTextCompass('deleteCategory')}
                confirmColor="error"
                onClose={handleCloseDelete}
                onConfirm={onDeleteConfirm}
                prefix="compass-category-dialog-delete"
                maxWidth="sm"
              >
                {getNode(
                  'getNodeDeleteQuestion',
                  'compass'
                )({ categoryName: title })}
              </DialogGeneric>
            )}
          </Stack>
        )}

        <BreadcrumbNavigation
          customItem={{
            2: {
              title
            }
          }}
        />

        {/* title except xs */}
        <Typography variant="h1" sx={{ display: { xs: 'none', sm: 'block' } }}>
          {title}
        </Typography>

        {/* create article */}
        {isAdmin && (
          <Stack>
            <Box
              sx={{
                textAlign: { xs: 'center', sm: 'right' },
                mx: { lg: 0.75 },
                mt: { sm: -2.5, md: -5.25 },
                mb: { xs: 3.25, sm: 3.75 }
              }}
            >
              <Button
                startIcon={<Add />}
                component={Link}
                to={getPath('create')}
                data-cy="compass-link-create-article"
                sx={{ width: 242, whiteSpace: 'nowrap' }}
              >
                {getTextCompass('createArticle')}
              </Button>
            </Box>
          </Stack>
        )}

        <ArticleGrid articles={articles} isLoading={isLoading} />
      </ContentWrapper>

      {!isEmptyArray(keywords) && <PreviewOffers keywords={keywords} />}
    </RequestErrorFallback>
  );
};

export default CompassArticleList;
