import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { useOnboarding } from 'context/onboarding';
import { DONE_FLAG } from './logic';
import { PROFILE, KEY, OFFER, type SetType } from './config';
import IconItem from './icon-item';
import { getTextIn } from 'localization';

const getTextOnboarding = getTextIn('onboarding');

type OnboardingPanelProps = {
  showKeyStep: boolean;
  setType: SetType;
  allStepsDone: boolean;
};

const OnboardingPanel = ({
  showKeyStep,
  setType,
  allStepsDone = false
}: OnboardingPanelProps) => {
  const { changeOnboardingState } = useOnboarding();

  return (
    <Card
      sx={{
        width: showKeyStep ? 262 : 190,
        position: 'fixed',
        right: 17,
        marginTop: 1,
        zIndex: 100
      }}
    >
      <CardContent>
        <Stack direction="row">
          <IconItem name={PROFILE} setType={setType} small />
          {showKeyStep && <IconItem name={KEY} setType={setType} small />}
          <IconItem name={OFFER} setType={setType} small />
        </Stack>
      </CardContent>

      {allStepsDone && (
        <CardActions
          sx={{
            fontSize: 12,
            color: 'primary.main',
            px: 3,
            pt: 0,
            pb: 0.5
          }}
        >
          <Stack direction="column">
            <Box
              sx={{
                fontSize: 12,
                color: 'primary.main',
                px: 1
              }}
            >
              {getTextOnboarding('success')}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end'
              }}
            >
              <Button
                size="small"
                variant="text"
                sx={{
                  color: 'secondary.main'
                }}
                onClick={() => changeOnboardingState(DONE_FLAG)}
              >
                {getTextOnboarding('close')}
              </Button>
            </Box>
          </Stack>
        </CardActions>
      )}
    </Card>
  );
};

export default OnboardingPanel;
