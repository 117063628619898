import { Typography } from '@mui/material';
import { ContentWrapper } from 'feature';
import { getText } from 'localization';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import useGetProjectAccessibilityText from 'feature/hooks/legal/useGetProjectAccessibilityText';
import LegalTextBox from './LegalTexts/LegalTextBox';
import { useSetDocumentTitle } from 'context/project';

const AccessibilityDeclarationPage = () => {
  const accessibility = getText('accessibility');
  useSetDocumentTitle(accessibility);

  const { data } = useGetProjectAccessibilityText();

  return (
    <ContentWrapper>
      <BreadcrumbNavigation />
      <Typography variant="h1" textAlign="center">
        {accessibility}
      </Typography>
      <LegalTextBox legalHtml={data ?? ''} />
    </ContentWrapper>
  );
};

export default AccessibilityDeclarationPage;
