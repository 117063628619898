import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  Skeleton,
  Typography
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { type EntityModelOffer } from '@SLR/solution3-sdk';
import { useBooking } from 'context/booking';
import { AddBookingListButton } from 'components';
import { AvatarFallback } from 'feature/user-avatar';
import { SEARCH_PARAMS_CONVERSION_TABLE } from 'pages/offer/offers-page';
import { CustomCard } from '.';
import CardDescription from './card-description';
import { convertSearchToQueryParams, useUrlParams } from 'utils/url-param';
import { Loadable } from 'types';
import { getPath, getText, getTextIn } from 'localization';

const getOfferText = getTextIn('offer-booking');

type OfferCardProps = {
  offer: EntityModelOffer;
  showBookingButton?: boolean;
  isBookingEnabled?: boolean;
};

const AVATAR_SIZE = 56;
const AVATAR_SX = {
  width: AVATAR_SIZE,
  height: AVATAR_SIZE
};

const OfferCard = ({
  offer,
  showBookingButton,
  isBookingEnabled,
  loading
}: Loadable<OfferCardProps>) => {
  const navigate = useNavigate();
  const [search] = useUrlParams();
  const { addOfferToList, isMultiOffersBookable } = useBooking();
  const { id, title, description, organization, category } = offer ?? {};
  const { logo } = organization ?? {};

  const url = getPath('getOffer', {
    category: category?.name,
    offerId: id ?? ''
  });

  const queryParams = convertSearchToQueryParams(
    search,
    SEARCH_PARAMS_CONVERSION_TABLE
  );

  const isOfferExpired = offer?.expired;

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ opacity: isOfferExpired ? 0.5 : 'unset' }}>
        <CustomCard
          href={loading ? '' : url}
          cardActions={
            showBookingButton &&
            offer && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                {isMultiOffersBookable && (
                  <AddBookingListButton
                    offer={offer}
                    isBookingEnabled={isBookingEnabled}
                  />
                )}
                <Button
                  disabled={!isBookingEnabled}
                  variant="outlined"
                  size="small"
                  data-cy="offer-booking-button"
                  onClick={() => {
                    addOfferToList(offer, queryParams);
                    navigate(`/${getPath('booking')}`);
                  }}
                  startIcon={<EventIcon />}
                >
                  {getOfferText('book')}
                </Button>
              </Box>
            )
          }
        >
          <CardContent sx={{ pb: 0 }}>
            <Typography component="h3" variant="customCardTitle">
              {loading ? <Skeleton sx={{ width: '80%' }} /> : title}
            </Typography>
            <CardDescription html={description} loading={loading} />
          </CardContent>
          <CardHeader
            disableTypography
            avatar={
              loading ? (
                <Skeleton variant="circular">
                  <Avatar sx={AVATAR_SX} />
                </Skeleton>
              ) : (
                <Avatar
                  alt={logo?.alternativeText}
                  src={logo?.urls.small}
                  sx={AVATAR_SX}
                >
                  <AvatarFallback isOrganization={!!organization} />
                </Avatar>
              )
            }
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            title={
              <Typography variant="customCardHeaderTitle">
                {loading ? (
                  <Skeleton sx={{ width: '45%' }} />
                ) : (
                  organization?.name
                )}
              </Typography>
            }
          />
        </CustomCard>

        {isOfferExpired && (
          <Box
            sx={{
              bgcolor: '#A9A9A9',
              textAlign: 'center',
              color: 'white',
              position: 'absolute',
              top: 0,
              width: '100%',
              borderRadius: '4px 4px 0 0'
            }}
          >
            {getText('expired', 'offer')}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OfferCard;
