import { useQuery } from '@tanstack/react-query';
import useOrganizationKeysApi from './useOrganizationKeysApi';
import { useGetProjectId } from 'context/project';

const useGetOrganizationSpecificKey = (
  organizationId: string,
  keyId: string
) => {
  const { getOrganizationSpecificKey } = useOrganizationKeysApi();
  const projectId = useGetProjectId();

  return useQuery(
    ['organizationSpecificKey', { projectId, organizationId, keyId }],
    () => {
      return getOrganizationSpecificKey({
        projectId: projectId ?? '',
        organizationId,
        keyId
      });
    },
    {
      enabled: !!projectId && organizationId !== '',
      select: (data) => data,
      retry: 3
    }
  );
};

export default useGetOrganizationSpecificKey;
