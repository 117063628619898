import { Box, Stack, Toolbar } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { SearchBar } from 'feature';
import NavTopbarLinks from './nav-topbar-links';
import { NavLinksBasicProps } from './types';
import { getText } from 'localization';
interface TopbarProps extends NavLinksBasicProps {
  isSearch: boolean;
  onSearchIconClick?: () => void;
}

const Topbar = ({
  mainRoute,
  navigationLinks,
  isSearch,
  onSearchIconClick
}: TopbarProps) => {
  return (
    <Toolbar
      disableGutters
      data-cy="topbar"
      sx={{
        display: { xs: 'none', sm: 'flex' },
        height: { sm: 36, md: 44 },
        minHeight: { sm: 36, md: 44 },
        bgcolor: 'primary.main',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          px: { xs: 1.75, sm: 1.625, md: 4.5 },
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '100%',
          width: 2120
        }}
      >
        <NavTopbarLinks
          mainRoute={mainRoute}
          navigationLinks={navigationLinks}
        />

        {/* search */}
        {isSearch && (
          <Box
            sx={{
              ml: { sm: 1.75, md: 2.5 }
            }}
          >
            <SearchBar
              callback={onSearchIconClick}
              height={30}
              width={200}
              placeholder={getText('navBarSearchBarPlaceholder')}
            />
          </Box>
        )}

        {/* toggle button for search */}
        <Stack
          direction="row"
          sx={{
            height: { sm: 36, md: 44 },
            ml: { sm: 1.75, md: 2.5 },
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
          onClick={onSearchIconClick}
        >
          {isSearch ? (
            <Close sx={{ cursor: 'pointer' }} />
          ) : (
            <Search sx={{ color: 'white', cursor: 'pointer' }} />
          )}
        </Stack>
      </Box>
    </Toolbar>
  );
};
export default Topbar;
