import { PropsWithChildren } from 'react';
import RequestFailed from './request-failed';
import { type UseQueryResult } from '@tanstack/react-query';
import { ResponseError } from '@SLR/solution3-sdk';
import { ErrorPageNotFound } from 'pages/error';

interface ErrorInfo {
  title: string;
  description: string;
}

interface Props {
  info?: ErrorInfo;
  queryResult: UseQueryResult<unknown, Response>;
}

export const unwrapErrorResponse = (
  response?: Response
): Response | undefined => {
  return response instanceof ResponseError ? response.response : response;
};

const RequestErrorFallback = ({
  children,
  queryResult,
  info
}: PropsWithChildren<Props>) => {
  if (queryResult.isError) {
    const errorResponse = unwrapErrorResponse(queryResult.error);
    if (errorResponse?.status === 404) {
      return <ErrorPageNotFound />;
    } else {
      return (
        <RequestFailed
          title={info?.title}
          description={info?.description}
          response={errorResponse}
        />
      );
    }
  }

  return <>{children}</>;
};

export default RequestErrorFallback;
