import { useQuery } from '@tanstack/react-query';
import { type GetGeoAreaRequest } from '@SLR/solution3-sdk';
import useGeoAreasApi from './use-geo-areas-api';

const useGetGeoArea = (geoAreaId: string) => {
  const { getGeoArea } = useGeoAreasApi();
  const requestParameters: GetGeoAreaRequest = {
    geoAreaId
  };

  return useQuery(
    ['geoArea', { geoAreaId }],
    () => {
      return getGeoArea(requestParameters);
    },
    {
      staleTime: Infinity,
      enabled: !!geoAreaId
    }
  );
};

export default useGetGeoArea;
