import { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { PLUGINS, contentCss, contentUiCss, TinyMCEEditor } from './tinymce';
import { Box, type Theme, useTheme } from '@mui/material';
import { useCreateImage } from 'feature/hooks';
import useGetIsMounted from 'utils/use-get-is-mounted';
import { onUploadError } from 'utils/image-utils';
import { getText } from 'localization';

const customStyle = (theme: Theme) =>
  [
    'body { font-family: Roboto, sans-serif; font-size: 1.1667rem; font-weight: 400 }',
    `a { color: ${theme.palette.secondary.main}; }`,
    `h2 { color: ${theme.palette.secondary.main}; }`,
    `img[style*="float: right"] { padding-left: 16px; }`,
    `img[style*="float: left"] { padding-right: 16px; }`
  ].join('\n');

export type TextEditorProps = {
  content?: string;
  placeholderText: string;
  onEditorChange: (value: string) => void;
  showError?: boolean;
  compassEdition?: boolean;
  dataCy?: string;
};

const TextEditor = ({
  content,
  placeholderText,
  onEditorChange,
  showError = false,
  compassEdition = false,
  dataCy
}: TextEditorProps) => {
  const editorRef = useRef<TinyMCEEditor>();
  const theme = useTheme();
  const getIsMounted = useGetIsMounted();
  const addImage = useCreateImage();
  const [newImage, setNewImage] = useState<Blob>(new Blob());

  const toolbar = compassEdition
    ? 'undo redo | customH2 | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | link image | removeformat'
    : 'undo redo | bold italic underline | bullist numlist | link | removeformat';

  return (
    <Box
      sx={[
        {
          background: 'white',
          width: '100%',
          fontSize: {
            xs: 16,
            md: 18
          },
          '.tox-tinymce': {
            border: `1px solid ${
              showError ? 'error.main' : 'rgba(0, 0, 0, 0.25)'
            }`,
            borderRadius: '4px'
          },
          '.tox-tinymce:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87) !important'
          }
        }
      ]}
      data-cy={dataCy}
    >
      <Editor
        onInit={(_, editor) => (editorRef.current = editor)}
        plugins={PLUGINS}
        toolbar={toolbar}
        init={{
          skin: 'oxide',
          language: 'de',
          height: 500,
          placeholder: placeholderText,
          menubar: false,
          statusbar: false,
          quickbars_selection_toolbar:
            'bold italic underline | customH2 | quicklink',
          quickbars_insert_toolbar: false,
          content_style: [
            contentCss,
            contentUiCss,
            customStyle(theme),
            // remove the placeholder on focus to avoid problems with the dictate function on Safari
            `.mce-content-body[data-mce-placeholder]:focus::before {
            content: ''
            }`,
            // the color corresponds to the defined placeholder color darken('#E9E9E9', 0.5)
            `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
              color: #747474;
              }
              `
          ].join('\n'),
          browser_spellcheck: true,
          link_title: false,
          link_target_list: false,
          link_default_target: '_self',
          relative_urls: false,
          remove_script_host: false,
          paste_block_drop: true,
          paste_as_text: true,
          /* enable title field in the Image dialog*/
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          // custom image upload
          images_upload_handler: (blobInfo) => {
            return new Promise((resolve) => {
              if (newImage) {
                addImage.mutate(blobInfo.blob(), {
                  onSuccess: (imageToAdd) => {
                    if (getIsMounted()) {
                      resolve(imageToAdd.urls.original);
                      setNewImage(new Blob());
                    }
                  },
                  onError: onUploadError
                });
              }
            });
          },
          file_picker_types: 'image',
          file_picker_callback: (callback) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            const handler: EventListener = (event) => {
              const target = event.target as unknown as HTMLInputElement;

              if (target.files?.[0]) {
                const file = target.files?.[0] as unknown as Blob;

                const reader = new FileReader();
                reader.addEventListener('load', () => {
                  /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
                  const id = 'blobid' + new Date().getTime();
                  const blobCache = editorRef?.current?.editorUpload.blobCache;
                  const { result } = reader;

                  if (blobCache && typeof result === 'string') {
                    const base64 = result.split(',')[1];
                    const blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    const base64str =
                      'data:' +
                      blobInfo.blob().type +
                      ';base64,' +
                      blobInfo.base64();

                    // populate the source field with the base64 string and the title field with the file name
                    callback(base64str, { title: file?.name });
                  }
                });

                if (file) {
                  reader.readAsDataURL(file);
                  setNewImage(file);
                }
              }
            };

            input.addEventListener('change', handler);
            input.click();
          },

          setup: (editor) => {
            editor.on('focus', function () {
              const element = editor.getContainer();
              if (element) {
                element.style.border = `2px solid ${theme.palette.primary.main}`;
              }
            });
            editor.on('blur', function () {
              const element = editor.getContainer();
              if (element) {
                element.style.border = `1px solid ${
                  showError ? theme.palette.error.main : 'rgba(0, 0, 0, 0.25)'
                }`;
              }
            });
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            compassEdition
              ? editor.ui.registry.addToggleButton('customH2', {
                  text: getText('editorButtonH2'),
                  onAction() {
                    editor.formatter.toggle('h2');
                  },
                  onSetup: (api) => {
                    api.setActive(editor.formatter.match('h2'));
                    const changed = editor.formatter.formatChanged(
                      'h2',
                      (state) => api.setActive(state)
                    );
                    return () => changed.unbind();
                  }
                })
              : undefined;
          }
        }}
        value={content}
        onEditorChange={onEditorChange}
      />
    </Box>
  );
};

export default TextEditor;
export { customStyle };
