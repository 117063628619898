import { type BoxProps } from '@mui/material/Box';
import { useTheme, type Theme } from '@mui/material';

const useStyleOverrides = () => {
  const theme = useTheme();

  const h2StyleOverrides = theme.components?.MuiTypography?.styleOverrides
    ?.h2 as unknown as ({ theme }: { theme: Theme }) => BoxProps;

  return { h2StyleOverrides };
};

export default useStyleOverrides;
