import React from 'react';
import { Typography, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useProject } from 'context/project';
import { useUser } from 'context/user';
import { EditWrapper, TextSectionPlaceholder } from 'feature/editor';
import { getTextIn } from 'localization';

const getEditText = getTextIn('edit');

type HeroTitleProps = {
  slogan: string;
  handleSloganChange?: (newText: string) => void;
  handleSave: VoidFunction;
  onCancel: VoidFunction;
  isSloganFieldVisible: boolean;
  setIsSloganFieldVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdateLoading: boolean;
};

const HeroTitle = ({
  slogan,
  handleSloganChange,
  handleSave,
  onCancel,
  isSloganFieldVisible,
  setIsSloganFieldVisible,
  isUpdateLoading
}: HeroTitleProps) => {
  const { isEditModeOn } = useUser();
  const { project } = useProject();

  const showBackground = project?.slogan || isEditModeOn;

  return (
    <Box
      sx={{
        px: {
          xs: 2,
          sm: 4,
          md: 6,
          lg: 8,
          xxl: 24
        },
        py: {
          xs: 2,
          sm: 4
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: { xs: 75, sm: 105, md: 112, lg: 190 },
        textAlign: 'center',
        backdropFilter: showBackground ? 'blur(8px)' : 'unset',
        border: showBackground ? '1px solid #FFFFFF30' : 'unset',
        borderRadius: 3,
        boxShadow: showBackground ? '0px 3px 6px #00000029' : 'unset',
        background: showBackground
          ? 'transparent linear-gradient(113deg, #FFFFFF7F 0%, #FFFFFF0F 100%) 0% 0% no-repeat padding-box'
          : 'unset'
      }}
    >
      {isEditModeOn ? (
        <EditWrapper
          showFields={isSloganFieldVisible}
          setShowFields={setIsSloganFieldVisible}
          onCancelClicked={onCancel}
          onSaveClicked={handleSave}
          loading={isUpdateLoading}
          disabled={project?.slogan === slogan}
          dataCyPostfix="project-slogan"
          bgcolor="white"
        >
          {isSloganFieldVisible ? (
            <TextField
              id="project-slogan"
              label={getEditText('projectSloganLabel')}
              defaultValue={slogan}
              placeholder={getEditText('editorPlaceholder')}
              fullWidth
              helperText={getEditText('projectSloganHelpText')}
              onChange={(event) => handleSloganChange?.(event.target.value)}
              data-cy="projectSloganTextField"
              inputProps={{ maxLength: 150 }}
            />
          ) : project?.slogan ? (
            <Typography variant="hero" data-cy="projectSlogan">
              {project?.slogan}
            </Typography>
          ) : (
            <TextSectionPlaceholder loading={false} />
          )}
        </EditWrapper>
      ) : (
        <Typography variant="hero" data-cy="projectSlogan">
          {project?.slogan}
        </Typography>
      )}
    </Box>
  );
};

export default HeroTitle;
