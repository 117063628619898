import { useState, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Box, Toolbar } from '@mui/material';
import { useKeycloakAuth } from 'auth';
import { useRouteLinks } from 'routes';
import { Logo } from 'feature';
import Topbar from './nav-topbar';
import NavMainbarLinks from './nav-mainbar-links';
import NavMenu from './nav-menu';
import UserMenu from './user-menu';

const CommonAppBar = ({ children }: { children: ReactNode }) => (
  <AppBar
    position="sticky"
    sx={{
      bgcolor: 'white',
      boxShadow: '0px 3px 6px 0px #00000029',
      justifyContent: 'center',
      textAlign: 'center',
      zIndex: (theme) => theme.zIndex.drawer + 1
    }}
  >
    {children}
  </AppBar>
);

const NavBar = () => {
  const { isAuthenticated } = useKeycloakAuth();
  const topNavigationLinks = useRouteLinks('topNavigationLinks');
  const navigationLinks = useRouteLinks('navigationLinks');

  const location = useLocation();
  const mainRoute = location.pathname.split('/')[1];

  const hasUserMenu = isAuthenticated;
  const [isSearch, setIsSearch] = useState(false);

  const toggleSearchBar = () => {
    setIsSearch(!isSearch);
  };

  return (
    <CommonAppBar>
      <Topbar
        mainRoute={mainRoute}
        navigationLinks={topNavigationLinks}
        isSearch={isSearch}
        onSearchIconClick={toggleSearchBar}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Toolbar
          disableGutters
          data-cy="navbar"
          sx={{
            pl: { xs: 0.5, sm: 3.75, md: 4.5 },
            pr: { xs: 1.5, sm: 3.75, md: 4.5 },
            height: { xs: 72, md: 104 },
            justifyContent: 'space-between',
            width: 2120
          }}
        >
          <NavMenu
            mainRoute={mainRoute}
            navigationLinks={navigationLinks}
            topNavigationLinks={topNavigationLinks}
          />

          <Logo addRightMargin={!hasUserMenu} />

          <NavMainbarLinks
            mainRoute={mainRoute}
            navigationLinks={navigationLinks}
          />

          <UserMenu />
        </Toolbar>
      </Box>
    </CommonAppBar>
  );
};

export default NavBar;
