import { useCallback } from 'react';
import {
  useForm,
  type FieldValues,
  type DefaultValues,
  type FieldPath,
  type FieldPathValue,
  type Control,
  type UseFormGetValues,
  type UseFormSetValue,
  type UseFormHandleSubmit,
  type UseFormReset,
  type UseFormResetField,
  type FormState
} from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export type UseFormSetValueValidating<TFieldValues extends FieldValues> = <
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  name: TFieldName,
  value: FieldPathValue<TFieldValues, TFieldName>
) => void;

export type UseSchemaValidationFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any
> = {
  control: Control<TFieldValues, TContext>;
  formState: FormState<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  setValueValidating: UseFormSetValueValidating<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  reset: UseFormReset<TFieldValues>;
  resetField: UseFormResetField<TFieldValues>;
  handleSubmit: UseFormHandleSubmit<TFieldValues>;
  valid: boolean;
  modified: boolean;
};

/**
 * General information on form handling:
 *
 * Because we are using MUI components, we need to use react hook form [with controlled components/inputs](https://react-hook-form.com/get-started/#IntegratingControlledInputs)
 * and not as default in react hook form with uncontrolled inputs.
 * This means that the input fields must explicitly call the `setValue` method when a change is made,
 * so that the state of the form is updated.
 * References (`ref` property) to the input components are therefore not necessary.
 *
 * The input data, its validity and whether the data has been changed is managed internally by react hooks form,
 * so it does not need to be managed in an additional external state.
 * The initial value of the form is set via `defaultValues` property.
 * The final value is obtained via `handleSubmit` method. Thereby input data is converted to the correct data type by `yup`.
 *
 * Note: React Hook Form [cannot handle `undefined` values](https://react-hook-form.com/api/useform/setvalue/).
 * Therefore, properties that are optional must be defined as nullable in the schema and then converted to `undefined` between the form entity and the API entities.
 *
 * @param defaultValues The initial form data.
 * @param schema The yup scheme for validating and converting form data.
 */
const useSchemaValidationForm = <
  TFieldValues extends FieldValues = FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any
>(
  defaultValues: DefaultValues<TFieldValues>,
  values: TFieldValues | undefined,
  schema: ObjectSchema<TFieldValues>
): UseSchemaValidationFormReturn<TFieldValues, TContext> => {
  const {
    control,
    formState,
    setValue,
    getValues,
    reset,
    resetField,
    handleSubmit
  } = useForm<TFieldValues, TContext>({
    mode: 'onChange',
    defaultValues,
    values,
    resolver: yupResolver(schema)
  });

  const setValueValidating: UseFormSetValueValidating<TFieldValues> =
    useCallback(
      (name, value) => {
        setValue(name, value, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true
        });
      },
      [setValue]
    );

  // //  For debugging the dirty state
  // useEffect(() => {
  //   console.log('Dirty fields: ', formState.isDirty, formState.dirtyFields);
  //   if (formState.dirtyFields) {
  //     console.log('Initial values: ', formState.defaultValues);
  //     console.log('Current values: ', getValues());
  //   }
  // }, [formState, formState.isDirty, getValues]);

  return {
    control,
    formState,
    setValue,
    setValueValidating,
    getValues,
    reset,
    resetField,
    handleSubmit,
    valid: formState.isValid,
    modified: formState.isDirty
  };
};

export default useSchemaValidationForm;
