import { useNavigate } from 'react-router-dom';

const useProjectNavigate = () => {
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateToHome = () => navigate('/');

  return {
    navigateBack,
    navigateToHome
  };
};

export default useProjectNavigate;
