import React from 'react';
import { Box, Skeleton } from '@mui/material';

/**
 * @property lines - The number of lines that should be rendered (default: 1)
 * @property pulse - Whether the skeletons should have a pulse animation (usually for loading visuals) (default: false)
 */
type ParagraphSkeletonProps = {
  lines?: number;
  pulse?: boolean;
};

const ParagraphSkeleton = ({ pulse, lines = 1 }: ParagraphSkeletonProps) => {
  return (
    <Box>
      {new Array(lines).fill(undefined).map((_, idx) => (
        <Skeleton
          animation={pulse ? 'pulse' : false}
          key={`ParagraphSkeleton-${idx}`}
        />
      ))}
    </Box>
  );
};

export default ParagraphSkeleton;
