import React, { ReactElement } from 'react';
import { Box, Button } from '@mui/material';
import { getText } from 'localization';
import CreateIcon from '@mui/icons-material/Create';
import LoadingButton from 'components/loading-button';

type EditWrapperProps = {
  showFields: boolean;
  setShowFields: React.Dispatch<React.SetStateAction<boolean>>;
  onCancelClicked: React.MouseEventHandler<HTMLButtonElement>;
  onSaveClicked: React.MouseEventHandler<HTMLButtonElement>;
  loading: boolean;
  disabled: boolean;
  dataCyPostfix: string;
  children: ReactElement | ReactElement[];
  bgcolor?: string;
};

const EditWrapper = ({
  showFields,
  setShowFields,
  onCancelClicked,
  onSaveClicked,
  loading,
  disabled,
  dataCyPostfix,
  children,
  bgcolor = 'inherit'
}: EditWrapperProps) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '4px',
        p: 3,
        position: 'relative',
        zIndex: 1,
        my: 1,
        bgcolor,
        width: '100%'
      }}
    >
      {children}
      <Box
        sx={{
          position: 'absolute',
          bottom: '-1rem',
          right: '0.5rem',
          zIndex: 3
        }}
      >
        {showFields ? (
          <>
            <Button
              variant="outlined"
              size="small"
              sx={{ mr: 1 }}
              onClick={onCancelClicked}
              data-cy={`cancel-${dataCyPostfix}`}
            >
              {getText('cancel')}
            </Button>
            <LoadingButton
              size="small"
              onClick={onSaveClicked}
              dataCy={`save-${dataCyPostfix}`}
              label={getText('save')}
              loading={loading}
              disabled={disabled}
            />
          </>
        ) : (
          <Button
            variant="outlined"
            size="small"
            startIcon={<CreateIcon />}
            onClick={() => setShowFields(true)}
            data-cy={`edit-${dataCyPostfix}`}
          >
            {getText('edit', 'edit')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default EditWrapper;
