import { useCallback } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Grid, Stack } from '@mui/material';
import RecurrenceControl from './recurrence-control';
import { useSchemaValidationFormContext } from 'feature/forms';
import { Offer } from './model';
import ValidatedDatePicker, {
  DefaultDateTimePicker
} from 'feature/forms/validated-date-picker';
import { dateToDayjs } from 'utils/date';
import { getTextIn, required } from 'localization';

const getOfferText = getTextIn('offer-details');

const TimeRangeForm = () => {
  const { control, setValueValidating } =
    useSchemaValidationFormContext<Offer>();

  const startTime = useWatch<Offer, 'startTime'>({
    control,
    name: 'startTime'
  });

  const endTime = useWatch<Offer, 'endTime'>({
    control,
    name: 'endTime'
  });

  const handleRecurrenceRuleChanged = useCallback(
    (rrule: string) => setValueValidating('recurrenceRule', rrule),
    [setValueValidating]
  );

  return (
    <Stack gap={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ValidatedDatePicker
            name="startTime"
            render={(props) => (
              <DefaultDateTimePicker
                label={`${required(getOfferText('startTime'))}`}
                disablePast
                slotProps={{
                  textField: {
                    fullWidth: true,
                    inputProps: { 'data-cy': 'offerform-timerange-start-edit' }
                  }
                }}
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValidatedDatePicker
            name="endTime"
            render={(props) => (
              <DefaultDateTimePicker
                label={`${required(getOfferText('endTime'))}`}
                disablePast
                minDateTime={dateToDayjs(startTime)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    inputProps: { 'data-cy': 'offerform-timerange-end-edit' }
                  }
                }}
                {...props}
              />
            )}
          />
        </Grid>
      </Grid>
      <Controller
        name="recurrenceRule"
        control={control}
        render={({ field: { ref: _, ...fieldProps } }) => (
          <RecurrenceControl
            start={startTime}
            onValueChanged={handleRecurrenceRuleChanged}
            maxDate={dateToDayjs(endTime)}
            prefixCy="offerform-timerange-recurrence"
            {...fieldProps}
          />
        )}
      />
    </Stack>
  );
};

export default TimeRangeForm;
