import { type SxProps, type Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { type EntityModelOffer } from '@SLR/solution3-sdk';
import LoadingSpinner from 'components/loading-spinner';
import { OfferCard } from 'feature/cards';
import { getErrorText, getTextIn } from 'localization';

type OfferGridProps = {
  isError: boolean;
  isLoading: boolean;
  offers?: EntityModelOffer[];
  showBookingButton?: boolean;
  isBookingEnabled?: boolean;
  query?: string;
  totalElements?: number;
  isPreview?: boolean;
  sx?: SxProps<Theme>;
};

const getOfferText = getTextIn('offer');

const OfferGrid = ({
  isError,
  isLoading,
  offers,
  showBookingButton,
  isBookingEnabled,
  query,
  totalElements,
  isPreview = false,
  sx
}: OfferGridProps) => {
  return (
    <>
      {isError ? (
        <Typography variant="h2">{getErrorText('someError')}</Typography>
      ) : isLoading ? (
        <LoadingSpinner dataCy="offerspage" />
      ) : offers ? (
        <Grid
          container
          spacing={2}
          sx={[
            {
              mb: 2
            },
            // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
            ...(Array.isArray(sx) ? sx : [sx])
          ]}
        >
          {offers.map((offer) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                xl={isPreview ? 3 : 4}
                key={offer.id}
              >
                <OfferCard
                  offer={offer}
                  showBookingButton={showBookingButton}
                  isBookingEnabled={isBookingEnabled}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : totalElements === 0 ? (
        <Box
          sx={{
            mt: { xs: 3.125, md: 4 },
            mb: 3
          }}
        >
          <Typography>{getOfferText('noOfferResults')}</Typography>
          {query && (
            <Typography fontWeight="medium">
              {getOfferText('noOfferResultsHint')}
            </Typography>
          )}
        </Box>
      ) : null}
    </>
  );
};
export default OfferGrid;
