import { type EntityModelOffer } from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useOffersApi, { OFFER_KEY } from './useOffersApi';

const useGetOffer = (offerId: string) => {
  const { getOffer } = useOffersApi();
  const projectId = useGetProjectId();

  return useQuery<EntityModelOffer, Response>(
    [OFFER_KEY, { projectId, offerId }],
    () => {
      return getOffer({
        projectId: projectId ?? '',
        offerId,
        includeReferenced: true
      });
    },

    {
      staleTime: Infinity,
      enabled: !!projectId && !!offerId
    }
  );
};

export default useGetOffer;
