import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import type { SxProps, Theme } from '@mui/material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PersonIcon from '@mui/icons-material/Person';
import { useUser } from 'context/user';

type UserAvatarProps = {
  sx?: SxProps<Theme>;
};

const AvatarFallback = ({ isOrganization = false }) => {
  const Fallback = isOrganization ? CorporateFareIcon : PersonIcon;
  return (
    <Fallback
      sx={{
        color: 'primary.contrastText',
        height: '60%',
        width: '60%'
      }}
    />
  );
};

const UserAvatar = ({ sx }: UserAvatarProps) => {
  const { perspective } = useUser();
  const { image, isOrganization } = perspective;

  return (
    <AvatarGroup>
      <Avatar
        sx={{
          zIndex: 1,
          borderWidth: { xs: '0 !important', lg: '3px !important' },
          ...sx
        }}
        alt={image?.alternativeText}
        src={image?.urls.small}
      >
        <AvatarFallback isOrganization={isOrganization} />
      </Avatar>
    </AvatarGroup>
  );
};

export default UserAvatar;
export { AvatarFallback };
