import { ReactElement } from 'react';
import { Section } from '@SLR/solution3-sdk';
import { InfoSection, LinkButtonList } from 'feature/info';
import { ProcessViz } from 'feature/process-visualization';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ContentWrapper } from 'feature';
import { LinkButtonProps } from 'feature/info/link-button-list';
import { CIRCLE_DIAMETER } from './process-viz';
import theme from 'theme/theme';
import compact from 'lodash/compact';

export type ProcessVizLabels = {
  text: string;
  linkTo: string;
};

type ProcessWrapperProps = {
  processSection: {
    title: string;
    section: Section;
    linkButtons?: LinkButtonProps[];
    id?: string;
  };
  vizLabels: Array<ProcessVizLabels>;
  children: (ReactElement | undefined)[];
};

const getBackgroundColor = (
  child?: ReactElement | null
): string | undefined => {
  if (!child) {
    return undefined;
  }
  return child?.props?.coloredBackground ? 'primary.background' : 'unset';
};

const ProcessWrapper = ({
  processSection,
  vizLabels,
  children
}: ProcessWrapperProps) => {
  const isXxlDown = useMediaQuery(theme.breakpoints.down('xxl'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const vizContent = vizLabels.map((label, index) => ({
    ...label,
    icon: <Typography variant="processNumber">{index + 1}</Typography>
  }));

  return (
    <>
      {/* arrange introduction section above the process viz on all viewports except xxl */}
      {isXxlDown && (
        <ContentWrapper
          key="isXxlDown"
          additionalSx={{
            display: 'flex',
            mx: { lg: 0 }
          }}
          backgroundColor={getBackgroundColor(children[0])}
        >
          {children[0]}
        </ContentWrapper>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: { lg: 'space-between' },
          alignItems: 'start'
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'none',
              lg: 'inline'
            },
            my: 'auto',
            ml: { xs: 0, lg: '-32rem', xl: '-27rem', xxl: '-38rem' },
            py: { xxl: 1 },
            pr: { xs: 8, lg: 10 }
          }}
        >
          <ProcessViz content={vizContent} />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', lg: '70vw', xxl: '100%' },
            display: 'inline'
          }}
        >
          {/* arrange introduction section right to the process viz on xxl viewport only */}
          {!isXxlDown && (
            <ContentWrapper
              key="not_isXxlDown"
              additionalSx={{
                display: 'flex',
                mx: 'auto'
              }}
              backgroundColor={getBackgroundColor(children[0])}
            >
              {children[0]}
            </ContentWrapper>
          )}
          <ContentWrapper
            key="default"
            backgroundColor="primary.background"
            additionalSx={{
              mx: { lg: 0, xxl: 'auto' }
            }}
          >
            <InfoSection
              title={processSection.title}
              section={processSection.section}
            >
              <>
                {processSection.linkButtons && (
                  <LinkButtonList linkButtons={processSection.linkButtons} />
                )}
                <Box
                  sx={{
                    display: { xs: 'none', sm: 'inline', lg: 'none' }
                  }}
                >
                  <ProcessViz content={vizContent} />
                </Box>
              </>
            </InfoSection>
          </ContentWrapper>
          {/* arrange third section right to the process viz on lg, xl and xxl viewport */}
          {isLgUp && (
            <ContentWrapper
              key="isLgUp"
              additionalSx={{
                mx: { lg: 0, xxl: 'auto' }
              }}
              backgroundColor={getBackgroundColor(children[1])}
            >
              {children[1]}
            </ContentWrapper>
          )}
          {/* arrange last sections right to the process viz on xxl viewport only */}
          {!isXxlDown &&
            children?.slice(2)?.map((section, index) => (
              <ContentWrapper
                key={`not_isXxlDown_${index}`}
                additionalSx={{
                  display: { xxl: 'flex' },
                  mx: { xxl: 'auto' }
                }}
                backgroundColor={getBackgroundColor(section)}
              >
                {section}
              </ContentWrapper>
            ))}
        </Box>
        {/* only on xxl viewport mock a box on the right with same width like process view to center layout correctly */}
        {!isXxlDown && (
          <Box
            sx={{
              display: 'inline',
              minWidth: {
                xxl: `${CIRCLE_DIAMETER + 10 - 33}rem`
              }
            }}
          ></Box>
        )}
      </Box>
      {/* arrange third section under the process viz viewport on viewports smaller than lg*/}
      {!isLgUp && (
        <ContentWrapper
          key="not_isLgUp"
          backgroundColor={getBackgroundColor(children[1])}
        >
          {children[1]}
        </ContentWrapper>
      )}
      {/* arrange last sections under the process viz on all viewports except xxl */}
      {isXxlDown &&
        compact(children?.slice(2))?.map((section, index) => (
          <ContentWrapper
            key={`isXxlDown_${index}`}
            additionalSx={{
              mx: { lg: 0 }
            }}
            backgroundColor={getBackgroundColor(section)}
          >
            {section}
          </ContentWrapper>
        ))}
    </>
  );
};

export default ProcessWrapper;
