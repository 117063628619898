import { useQuery } from '@tanstack/react-query';
import useOrganizationKeysApi from './useOrganizationKeysApi';
import { useGetProjectId } from 'context/project';

const useOrganizationPublicKey = (organizationId: string) => {
  const { getOrganizationPublicKey } = useOrganizationKeysApi();
  const projectId = useGetProjectId();

  return useQuery(
    ['organizationPublicKey', { projectId, organizationId }],
    () => {
      return getOrganizationPublicKey({
        projectId: projectId ?? '',
        organizationId
      });
    },
    {
      enabled: !!projectId && organizationId !== '',
      select: (data) => data
    }
  );
};

export default useOrganizationPublicKey;
