import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { SchemaProvider } from 'feature/forms';
import { DateFilterSchema, type DateFilter } from './model';
import TimeForm from './time-form';
import { addHours, dateToDayjs } from 'utils/date';
import { getText } from 'localization';
import { useBooking } from 'context/booking';
import { useProject } from 'context/project';

interface TimeFormBlockProps {
  defaultFilter: DateFilter;
  dateFilter?: DateFilter;
  onConfirm: (dateFilter: DateFilter) => void;
  onCancel: VoidFunction;
}

const TimeFormBlock = ({
  defaultFilter,
  dateFilter,
  onConfirm,
  onCancel
}: TimeFormBlockProps) => {
  const { maxDateFromNow, minDateFromNow } = useProject();
  const { isOffersBookable } = useBooking();

  const minPointInTimeFromNow = addHours(4);

  return (
    <SchemaProvider<DateFilter>
      defaultValues={defaultFilter}
      values={dateFilter}
      schema={DateFilterSchema(
        maxDateFromNow,
        minDateFromNow,
        minPointInTimeFromNow
      )}
    >
      {({ handleSubmit, getValues }) => {
        // get the minimum value for the rangeTo picker
        const rangeFromValue = dateToDayjs(getValues().rangeFrom);

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%'
            }}
          >
            <Stack spacing={4}>
              <TimeForm
                values={dateFilter}
                rangeFromValue={rangeFromValue}
                minPointInTime={dateToDayjs(minPointInTimeFromNow)}
                showOnlyRange={!isOffersBookable}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-around"
              sx={{
                flexWrap: 'wrap'
              }}
            >
              <Button
                variant="text"
                onClick={onCancel}
                sx={{ mt: 1.5, mb: 1.75, display: { xs: 'flex', md: 'none' } }}
              >
                {getText('removeAll')}
              </Button>

              <Button
                onClick={handleSubmit(onConfirm)}
                sx={{ mt: 1.5, mb: 1.75, width: { xs: 'unset', md: '100%' } }}
              >
                {getText('apply')}
              </Button>
            </Stack>
          </Box>
        );
      }}
    </SchemaProvider>
  );
};

export default TimeFormBlock;
