import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  PropsWithChildren
} from 'react';
// import { Page } from 'index.d';

interface RouterContextValues {
  // breadcrumbs: Page[],
  breadcrumbs: [];
  // setBreadcrumbs: Dispatch<SetStateAction<Page[]>>
  setBreadcrumbs: Dispatch<SetStateAction<[]>>;
}

const defaultRouterContextValues: RouterContextValues = {
  breadcrumbs: [],
  setBreadcrumbs: () => {}
};

const RouterContext = createContext<RouterContextValues>(
  defaultRouterContextValues
);

const useRouter = () => useContext(RouterContext);

const RouterContextProvider = ({ children }: PropsWithChildren) => {
  // const [breadcrumbs, setBreadcrumbs] = useState<Page[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<[]>([]);
  return (
    <RouterContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </RouterContext.Provider>
  );
};

export default RouterContextProvider;
export { useRouter };
