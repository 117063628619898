import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';
import {
  type CreateAdminMessageRequest,
  type CreateOfferMessageRequest,
  type CreateOrganizationMessageRequest,
  type CreateUserMessageRequest,
  MessagesApi
} from '@SLR/solution3-sdk';

const useMessagesApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.message) as MessagesApi;
  }, []);

  const createAdminMessage = useCallback(
    async (requestParameters: CreateAdminMessageRequest) => {
      try {
        return await apiInstance.createAdminMessage(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createOfferMessage = useCallback(
    async (requestParameters: CreateOfferMessageRequest) => {
      try {
        return await apiInstance.createOfferMessage(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createOrganizationMessage = useCallback(
    async (requestParameters: CreateOrganizationMessageRequest) => {
      try {
        return await apiInstance.createOrganizationMessage(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createUserMessage = useCallback(
    async (requestParameters: CreateUserMessageRequest) => {
      try {
        return await apiInstance.createUserMessage(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    createOfferMessage,
    createOrganizationMessage,
    createAdminMessage,
    createUserMessage
  };
};

export default useMessagesApi;
