import { Dispatch, SetStateAction, ChangeEventHandler } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import type { EntityModelGalleryEntry, Image } from '@SLR/solution3-sdk';
import LoadingSpinner from 'components/loading-spinner';
import { ImageUpdateLogic } from 'feature';
import { useImageLoader } from 'feature/gallery';
import { getTextIn } from 'localization';

const getProfileText = getTextIn('profile');

const ImageListItemLoader = ({
  image,
  srcFormat = '',
  onClick,
  dataCy,
  isActive,
  isNotValidFor
}: {
  image: Image;
  srcFormat?: string;
  onClick: VoidFunction;
  dataCy: string;
  isActive: boolean;
  isNotValidFor: (key: string) => boolean;
}) => {
  const { loadedSrc } = useImageLoader({
    image,
    srcFormat
  });

  return (
    <ImageListItem
      sx={{
        ...(isActive
          ? {
              borderWidth: 3,
              borderStyle: 'solid',
              borderColor: 'secondary.main'
            }
          : {}),
        position: 'relative'
      }}
    >
      {loadedSrc ? (
        <img
          src={loadedSrc}
          alt={image.alternativeText}
          loading="lazy"
          style={{ cursor: 'pointer' }}
          onClick={onClick}
        />
      ) : (
        <LoadingSpinner dataCy={dataCy} />
      )}

      {loadedSrc && isNotValidFor(image.id) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'end',
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: 40,
            height: 40,
            pr: 1.25,
            fontSize: 16,
            fontWeight: 'bold',
            color: 'white',
            bgcolor: 'error.main',
            clipPath: 'polygon(0% 100%, 100% 0%, 100% 100%)'
          }}
        >
          !
        </Box>
      )}
    </ImageListItem>
  );
};

const UploadComponent = ({ isLoadingImage }: { isLoadingImage: boolean }) => (
  <Box
    sx={{
      fontSize: 12,
      color: 'primary.main',
      background: '#27537A1F',
      pt: 2.25,
      lineHeight: 1.7,
      height: 100,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer'
    }}
    onClick={(event) => {
      // remove input value, for possible reload of a before uploaded and deleted img
      (event.currentTarget.previousElementSibling as HTMLInputElement).value =
        '';
    }}
  >
    <span>{getProfileText('uploadImage')}</span>
    <span>{getProfileText('maxUploadSize')}</span>
    <FileUploadOutlinedIcon />

    {isLoadingImage && (
      <CircularProgress
        data-cy="profile-gallery-edit-tmpImage-loadingbutton-progress"
        size={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          mt: '-12px',
          ml: '-12px'
        }}
      />
    )}
  </Box>
);

type GalleryEditImageListProps = {
  getNewImage: ChangeEventHandler<HTMLInputElement>;
  activeImageId: string;
  setActiveImageId: Dispatch<SetStateAction<string>>;
  images: EntityModelGalleryEntry[];
  maxTiles: number;
  isNotValidFor: (key: string) => boolean;
};

const GalleryEditImageList = ({
  getNewImage,
  activeImageId,
  setActiveImageId,
  images,
  maxTiles,
  isNotValidFor
}: GalleryEditImageListProps) => {
  return (
    <ImageList
      data-cy="profile-gallery-grid"
      variant="quilted"
      cols={3}
      rowHeight={100}
      gap={12}
      sx={{
        width: '100%',
        maxHeight: 450,
        gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))!important'
      }}
    >
      <ImageListItem key="upload">
        <ImageUpdateLogic
          getNewImage={getNewImage}
          inputDataCy="profile-gallery-grid-upload-input"
          UploadComponent={UploadComponent}
        />
      </ImageListItem>

      {images.slice(0, maxTiles).map((entry) => {
        const image = entry?.image;
        const isActive = image.id === activeImageId;

        return (
          !!image && (
            <ImageListItemLoader
              key={image.id}
              image={image}
              srcFormat="w=124&h=164&fit=crop&auto=format"
              onClick={() => {
                setActiveImageId(image.id);
              }}
              dataCy="profile-gallery-edit-grid"
              isActive={isActive}
              isNotValidFor={isNotValidFor}
            />
          )
        );
      })}
    </ImageList>
  );
};

export default GalleryEditImageList;
