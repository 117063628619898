import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { type ImageUpdate } from '@SLR/solution3-sdk';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { ContentWrapper } from 'feature';
import {
  RequestErrorFallback,
  notifyMutationError,
  notifyMutationSuccess
} from 'feature/error';
import {
  EditableOfferForm,
  type Offer,
  EMPTY_OFFER,
  toFormRepresentation,
  toUpdateRepresentation
} from 'feature/offers';
import { convertDeleteValue } from 'feature/forms';
import { useUpdateOffer, useGetOffer } from 'feature/hooks';
import { Typography } from '@mui/material';
import { useSetDocumentTitle } from 'context/project';
import { getText, getPath } from 'localization';
import { Restricted } from 'routes';
import { useUser } from 'context/user';

const OfferEditPage = () => {
  useSetDocumentTitle(getText('editOffer', 'offer'));
  const { offerId = '' } = useParams();
  const { perspective } = useUser();
  const navigate = useNavigate();
  const getOffer = useGetOffer(offerId);
  const updateOffer = useUpdateOffer(offerId);

  const offerData = useMemo(
    () => toFormRepresentation(getOffer.data),
    [getOffer.data]
  );

  const offerUrl = getPath('getOffer', {
    category: getOffer.data?.category?.name,
    offerId
  });

  const handleConfirm = useCallback(
    (offer: Offer) => {
      convertDeleteValue<Offer, ImageUpdate>(offer, 'picture');

      updateOffer.mutate(toUpdateRepresentation(offer), {
        onSuccess: (data) => {
          notifyMutationSuccess();
          navigate(
            getPath('getOffer', {
              category: data?.category?.name,
              offerId: data.id
            })
          );
        },
        onError: () => {
          notifyMutationError();
        }
      });
    },
    [updateOffer, navigate]
  );

  const title = getOffer.data?.title ?? getText('offer', 'offer');

  return (
    <Restricted
      isVisible={getOffer.data?.organizationId === perspective.id}
      forceLogin
    >
      <RequestErrorFallback queryResult={getOffer}>
        <ContentWrapper breadcrumbOnly>
          <BreadcrumbNavigation
            customItem={{
              2: {
                title,
                path: getPath('getOffer', {
                  category: getOffer.data?.category?.name,
                  offerId
                })
              }
            }}
          />

          <Typography variant="h1" align="center">
            {getText('editOffer', 'offer')}
          </Typography>
        </ContentWrapper>

        <EditableOfferForm
          defaultOffer={EMPTY_OFFER}
          offer={offerData}
          inProgress={updateOffer.isLoading}
          onConfirm={handleConfirm}
          onCancel={() => navigate(offerUrl)}
        />
      </RequestErrorFallback>
    </Restricted>
  );
};

export default OfferEditPage;
