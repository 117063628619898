import { ResponseError, type Image } from '@SLR/solution3-sdk';
import { getTextIn } from 'localization';
import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';

const getEditModalText = getTextIn('edit-editHeroImage');

const filterImageChange = (
  updatedImage: Image | undefined,
  initialImage: Image | undefined
) => {
  if (updatedImage && !isEqual(updatedImage, initialImage)) {
    return {
      imageId:
        updatedImage.id === initialImage?.id ? undefined : updatedImage.id,
      alternativeText: updatedImage.alternativeText
    };
  } else if (updatedImage == undefined) {
    return null;
  }
};

const onUploadError = (error: Response) => {
  const responseStatus =
    error instanceof ResponseError && error.response?.status;

  const badFormatError = responseStatus === 502;
  const isTooLargeError = responseStatus === 413;

  const errorMessage = `${getEditModalText('uploadFail')}${
    isTooLargeError
      ? ` ${getEditModalText('fileTooLargeError')}`
      : badFormatError
      ? ` ${getEditModalText('badFormatError')}`
      : ''
  }`;

  enqueueSnackbar(errorMessage, {
    variant: 'error'
  });
};

export { filterImageChange, onUploadError };
