import { useMediaQuery } from '@mui/material';
import theme from 'theme/theme';

const useCardPreviewNumber = (max?: number) => {
  const mobilePagination = useMediaQuery(theme.breakpoints.down('md'));
  const isBreakpointUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const previewNumber = mobilePagination ? 2 : isBreakpointUpXl ? 4 : 3;

  return max ? Math.min(previewNumber, max) : previewNumber;
};

export default useCardPreviewNumber;
