import { useCallback, useMemo } from 'react';
import { ProjectsApi, type UpdateProjectRequest } from '@SLR/solution3-sdk';
import { APINames, getApiInstance } from 'utils/api-utils';

const useProjectsApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.projects) as ProjectsApi;
  }, []);

  const getCurrentProject = useCallback(async () => {
    try {
      return await apiInstance.getCurrentProject();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [apiInstance]);

  const updateProject = useCallback(
    async (requestParameters: UpdateProjectRequest) => {
      try {
        return await apiInstance.updateProject(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );
  return {
    getCurrentProject,
    updateProject
  };
};

export default useProjectsApi;
