import { useMutation, useQueryClient } from '@tanstack/react-query';
import type {
  EntityModelCategory,
  EntityModelTargetGroup
} from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { notifyMutationError, notifyMutationSuccess } from 'feature/error';
import useCategoriesApi from './useCategoriesApi';
import useTargetGroupsApi from './useTargetGroupsApi';
import { CATEGORIES, TARGET_GROUPS, type SegmentType } from './constants';
import { getSegmentListType } from './helper';
import { getText, getTextIn } from 'localization';

const getSegmentsText = getTextIn('settings-segments');

const useUpdateSegment = (segmentType: SegmentType) => {
  const queryClient = useQueryClient();
  const { updateCategory } = useCategoriesApi();
  const { updateTargetGroup } = useTargetGroupsApi();
  const projectId = useGetProjectId() ?? '';

  return useMutation(
    ({
      id,
      name
    }: {
      id: string;
      name: string;
    }): Promise<EntityModelTargetGroup | EntityModelCategory> => {
      switch (segmentType) {
        case CATEGORIES: {
          return updateCategory({
            projectId,
            categoryId: id,
            categoryUpdate: { name }
          });
        }
        case TARGET_GROUPS: {
          return updateTargetGroup({
            projectId,
            targetGroupId: id,
            targetGroupUpdate: { name }
          });
        }
      }
    },
    {
      onSuccess: () => {
        notifyMutationSuccess(
          getSegmentsText('getSuccessAction', {
            singular: getText('segment', `settings-${segmentType}`),
            action: getSegmentsText('update')
          })
        );
        queryClient.refetchQueries([getSegmentListType(segmentType)]);
      },
      onError: () =>
        notifyMutationError(
          getSegmentsText('getErrorAction', {
            singular: getText('segment', `settings-${segmentType}`),
            action: getSegmentsText('update')
          })
        )
    }
  );
};

export default useUpdateSegment;
