import {
  createContext,
  useContext,
  ReactElement,
  useState,
  useCallback
} from 'react';
import {
  useUser,
  useOrganizationSettings,
  useUpdateOrganizationSettings
} from 'context/user';
import { INIT, type StepType } from 'feature/onboarding';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import { getTextIn } from 'localization';

const getTextOnboarding = getTextIn('onboarding');

interface OnboardingContextValues {
  onboardingState: number;
  changeOnboardingState: (stepBinary: number) => void;
  successMode: boolean;
  setSuccessMode: (fired: boolean) => void;
  type: StepType;
  setType: (type: StepType) => void;
}

const defaultOnboardingContextValues: OnboardingContextValues = {
  onboardingState: 0,
  changeOnboardingState: () => 0,
  successMode: false,
  setSuccessMode: () => false,
  type: INIT,
  setType: () => INIT
};

const OnboardingContext = createContext<OnboardingContextValues>(
  defaultOnboardingContextValues
);

const useOnboarding = () => useContext(OnboardingContext);

const OnboardingContextProvider = ({
  children
}: {
  children: ReactElement;
}) => {
  const { perspective } = useUser();
  const organizationId = perspective?.isOrganization ? perspective.id : '';

  const { data } = useOrganizationSettings({ organizationId });
  const onboardingState = data?.onboardingState ?? -1;

  const updateOrganizationSettings = useUpdateOrganizationSettings();

  const changeOnboardingState = useCallback(
    (newOnboardingState: number) => {
      if (
        organizationId &&
        newOnboardingState !== undefined &&
        newOnboardingState !== onboardingState
      ) {
        updateOrganizationSettings.mutate(
          {
            organizationSettingsUpdate: { onboardingState: newOnboardingState }
          },
          {
            onSuccess: () => {
              notifyMutationSuccess(getTextOnboarding('successful'));
            },
            onError: () => {
              notifyMutationError(getTextOnboarding('error'));
            }
          }
        );
      }
    },
    [onboardingState, organizationId, updateOrganizationSettings]
  );

  // hard reset for dev -> remove, when onboarding is finished and presented
  // changeOnboardingState(0);

  const [type, setType] = useState<StepType>(INIT);
  const [successMode, setSuccessMode] = useState(false);

  return (
    <OnboardingContext.Provider
      value={{
        onboardingState,
        changeOnboardingState,
        successMode,
        setSuccessMode,
        type,
        setType
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContextProvider;
export { useOnboarding };
