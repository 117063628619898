import { type ArticleCreate } from '@SLR/solution3-sdk';
import { DEFAULT_MAX_CLOB_LENGTH } from 'feature/forms';
import { NON_EMPTY_HTML } from 'feature/forms/model';
import { getTextIn } from 'localization';
import { ObjectSchema, object, string, array } from 'yup';

const TITLE = 'title';
const CONTENT = 'content';
const CATEGORY_ID = 'categoryId';
const KEYWORDS = 'keywords';

type Keyword = {
  id: string;
  name: string;
};

interface Article {
  [TITLE]: string;
  [CONTENT]: string;
  [CATEGORY_ID]: string;
  [KEYWORDS]: { id: string; keyword: Keyword }[];
}

const getTextCompass = getTextIn('compass');

const EMPTY_ARTICLE: ArticleCreate = Object.freeze({
  [TITLE]: '',
  [CONTENT]: '',
  [CATEGORY_ID]: '',
  [KEYWORDS]: []
});

const ArticleSchema = (): ObjectSchema<ArticleCreate> =>
  object().shape({
    [TITLE]: string().required().min(1).max(100).label(getTextCompass('title')),
    [CONTENT]: string()
      .required()
      .min(1)
      .max(DEFAULT_MAX_CLOB_LENGTH)
      .test(NON_EMPTY_HTML)
      .label(getTextCompass('description')),

    [CATEGORY_ID]: string().required().label(getTextCompass('category')),
    [KEYWORDS]: array().required().label(getTextCompass('keywords'))
  });

export {
  ArticleSchema,
  EMPTY_ARTICLE,
  TITLE,
  CONTENT,
  CATEGORY_ID,
  KEYWORDS,
  type Article,
  type Keyword
};
