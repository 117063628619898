import { useEffect, useRef, useState } from 'react';
import { Add, Check, Clear, Delete, Edit } from '@mui/icons-material';
import { Button, Stack, TextField, Typography } from '@mui/material';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import {
  DataGrid,
  GridActionsCellItem,
  type GridRowId,
  GridRowModes,
  type GridColDef,
  type GridRowModesModel,
  GridToolbarContainer,
  type GridRenderCellParams,
  useGridApiContext,
  type GridEventListener,
  GridRowEditStopReasons
} from '@mui/x-data-grid';
import { DialogGeneric, SidebarPageWrapper } from 'feature';
import {
  useCreateSegment,
  useDeleteSegment,
  useListSegments,
  useUpdateSegment,
  type SegmentType
} from 'feature/hooks';
import { notifyMutationError } from 'feature/error';
import { OTHER } from 'utils/url-param';
import { isEmptyOrNull } from 'utils/helper';
import { GetTextFxParams, getNode, getTextIn } from 'localization';

const getSegmentsText = getTextIn('settings-segments');
const MAX_INPUT_LENGTH = 40;

// eslint-disable-next-line
const EditCell = (props: GridRenderCellParams<any, number>) => {
  const { id, value, field, hasFocus } = props;
  const gridRef = useGridApiContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    gridRef.current.setEditCellValue({
      id,
      field,
      value: (event.target as HTMLInputElement).value
    });
  };

  const ref = useRef<HTMLInputElement | null>(null);

  useEnhancedEffect(() => {
    if (ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>('input');

      if (input) {
        if (hasFocus) {
          input.onblur = () => {
            if (hasFocus) {
              input.focus();
            }
          };
          input.focus();
        } else {
          input.onblur = null;
          input.blur();
        }
      }
    }
  }, [hasFocus, value]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      width="100%"
    >
      <TextField
        ref={ref}
        variant="standard"
        value={value}
        onChange={handleChange}
        inputProps={{
          maxLength: MAX_INPUT_LENGTH,
          sx: { fontSize: '0.875rem' }
        }}
        InputProps={{
          disableUnderline: true
        }}
        sx={{ pl: '10px', width: '80%' }}
      />
      <Typography sx={{ pr: 1, fontSize: '0.875rem !important' }}>
        {value ? `${value?.toString().length} / ${MAX_INPUT_LENGTH}` : ' '}
      </Typography>
    </Stack>
  );
};

type Segment = {
  id: string;
  name: string;
};

type SegmentsGridType = {
  segmentType: SegmentType;
  dataCyPrefix: string;
  getSpecificText: (key: string, params?: GetTextFxParams) => string;
};

const SegmentsGrid = ({
  segmentType,
  dataCyPrefix,
  getSpecificText
}: SegmentsGridType) => {
  const { data, isLoading } = useListSegments(segmentType);
  const createSegment = useCreateSegment(segmentType);
  const updateSegment = useUpdateSegment(segmentType);
  const deleteSegment = useDeleteSegment(segmentType);

  const [segments, setSegments] = useState<Segment[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  useEffect(() => setSegments(data ?? []), [data]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | undefined>();

  const singular = getSpecificText('segment');

  const handleAdd = () => {
    const id = `new-${Math.random()}`;
    setSegments((oldSegments) => [...oldSegments, { id, name: '' }]);
    setIsEditing(true);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' }
    }));
  };

  const handleEdit = (id: GridRowId) => () => {
    setIsEditing(true);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSave = (id: GridRowId) => () => {
    setIsEditing(false);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDelete = () => {
    if (deleteId) {
      deleteSegment.mutate(deleteId, {
        onSuccess: () => setDeleteId(undefined)
      });
    }
  };

  const handleCancel = (id: GridRowId) => () => {
    setIsEditing(false);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    }));
    setSegments(data ?? []);
  };

  const handleUpdate = (newSegment: Segment, oldSegment: Segment) => {
    setIsEditing(false);

    // Show error when name already exists or name is empty
    if (
      isEmptyOrNull(newSegment.name) ||
      segments
        .filter((segment) => segment.id !== oldSegment.id)
        .find(
          (s) =>
            s.name.replace(/\s/g, '').toLocaleLowerCase() ===
            newSegment.name.replace(/\s/g, '').toLocaleLowerCase()
        )
    ) {
      notifyMutationError(
        getSegmentsText(
          isEmptyOrNull(newSegment.name) ? 'getNotEmpty' : 'getAlreadyExists',
          { singular }
        )
      );
      setSegments(data ?? []);
      return oldSegment;
    } else {
      if (newSegment.id.toString().includes('new')) {
        createSegment.mutate(newSegment.name);
      } else {
        const segment = segments.find((c) => c.id === newSegment.id);
        if (segment?.name !== newSegment.name) {
          updateSegment.mutate({
            id: newSegment.id,
            name: newSegment.name
          });
        }
      }
      return newSegment;
    }
  };

  const handleRowModeChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const segmentColumns: GridColDef<Segment>[] = [
    {
      field: 'name',
      editable: true,
      disableColumnMenu: true,
      flex: 1,
      headerName: getSpecificText('segment'),
      renderEditCell: (params) => <EditCell {...params} />
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      headerName: getSegmentsText('actions'),
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return id === OTHER
          ? []
          : isInEditMode
          ? [
              <GridActionsCellItem
                icon={<Check />}
                label="Save"
                sx={{ color: 'secondary.main' }}
                onClick={handleSave(id)}
                data-cy={`${dataCyPrefix}-save-button`}
              />,
              <GridActionsCellItem
                icon={<Clear />}
                label="Cancel"
                onClick={handleCancel(id)}
                data-cy={`${dataCyPrefix}-cancel-button`}
              />
            ]
          : [
              <GridActionsCellItem
                icon={<Edit />}
                label="Edit"
                onClick={handleEdit(id)}
                data-cy={`${dataCyPrefix}-edit-button-${row.name}`}
              />,
              <GridActionsCellItem
                icon={<Delete />}
                label="Delete"
                onClick={() => setDeleteId(id.toString())}
                data-cy={`${dataCyPrefix}-delete-button-${row.name}`}
              />
            ];
      }
    }
  ];

  const title = getSegmentsText('getDelete', { singular });

  return (
    <>
      <DialogGeneric
        open={!!deleteId}
        title={title}
        onConfirm={handleDelete}
        onClose={() => setDeleteId(undefined)}
        confirmColor="error"
        confirmText={title}
        prefix="delete-segment-alert-dialog"
        prefixCy="segmentdeletedialog"
        isLoading={deleteSegment.isLoading}
      >
        {getNode(
          'getNodeDeleteQuestion',
          'settings-segments'
        )({
          segment: segments.find((c) => c.id === deleteId)?.name,
          singular
        })}
      </DialogGeneric>
      <SidebarPageWrapper>
        <>
          <Typography variant="h1">{getSpecificText('manageTitle')}</Typography>

          <DataGrid
            sx={{
              '.MuiDataGrid-columnHeaderTitle': {
                color: 'primary.main',
                fontWeight: 'bold'
              },
              '.MuiDataGrid-virtualScroller': {
                minHeight: 100
              },
              '.MuiDataGrid-sortIcon': {
                color: 'secondary.main'
              }
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }]
              }
            }}
            editMode="row"
            isCellEditable={(params) => params.id !== OTHER}
            rows={segments}
            rowModesModel={rowModesModel}
            columns={segmentColumns}
            loading={isLoading}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  sx={{
                    minHeight: 100
                  }}
                  justifyContent="center"
                  alignItems="center"
                  data-cy={`${dataCyPrefix}-no-data`}
                >
                  <Typography variant="body2">
                    {getSegmentsText('getEmpty', { singular })}
                  </Typography>
                </Stack>
              ),
              toolbar: () => (
                <GridToolbarContainer>
                  <Button
                    color="primary"
                    variant="text"
                    startIcon={<Add />}
                    onClick={handleAdd}
                    disabled={isEditing}
                    data-cy={`${dataCyPrefix}-add-button`}
                  >
                    {getSegmentsText('getAdd', { singular })}
                  </Button>
                </GridToolbarContainer>
              )
            }}
            processRowUpdate={handleUpdate}
            onRowModesModelChange={handleRowModeChange}
            onRowEditStop={handleRowEditStop}
            disableRowSelectionOnClick
            hideFooter
          />
        </>
      </SidebarPageWrapper>
    </>
  );
};

export default SegmentsGrid;
