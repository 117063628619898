import { ChangeEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

type OnChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => void;

type CheckboxGenericProps = {
  name: string;
  label: string;
  value: boolean;
  onChange: OnChangeCheckbox;
  disableTypography?: boolean;
  disableUnchecked?: boolean;
};

const CheckboxGeneric = ({
  name,
  label,
  value,
  onChange,
  disableTypography = false,
  disableUnchecked = false
}: CheckboxGenericProps) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={value}
        onChange={onChange}
        name={name}
        disabled={disableUnchecked && !value}
      />
    }
    label={label}
    disableTypography={disableTypography}
    sx={{ '&.Mui-disabled': { color: 'neutral.dark' } }}
  />
);

type CheckboxOption = { id: string; name: string };
type CheckboxStateValues = { [key: string]: boolean };

// set checkboxes boolean values
const initCheckboxesBlock = (options: CheckboxOption[], values?: string) => {
  const initState: CheckboxStateValues = {};

  return options.reduce((acc, option: CheckboxOption) => {
    acc[option.id] = (values ?? '').includes(option.id);
    return acc;
  }, initState);
};

type CheckboxesBlockProps = {
  options: CheckboxOption[];
  values: CheckboxStateValues;
  onChange: OnChangeCheckbox;
  disableTypography?: boolean;
  disableUnchecked?: boolean;
};

const CheckboxesBlock = ({
  options,
  values,
  onChange,
  disableTypography,
  disableUnchecked
}: CheckboxesBlockProps) => {
  const oneValueChecked = Object.values(values).some((value) => value === true);

  return (
    <FormGroup>
      {options.map(({ id, name }) => {
        return (
          <CheckboxGeneric
            key={id}
            name={id}
            label={name}
            value={values[id]}
            onChange={onChange}
            disableTypography={disableTypography}
            disableUnchecked={disableUnchecked && oneValueChecked}
          />
        );
      })}
    </FormGroup>
  );
};

export default CheckboxesBlock;
export { initCheckboxesBlock };

export type { OnChangeCheckbox, CheckboxOption, CheckboxStateValues };
