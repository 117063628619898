import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import {
  START_TIME,
  END_TIME,
  LOCAL_TIME_FORMAT,
  ERROR_EMPTY
} from './constants';
import type {
  TimeRangeOptional,
  WeekdayRowProps,
  InputRowProps,
  InputSectionProps
} from './types';

import { getTextIn, getTextFx } from 'localization';

const getTextWeekday = getTextIn('weekDay');
const getTextFilterTime = getTextIn('filter-time');

const errorConversion = { invalidDate: 'insertValidValue' };

const getErrorText = (error: string | null, min?: Dayjs) => {
  const configKey =
    error === 'minTime' && min
      ? getTextFx('getTimeValueFrom', 'filter-time')({ min, onlyTime: true })
      : getTextFilterTime(
          errorConversion[(error ?? '') as keyof typeof errorConversion] ||
            error ||
            ''
        );

  return configKey;
};

const timeRangeIsFilled = (timeRange: TimeRangeOptional) => {
  return !!timeRange?.[START_TIME] && !!timeRange?.[END_TIME];
};

const InputRow = ({
  timeRange,
  lastEndTime,
  onChange,
  addRow,
  removeRow,
  ctrlErrorsWeekdayRow
}: InputRowProps) => {
  const startTime = timeRange?.[START_TIME];
  const endTime = timeRange?.[END_TIME];

  const startTimeFormatted = dayjs(startTime as string, LOCAL_TIME_FORMAT);
  const minTimeStart =
    lastEndTime && dayjs(lastEndTime as string, LOCAL_TIME_FORMAT);

  const errorStartTime = ctrlErrorsWeekdayRow(START_TIME);
  const errorEndTime = ctrlErrorsWeekdayRow(END_TIME);

  const isStartTimeEmpty = errorStartTime === ERROR_EMPTY;
  const isEndTimeEmpty = errorEndTime === ERROR_EMPTY;

  return (
    <Stack direction="row" gap={5} sx={{ alignItems: 'start' }}>
      <TimePicker
        label={getTextFilterTime('from')}
        value={startTime ? startTimeFormatted : null}
        minTime={minTimeStart}
        onChange={(newTime) => {
          onChange(START_TIME, newTime);

          if (isStartTimeEmpty && newTime !== null) {
            ctrlErrorsWeekdayRow(START_TIME, null);
          }
        }}
        onError={(error) => {
          ctrlErrorsWeekdayRow(START_TIME, error);
        }}
        slotProps={{
          textField: {
            error: !!errorStartTime,
            helperText: getErrorText(
              errorStartTime as string | null,
              minTimeStart
            )
          }
        }}
      />
      <TimePicker
        label={getTextFilterTime('to')}
        value={endTime ? dayjs(endTime as string, LOCAL_TIME_FORMAT) : null}
        minTime={startTimeFormatted}
        onChange={(newTime) => {
          onChange(END_TIME, newTime);

          if (isEndTimeEmpty && newTime !== null) {
            ctrlErrorsWeekdayRow(END_TIME, null);
          }
        }}
        onError={(error) => {
          ctrlErrorsWeekdayRow(END_TIME, error);
        }}
        slotProps={{
          textField: {
            error: !!errorEndTime,
            helperText: getErrorText(
              errorEndTime as string | null,
              startTimeFormatted
            )
          }
        }}
      />

      {removeRow ? (
        <Box sx={{ alignSelf: 'center' }}>
          <IconButton
            aria-label="clear search input"
            onClick={removeRow}
            sx={{
              width: 36,
              height: 36,
              borderRadius: 1,
              ml: -2
            }}
          >
            <CloseIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        </Box>
      ) : (
        !!timeRange &&
        timeRangeIsFilled(timeRange) &&
        addRow && (
          <Box sx={{ alignSelf: 'center' }}>
            <IconButton
              aria-label="add new line"
              onClick={addRow}
              sx={{
                width: 36,
                height: 28,
                borderRadius: 1,
                border: 1,
                borderColor: 'rgba(0,0,0,0.33)',
                ml: -2
              }}
            >
              <AddIcon sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box>
        )
      )}
    </Stack>
  );
};

const InputSection = ({
  timeRanges,
  onTimeChange,
  ctrlErrorsWeekday
}: InputSectionProps) => {
  const moreThanOneRow = timeRanges && timeRanges.length > 1;

  return timeRanges ? (
    <Stack gap={3}>
      {timeRanges.map((timeRange, rowIndex) => {
        const lastEndTime =
          rowIndex > 0 ? timeRanges[rowIndex - 1][END_TIME] : undefined;

        const ctrlErrorsWeekdayRow = ctrlErrorsWeekday(rowIndex);

        return (
          <InputRow
            key={rowIndex}
            timeRange={timeRange}
            lastEndTime={lastEndTime}
            onChange={(rangeTimeType, newTimeRaw) => {
              const newTime = newTimeRaw?.format(LOCAL_TIME_FORMAT);

              onTimeChange({
                rowIndex,
                rangeTimeType,
                newTime
              });
            }}
            addRow={
              rowIndex === 0
                ? () => onTimeChange({ rowIndex: 1, setEmpty: true })
                : undefined
            }
            removeRow={
              moreThanOneRow
                ? () => {
                    const isRowIndex0 = rowIndex === 0;
                    const ctrlErrorsWeekdayRow1 = ctrlErrorsWeekday(1);

                    onTimeChange({ rowIndex, removeRow: true });

                    ctrlErrorsWeekdayRow(
                      START_TIME,
                      (isRowIndex0 && ctrlErrorsWeekdayRow1(START_TIME)) || null
                    );
                    ctrlErrorsWeekdayRow(
                      END_TIME,
                      (isRowIndex0 && ctrlErrorsWeekdayRow1(END_TIME)) || null
                    );

                    if (isRowIndex0) {
                      ctrlErrorsWeekdayRow1(START_TIME, null);
                      ctrlErrorsWeekdayRow1(END_TIME, null);
                    }
                  }
                : undefined
            }
            ctrlErrorsWeekdayRow={ctrlErrorsWeekdayRow}
          />
        );
      })}
    </Stack>
  ) : null;
};

const WeekdayRow = ({
  hours,
  weekday,
  onCheckedChange,
  onTimeChange,
  ctrlErrors
}: WeekdayRowProps) => {
  const timeRanges = hours?.[weekday];
  const hasValues = !!timeRanges;

  return (
    <Grid container spacing={2} sx={{ my: hasValues ? 2 : 0 }}>
      {/* <Grid item xs={2} sx={{ pt: '24px !important' }}> */}
      <Grid item xs={12} sm={2} sx={{ pt: '24px !important' }}>
        <FormControlLabel
          label={getTextWeekday(weekday)}
          control={<Checkbox checked={hasValues} onChange={onCheckedChange} />}
        />
      </Grid>
      {hasValues && (
        // <Grid item xs={10}>
        <Grid item xs={12} sm={10}>
          <InputSection
            timeRanges={timeRanges}
            onTimeChange={onTimeChange}
            ctrlErrorsWeekday={ctrlErrors(weekday)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default WeekdayRow;
