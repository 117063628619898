import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { type Dayjs } from 'dayjs';
import { ConfigurableDatePicker } from 'feature/forms';
import { NdashBox } from 'feature/filter/common';
import { dateToDayjs } from 'utils/date';
import { getTextIn } from 'localization';
import { useProject } from 'context/project';

const getTextInFilterTime = getTextIn('filter-time');

const TimeRangeFilterForm = ({
  rangeFromValue,
  disabled
}: {
  rangeFromValue?: Dayjs;
  disabled?: boolean;
}) => {
  const { maxDateFromNow, minDateFromNow } = useProject();
  const sx = { fontSize: 16 };
  // md: reduced to 212 instead of 236 due to space restriction for 900px screen
  const pickerSx = { width: { xs: 'auto', md: 212 } };
  const maxDateTime = dateToDayjs(maxDateFromNow);

  return (
    <Stack gap={4} sx={{ mt: 2, mb: 1.5 }} width={{ xs: '100%', md: 'unset' }}>
      <Stack gap={2} direction={{ xs: 'column', md: 'row' }}>
        <ConfigurableDatePicker
          name="rangeFrom"
          label={getTextInFilterTime('rangeFrom')}
          disabled={disabled}
          minDateTime={dateToDayjs(minDateFromNow)}
          maxDateTime={maxDateTime}
          inputProps={{
            'data-cy': 'filter-timerange-start',
            sx
          }}
          pickerSx={pickerSx}
        />

        <Box sx={{ display: { xs: 'none', md: 'unset' } }}>
          <NdashBox />
        </Box>

        <ConfigurableDatePicker
          name="rangeTo"
          label={getTextInFilterTime('rangeTo')}
          disabled={disabled}
          minDateTime={rangeFromValue ?? dateToDayjs(minDateFromNow)}
          maxDateTime={maxDateTime}
          inputProps={{
            'data-cy': 'filter-timerange-end',
            sx
          }}
          pickerSx={pickerSx}
        />
      </Stack>
    </Stack>
  );
};

export default TimeRangeFilterForm;
