const IS_ADMIN = 'isAdmin';
const PROVIDER_ORGANIZATIONS_VISIBILITY = 'providerOrganizationsVisibility';
const SEEKER_ORGANIZATIONS_VISIBILITY = 'seekerOrganizationsVisibility';
const OFFER_VISIBILITY = 'offerVisibility';
const OFFERS_BOOKABLE_ORGANIZATION = 'offersBookableOrganization';
const OFFERS_BOOKABLE_SEEKER = 'offersBookableSeeker';
const HAS_TARGET_GROUPS = 'hasTargetGroups';
const HAS_KNOWLEDGE_BASE = 'hasKnowledgeBase';

export {
  IS_ADMIN,
  PROVIDER_ORGANIZATIONS_VISIBILITY,
  SEEKER_ORGANIZATIONS_VISIBILITY,
  OFFER_VISIBILITY,
  OFFERS_BOOKABLE_ORGANIZATION,
  OFFERS_BOOKABLE_SEEKER,
  HAS_TARGET_GROUPS,
  HAS_KNOWLEDGE_BASE
};
