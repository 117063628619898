import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link } from '@mui/material';
import { useAutoBreadcrumbItems } from './helper';
interface BreadcrumbItem {
  title: string;
  path?: string;
}

interface BreadcrumbNavigationProps {
  customItem?: { [key: number]: BreadcrumbItem };
}

/**
 * Breadcrumbs will be generated directly from the url and the hash table values in
 * getBreadcrumb() in breadcrumbs/helper.ts.
 * A different breadcrumb title can be defined by the customItem parameter
 *
 * @param customItem Object with key (position in the breadcrumb array) and object value (s. BreadcrumbItem),
 *    z.B. { 2: { title: 'Title' } } - the third (start value has key 0!) breadcrumb element has
 *          the title 'Title
 *
 * @returns React.element
 */
const BreadcrumbNavigation = ({ customItem }: BreadcrumbNavigationProps) => {
  const breadcrumbItems = useAutoBreadcrumbItems({ customItem });

  const lastIndex = breadcrumbItems.length - 1;

  const components = breadcrumbItems.map((breadcrumbItem, index) => {
    const isLast = index === lastIndex;
    const variant = isLast ? 'currentBreadcrumb' : 'breadcrumb';

    return breadcrumbItem.path ? (
      <Link
        key={index}
        variant={variant}
        component={RouterLink}
        to={breadcrumbItem.path}
        aria-current={isLast ? 'page' : undefined}
      >
        {breadcrumbItem.title}
      </Link>
    ) : (
      <Link
        key={index}
        variant={variant}
        sx={{ display: 'block' }}
        underline="none"
      >
        {breadcrumbItem.title}
      </Link>
    );
  });

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{ display: { xs: 'none', sm: 'unset' }, my: 1, zIndex: 1 }}
    >
      {components}
    </Breadcrumbs>
  );
};

export default BreadcrumbNavigation;
export type { BreadcrumbItem };
