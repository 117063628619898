import { Typography } from '@mui/material';
import { ContentWrapper } from 'feature';
import { getText } from 'localization';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import useGetProjectPrivacyPolicyText from 'feature/hooks/legal/useGetProjectPrivacyPolicyText';
import LegalTextBox from './LegalTexts/LegalTextBox';
import { useSetDocumentTitle } from 'context/project';

const DataProtectionPage = () => {
  const dataProtection = getText('privacyPolicy');
  useSetDocumentTitle(dataProtection);

  const { data } = useGetProjectPrivacyPolicyText();

  return (
    <ContentWrapper>
      <BreadcrumbNavigation />
      <Typography variant="h1" textAlign="center">
        {dataProtection}
      </Typography>
      <LegalTextBox legalHtml={data ?? ''} />
    </ContentWrapper>
  );
};

export default DataProtectionPage;
