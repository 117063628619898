import {
  createContext,
  useContext,
  ReactElement,
  useState,
  useEffect
} from 'react';
import {
  Bookable,
  ListOffersLocationTypesEnum,
  type FixedLocation,
  type FixedLocationCreate,
  type Offer
} from '@SLR/solution3-sdk';
import { useProject } from 'context/project';
import { SearchParamType } from 'utils/url-param';
// import { EMPTY_FIXED_LOCATION } from 'feature/offers/model'; --> leads to ERROR can't access lexical declaration 'EMPTY_OFFER' before initialization

const EMPTY_FIXED_LOCATION: FixedLocation = Object.freeze({
  street: '',
  houseNumber: '',
  city: '',
  zipCode: '',
  description: ''
});

type TimeRange = { startTime?: Date; endTime?: Date };

interface BookingContextValues {
  offers: Offer[];
  clearBookingList: () => void;
  addOfferToList: (offer: Offer, queryParams: SearchParamType) => void;
  removeOfferFromList: (offerId: string) => void;
  requestedLocationType?: ListOffersLocationTypesEnum;
  requestedGeoAreaId?: string;
  requestedTimeRange?: TimeRange;
  selectedProvider?: {
    id: string;
    name?: string;
  };
  isOffersBookable: boolean;
  isMultiOffersBookable: boolean;
  withOnlineParticipation: boolean;
  setWithOnlineParticipation: React.Dispatch<React.SetStateAction<boolean>>;
  fixedLocation: FixedLocationCreate;
  setFixedLocation: React.Dispatch<React.SetStateAction<FixedLocationCreate>>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  selectedTimeRange?: TimeRange;
  setSelectedTimeRange: React.Dispatch<
    React.SetStateAction<TimeRange | undefined>
  >;
  savedQueryParams?: SearchParamType;
  setIsFixedTime: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultBookingContextValues: BookingContextValues = {
  offers: [],
  clearBookingList: () => {},
  addOfferToList: () => {},
  removeOfferFromList: () => {},
  isOffersBookable: false,
  isMultiOffersBookable: false,
  withOnlineParticipation: false,
  setWithOnlineParticipation: () => {},
  fixedLocation: EMPTY_FIXED_LOCATION,
  setFixedLocation: () => {},
  firstName: '',
  setFirstName: () => {},
  lastName: '',
  setLastName: () => {},
  setSelectedTimeRange: () => {},
  setIsFixedTime: () => {}
};

const BookingContext = createContext<BookingContextValues>(
  defaultBookingContextValues
);

const useBooking = () => useContext(BookingContext);
interface BookingContextProviderProps {
  children: ReactElement;
}

const BookingContextProvider = ({ children }: BookingContextProviderProps) => {
  const { project } = useProject();
  const isOffersBookable = project?.offersBookable !== Bookable.None;
  const [offers, setOffers] = useState<Offer[]>([]);
  const [isFixedTime, setIsFixedTime] = useState(false);
  const [savedQueryParams, setSavedQueryParams] = useState<SearchParamType>();
  const requestedLocationType = savedQueryParams?.locationTypes[0];
  const requestedGeoAreaId = savedQueryParams?.geoAreaId;
  const requestedTimeRange = savedQueryParams?.time
    ? {
        startTime: savedQueryParams?.time,
        endTime: savedQueryParams?.time
      }
    : savedQueryParams?.timeRange
    ? {
        startTime: savedQueryParams?.timeRange[0],
        endTime: savedQueryParams?.timeRange[1]
      }
    : undefined;
  const isMultiOffersBookable =
    project?.offersBookable === Bookable.Multi && !isFixedTime;

  const [selectedProvider, setSelectedProvider] = useState<{
    id: string;
    name?: string;
  }>();
  const [withOnlineParticipation, setWithOnlineParticipation] = useState(false);
  const [fixedLocation, setFixedLocation] = useState<FixedLocationCreate>(
    requestedLocationType == ListOffersLocationTypesEnum.Fixed
      ? offers[0]?.fixedLocation ?? EMPTY_FIXED_LOCATION
      : EMPTY_FIXED_LOCATION
  );
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>();

  const resetBookingFormData = () => {
    setWithOnlineParticipation(false);
    setFixedLocation(EMPTY_FIXED_LOCATION);
    setFirstName('');
    setLastName('');
    setSelectedTimeRange(undefined);
    setSavedQueryParams(undefined);
  };

  const clearBookingList = () => {
    setOffers([]);
    setSelectedProvider(undefined);
    resetBookingFormData();
  };

  const addOfferToList = (offer: Offer, queryParams: SearchParamType) => {
    delete queryParams.loadFilter;
    setSavedQueryParams(queryParams);
    if (
      queryParams.locationTypes[0] === ListOffersLocationTypesEnum.Fixed &&
      offer?.fixedLocation
    ) {
      setFixedLocation(offer.fixedLocation);
    }
    if (isMultiOffersBookable) {
      if (!offers.some((o) => o.id === offer.id)) {
        setOffers((prev) => prev.concat(offer));
        return;
      }
    } else if (isOffersBookable) {
      setOffers([offer]);
    }
  };

  const removeOfferFromList = (offerId: string) => {
    if (offers.length === 1) {
      resetBookingFormData();
    }
    setOffers((prev) => prev.filter((offer) => offer.id !== offerId));
  };

  useEffect(() => {
    if (isMultiOffersBookable) {
      if (offers[0]?.organizationId) {
        setSelectedProvider({
          id: offers[0].organizationId,
          name: offers[0].organization?.name
        });
      } else {
        setSelectedProvider(undefined);
      }
    }
  }, [isMultiOffersBookable, offers]);

  return (
    <BookingContext.Provider
      value={{
        offers,
        clearBookingList,
        addOfferToList,
        removeOfferFromList,
        requestedLocationType,
        requestedGeoAreaId,
        requestedTimeRange,
        selectedProvider,
        isOffersBookable,
        isMultiOffersBookable,
        withOnlineParticipation,
        setWithOnlineParticipation,
        fixedLocation,
        setFixedLocation,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        selectedTimeRange,
        setSelectedTimeRange,
        savedQueryParams,
        setIsFixedTime
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingContextProvider;
export { useBooking, type TimeRange };
