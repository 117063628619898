import React from 'react';
import { Stack, Typography } from '@mui/material';
import FooterItem from '../footer-item';
import FooterImage from 'assets/bmel-logo.png';
import { getText, getTextIn } from 'localization';
import { useProject } from 'context/project';

const getFooterText = getTextIn('footer');

const Notes = () => {
  const { project } = useProject();
  return (
    <FooterItem title={getFooterText('hintTitle')}>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Typography variant="footer">
          {(project?.domain ?? getText('projectFallback')) +
            getFooterText('hintIESE')}
          <br></br>
          <br></br>
          {getFooterText('hintSLR')}
        </Typography>
        <img
          src={FooterImage}
          alt={getFooterText('imgAltText')}
          width="auto"
          height={140}
        />
      </Stack>
    </FooterItem>
  );
};

export default Notes;
