import { useKeycloakAuth } from 'auth';
import { useGetProjectId } from 'context/project';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import useInvitationsApi from './useInvitationsApi';
import { getText } from 'localization';

const useGetInvitation = (invitationId = '') => {
  const { getInvitation } = useInvitationsApi();
  const { isAuthenticated } = useKeycloakAuth();
  const projectId = useGetProjectId();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    ['invitation', { projectId, invitationId }],
    () => {
      return getInvitation({ projectId: projectId ?? '', invitationId });
    },

    {
      staleTime: Infinity,
      enabled: !!projectId && !!invitationId && isAuthenticated,
      onError: (res: Response) => {
        if (res.status === 404) {
          enqueueSnackbar(getText('alreadyAccepted', 'invitation'), {
            variant: 'success'
          });
        }
      }
    }
  );
};

export default useGetInvitation;
