import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  type EntityModelOrganizationProfile,
  EntityType
} from '@SLR/solution3-sdk';
import { useUser } from 'context/user';
import { ReportButton } from 'feature';
import { getTextIn, getPath } from 'localization';

const getProfileText = getTextIn('profile');

interface Props {
  profile?: EntityModelOrganizationProfile;
}

const ProviderActions = ({ profile }: Props) => {
  const { perspective } = useUser();
  const organizationId = profile?.organizationId ?? '';
  const isOwner =
    perspective.isOrganization && organizationId === perspective.id;

  return (
    <Box
      sx={{
        mt: 1,
        display: 'flex',
        justifyContent: { xs: 'center', md: 'end' }
      }}
    >
      {isOwner ? (
        <Button
          variant="outlined"
          size="small"
          startIcon={<ModeEditIcon />}
          component={Link}
          to={getPath('edit')}
          sx={{
            mt: 0.75
          }}
          data-cy="edit-profile-button"
        >
          {getProfileText('editProfile')}
        </Button>
      ) : (
        <ReportButton
          type={EntityType.OrganizationProfile}
          entityId={organizationId}
        />
      )}
    </Box>
  );
};

export default ProviderActions;
