import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { type ListProjectGalleryEntriesRequest } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import useProfileGalleryApi from './use-profile-gallery-api';

type UseListProjectGalleryEntries = Omit<
  ListProjectGalleryEntriesRequest,
  'projectId'
>;

const useListProjectGalleryEntries = (
  params?: UseListProjectGalleryEntries
) => {
  const { listProfileGalleryEntries } = useProfileGalleryApi();
  const projectId = useGetProjectId();
  const { profileId } = useParams();

  const { page, size, sort } = params ?? {};

  return useQuery(
    ['profileGalleryEntries', { projectId, profileId, page, size, sort }],
    () => {
      return listProfileGalleryEntries({
        projectId: projectId ?? '',
        organizationId: profileId ?? '',
        page,
        size,
        sort
      });
    },

    {
      staleTime: Infinity,
      enabled: !!projectId && !!profileId
    }
  );
};

export default useListProjectGalleryEntries;
