import { Box } from '@mui/material';
import { type Image } from '@SLR/solution3-sdk';

type LogoMarkProps = {
  logo?: Image;
};

const LogoMark = ({ logo }: LogoMarkProps) => {
  return logo ? (
    <Box
      sx={{
        height: { xs: 54, md: 64, lg: 84 }
      }}
    >
      <img
        src={logo.urls.small}
        alt={logo.alternativeText}
        width="auto"
        height="100%"
        data-cy="project-logo"
      />
    </Box>
  ) : null;
};
export default LogoMark;
