import { useCallback, useMemo } from 'react';
import {
  BusinessHoursApi,
  type ReplaceBusinessHoursRequest,
  type GetBusinessHoursRequest
} from '@SLR/solution3-sdk';
import { APINames, getApiInstance } from 'utils/api-utils';

const useBusinessHoursApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.businessHours) as BusinessHoursApi;
  }, []);

  const getBusinessHours = useCallback(
    async (requestParameters: GetBusinessHoursRequest) => {
      try {
        return await apiInstance.getBusinessHours(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const replaceBusinessHours = useCallback(
    async (requestParameters: ReplaceBusinessHoursRequest) => {
      try {
        return await apiInstance.replaceBusinessHours(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getBusinessHours,
    replaceBusinessHours
  };
};

export default useBusinessHoursApi;
