import { createContext, useContext, ReactElement, useState } from 'react';
import {
  type ErrorInformation,
  type EventCreate,
  type EntityModelEvent,
  ResponseError
} from '@SLR/solution3-sdk';
import useGetOrganizationPublicKey from './hooks/useGetOrganizationPublicKey';
import useReplaceOrganizationSpecificKey from './hooks/useReplaceOrganizationSpecificKey';
import { useCreateEvent } from 'feature/hooks';
import { notifyMutationError } from 'feature/error';
import { getOfferBookingText } from 'feature/offers/booking';
import * as openpgp from 'openpgp';
import { Buffer } from 'buffer';
import { getTextIn } from 'localization';
import { useBooking } from 'context/booking';

const getCryptoText = getTextIn('crypto');

/* AES is the only supported algorithm for symmetric encryption/decryption via Web Crypto API
     https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto#supported_algorithms
   GCM is recommended because it does provide built-in authentication (authenticated encryption)
     https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/encrypt#supported_algorithms
 */
export const SYMMETRIC_ALGORITHM = 'AES-GCM';

export const generateSymmetricMessageKey = async () => {
  const symKey = await window.crypto.subtle.generateKey(
    {
      name: SYMMETRIC_ALGORITHM,
      length: 256
    },
    true,
    ['encrypt', 'decrypt']
  );
  return window.crypto.subtle.exportKey('raw', symKey);
};

export const importSymmetricMessageKey = async (
  exportedSymmetricMessageKey: BufferSource
) =>
  window.crypto.subtle.importKey(
    'raw',
    exportedSymmetricMessageKey,
    SYMMETRIC_ALGORITHM,
    true,
    ['encrypt', 'decrypt']
  );

export const generateRandomPart = () => {
  const random = self.crypto.randomUUID();
  const randomBuffer = Buffer.from(random);
  return randomBuffer.toString('base64');
};

/*
 * An ArrayBuffer, a TypedArray, or a DataView with the initialization vector.
 * This must be unique for every encryption operation carried out with a given key.
 * Put another way: never reuse an IV with the same key.
 * The AES-GCM specification recommends that the IV should be 96 bits long, and typically contains bits from a random number generator.
 * Note that the IV does not have to be secret, just unique: so it is OK, for example, to transmit it in the clear alongside the encrypted message.
 */
export const getInitializationVector = (
  randomPart: string,
  freePart: string
) => {
  const random = Buffer.from(randomPart);
  const free = Buffer.from(freePart);
  return Buffer.concat([random, free]);
};

const decryptSymmetricMessageKey = async (
  encryptedSymmetricMessageArmored: string,
  privateKeyArmored: string
) => {
  const participantPrivateKey = await openpgp.readPrivateKey({
    armoredKey: privateKeyArmored
  });
  const symKeyMessage = await openpgp.readMessage({
    armoredMessage: encryptedSymmetricMessageArmored
  });
  const { data: symKey } = await openpgp.decrypt({
    message: symKeyMessage,
    decryptionKeys: participantPrivateKey,
    format: 'binary'
  });
  return importSymmetricMessageKey(symKey as BufferSource);
};

const encryptSymmetricMessageKey = async (
  msgKey: ArrayBuffer,
  publicKeyArmored: string
): Promise<string> => {
  const participantPublicKey = await openpgp.readKey({
    armoredKey: publicKeyArmored
  });
  const symKeyMessage = await openpgp.createMessage({
    binary: new Uint8Array(msgKey),
    format: 'binary'
  });
  return (await openpgp.encrypt({
    message: symKeyMessage,
    encryptionKeys: participantPublicKey
  })) as string;
};

const encryptString = async (
  value: string,
  symmetricKey: ArrayBuffer,
  randomPart: string,
  attributeName: string
) => {
  const enc = new TextEncoder();
  return window.crypto.subtle
    .encrypt(
      {
        name: SYMMETRIC_ALGORITHM,
        iv: getInitializationVector(randomPart, attributeName)
      },
      await importSymmetricMessageKey(symmetricKey),
      enc.encode(value)
    )
    .then((res) => {
      const buffer = Buffer.from(res);
      return buffer.toString('base64');
    });
};

const useSendEncryptedEvent = (bookerId: string, offererId: string) => {
  const bookerPublicKey = useGetOrganizationPublicKey(bookerId);
  const offererPublicKey = useGetOrganizationPublicKey(offererId);
  const createEvent = useCreateEvent();
  const replaceOrganizationSpecificKey = useReplaceOrganizationSpecificKey();
  const { clearBookingList } = useBooking();

  const create = async (event: EventCreate) => {
    const ivRandomPart = generateRandomPart().slice(0, 16); // BE meckert, wenn nicht genau 16 Zeichen lang
    const symmetricMessageKey = await generateSymmetricMessageKey();

    const eventDetails = event.eventDetails;
    const encryptEventDetailParam = async (
      paramValue: string,
      paramKey: string
    ) => {
      return encryptString(
        paramValue,
        symmetricMessageKey,
        ivRandomPart,
        paramKey
      );
    };
    const encryptedEventDetails = {
      firstName: await encryptEventDetailParam(
        eventDetails.firstName,
        'firstName'
      ),
      lastName: await encryptEventDetailParam(
        eventDetails.lastName,
        'lastName'
      ),
      fixedLocation: {
        description: await encryptEventDetailParam(
          eventDetails.fixedLocation.description,
          'description'
        ),
        city: await encryptEventDetailParam(
          eventDetails.fixedLocation.city,
          'city'
        ),
        street: await encryptEventDetailParam(
          eventDetails.fixedLocation.street,
          'street'
        ),
        zipCode: await encryptEventDetailParam(
          eventDetails.fixedLocation.zipCode,
          'zipCode'
        ),
        houseNumber: await encryptEventDetailParam(
          eventDetails.fixedLocation.houseNumber,
          'houseNumber'
        )
      },
      initializationVector: ivRandomPart
    };

    createEvent.mutate(
      {
        ...event,
        eventDetails: {
          ...encryptedEventDetails,
          gpsPosition: event.eventDetails.gpsPosition
        }
      },
      {
        onSuccess(data) {
          clearBookingList();
          const keyId = data.id;
          [
            { id: bookerId, pk: bookerPublicKey.data },
            {
              id: offererId,
              pk: offererPublicKey.data
            }
          ].map((org) => {
            if (org.pk) {
              encryptSymmetricMessageKey(symmetricMessageKey, org.pk)
                .then((encryptedKey) => {
                  replaceOrganizationSpecificKey.mutate(
                    {
                      organizationId: org.id,
                      keyId,
                      key: encryptedKey
                    },
                    {
                      onError: (reason) => {
                        notifyMutationError(
                          getCryptoText('keySendFail') + '\n' + reason
                        );
                        // TODO Fehlerbehandlung definieren
                      }
                    }
                  );
                })
                .catch((reason) => {
                  console.log(reason);
                  notifyMutationError(
                    `${getCryptoText('encryptSymMsgKeyFail')} ${reason}`
                  );
                  // TODO Fehlerbehandlung definieren
                });
            } else {
              notifyMutationError(getCryptoText('publicKeyMissing'));
              // TODO Fehlerbehandlung definieren
            }
          });
        },
        onError: (error) => {
          if (error instanceof ResponseError) {
            error?.response
              ?.json()
              .then((errorInformation: ErrorInformation) =>
                notifyMutationError(errorInformation.message)
              );
          } else notifyMutationError(getOfferBookingText('errorMessage'));
        }
      }
    );
  };
  return { create, createEvent };
};

const decryptString = async (
  importedSymKey: CryptoKey,
  encryptedString: string,
  randomPart: string,
  attributeName: string
): Promise<string> => {
  const dec = new TextDecoder();
  const encryptedBuffer = Buffer.from(encryptedString, 'base64');
  return dec.decode(
    await window.crypto.subtle.decrypt(
      {
        name: SYMMETRIC_ALGORITHM,
        iv: getInitializationVector(randomPart, attributeName)
      },
      importedSymKey,
      encryptedBuffer
    )
  );
};

const decryptEventDetails = async (
  event: EntityModelEvent,
  encryptedSymMsgKey: string,
  privateKey: string
) => {
  const eventDetails = event.eventDetails;
  const messageKey = await decryptSymmetricMessageKey(
    encryptedSymMsgKey,
    privateKey
  );
  const decryptEventDetailParam = async (
    paramValue: string,
    paramKey: string
  ) => {
    return decryptString(
      messageKey,
      paramValue,
      eventDetails.initializationVector,
      paramKey
    );
  };
  const firstName = await decryptEventDetailParam(
    eventDetails.firstName,
    'firstName'
  );
  const lastName = await decryptEventDetailParam(
    eventDetails.lastName,
    'lastName'
  );
  const description = await decryptEventDetailParam(
    eventDetails.fixedLocation.description,
    'description'
  );
  const city = await decryptEventDetailParam(
    eventDetails.fixedLocation.city,
    'city'
  );
  const street = await decryptEventDetailParam(
    eventDetails.fixedLocation.street,
    'street'
  );
  const zipCode = await decryptEventDetailParam(
    eventDetails.fixedLocation.zipCode,
    'zipCode'
  );
  const houseNumber = await decryptEventDetailParam(
    eventDetails.fixedLocation.houseNumber,
    'houseNumber'
  );

  return Promise.all([
    firstName,
    lastName,
    description,
    city,
    street,
    zipCode,
    houseNumber
  ]).then((values) => {
    const [
      decryptedFirstName,
      decryptedLastName,
      decryptedDescription,
      decryptedCity,
      decryptedStreet,
      decryptedZipCode,
      decryptedHouseNumber
    ] = values;
    return {
      ...event,
      eventDetails: {
        firstName: decryptedFirstName,
        lastName: decryptedLastName,
        fixedLocation: {
          description: decryptedDescription,
          city: decryptedCity,
          street: decryptedStreet,
          zipCode: decryptedZipCode,
          houseNumber: decryptedHouseNumber
        },
        gpsPosition: event.eventDetails.gpsPosition
      }
    } as EntityModelEvent;
  });
};

interface CryptoContextValues {
  decryptEventDetails: (
    event: EntityModelEvent,
    encryptedSymMsgKey: string,
    privateKey: string
  ) => Promise<EntityModelEvent>;
  selectedOrganizationPrivateKey?: string;
  addPrivateKey: (privateKey: string) => void;
  forgetPrivateKey: () => void;
  showPrivateKeyDialog: boolean;
}

const defaultCryptoContextValues: CryptoContextValues = {
  decryptEventDetails,
  addPrivateKey: () => {},
  forgetPrivateKey: () => {},
  showPrivateKeyDialog: true
};

const CryptoContext = createContext<CryptoContextValues>(
  defaultCryptoContextValues
);
const useCrypto = () => useContext(CryptoContext) as CryptoContextValues;

interface CryptoContextProviderProps {
  children: ReactElement;
}

const CryptoContextProvider = ({ children }: CryptoContextProviderProps) => {
  const [selectedOrganizationPrivateKey, setSelectedOrganizationPrivateKey] =
    useState<string>();
  const showPrivateKeyDialog = selectedOrganizationPrivateKey === undefined;

  const forgetPrivateKey = () => {
    setSelectedOrganizationPrivateKey(undefined);
  };

  return (
    // Creating the provider and passing the state into it. Whenever the state changes the components using this context will be re-rendered.
    <CryptoContext.Provider
      value={{
        decryptEventDetails,
        selectedOrganizationPrivateKey,
        addPrivateKey: setSelectedOrganizationPrivateKey,
        forgetPrivateKey,
        showPrivateKeyDialog
      }}
    >
      {children}
    </CryptoContext.Provider>
  );
};

export default CryptoContextProvider;

export { useCrypto, useSendEncryptedEvent };
