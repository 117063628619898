import {
  createTheme,
  lighten,
  responsiveFontSizes
} from '@mui/material/styles';
import type { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { deDE as pickerDE } from '@mui/x-date-pickers/locales';
import { deDE as gridDE } from '@mui/x-data-grid';
import { deDE as coreDE } from '@mui/material/locale';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
  }
  interface TypographyVariants {
    hero: TypographyStyleOptions;
    processLabel: TypographyStyleOptions;
    processNumber: TypographyStyleOptions;
    formLabel: TypographyStyleOptions;
    footer: TypographyStyleOptions;
    customCardTitle: TypographyStyleOptions;
    customCardDescription: TypographyStyleOptions;
    customCardHeaderTitle: TypographyStyleOptions;
    sectionTitle: TypographyStyleOptions;
    editorLabel: TypographyStyleOptions;
    bookingSectionHeading: TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    hero?: TypographyStyleOptions;
    processLabel?: TypographyStyleOptions;
    processNumber?: TypographyStyleOptions;
    formLabel?: TypographyStyleOptions;
    footer?: TypographyStyleOptions;
    customCardTitle?: TypographyStyleOptions;
    customCardDescription?: TypographyStyleOptions;
    customCardHeaderTitle?: TypographyStyleOptions;
    sectionTitle: TypographyStyleOptions;
    editorLabel: TypographyStyleOptions;
    bookingSectionHeading: TypographyStyleOptions;
  }

  interface PaletteColor {
    background?: string;
    calendar?: string;
  }

  interface SimplePaletteColorOptions {
    background?: string;
    calendar?: string;
  }

  interface PaletteColor {
    background?: string;
    calendar?: string;
  }

  interface SimplePaletteColorOptions {
    background?: string;
    calendar?: string;
  }
  interface Palette {
    placeholder: Palette['primary'];
    calendar: Palette['primary'];
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    placeholder?: PaletteOptions['primary'];
    calendar?: Palette['primary'];
    hover?: PaletteOptions['primary'];
    neutral?: PaletteOptions['primary'];
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    hero: true;
    breadcrumb: true;
    currentBreadcrumb: true;
    processLabel: true;
    processNumber: true;
    formLabel: true;
    footer: true;
    offercardTitle: true;
    offercardDescription: true;
    customCardTitle: true;
    customCardDescription: true;
    customCardHeaderTitle: true;
    sectionTitle: true;
    editorLabel: true;
    bookingSectionHeading: true;
  }
}

// Note: instead of a theme callback use these shortcuts (pxToRem, palette, unstable_sx) for a less bloated configuration
//   e.g. see MuiButton
const {
  typography: { pxToRem },
  palette,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  unstable_sx
} = createTheme();

const SANS_SERIF = ['Roboto', 'sans-serif'].join(',');
const SERIF = ['Lusitana', 'serif'].join(',');

const hRules: TypographyStyleOptions = {
  fontFamily: SERIF,
  letterSpacing: 0
};

const clampRules: TypographyStyleOptions = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
};

const customTheme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1500,
        xxl: 2000
      }
    },
    // color related theming is handled in context/project
    palette: {
      primary: {
        main: '#616161'
      },
      secondary: {
        main: '#000000'
      },
      error: {
        main: '#AF3325'
      },
      // neutral grey
      neutral: {
        main: '#bfccd7',
        dark: '#adbbc8'
      }
    },
    typography: {
      fontFamily: SANS_SERIF,
      h1: {
        ...hRules,
        fontSize: 42,
        textAlign: 'start'
      },
      h2: {
        ...hRules,
        fontSize: 32,
        textAlign: 'center'
      },
      body1: {
        hyphens: 'auto',
        letterSpacing: 0,
        color: '#000000DE'
      },
      hero: {
        ...hRules,
        fontWeight: 300,
        textAlign: 'center',
        lineHeight: 1.1,
        hyphens: 'auto',
        whiteSpace: 'pre-line'
      },
      processLabel: {
        fontSize: 16,
        textAlign: 'center',
        letterSpacing: 0,
        lineHeight: 1.2
      },
      processNumber: {
        hRules,
        textAlign: 'center',
        fontWeight: 'bold'
      },
      formLabel: {
        fontWeight: 600,
        textAlign: 'left',
        letterSpacing: 0
      },
      footer: {
        color: 'white',
        fontStyle: 'normal'
      },
      customCardTitle: {
        ...hRules,
        ...clampRules,
        fontSize: 20,
        textAlign: 'left',
        fontFamily: SANS_SERIF,
        minHeight: 60,
        fontWeight: 'normal'
      },
      customCardDescription: {
        ...clampRules,
        fontSize: 14
      },
      customCardHeaderTitle: {
        ...clampRules,
        fontSize: 14
      },
      sectionTitle: {
        fontSize: pxToRem(16),
        fontWeight: 600
      },
      editorLabel: {
        fontSize: pxToRem(16),
        fontWeight: 400
      },
      bookingSectionHeading: {
        fontSize: pxToRem(24),
        fontWeight: 600
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `#root { margin-bottom: -112px;}`
      },
      MuiTooltip: {
        defaultProps: {
          arrow: true
        },
        styleOverrides: {
          tooltip: ({ theme }) =>
            theme.unstable_sx({
              backgroundColor: 'primary.main',
              color: 'primary.contrastText.main'
            }),
          arrow: ({ theme }) =>
            theme.unstable_sx({
              color: 'primary.main'
            })
        }
      },
      MuiLink: {
        variants: [
          {
            props: {
              variant: 'breadcrumb'
            },
            style: ({ theme }) =>
              theme.unstable_sx({
                color: theme.palette.primary.light,
                fontSize: {
                  xs: 12,
                  md: 16,
                  display: 'block'
                }
              })
          },
          {
            props: {
              variant: 'currentBreadcrumb'
            },
            style: ({ theme }) =>
              theme.unstable_sx({
                color: theme.palette.primary.main,
                fontSize: {
                  xs: 12,
                  md: 16
                }
              })
          }
        ]
      },
      MuiTypography: {
        styleOverrides: {
          root: ({ ownerState, theme }) => [
            ownerState.variant === 'hero' &&
              theme.unstable_sx({
                color: theme.palette.primary.main,
                fontSize: {
                  xs: 22,
                  sm: 32,
                  md: 40,
                  lg: 48
                }
              }),
            ownerState.variant === 'processLabel' &&
              theme.unstable_sx({
                color: 'inherit',
                textAlign: { xs: 'center', lg: 'right' },
                fontSize: { xs: 14, sm: 15, xxl: 18 },
                marginTop: { xs: -1, sm: 'unset' },
                paddingTop: { xs: 0.5, sm: 'unset' },
                paddingBottom: { xs: 0.5, sm: 'unset' },
                fontWeight: 600
              }),
            ownerState.variant === 'processNumber' &&
              theme.unstable_sx({
                color: 'inherit',
                fontSize: { xs: 32, lg: 42, xxl: 64 }
              }),
            ownerState.variant === 'formLabel' &&
              theme.unstable_sx({
                paddingBottom: 2
              }),
            ownerState.variant === 'footer' &&
              theme.unstable_sx({
                a: {
                  color: 'white'
                }
              }),
            ownerState.variant === 'customCardTitle' &&
              theme.unstable_sx({
                color: theme.palette.secondary.main,
                WebkitLineClamp: 2,
                mb: 2
              }),
            ownerState.variant === 'customCardDescription' &&
              theme.unstable_sx({
                WebkitLineClamp: 3,
                minHeight: 30
              }),
            ownerState.variant === 'customCardHeaderTitle' &&
              theme.unstable_sx({
                WebkitLineClamp: 2
              }),
            ownerState.variant === 'sectionTitle' &&
              theme.unstable_sx({
                mb: 1.5
              }),
            ownerState.variant === 'editorLabel' &&
              theme.unstable_sx({
                color: theme.palette.grey[700],
                mb: 1.5
              }),
            ownerState.variant === 'bookingSectionHeading' &&
              theme.unstable_sx({
                color: theme.palette.secondary.main,
                WebkitLineClamp: 2,
                mb: 2
              })
          ],
          h1: ({ theme }) =>
            theme.unstable_sx({
              color: theme.palette.primary.main,
              pt: {
                md: 2
              },
              pb: {
                xs: 2,
                sm: pxToRem(20),
                md: pxToRem(30)
              },
              fontSize: {
                xs: 22,
                sm: 32,
                md: 40,
                lg: 48
              }
            }),
          h2: ({ theme }) =>
            theme.unstable_sx({
              color: theme.palette.primary.main,
              py: 2,
              my: 0,
              fontSize: {
                xs: 20,
                sm: 25,
                md: 28,
                lg: 32
              }
            }),
          h3: ({ theme }) =>
            theme.unstable_sx({
              color: theme.palette.secondary.main,
              py: 2
            }),
          body1: ({ theme }) =>
            theme.unstable_sx({
              textAlign: { xs: 'left', sm: 'justify' },
              fontSize: {
                xs: 16,
                md: 18
              }
            })
        }
      },
      MuiButton: {
        defaultProps: {
          size: 'large',
          variant: 'contained'
        },
        styleOverrides: {
          root: unstable_sx({
            textAlign: 'center',
            wordBreak: 'keep-all'
          }),
          outlined: unstable_sx({
            bgcolor: 'white',
            '&:hover': {
              bgcolor: lighten(palette.primary.main, 0.9)
            }
          })
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '[data-shrink="false"]': {
              color: '#0000008A'
            }
          }
        }
      },

      MuiFormLabel: {
        styleOverrides: {
          root: unstable_sx({
            fontSize: { xs: 16, md: 20 }
          })
        }
      },

      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            background: 'white',
            paddingRight: pxToRem(10)
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          root: ({ ownerState, theme }) => [
            theme.unstable_sx({
              '.MuiAlert-icon': {
                color: 'inherit'
              }
            }),
            ownerState.severity === 'success' &&
              theme.unstable_sx({
                bgcolor: theme.palette.primary.background,
                color: theme.palette.primary.main
              })
          ]
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ theme }) => [
            theme.unstable_sx({
              bgcolor: theme.palette.primary.light
            })
          ]
        }
      }
    }
  },
  pickerDE,
  gridDE,
  coreDE
);

export const responsiveTheme = responsiveFontSizes(
  customTheme,
  /* Default to 2. This value determines the strength of font size resizing.
  The higher the value, the less difference there is between font sizes on small screens.
  The lower the value, the bigger font sizes for small screens. The value must be greater than 1. */
  { factor: 3 }
);
export default responsiveTheme;
