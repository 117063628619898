import {
  type EntityModelOrganizationProfile,
  type GetOrganizationProfileRequest
} from '@SLR/solution3-sdk';
import useOrganizationProfilesApi from './useOrganizationProfilesApi';
import { useQuery } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';

const useGetOrganizationProfile = (organizationId: string | undefined) => {
  const projectId = useGetProjectId();
  const { getOrganizationProfile } = useOrganizationProfilesApi();
  const request: GetOrganizationProfileRequest = {
    projectId: projectId ?? '',
    organizationId: organizationId ?? ''
  };
  return useQuery<EntityModelOrganizationProfile, Response>(
    ['organization profile', { projectId, organizationId }],
    () => {
      return getOrganizationProfile(request);
    },

    {
      enabled: !!projectId && !!organizationId
    }
  );
};

export default useGetOrganizationProfile;
