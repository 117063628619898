import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';
import {
  type GetProjectSectionTextRequest,
  ProjectSectionsApi,
  type ReplaceProjectSectionTextRequest
} from '@SLR/solution3-sdk';

const useProjectSectionsApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.projectSections) as ProjectSectionsApi;
  }, []);

  const getProjectSectionText = useCallback(
    async (requestParameters: GetProjectSectionTextRequest) => {
      try {
        return await apiInstance.getProjectSectionText(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const replaceProjectSectionText = useCallback(
    async (requestParameters: ReplaceProjectSectionTextRequest) => {
      try {
        return await apiInstance.replaceProjectSectionText(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return { getProjectSectionText, replaceProjectSectionText };
};

export default useProjectSectionsApi;
