import { type AnonymousMessage } from '@SLR/solution3-sdk';
import { getTextIn, getErrorText } from 'localization';
import { EMAIL_REGEX } from 'utils/helper';
import { ObjectSchema, boolean, object, string } from 'yup';

const getContactText = getTextIn('contact');

const MAX_SUBJECT_LENGTH = 200;
const MAX_CONTENT_LENGTH = 2000;

type ExtendedAnonymousMessage = AnonymousMessage & {
  privacyPolicy: boolean;
};

const EMPTY_ANONYMOUSMESSAGE: ExtendedAnonymousMessage = Object.freeze({
  firstName: '',
  lastName: '',
  email: '',
  subject: '',
  content: '',
  privacyPolicy: false
});

const AnonymousMessageSchema = (): ObjectSchema<ExtendedAnonymousMessage> =>
  object().shape({
    firstName: string().required().min(1).label(getContactText('firstName')),
    lastName: string().required().min(1).label(getContactText('lastName')),
    email: string()
      .required()
      .min(1)
      .matches(EMAIL_REGEX, { message: getErrorText('emailFormatError') })
      .label(getContactText('email')),
    subject: string()
      .required()
      .min(1)
      .max(MAX_SUBJECT_LENGTH)
      .label(getContactText('subject')),
    content: string()
      .required()
      .min(1)
      .max(MAX_CONTENT_LENGTH)
      .label(getContactText('content')),
    privacyPolicy: boolean().required().oneOf([true], '')
  });

export {
  AnonymousMessageSchema,
  EMPTY_ANONYMOUSMESSAGE,
  MAX_SUBJECT_LENGTH,
  MAX_CONTENT_LENGTH
};
export type { ExtendedAnonymousMessage };
