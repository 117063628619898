const WEEKDAYS = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
] as const;

const START_TIME = 'startTime';
const END_TIME = 'endTime';

const LOCAL_TIME_FORMAT = 'HH:mm:ss';
const ERROR_EMPTY = 'empty';

export { WEEKDAYS, START_TIME, END_TIME, LOCAL_TIME_FORMAT, ERROR_EMPTY };
