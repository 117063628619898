import { useQuery } from '@tanstack/react-query';
import { useKeycloakAuth } from 'auth';
import useUsersApi from './useUsersApi';
import { useGetProjectId } from 'context/project';

const useGetCurrentUser = () => {
  const { getCurrentUser } = useUsersApi();
  const { isAuthenticated } = useKeycloakAuth();
  const projectId = useGetProjectId();

  return useQuery(
    ['currentUser', { projectId }],
    () => {
      return getCurrentUser({ projectId: projectId ?? '' });
    },
    {
      enabled: !!projectId && isAuthenticated
    }
  );
};

export default useGetCurrentUser;
