import { type AnonymousMessage } from '@SLR/solution3-sdk';
import { useMutation } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useMessagesApi from './useMessageApi';

const useCreateAdminMessage = () => {
  const { createAdminMessage } = useMessagesApi();
  const projectId = useGetProjectId() ?? '';

  return useMutation<void, Error, AnonymousMessage>(
    (anonymousMessage: AnonymousMessage) =>
      createAdminMessage({
        projectId,
        anonymousMessage
      })
  );
};

export default useCreateAdminMessage;
