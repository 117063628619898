import { useCallback, useMemo } from 'react';
import { getApiInstance, APINames } from 'utils/api-utils';
import {
  InvitationsApi,
  type GetInvitationRequest,
  type CreateInvitationRequest,
  type DeleteInvitationRequest,
  type CreateInvitationAcceptanceRequest,
  type CreateInvitationApprovalRequest
} from '@SLR/solution3-sdk';

const useInvitationsApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.invitations) as InvitationsApi;
  }, []);

  const getInvitation = useCallback(
    async (requestParameters: GetInvitationRequest) => {
      try {
        return await apiInstance.getInvitation(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createInvitation = useCallback(
    async (requestParameters: CreateInvitationRequest) => {
      try {
        return await apiInstance.createInvitation(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const deleteInvitation = useCallback(
    async (requestParameters: DeleteInvitationRequest) => {
      try {
        return await apiInstance.deleteInvitation(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createInvitationAcceptance = useCallback(
    async (requestParameters: CreateInvitationAcceptanceRequest) => {
      try {
        return await apiInstance.createInvitationAcceptance(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createInvitationApproval = useCallback(
    async (requestParameters: CreateInvitationApprovalRequest) => {
      try {
        return await apiInstance.createInvitationApproval(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getInvitation,
    createInvitation,
    deleteInvitation,
    createInvitationAcceptance,
    createInvitationApproval
  };
};

export default useInvitationsApi;
