import { type EntityModelOffer, type OfferCreate } from '@SLR/solution3-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useOffersApi, { OFFERS_LIST_KEY } from './useOffersApi';
import { useUser } from 'context/user';

const useCreateOffer = () => {
  const queryClient = useQueryClient();
  const projectId = useGetProjectId();
  const { createOffer } = useOffersApi();
  const { perspective } = useUser();

  return useMutation<EntityModelOffer, Error, OfferCreate>(
    (offerCreate: OfferCreate) => {
      const offerWithOrganization = { ...offerCreate };
      if (perspective.isOrganization) {
        offerWithOrganization.organizationId = perspective.id;
      }
      return createOffer({
        projectId: projectId ?? '',
        offerCreate: offerWithOrganization
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([OFFERS_LIST_KEY]);
      }
    }
  );
};

export default useCreateOffer;
