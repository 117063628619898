import Button from '@mui/material/Button';
import { getPath, getText } from 'localization';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';

const ContactButton = ({ dataCy }: { dataCy?: string }) => {
  return (
    <Button
      endIcon={<NavigateNextIcon />}
      component={RouterLink}
      to={`../../${getPath('contact')}`}
      data-cy={dataCy}
    >
      {getText('contact', 'contact')}
    </Button>
  );
};

export default ContactButton;
