import useOrganizationSettingsApi from './use-organization-settings-api';
import {
  type UpdateOrganizationSettingsRequest,
  type EntityModelOrganizationSettings
} from '@SLR/solution3-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import { useUser } from 'context/user';

type UseUpdateOrganizationSettings = Omit<
  UpdateOrganizationSettingsRequest,
  'projectId' | 'organizationId'
>;

const useUpdateOrganizationSettings = () => {
  const queryClient = useQueryClient();
  const { updateOrganizationSettings } = useOrganizationSettingsApi();
  const projectId = useGetProjectId() ?? '';
  const { perspective } = useUser();
  const organizationId = perspective?.isOrganization ? perspective.id : '';

  return useMutation<
    EntityModelOrganizationSettings,
    Error,
    UseUpdateOrganizationSettings
  >(
    ({ organizationSettingsUpdate }: UseUpdateOrganizationSettings) => {
      return updateOrganizationSettings({
        projectId,
        organizationId,
        organizationSettingsUpdate
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['organizationSettings']);
      }
    }
  );
};

export default useUpdateOrganizationSettings;
