import { type GetProjectTermsOfUseTextRequest } from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import useLegalApi from './useLegalApi';
import { useGetProjectId } from 'context/project';

const useGetProjectTermsOfUseText = () => {
  const projectId = useGetProjectId();
  const { getProjectTermsOfUseText } = useLegalApi();

  const requestParameters: GetProjectTermsOfUseTextRequest = {
    projectId: projectId ?? ''
  };
  return useQuery(
    ['termsOfUseText', { projectId }],
    () => {
      return getProjectTermsOfUseText(requestParameters);
    },
    {
      staleTime: 259200000,
      enabled: !!projectId
    }
  );
};
export default useGetProjectTermsOfUseText;
