import {
  type EntityModelBusinessHours,
  type GetBusinessHoursRequest
} from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useBusinessHoursApi from './useBusinessHoursApi';

const ORGANIZATION_BUSINESS_HOURS = 'organizationBusinessHours';

const useGetBusinessHours = (organizationId: string | undefined) => {
  const projectId = useGetProjectId();
  const { getBusinessHours } = useBusinessHoursApi();
  const request: GetBusinessHoursRequest = {
    projectId: projectId ?? '',
    organizationId: organizationId ?? ''
  };
  return useQuery<EntityModelBusinessHours, Response>(
    [ORGANIZATION_BUSINESS_HOURS, { projectId, organizationId }],
    () => {
      return getBusinessHours(request);
    },

    {
      enabled: !!projectId && !!organizationId
    }
  );
};

export default useGetBusinessHours;
export { ORGANIZATION_BUSINESS_HOURS };
