import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';
import {
  type CreateEventSuggestionsRequest,
  EventSuggestionsApi
} from '@SLR/solution3-sdk';

const useEventSuggestionsApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.eventSuggestion) as EventSuggestionsApi;
  }, []);

  const getEventSuggestions = useCallback(
    async (requestParameters: CreateEventSuggestionsRequest) => {
      try {
        return await apiInstance.createEventSuggestions(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getEventSuggestions
  };
};

export default useEventSuggestionsApi;
