import { getText, getTextIn, getPath } from 'localization';
import { ParticipantGrid } from 'feature';
import { CardPreviewSection, useCardPreviewNumber } from 'feature/cards';
import { useListParticipantsInfo } from 'feature/hooks';

const getInfoText = getTextIn('info');

type ProviderPreviewSectionType = {
  title?: string;
  coloredBackground?: boolean;
};

const ProviderPreviewSection = ({
  title = getText('members')
}: ProviderPreviewSectionType) => {
  const providers = useListParticipantsInfo({
    size: 4,
    showProviders: true,
    showSeekers: false,
    sort: ['random']
  });

  const previewNumber = useCardPreviewNumber();

  return (
    <CardPreviewSection
      title={title}
      showMoreButtonConfig={{
        to: `/${getPath('providers')}`,
        text: getInfoText('moreProviders')
      }}
    >
      <ParticipantGrid
        participantInfos={providers.data?.embedded?.participantInfos?.slice(
          0,
          previewNumber
        )}
        isLoading={providers.isLoading}
        isPreview
        role="provider"
      />
    </CardPreviewSection>
  );
};

export default ProviderPreviewSection;
