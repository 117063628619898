import { useState, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CheckboxesBlock, type CheckboxStateValues } from 'components';
import {
  SearchParams,
  getFilterOptions,
  getFilterCheckboxValues,
  FilterParamsCheckbox,
  type UrlParams,
  type KeyCheckboxParam,
  type VariableCheckboxOptions
} from 'utils/url-param';
import { getText } from 'localization';

type OnClickCheckboxes = (checkboxValues: CheckboxStateValues) => void;

type FilterCheckboxClick = (
  filterParam: FilterParamsCheckbox
) => OnClickCheckboxes;

type FilterCheckboxesProps = {
  search: UrlParams;
  filterParam: FilterParamsCheckbox;
  options?: VariableCheckboxOptions;
  disableUnchecked?: boolean;
  onClick: OnClickCheckboxes;
};

const FilterCheckboxes = ({
  search,
  filterParam,
  options,
  disableUnchecked = false,
  onClick
}: FilterCheckboxesProps) => {
  const filterOptions = options ?? getFilterOptions({ filterParam });

  const values = options
    ? getFilterCheckboxValues(
        search,
        SearchParams[filterParam as KeyCheckboxParam],
        {
          [filterParam]: options
        }
      )
    : getFilterCheckboxValues(search, filterParam);

  const [newValues, setNewValues] = useState<CheckboxStateValues>(values);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setNewValues((currentValues) => {
      return {
        ...currentValues,
        [name]: checked
      };
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <CheckboxesBlock
        options={filterOptions}
        values={newValues}
        onChange={onChange}
        disableTypography
        disableUnchecked={disableUnchecked}
      />

      <Button
        variant="contained"
        onClick={() => {
          onClick(newValues);
        }}
        sx={{ mt: 1.5, mb: 1.75 }}
      >
        {getText('apply')}
      </Button>
    </Box>
  );
};

export default FilterCheckboxes;
export type { OnClickCheckboxes, FilterCheckboxClick };
