import { useCallback, useMemo } from 'react';
import {
  type DeleteOrganizationRequest,
  type OrganizationsApi,
  type ListOrganizationsRequest
} from '@SLR/solution3-sdk';
import { APINames, getApiInstance } from 'utils/api-utils';

const useOrganizationsApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.organizations) as OrganizationsApi;
  }, []);

  const deleteOrganization = useCallback(
    async (requestParameters: DeleteOrganizationRequest) => {
      try {
        return await apiInstance.deleteOrganization(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const listOrganizations = useCallback(
    async (requestParameters: ListOrganizationsRequest) => {
      try {
        return await apiInstance.listOrganizations(requestParameters);
      } catch {
        return undefined;
      }
    },
    [apiInstance]
  );

  return {
    deleteOrganization,
    listOrganizations
  };
};

export default useOrganizationsApi;
