import { type EntityModelProject } from '@SLR/solution3-sdk';
import { Config, Locales, globalConfigKey } from 'localization';
import { merge } from 'lodash';
import config, { LOCALE_DEFAULT } from 'localization/config';

const LOCALE_SEPARATOR = '-';

const useSetConfig = (project?: EntityModelProject) => {
  const locale = (project?.locale ?? LOCALE_DEFAULT) as keyof Locales;

  if (project) {
    const [language, region, projectTag] = locale.split(LOCALE_SEPARATOR);

    globalThis[globalConfigKey].config = projectTag
      ? (merge(
          config[`${language}${LOCALE_SEPARATOR}${region}` as keyof Locales],
          config[locale]
        ) as Config)
      : config[locale];

    return true;
  }

  return false;
};

export default useSetConfig;
