import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  Bookable,
  type EntityModelCategory,
  type EntityModelTargetGroup
} from '@SLR/solution3-sdk';
import { useProject } from 'context/project';
import Dropdown from './dropdown';
import { useGetGeoArea } from 'feature/hooks';
import { RadioBlock, type OnChangeRadio } from 'components';
import {
  SearchParams,
  getIsFilterSet,
  getSortOptions,
  getSortParam,
  onRemoveAllFilters,
  type FilterHandler,
  type OnRemoveFilter,
  type SetSearchParamWithPageSetback,
  type UrlParams,
  type OnClickDate,
  type OnClickDuration
} from 'utils/url-param';
import { isEmptyArray } from 'utils/helper';
import FilterMobile from './filter-mobile';
import FilterCheckboxes, { type OnClickCheckboxes } from './filter-checkboxes';
import Date from './date';
import Duration from './duration';
import FilterGeoArea, { type OnClickGeoArea } from './geo-area';
import RemoveFilterBlock from './remove-block';
import { getTextIn } from 'localization';

const getFilterText = getTextIn('filter');

type FilterSectionProps = FilterHandler & {
  search: UrlParams;
  totalElements?: number;
  categories?: EntityModelCategory[];
  targetGroups?: EntityModelTargetGroup[];
  hideCategoryFilter?: boolean;
  hasTargetGroup: boolean;
  setSearchParamWithPageSetback: SetSearchParamWithPageSetback;
  onRemoveFilter: OnRemoveFilter;
};

const Filter = ({
  search,
  totalElements,
  categories,
  targetGroups,
  hideCategoryFilter,
  hasTargetGroup,
  setSearchParamWithPageSetback,
  onRemoveFilter,
  onClickApplyCheckbox,
  onClickApplyDate,
  onClickApplyDuration,
  onClickApplyGeoArea,
  onChangeSort
}: FilterSectionProps) => {
  const { project } = useProject();
  const geoArea = useGetGeoArea(search[SearchParams.geoAreaId] ?? '');
  const geoAreaData = geoArea.data;

  const isFilterSet = getIsFilterSet(search);
  const isQuerySet = !!search[SearchParams.query];

  const [dropDownsShouldClose, setDropDownsShouldClose] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRemoveAllFiltersPrefilled = useCallback(
    onRemoveAllFilters(search, setSearchParamWithPageSetback),
    [search, setSearchParamWithPageSetback]
  );

  const closeCtrl = {
    dropDownsShouldClose,
    setDropDownsShouldClose
  };

  return (
    <>
      {/* filter mobile */}
      <Container
        sx={{
          mt: '0 !important',
          px: '0 !important',
          display: { xs: 'flex', md: 'none' }
        }}
      >
        <FilterMobile
          search={search}
          totalElements={totalElements}
          geoAreaData={geoAreaData}
          hideCategoryFilter={hideCategoryFilter}
          hasTargetGroup={hasTargetGroup}
          onRemoveFilter={onRemoveFilter}
          onRemoveAllFilters={onRemoveAllFiltersPrefilled}
          onClickApplyCheckbox={onClickApplyCheckbox}
          onClickApplyGeoArea={onClickApplyGeoArea}
          onClickApplyDate={onClickApplyDate}
          onClickApplyDuration={onClickApplyDuration}
          onChangeSort={onChangeSort}
        />
      </Container>

      {/* filter dropdowns */}
      <Box
        mt="1.875rem !important"
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            m: -1.25
          }}
        >
          <Dropdown
            text={getFilterText('sortBy')}
            onChangeSort={onChangeSort}
            {...closeCtrl}
          >
            {(onChangeWithClose) => (
              <RadioBlock
                row={false}
                radioOption={getSortOptions(isQuerySet)}
                controlledValue={getSortParam(search)}
                onValueChange={onChangeWithClose as OnChangeRadio}
                disableTypography
              />
            )}
          </Dropdown>
          <Dropdown
            text={getFilterText('timeRange')}
            onClickApplyDate={onClickApplyDate}
            {...closeCtrl}
          >
            {(onClickWithClose) => (
              <Date
                search={search}
                onClick={onClickWithClose as OnClickDate}
                onCancel={onRemoveFilter}
              />
            )}
          </Dropdown>
          <Dropdown
            text={getFilterText('duration')}
            onClickApplyDuration={onClickApplyDuration}
            {...closeCtrl}
          >
            {(onClickWithClose) => (
              <Duration
                search={search}
                onClick={onClickWithClose as OnClickDuration}
              />
            )}
          </Dropdown>
          <Dropdown
            text={getFilterText('location')}
            onClick={onClickApplyGeoArea}
            {...closeCtrl}
          >
            {(onClickWithClose, setDisabledClickAway) => (
              <FilterGeoArea
                geoAreaData={geoAreaData}
                distanceValue={search[SearchParams.distance]}
                onClick={onClickWithClose as OnClickGeoArea}
                setDisabledClickAway={setDisabledClickAway}
                width={250}
              />
            )}
          </Dropdown>
          <Dropdown
            text={getFilterText('locationType')}
            onClickApplyCheckbox={onClickApplyCheckbox(
              SearchParams.locationTypes
            )}
            {...closeCtrl}
          >
            {(onClickWithClose) => (
              <FilterCheckboxes
                search={search}
                filterParam={SearchParams.locationTypes}
                disableUnchecked={project?.offersBookable === Bookable.Multi}
                onClick={onClickWithClose as OnClickCheckboxes}
              />
            )}
          </Dropdown>

          {!hideCategoryFilter && (
            <Dropdown
              categories={categories}
              text={getFilterText('category')}
              onClickApplyCheckbox={onClickApplyCheckbox(
                SearchParams.categories
              )}
              {...closeCtrl}
            >
              {(onClickWithClose) => (
                <FilterCheckboxes
                  search={search}
                  options={categories}
                  filterParam={SearchParams.categories}
                  onClick={onClickWithClose as OnClickCheckboxes}
                />
              )}
            </Dropdown>
          )}

          {hasTargetGroup && !isEmptyArray(targetGroups) && (
            <Dropdown
              targetGroups={targetGroups}
              text={getFilterText('targetGroup')}
              onClickApplyCheckbox={onClickApplyCheckbox(
                SearchParams.targetGroups
              )}
              {...closeCtrl}
            >
              {(onClickWithClose) => (
                <FilterCheckboxes
                  search={search}
                  options={targetGroups}
                  filterParam={SearchParams.targetGroups}
                  onClick={onClickWithClose as OnClickCheckboxes}
                />
              )}
            </Dropdown>
          )}
        </Box>
      </Box>

      {/* filter remove buttons  */}
      {isFilterSet && (
        <RemoveFilterBlock
          search={search}
          onRemoveFilter={onRemoveFilter}
          onRemoveAllFilters={onRemoveAllFiltersPrefilled}
          setDropDownsShouldClose={setDropDownsShouldClose}
        />
      )}
    </>
  );
};

export default Filter;
