import { Stack, Typography } from '@mui/material';
import compact from 'lodash/compact';
import join from 'lodash/join';

type AddressProps = {
  dataCyPrefix?: string;
  name?: {
    firstName: string;
    lastName: string;
  };
  address: {
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
    description?: string;
  };
  asString?: boolean;
};

const Address = ({
  dataCyPrefix,
  name,
  address,
  asString = false
}: AddressProps) => {
  const description = address.description;
  const street = join(compact([address.street, address.houseNumber]), ' ');
  const city = join(compact([address.zipCode, address.city]), ' ');

  return asString ? (
    <>{`${street}${street && ','} ${city}${
      city && description && ','
    } ${description}`}</>
  ) : (
    <Stack data-cy={`${dataCyPrefix}-address`} direction="column">
      {name && (
        <Typography>
          {name.firstName} {name.lastName}
        </Typography>
      )}
      {description && <Typography>{description}</Typography>}
      {street && <Typography>{street}</Typography>}
      {city && <Typography>{city}</Typography>}
    </Stack>
  );
};

export default Address;
