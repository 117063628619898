import {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  ChangeEventHandler,
  ReactNode
} from 'react';
import type { ButtonProps } from '@mui/material/Button';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import type { Image } from '@SLR/solution3-sdk';
import LoadingButton from 'components/loading-button';
import { useCreateImage } from 'feature/hooks';
import useGetIsMounted from 'utils/use-get-is-mounted';
import { onUploadError } from 'utils/image-utils';

type ImageUpdateLogicProps = {
  handleAddImage?: (imageToAdd: Image) => void;
  getNewImage?: ({
    currentTarget
  }: {
    currentTarget: EventTarget & HTMLInputElement;
  }) => void;
  setIsLoadingImage?: Dispatch<SetStateAction<boolean>>;
  inputId?: string;
  inputDataCy?: string;
  label?: string;
  dataCy?: string;
  startIcon?: ReactNode;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  additionalOnClick?: VoidFunction;
  UploadComponent?: ({
    isLoadingImage
  }: {
    isLoadingImage: boolean;
  }) => React.ReactElement;
};

const ImageUpdateLogic = ({
  handleAddImage,
  getNewImage,
  setIsLoadingImage,
  inputId,
  inputDataCy = '',
  label = '',
  dataCy = '',
  startIcon = <FileUploadOutlinedIcon />,
  variant,
  size = 'small',
  additionalOnClick,
  UploadComponent
}: ImageUpdateLogicProps) => {
  const getIsMounted = useGetIsMounted();
  const addImage = useCreateImage();
  const [newImage, setNewImage] = useState<Blob>(new Blob());

  const id = inputId ?? 'upload-image';

  const getNewImageHandler: ChangeEventHandler<HTMLInputElement> = ({
    currentTarget
  }) => {
    if (currentTarget?.files) {
      Object.values(currentTarget?.files)?.map((file) => {
        setNewImage(file);
      });
    }
  };

  useEffect(() => {
    setIsLoadingImage?.(addImage.isLoading);
  }, [addImage.isLoading, setIsLoadingImage]);

  // fetch imageId
  useEffect(() => {
    if (handleAddImage && newImage?.size !== 0) {
      addImage.mutate(newImage, {
        onSuccess: (imageToAdd) => {
          if (getIsMounted()) {
            handleAddImage({
              ...imageToAdd,
              alternativeText: ''
            });
            setNewImage(new Blob()); // reset newImage
          }
        },
        onError: onUploadError
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newImage]);

  return (
    <label htmlFor={id}>
      <input
        data-cy={inputDataCy}
        accept="image/*"
        id={id}
        onChange={getNewImage ?? getNewImageHandler}
        type="file"
        hidden
      />

      {UploadComponent ? (
        <UploadComponent isLoadingImage={addImage.isLoading} />
      ) : (
        <LoadingButton
          dataCy={dataCy}
          component="div"
          variant={variant}
          startIcon={startIcon}
          size={size}
          loading={addImage.isLoading}
          onClick={(event) => {
            (
              event.currentTarget.previousElementSibling as HTMLInputElement
            ).value = '';

            additionalOnClick?.();
          }}
          label={label}
        />
      )}
    </label>
  );
};

export default ImageUpdateLogic;
