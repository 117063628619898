import { useRef, type ReactElement } from 'react';
import { Button, Grid, Box, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps
} from 'react-router-dom';
import { ScrollToId } from 'routes';
import { transformToFragment } from 'utils/fragments-utils';

type ShowMoreButtonConfig = {
  text: string;
  to: RouterLinkProps['to'];
};

type CardPreviewSectionProps = {
  children: ReactElement[] | ReactElement;
  title?: string;
  showMoreButtonConfig?: ShowMoreButtonConfig;
  coloredBackground?: boolean; // Necessary for drilling of backgroundColor in the process wrapper
};

const CardPreviewSection = ({
  title,
  children,
  showMoreButtonConfig
}: CardPreviewSectionProps) => {
  const titleRef = useRef<HTMLHeadingElement | null>(null);

  return (
    <>
      <ScrollToId element={titleRef} />
      <Box>
        {title && (
          <Typography
            ref={titleRef}
            variant="h2"
            align="center"
            id={transformToFragment(title)}
          >
            {title}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-around',
            gap: 2,
            mb: 3
          }}
        >
          {children}
        </Box>
        {showMoreButtonConfig && (
          <Grid
            container
            sx={{
              justifyContent: {
                xs: 'center',
                sm: 'right'
              }
            }}
          >
            <Grid item>
              <Button
                endIcon={<NavigateNextIcon />}
                component={RouterLink}
                to={showMoreButtonConfig.to}
                data-cy="cardpreviewsection-more-button"
              >
                {showMoreButtonConfig.text}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default CardPreviewSection;
