import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';
import {
  OrganizationSettingsApi,
  type GetOrganizationSettingsRequest,
  type UpdateOrganizationSettingsRequest
} from '@SLR/solution3-sdk';

const useOrganizationSettingsApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(
      APINames.organizationSettings
    ) as OrganizationSettingsApi;
  }, []);

  const getOrganizationSettings = useCallback(
    (requestParameters: GetOrganizationSettingsRequest) => {
      try {
        return apiInstance.getOrganizationSettings(requestParameters);
      } catch {
        return undefined;
      }
    },
    [apiInstance]
  );

  const updateOrganizationSettings = useCallback(
    (requestParameters: UpdateOrganizationSettingsRequest) => {
      try {
        return apiInstance.updateOrganizationSettings(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getOrganizationSettings,
    updateOrganizationSettings
  };
};

export default useOrganizationSettingsApi;
