import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import { useUser } from 'context/user';
import { ItemText } from 'feature';
import { getTextIn } from 'localization';
import { AvatarFallback } from 'feature/user-avatar';
import { MenuDivider } from 'components';

const getTextUser = getTextIn('user');

const OtherProfiles = ({ closeUserMenu }: { closeUserMenu: VoidFunction }) => {
  const {
    currentUser,
    organizations,
    perspective,
    getPerspective,
    changePerspective,
    indexedProfilesList
  } = useUser();

  const { enqueueSnackbar } = useSnackbar();
  const [userChangeFired, setUserChangeFired] = useState<string | boolean>(
    false
  );

  useEffect(() => {
    if (userChangeFired && currentUser) {
      enqueueSnackbar(`${getTextUser('changed')} ${userChangeFired}`, {
        variant: 'info'
      });
      setUserChangeFired(false);
    }
  }, [currentUser, enqueueSnackbar, userChangeFired]);

  const items =
    currentUser && organizations ? (
      <>
        <MenuDivider key="menu-appbar-logout-divider" />
        <MenuItem
          disableRipple
          sx={{
            px: 2.5,
            pt: 2.5,
            pb: 0,
            cursor: 'default',
            bgcolor: 'transparent !important'
          }}
        >
          <ItemText
            text={`${getTextUser('usePortalAs')}\u{2026}`}
            fontWeight={600}
          />
        </MenuItem>

        {Object.values(indexedProfilesList)
          .filter((details) => details.profile.id !== perspective.id)
          .map((details) => getPerspective(currentUser, details.profile))
          .map((profile, index, array) => {
            return (
              <MenuItem
                key={profile.label}
                data-cy={`available-profile-${profile.label}`}
                sx={{
                  height: 44,
                  p: 0,
                  mt: index ? 0 : 1,
                  mb: index === array.length - 1 ? 1 : 0
                }}
                onClick={() => {
                  changePerspective(profile.id);
                  closeUserMenu();
                  setUserChangeFired(profile.label);
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    px: 2.5,
                    py: 2,
                    width: '100%'
                  }}
                >
                  <ListItemIcon>
                    <Avatar
                      sx={{
                        width: 27,
                        height: 27
                      }}
                      alt={`${profile?.image?.alternativeText}`}
                      src={profile?.image?.urls.small}
                    >
                      <AvatarFallback isOrganization={profile.isOrganization} />
                    </Avatar>
                  </ListItemIcon>

                  <ItemText text={profile.label} />
                </Stack>
              </MenuItem>
            );
          })}
      </>
    ) : null;

  return items;
};

export default OtherProfiles;
