import { useQuery } from '@tanstack/react-query';
import { type PagedModelEntityModelParticipantInfo } from '@SLR/solution3-sdk';
import useParticipantsApi from './useParticipantsApi';
import { useGetProjectId } from 'context/project';

export type ListFilterTypes = {
  page?: number;
  size?: number;
  sort?: Array<string>;
  onlyWithDescription?: boolean;
  showProviders?: boolean;
  showSeekers?: boolean;
};

const useListParticipantsInfo = ({
  page,
  size,
  sort,
  onlyWithDescription,
  showProviders,
  showSeekers
}: ListFilterTypes) => {
  const { listParticipantsInfo } = useParticipantsApi();
  const projectId = useGetProjectId();

  return useQuery<PagedModelEntityModelParticipantInfo, Response>(
    [
      'participantsInfo',
      {
        projectId,
        page,
        size,
        sort,
        onlyWithDescription,
        showProviders,
        showSeekers
      }
    ],
    () => {
      return listParticipantsInfo({
        projectId: projectId ?? '',
        page,
        size,
        sort,
        onlyWithDescription,
        showProviders,
        showSeekers
      });
    },
    {
      staleTime: Infinity,
      enabled: !!projectId
    }
  );
};

export default useListParticipantsInfo;
