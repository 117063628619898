import { useCallback, useMemo } from 'react';
import {
  type GetOrganizationPublicKeyRequest,
  type GetOrganizationSpecificKeyRequest,
  type ReplaceOrganizationSpecificKeyRequest,
  OrganizationKeysApi
} from '@SLR/solution3-sdk';
import { APINames, getApiInstance } from 'utils/api-utils';

const useOrganizationKeysApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.organizationKeys) as OrganizationKeysApi;
  }, []);

  const getOrganizationPublicKey = useCallback(
    async (requestParameters: GetOrganizationPublicKeyRequest) => {
      try {
        return await apiInstance.getOrganizationPublicKey(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getOrganizationSpecificKey = useCallback(
    async (requestParameters: GetOrganizationSpecificKeyRequest) => {
      try {
        return await apiInstance.getOrganizationSpecificKey(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const replaceOrganizationSpecificKey = useCallback(
    async (requestParameters: ReplaceOrganizationSpecificKeyRequest) => {
      try {
        return await apiInstance.replaceOrganizationSpecificKey(
          requestParameters
        );
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getOrganizationPublicKey,
    getOrganizationSpecificKey,
    replaceOrganizationSpecificKey
  };
};

export default useOrganizationKeysApi;
