import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Stack, Typography } from '@mui/material';
import { DialogGeneric } from 'feature';
import { getTextIn, getNode } from 'localization';
import {
  formatAsDateTimeExtended,
  formatAsTime,
  getFormattedRangeString
} from 'utils/date';
import { EventDetailsDialogProps } from './event-details-dialog';
import useDeleteEvent from 'feature/hooks/events/useDeleteEvent';
import { notifyMutationError, notifyMutationSuccess } from 'feature/error';
import { useUser } from 'context/user';

const getCalendarText = getTextIn('calendar');

const DeleteEventDialog = ({
  event,
  showDialog,
  closeDialog,
  onDeleteClick
}: EventDetailsDialogProps) => {
  const deleteEvent = useDeleteEvent();
  const { perspective } = useUser();
  const { isProvider, isSeeker } = perspective.roles ?? {};

  const onDelete = () => {
    deleteEvent.mutate(event.id, {
      onSuccess: () => {
        notifyMutationSuccess(getCalendarText('deleteSuccess'));
        onDeleteClick();
      },
      onError: () => {
        notifyMutationError();
      }
    });
  };

  return (
    <DialogGeneric
      open={showDialog}
      title={getCalendarText('cancelAppointment')}
      onConfirm={onDelete}
      onClose={closeDialog}
      confirmText={getCalendarText('cancelAppointment')}
      confirmColor="error"
      prefix="delete-event-confirm-dialog"
    >
      <Stack
        direction="column"
        data-cy="delete-event-confirm-body"
        spacing={2}
        sx={{ py: 2 }}
      >
        <Box>
          <Typography component="span">
            {getNode(
              'getNodeDeleteConfirmation',
              'calendar'
            )({
              children: (
                <strong>{` ${event.title} ${getCalendarText(
                  'on'
                )} ${getFormattedRangeString(
                  event.timeSlot.startTime &&
                    formatAsDateTimeExtended(event.timeSlot.startTime),
                  event.timeSlot.endTime && formatAsTime(event.timeSlot.endTime)
                )} ${getCalendarText('oClock')} `}</strong>
              )
            })}
          </Typography>
        </Box>
        {isSeeker && (
          <Typography>{getCalendarText('seekerNotification')}</Typography>
        )}
        {isProvider && (
          <Typography>{getCalendarText('providerNotification')}</Typography>
        )}
      </Stack>
    </DialogGeneric>
  );
};

export default DeleteEventDialog;
