import { useParams } from 'react-router-dom';
import useProfileGalleryApi from './use-profile-gallery-api';
import { type ProfileGalleryEntriesUpdate } from '@SLR/solution3-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';

const useUpdateProfileGalleryEntries = () => {
  const queryClient = useQueryClient();
  const { updateProfileGalleryEntries } = useProfileGalleryApi();
  const projectId = useGetProjectId();
  const { profileId } = useParams();

  return useMutation<void, Error, ProfileGalleryEntriesUpdate>(
    (profileGalleryEntriesUpdate: ProfileGalleryEntriesUpdate) => {
      return updateProfileGalleryEntries({
        projectId: projectId ?? '',
        organizationId: profileId ?? '',
        profileGalleryEntriesUpdate
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'profileGalleryEntries',
          { projectId, profileId }
        ]);
      }
    }
  );
};

export default useUpdateProfileGalleryEntries;
