import {
  type InvitationCreate,
  type EntityModelInvitation
} from '@SLR/solution3-sdk';
import { useMutation } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useInvitationsApi from './useInvitationsApi';

const useCreateInvitation = () => {
  const { createInvitation } = useInvitationsApi();
  const projectId = useGetProjectId() ?? '';

  return useMutation<EntityModelInvitation, Error, InvitationCreate>(
    (invitationCreate: InvitationCreate) =>
      createInvitation({
        projectId,
        invitationCreate
      })
  );
};

export default useCreateInvitation;
