import { type EntityModelEvent, type EventCreate } from '@SLR/solution3-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useEventsApi from './useEventsApi';
import { OFFERS_LIST_KEY } from '../offers/useOffersApi';

const useCreateEvent = () => {
  const queryClient = useQueryClient();
  const { createEvent } = useEventsApi();
  const projectId = useGetProjectId() ?? '';

  return useMutation(
    (eventCreate: EventCreate): Promise<EntityModelEvent> => {
      return createEvent({
        projectId,
        eventCreate
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['events']);
        queryClient.refetchQueries([OFFERS_LIST_KEY]);
        queryClient.invalidateQueries(['eventSuggestions']);
      }
    }
  );
};

export default useCreateEvent;
