import { Routes, Route } from 'react-router-dom';
import { Layout } from 'layout';
import {
  OffersRoutes,
  ProviderRoutes,
  AccountRoutes,
  SettingsRoutes,
  ScrollToTop,
  RestrictedBookingRoute,
  InvitationRoutes,
  SeekerRoutes,
  CompassRoutes,
  Restricted
} from 'routes';
import {
  Home,
  PatientInfoPage,
  DataProtectionPage,
  CalendarPage,
  AccessibilityPage,
  ContactPage,
  TermsPage,
  AccessibilityDeclarationPage,
  ImprintPage,
  OfferBookingPage,
  DoctorInfoPage
} from 'pages';
import RouterContextProvider from 'context/router';
import { getPath, getPathSplat } from 'localization';
import { ErrorPageNotFound } from 'pages/error';
import { Onboarding } from 'feature/onboarding';
import { useProject } from 'context/project';

const App = () => {
  const { project } = useProject();

  return (
    <Layout>
      <Onboarding>
        <RouterContextProvider>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path={getPath('reportAccessibility')}
              element={
                <Restricted
                  isVisible={project?.features?.accessibility ?? false}
                  forceLogin={false}
                >
                  <AccessibilityPage />
                </Restricted>
              }
            />
            <Route path={getPath('contact')} element={<ContactPage />} />
            <Route path={getPathSplat('offers')} element={<OffersRoutes />} />
            <Route
              path={getPathSplat('settings')}
              element={<SettingsRoutes />}
            />
            <Route path={getPathSplat('account')} element={<AccountRoutes />} />
            <Route
              path={getPathSplat('providers')}
              element={<ProviderRoutes />}
            />
            <Route path={getPathSplat('seekers')} element={<SeekerRoutes />} />
            <Route
              path={getPathSplat('doctorInfo')}
              element={<DoctorInfoPage />}
            />
            <Route
              path={getPath('patientInfo')}
              element={<PatientInfoPage />}
            />
            <Route path={getPathSplat('compass')} element={<CompassRoutes />} />
            <Route path={getPath('privacy')} element={<DataProtectionPage />} />
            <Route path={getPath('terms')} element={<TermsPage />} />
            <Route path={getPath('imprint')} element={<ImprintPage />} />
            <Route
              path={getPath('accessibility')}
              element={<AccessibilityDeclarationPage />}
            />
            <Route
              path={getPathSplat('invitation')}
              element={<InvitationRoutes />}
            />
            <Route
              path={getPath('booking')}
              element={
                <RestrictedBookingRoute seekerOnly>
                  <OfferBookingPage />
                </RestrictedBookingRoute>
              }
            />
            <Route
              path={getPath('events')}
              element={
                <RestrictedBookingRoute>
                  <CalendarPage />
                </RestrictedBookingRoute>
              }
            />
            <Route path="/*" element={<ErrorPageNotFound />} />
          </Routes>
        </RouterContextProvider>
      </Onboarding>
    </Layout>
  );
};
export default App;
