import { useCallback } from 'react';
import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue,
  type ControllerRenderProps
} from 'react-hook-form';
import { useSchemaValidationFormContext } from './form-provider';

interface RenderProps<T extends FieldValues = FieldValues> {
  field: ControllerRenderProps<T, Path<T>>;
  props: {
    onChange: (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    error: boolean;
    errorMessage?: string;
  };
}

interface Props<T extends FieldValues = FieldValues> {
  name: Path<T>;
  render: (props: RenderProps<T>) => React.ReactElement;
}

/**
 * Note: The component can only be used within an {@link SchemaValidationFormProvider}.
 */
const ValidatedTextField = <T extends FieldValues = FieldValues>({
  name,
  render
}: Props<T>) => {
  const { control, setValueValidating } = useSchemaValidationFormContext<T>();

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValueValidating(
        name,
        event.currentTarget.value as PathValue<T, Path<T>>
      );
    },
    [name, setValueValidating]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const props = {
          onChange: handleOnChange,
          error: !!fieldState.error,
          errorMessage: fieldState.error?.message
        };
        return render({ field, props });
      }}
    />
  );
};

export default ValidatedTextField;
