import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { type ArticleUpdate, type ArticleCreate } from '@SLR/solution3-sdk';
import { ContentWrapper } from 'feature';
import { TextEditor, ReadOnlyTextEditor } from 'feature/editor';
import {
  ActionsPanel,
  SchemaProvider,
  ValidatedHtmlEditor,
  ValidatedTextField
} from 'feature/forms';
import SubFormWrapper from 'feature/forms/subform-wrapper';
import { ArticleSchema, TITLE, CONTENT } from './model';
import ArticleFormCategoryField from './article-form-category-field';
import ArticleKeywords from './article-keywords';
import { useStyleOverrides } from './hooks';
import { getText, getTextIn, required } from 'localization';

const getTextCompass = getTextIn('compass');

interface Props {
  categoryId: string;
  article: ArticleCreate;
  articleId?: string;
  inProgress?: boolean;
  onConfirm: (article: ArticleUpdate | ArticleCreate) => void;
  onCancel: VoidFunction;
  createMode?: boolean;
  editMode?: boolean;
}

const ArticleForm = ({
  categoryId,
  article,
  inProgress = false,
  onConfirm,
  onCancel,
  createMode = false,
  editMode = false
}: Props) => {
  const theme = useTheme();
  const { h2StyleOverrides } = useStyleOverrides();

  const readOnlyMode = !(createMode || editMode);
  const CurrentTextEditor = readOnlyMode ? ReadOnlyTextEditor : TextEditor;

  return (
    <SchemaProvider<ArticleUpdate>
      defaultValues={article}
      values={article}
      schema={ArticleSchema}
    >
      {({ handleSubmit }) => {
        return (
          <>
            <ContentWrapper additionalPadding={editMode ? 2 : 'unset'}>
              <Stack spacing={4} sx={{ position: 'relative' }}>
                {!readOnlyMode && (
                  <>
                    <ValidatedTextField
                      name={TITLE}
                      render={({ field, props }) => {
                        const { errorMessage, ...fieldProps } = props;
                        return (
                          <TextField
                            label={`${required(
                              getTextCompass('articleTitle')
                            )}`}
                            helperText={
                              errorMessage ??
                              getTextCompass('articleTitleHelperText')
                            }
                            inputProps={{
                              maxLength: 100,
                              'data-cy': 'articleform-title'
                            }}
                            {...field}
                            {...fieldProps}
                          />
                        );
                      }}
                    />

                    <ArticleFormCategoryField categoryId={categoryId} />
                  </>
                )}

                <ValidatedHtmlEditor
                  name={CONTENT}
                  render={({ field, props }) => {
                    const { error, errorMessage, content, onEditorChange } =
                      props;

                    return (
                      <FormControl>
                        {!readOnlyMode && (
                          <Typography variant="editorLabel">
                            {required(getTextCompass('articleContent'))}
                          </Typography>
                        )}

                        <Box
                          sx={{
                            '&.MuiBox-root .mce-content-body h2': {
                              ...h2StyleOverrides?.({
                                theme
                              }),
                              color: theme.palette.secondary.main
                            }
                          }}
                        >
                          <CurrentTextEditor
                            placeholderText={getTextCompass(
                              'articleContentPlaceholder'
                            )}
                            content={content}
                            onEditorChange={onEditorChange}
                            showError={error}
                            compassEdition
                            dataCy="articleform-content"
                            {...field}
                          />

                          <FormHelperText error={error}>
                            {error ? errorMessage : ' '}
                          </FormHelperText>
                        </Box>
                      </FormControl>
                    );
                  }}
                />

                {!readOnlyMode && (
                  <FormControl sx={{ pb: 2.5 }}>
                    <Typography variant="editorLabel">
                      {getTextCompass('articleKeywords')}
                    </Typography>

                    <SubFormWrapper>
                      <ArticleKeywords />
                    </SubFormWrapper>
                  </FormControl>
                )}
              </Stack>

              {!readOnlyMode && (
                <FormControl>
                  <FormHelperText>{getText('requiredFields')}</FormHelperText>
                </FormControl>
              )}
            </ContentWrapper>

            {!readOnlyMode && (
              <ActionsPanel
                confirmLabel={
                  createMode ? getTextCompass('createArticle') : getText('save')
                }
                inProgress={inProgress}
                onConfirm={handleSubmit(onConfirm)}
                onCancel={onCancel}
              />
            )}
          </>
        );
      }}
    </SchemaProvider>
  );
};

export default ArticleForm;
