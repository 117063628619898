import { Typography, Box } from '@mui/material';
import { ReactElement } from 'react';

type FooterItemProps = {
  title: string;
  children?: ReactElement | ReactElement[];
};

const FooterItem = ({ title, children }: FooterItemProps) => {
  return (
    <Box>
      <Typography
        variant="footer"
        component="h3"
        sx={{
          pb: 3
        }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default FooterItem;
