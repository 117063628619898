import { useQuery } from '@tanstack/react-query';
import type {
  ListArticlesRequest,
  GetArticleRequest,
  EntityModelArticle
} from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { useArticlesApi } from './use-compass-api';

type ListArticles = Omit<ListArticlesRequest, 'projectId'>;
type GetArticle = Omit<GetArticleRequest, 'projectId'>;

const LIST_ARTICLES = 'listArticles';
const GET_ARTICLE = 'getArticle';

const useListArticles = (params?: ListArticles) => {
  const projectId = useGetProjectId();
  const { listArticles } = useArticlesApi();

  const requestParameters: ListArticlesRequest = {
    ...params,
    projectId: projectId ?? ''
  };

  return useQuery(
    [LIST_ARTICLES, requestParameters],
    () => {
      return listArticles(requestParameters);
    },
    {
      enabled: !!projectId
    }
  );
};

const useGetArticle = ({ articleId }: GetArticle) => {
  const projectId = useGetProjectId();
  const { getArticle } = useArticlesApi();

  const requestParameters: GetArticleRequest = {
    projectId: projectId ?? '',
    articleId
  };

  return useQuery<EntityModelArticle, Response>(
    [GET_ARTICLE, requestParameters],
    () => {
      return getArticle(requestParameters);
    },
    {
      enabled: !!projectId && !!articleId
    }
  );
};

export { useListArticles, useGetArticle, LIST_ARTICLES, GET_ARTICLE };
