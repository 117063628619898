import { Routes, Route } from 'react-router-dom';
import {
  CompassArticle,
  CompassArticleCreate,
  CompassArticleList
} from 'pages/compass';
import { getPath } from 'localization';

const CompassCategoryRoutes = () => {
  return (
    <Routes>
      <Route index element={<CompassArticleList />} />
      <Route path={getPath('create')} element={<CompassArticleCreate />} />
      <Route path=":articleId" element={<CompassArticle />} />
    </Routes>
  );
};

export default CompassCategoryRoutes;
