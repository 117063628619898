import { Button, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SentimentDissatisfiedTwoToneIcon from '@mui/icons-material/SentimentDissatisfiedTwoTone';
import { getErrorText, getPath, getTextIn } from 'localization';
import FeedbackScreen from './feedback-screen';

const getInvitationText = getTextIn('invitation');

const NoOrganizationRightsScreen = () => {
  const icon = <SentimentDissatisfiedTwoToneIcon sx={{ flexGrow: 1 }} />;
  const text = <Typography>{getInvitationText('noOrgRights')}</Typography>;
  const button = (
    <Button
      variant="contained"
      component={RouterLink}
      to={'/' + getPath('contact')}
    >
      {getInvitationText('contact')}
    </Button>
  );

  return (
    <FeedbackScreen
      title={`${getErrorText('oops')} ${getErrorText('somethingWentWrong')}`}
      icon={icon}
      text={text}
      hasHomePageButton={true}
      actionButton={button}
      dataCy="norightsfororganizationscreen"
    />
  );
};

export default NoOrganizationRightsScreen;
