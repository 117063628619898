import { type GetProjectImprintTextRequest } from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import useLegalApi from './useLegalApi';
import { useGetProjectId } from 'context/project';

const useGetProjectImprintText = () => {
  const projectId = useGetProjectId();
  const { getProjectImprintText } = useLegalApi();

  const requestParameters: GetProjectImprintTextRequest = {
    projectId: projectId ?? ''
  };
  return useQuery(
    ['imprintText', { projectId }],
    () => {
      return getProjectImprintText(requestParameters);
    },
    {
      staleTime: 259200000,
      enabled: !!projectId
    }
  );
};

export default useGetProjectImprintText;
