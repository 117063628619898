import { useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  RadioSubForm,
  FormTimesDuration,
  useSchemaValidationFormContext
} from 'feature/forms';
import { getTextIn, required } from 'localization';
import { Offer, TimeType } from './model';
import FixedTimeForm from './fixed-time-form';
import TimeRangeForm from './time-range-form';
import { useBooking } from 'context/booking';

const getOfferText = getTextIn('offer-details');
const getBusinessTimeText = getTextIn('offer-details-businessTime');
const getFixedTimeText = getTextIn('offer-details-fixedTime');
const getTimeRangeText = getTextIn('offer-details-timeRange');

const TimesForm = () => {
  const { isOffersBookable } = useBooking();
  const { control, setValue } = useSchemaValidationFormContext<Offer>();

  const timeType = useWatch<Offer>({
    control,
    name: 'timeType'
  });

  return (
    <Stack spacing={1}>
      <Typography component="h2" variant="sectionTitle">
        {`${required(getOfferText('time'))}`}
      </Typography>

      <Box width={{ sm: '50%' }}>
        <FormTimesDuration
          label={`${getOfferText('duration')}${isOffersBookable ? '*' : ''}`}
          helperText={getOfferText('durationHelperText')}
          dataCy="offerform-duration-edit"
        />
      </Box>

      <FormControl>
        <FormLabel id="radio-buttons-time" sx={{ display: 'none' }}>
          {getOfferText('times')}
        </FormLabel>
        <RadioGroup
          name="radio-buttons-time"
          aria-labelledby="radio-buttons-time"
        >
          <RadioSubForm
            checked={timeType === TimeType.fixedTime}
            onChange={() => {
              setValue('timeType', TimeType.fixedTime);
            }}
            name="time"
            value="fixed"
            title={getFixedTimeText('title')}
            info={getFixedTimeText('info')}
            prefixCy="offerform-fixedtime"
          >
            <FixedTimeForm />
          </RadioSubForm>

          <RadioSubForm
            checked={timeType === TimeType.timeRange}
            onChange={() => {
              setValue('timeType', TimeType.timeRange);
            }}
            name="time"
            value="range"
            title={getTimeRangeText('title')}
            info={getTimeRangeText('info')}
            prefixCy="offerform-timerange"
          >
            <TimeRangeForm />
          </RadioSubForm>

          <RadioSubForm
            checked={timeType === TimeType.businessHours}
            onChange={() => {
              setValue('timeType', TimeType.businessHours);
            }}
            name="time"
            value="business"
            title={getBusinessTimeText('title')}
            info={getBusinessTimeText('info')}
            prefixCy="offerform-businesstime"
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default TimesForm;
