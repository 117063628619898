import { useQuery, type QueryFunction } from '@tanstack/react-query';
import type {
  PagedModelEntityModelCategory,
  PagedModelEntityModelTargetGroup
} from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import useCategoriesApi from './useCategoriesApi';
import useTargetGroupsApi from './useTargetGroupsApi';
import {
  CATEGORIES,
  TARGET_GROUPS,
  OTHER,
  type SegmentType
} from './constants';
import { getSegmentListType } from './helper';
import { getTextIn } from 'localization';

const getSegmentsText = getTextIn('settings-segments');

const useListSegments = (segmentType: SegmentType) => {
  const { listTargetGroups } = useTargetGroupsApi();
  const { listCategories } = useCategoriesApi();
  const projectId = useGetProjectId();

  return useQuery(
    [getSegmentListType(segmentType), projectId],
    () => {
      switch (segmentType) {
        case CATEGORIES: {
          return listCategories({
            projectId: projectId ?? '',
            size: 100
          }) as unknown as QueryFunction<
            PagedModelEntityModelCategory,
            (string | undefined)[]
          >;
        }
        case TARGET_GROUPS: {
          return listTargetGroups({
            projectId: projectId ?? '',
            size: 100
          }) as unknown as QueryFunction<
            PagedModelEntityModelTargetGroup,
            (string | undefined)[]
          >;
        }
      }
    },
    {
      select: (data) => {
        let segments = [];
        switch (segmentType) {
          case CATEGORIES: {
            segments =
              (data as unknown as PagedModelEntityModelCategory).embedded
                ?.categories ?? [];
            break;
          }
          case TARGET_GROUPS: {
            segments =
              (data as unknown as PagedModelEntityModelTargetGroup).embedded
                ?.targetGroups ?? [];
            break;
          }
        }
        return [
          { id: OTHER, name: getSegmentsText('other') },
          ...segments
        ].sort((a, b) => {
          const valueA = a.name.toLowerCase();
          const valueB = b.name.toLowerCase();

          return valueA === valueB ? 0 : valueA < valueB ? -1 : 1;
        });
      },
      staleTime: Infinity,
      enabled: !!projectId
    }
  );
};

export default useListSegments;
