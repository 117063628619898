import { useEffect, useCallback, useRef } from 'react';

/**
 *  Usage:
 *   import useGetIsMounted from 'utils/use-get-is-mounted';
 *
 *   const getIsMounted = useGetIsMounted();
 *
 *   const validateFields = useCallback(async () => {
 *      await MessageSchema.isValid(messageFields).then((value) => {
 *        if (getIsMounted()) {
 *          setMessageIsValid(value);
 *        }
 *      });
 *   }, [getIsMounted, messageFields]);
 */
const useGetIsMounted = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};

export default useGetIsMounted;
