import { useMutation } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useInvitationsApi from './useInvitationsApi';

const useCreateInvitationApproval = () => {
  const { createInvitationApproval } = useInvitationsApi();
  const projectId = useGetProjectId() ?? '';

  return useMutation<void, Response, string>((invitationId: string) =>
    createInvitationApproval({
      projectId,
      invitationId
    })
  );
};

export default useCreateInvitationApproval;
