import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type BusinessHoursReplace } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import useBusinessHoursApi from './useBusinessHoursApi';
import { ORGANIZATION_BUSINESS_HOURS } from './useGetBusinessHours';

const useReplaceBusinessHours = () => {
  const projectId = useGetProjectId();
  const { profileId } = useParams();
  const queryClient = useQueryClient();
  const { replaceBusinessHours } = useBusinessHoursApi();

  const organizationId = profileId ?? '';

  return useMutation(
    (businessHoursReplace: BusinessHoursReplace) => {
      return replaceBusinessHours({
        projectId: projectId ?? '',
        organizationId,
        businessHoursReplace
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ORGANIZATION_BUSINESS_HOURS,
          { projectId, organizationId }
        ]);
      }
    }
  );
};

export default useReplaceBusinessHours;
