import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { type EntityModelProject, type Image } from '@SLR/solution3-sdk';
import { useProject } from 'context/project';
import { useUpdateProject } from 'context/project/hooks';
import { getTextIn } from 'localization';
import { filterImageChange } from 'utils/image-utils';
import EditableHeroSection from './editable-hero';

const getEditText = getTextIn('edit');

const filterProjectUpdate = (
  fetchedProject: EntityModelProject,
  updatedBanner?: Image,
  updatedSlogan?: string
) => {
  return {
    banner: filterImageChange(updatedBanner, fetchedProject.banner),
    ...(fetchedProject.slogan !== updatedSlogan && {
      slogan: updatedSlogan
    })
  };
};

const HomePageHeroWrapper = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { project } = useProject();
  const [isHeroImageOpen, setHeroImageOpen] = useState(false);
  const [updatedHeroImage, setUpdatedHeroImage] = useState<Image>();
  const [showSloganField, setShowSloganField] = useState(false);
  const [updatedSlogan, setUpdatedSlogan] = useState<string>();

  const updateProject = useUpdateProject();

  const openHeroImageDialog = () => setHeroImageOpen(true);
  const closeHeroImageDialog = () => setHeroImageOpen(false);

  const handleAddImage = (image: Image) => setUpdatedHeroImage(image);
  const handleRemoveImage = () => setUpdatedHeroImage(undefined);
  const handleAltTextChange = (text: string) =>
    setUpdatedHeroImage((prevImage) => {
      if (prevImage) {
        return { ...prevImage, alternativeText: text };
      }
    });

  const handleCancelClick = () => {
    closeHeroImageDialog();
    setUpdatedHeroImage(project?.banner);
    setShowSloganField(false);
    setUpdatedSlogan(project?.slogan ?? '');
  };

  const handleSave = () => {
    if (project?.id) {
      const filteredUpdate = filterProjectUpdate(
        project,
        updatedHeroImage,
        updatedSlogan
      );
      if (filteredUpdate) {
        updateProject.mutate(
          {
            projectId: project?.id,
            // See: GP-378
            // eslint-disable-next-line
            // @ts-ignore
            projectUpdate: filteredUpdate
          },
          {
            onSuccess: () => {
              enqueueSnackbar(getEditText('editSuccess'), {
                variant: 'success'
              });
              closeHeroImageDialog();
              setShowSloganField(false);
            },
            onError: () => {
              enqueueSnackbar(getEditText('editError'), {
                variant: 'error',
                persist: true
              });
            }
          }
        );
      }
    }
  };

  useEffect(() => {
    if (project?.banner) {
      setUpdatedHeroImage(project?.banner);
    }
  }, [project?.banner]);

  useEffect(() => {
    if (project?.slogan) {
      setUpdatedSlogan(project?.slogan);
    }
  }, [project?.slogan]);

  return (
    <EditableHeroSection
      image={project?.banner}
      updatedImage={updatedHeroImage}
      handleAddImage={handleAddImage}
      handleAltTextChange={handleAltTextChange}
      handleRemoveImage={handleRemoveImage}
      handleSave={handleSave}
      slogan={updatedSlogan}
      handleSloganChange={setUpdatedSlogan}
      onCancel={handleCancelClick}
      isHeroImageDialogOpen={isHeroImageOpen}
      openHeroImageDialog={openHeroImageDialog}
      showHeroContent
      isSloganFieldVisible={showSloganField}
      setIsSloganFieldVisible={setShowSloganField}
      isUpdateLoading={updateProject.isLoading}
    />
  );
};

export default HomePageHeroWrapper;
