import {
  useCallback,
  useState,
  KeyboardEvent,
  SyntheticEvent,
  Dispatch,
  SetStateAction,
  ChangeEvent
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { type SxProps } from '@mui/material';
import { type EntityModelGeoArea } from '@SLR/solution3-sdk';
import { useListGeoAreas, useValidation } from 'feature/hooks';
import { limitValueRangeWithStep } from 'utils/helper';
import { GEO_AREA_STEP } from 'utils/url-param';
import { getText, getTextIn } from 'localization';
import { getLoadingInputProps } from 'components';

const getTextGeoArea = getTextIn('filter-geoArea');

type SetDisabledClickAway = React.Dispatch<React.SetStateAction<boolean>>;

type OnClickGeoArea = (
  location?: EntityModelGeoArea,
  distance?: number
) => void;

type InputSliderValue = number;

type InputSliderProps = {
  value: InputSliderValue;
  setValue: Dispatch<SetStateAction<InputSliderValue>>;
  disabled: boolean;
  setDisabledClickAway?: SetDisabledClickAway;
};

const InputSlider = ({
  value,
  setValue,
  disabled,
  setDisabledClickAway
}: InputSliderProps) => {
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    event.preventDefault();
    event.stopPropagation();
    setValue(Array.isArray(newValue) ? newValue[0] : newValue);
    return false;
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(
      event.target.value === ''
        ? 0
        : limitValueRangeWithStep({
            step: GEO_AREA_STEP,
            value: event.target.value
          })
    );
  };

  const handleBlur = () => {
    const limitedValue = limitValueRangeWithStep({
      step: GEO_AREA_STEP,
      value,
      always: true
    });

    if (limitedValue !== value) {
      setValue(limitedValue);
    }
  };

  return (
    <Stack
      spacing={2}
      onMouseDown={() => {
        setDisabledClickAway?.(true);
      }}
      onMouseUp={() => {
        setDisabledClickAway?.(false);
      }}
    >
      <TextField
        value={value || '0'}
        placeholder={getTextGeoArea('areaPlaceholder')}
        variant="outlined"
        label={getTextGeoArea('area')}
        InputProps={{ sx: { p: 0 } }}
        inputProps={{
          inputMode: 'numeric'
        }}
        onChange={handleInputChange}
        onBlur={handleBlur}
        disabled={disabled}
      />

      <Slider
        size="small"
        value={typeof value === 'number' ? value : 0}
        step={5}
        onChange={handleSliderChange}
        aria-labelledby="input-slider"
        disabled={disabled}
        sx={{ width: '97%', left: '2%', mt: '8px !important' }}
      />

      <Typography
        sx={{
          fontSize: 14,
          px: 0.25,
          mt: '8px !important',
          display: disabled ? 'none' : 'unset'
        }}
      >
        {getTextGeoArea('sliderInfo')}
      </Typography>
    </Stack>
  );
};

type FilterGeoAreaProps = {
  geoAreaData?: EntityModelGeoArea;
  distanceValue?: string;
  onClick: OnClickGeoArea;
  setDisabledClickAway?: SetDisabledClickAway;
  width?: string | number;
  buttonSx?: SxProps;
};

const FilterGeoArea = ({
  geoAreaData,
  distanceValue,
  onClick,
  setDisabledClickAway,
  width = 'auto',
  buttonSx = {}
}: FilterGeoAreaProps) => {
  const geoAreaName = geoAreaData?.name ?? '';

  const [searchInput, setSearchInput] = useState<string>(geoAreaName);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [distance, setDistance] = useState<number>(Number(distanceValue || 0));

  // for filtering out current selected values
  const listGeoAreas = useListGeoAreas(searchInput);

  const [selectedGeoArea, setSelectedGeoArea] = useState<
    EntityModelGeoArea | undefined
  >(geoAreaData);

  const { isNotValid, onConfirm } = useValidation({
    handleConfirm: () => onClick(selectedGeoArea, distance),
    checkForNotValid: () => !selectedGeoArea
  });

  /**
   * Set geoArea when a found geoArea is selected
   * @param event Event raised by search selection
   * @param value Selected value
   */
  const handleOnChange = useCallback(
    (
      _: SyntheticEvent<Element, Event>,
      value: EntityModelGeoArea | string | null
    ) => {
      if (value && typeof value !== 'string') {
        setSelectedGeoArea(value);
        setSearchIsOpen(false);
      }
    },
    []
  );

  const handleEnterPressed = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        event.defaultPrevented = true;
        if (selectedGeoArea) {
          onClick(selectedGeoArea, distance || undefined);
        }
      }
    },
    [distance, onClick, selectedGeoArea]
  );

  const handleBlur = () => {
    if (!searchInput) {
      setDistance(0);
    }
  };

  const isInputSameAsSelected = searchInput === selectedGeoArea?.name;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        pb: 1.75,
        width
      }}
    >
      <Stack
        spacing={2}
        sx={{
          mt: 2.5,
          mb: 1.75
        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            px: '0 !important',
            gap: 2
          }}
        >
          <Autocomplete
            id="offersGeoAreas"
            freeSolo
            inputValue={searchInput}
            onChange={handleOnChange}
            onInputChange={(e, input) => {
              setSearchInput(input ?? '');

              // remove a former selected geo area, if the input has changed (does not match the geo area name)
              if (selectedGeoArea?.name !== input) {
                setSelectedGeoArea(undefined);
              }

              setSearchIsOpen(input.length > 2);
            }}
            onBlur={() => setSearchIsOpen(false)}
            onKeyDown={handleEnterPressed}
            sx={{ flexBasis: '100%' }}
            options={listGeoAreas?.data?.embedded?.geoAreas ?? []}
            getOptionLabel={(option: string | EntityModelGeoArea) =>
              typeof option === 'string' ? '' : option.name
            }
            renderOption={(props, option: EntityModelGeoArea) => (
              <Box
                {...props}
                component="li"
                key={option.id}
                data-cy={option.name}
              >
                {option.name}
              </Box>
            )}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  autoFocus
                  variant="outlined"
                  label={getText('location', 'filter')}
                  onBlur={handleBlur}
                  error={isNotValid}
                  helperText={
                    isNotValid && getTextGeoArea('errorInputNecessary')
                  }
                  InputProps={getLoadingInputProps(
                    params,
                    listGeoAreas.isFetching
                  )}
                />
              );
            }}
            open={searchIsOpen}
            handleHomeEndKeys
            selectOnFocus
            noOptionsText={getText('noMatchingOptions')}
          />
        </Container>

        {isInputSameAsSelected && (
          <InputSlider
            value={distance}
            setValue={setDistance}
            disabled={!isInputSameAsSelected}
            setDisabledClickAway={setDisabledClickAway}
          />
        )}
      </Stack>

      <Button variant="contained" onClick={onConfirm} sx={{ ...buttonSx }}>
        {getText('apply')}
      </Button>
    </Box>
  );
};

export default FilterGeoArea;
export type { OnClickGeoArea };
