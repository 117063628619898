import Box from '@mui/material/Box';
import { Grid, Link, Stack, Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ComputerIcon from '@mui/icons-material/Computer';
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import UpdateIcon from '@mui/icons-material/Update';
import GroupsIcon from '@mui/icons-material/Groups';
import {
  GenericContentSection,
  type GenericContentSectionProps
} from 'feature';
import {
  type EntityModelOffer,
  type FixedLocation,
  type MobileLocation,
  type OnlineLocation
} from '@SLR/solution3-sdk';
import map from 'lodash/map';
import compact from 'lodash/compact';
import join from 'lodash/join';
import { getTextIn } from 'localization';
import { ParagraphSkeleton } from 'components';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { RRule, rrulestr } from 'rrule';
import { getFrequency, getFrequencyText } from 'utils/recurrence';
import {
  formatAsDate,
  formatAsTime,
  getFormattedRangeString
} from 'utils/date';
import Address from 'components/address';
import { useProject } from 'context/project';

const getOfferText = getTextIn('offer-details');
const getBusinessTimeText = getTextIn('offer-details-businessTime');
const getMobileLocationText = getTextIn('offer-details-mobileLocation');

const FixedLocationInfo = ({
  fixedLocation
}: {
  fixedLocation: FixedLocation;
}) => {
  return (
    <Stack direction="row" spacing={1} data-cy="info-fixedlocation">
      <PlaceIcon color="secondary" />
      <Address address={fixedLocation} />
    </Stack>
  );
};

const MobileLocationInfo = ({
  mobileLocation
}: {
  mobileLocation: MobileLocation;
}) => {
  const locations = join(
    compact(
      map(mobileLocation.locations, (location) => location.geoArea?.name)
    ),
    '; '
  );
  return (
    <Stack direction="row" spacing={1} data-cy="info-mobilelocation">
      <DirectionsCarIcon color="secondary" />
      <Typography sx={{ textAlign: 'left !important' }}>
        {getMobileLocationText('availableLocationsText') + locations}
      </Typography>
    </Stack>
  );
};

const OnlineLocationInfo = ({
  onlineLocation
}: {
  onlineLocation: OnlineLocation;
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      data-cy="info-onlinelocation"
      sx={{ alignItems: 'start' }}
    >
      <ComputerIcon color="secondary" />
      <Typography>{`${getOfferText('online')}:`}</Typography>

      {onlineLocation.websiteUrl ? (
        <Link href={onlineLocation.websiteUrl} target="_blank">
          {getOfferText('toOnlineLocation')}
        </Link>
      ) : (
        <Box sx={{ pt: 0.25 }}>{getOfferText('noOnlineLocation')}</Box>
      )}
    </Stack>
  );
};

const setPartsToUTCDate = (date: Date) =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes()
    )
  );

const setUTCPartsToDate = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes()
  );

const NextRecurrenceInfo = ({
  startTime,
  recurrenceRule
}: {
  startTime: Date;
  recurrenceRule: string;
}) => {
  // the recurrence calculations have to be made with the UTC date (without timezone parameter)
  // to avoid errors when the timezone passes a day threshold
  const rule = rrulestr(recurrenceRule, {
    dtstart: setPartsToUTCDate(startTime)
  });

  const nextDate = rule.after(setPartsToUTCDate(new Date()), true);

  const formatted = nextDate
    ? getOfferText('getNextEvent', {
        date: formatAsDate(setUTCPartsToDate(nextDate))
      })
    : '';

  const lastRecurrence = rule.options.until
    ? getOfferText('getLastEvent', {
        date: formatAsDate(
          setUTCPartsToDate(
            rule
              .between(
                setPartsToUTCDate(startTime),
                setPartsToUTCDate(rule.options.until),
                true
              )
              .slice(-1)[0]
          )
        )
      })
    : '';

  return (
    <>
      <Typography>{formatted}</Typography>
      <Typography>{lastRecurrence}</Typography>
    </>
  );
};

const FixedTimeInfo = ({
  startTime,
  recurrenceRule
}: {
  startTime: Date;
  recurrenceRule?: string;
}) => {
  const formatted = useMemo(() => {
    const date = formatAsDate(startTime);
    if (recurrenceRule) {
      const rrule = RRule.fromString(recurrenceRule);
      const frequency = getFrequency(rrule);
      return getFrequencyText(frequency);
    } else {
      return date;
    }
  }, [startTime, recurrenceRule]);

  const formattedTime = useMemo(() => {
    return getOfferText('getEventTime', {
      from: formatAsTime(startTime)
    });
  }, [startTime]);

  return (
    <>
      <Stack direction="row" spacing={1} data-cy="info-fixedtime">
        <EventIcon color="secondary" />
        <Stack direction="column">
          <Typography>{formatted}</Typography>
          {recurrenceRule && (
            <NextRecurrenceInfo
              startTime={startTime}
              recurrenceRule={recurrenceRule}
            />
          )}
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1} data-cy="info-timerange-time">
        <ScheduleIcon color="secondary" />
        <Typography>{formattedTime}</Typography>
      </Stack>
    </>
  );
};

const BusinessTimeInfo = () => {
  return (
    <Stack direction="row" spacing={1} data-cy="info-businesstime">
      <EventIcon color="secondary" />
      <Stack direction="column">
        <Typography sx={{ textAlign: 'left !important' }}>
          {getBusinessTimeText('title')}
        </Typography>
      </Stack>
    </Stack>
  );
};

const TimeRangeDateInfo = ({
  startTime,
  endTime,
  recurrenceRule
}: {
  startTime: Date;
  endTime: Date;
  recurrenceRule?: string;
}) => {
  const formattedDate = useMemo(() => {
    const date = getFormattedRangeString(
      formatAsDate(startTime),
      formatAsDate(endTime)
    );

    if (recurrenceRule) {
      const rrule = RRule.fromString(recurrenceRule);
      const frequency = getFrequency(rrule);
      return getFrequencyText(frequency);
    } else {
      return date;
    }
  }, [startTime, endTime, recurrenceRule]);

  return (
    <Stack direction="row" spacing={1} data-cy="info-timerange-date">
      <EventIcon color="secondary" />
      <Stack
        direction="column"
        sx={{ '.MuiTypography-root': { textAlign: 'unset !important' } }}
      >
        <Typography>{formattedDate}</Typography>
        {recurrenceRule && (
          <NextRecurrenceInfo
            startTime={startTime}
            recurrenceRule={recurrenceRule}
          />
        )}
      </Stack>
    </Stack>
  );
};

const TimeRangeTimeInfo = ({
  startTime,
  endTime
}: {
  startTime: Date;
  endTime: Date;
}) => {
  const formattedTime = useMemo(() => {
    return getOfferText('getEventTimeRange', {
      from: formatAsTime(startTime),
      to: formatAsTime(endTime)
    });
  }, [startTime, endTime]);

  return (
    <Stack direction="row" spacing={1} data-cy="info-timerange-time">
      <ScheduleIcon color="secondary" />
      <Typography>{formattedTime}</Typography>
    </Stack>
  );
};

const DurationInfo = ({ duration }: { duration: string }) => {
  const formatted = useMemo(() => {
    return getOfferText('getEventDuration', {
      duration: dayjs.duration(duration).format('H:mm')
    });
  }, [duration]);

  return (
    <Stack direction="row" spacing={1} data-cy="info-duration">
      <UpdateIcon color="secondary" />
      <Typography>{formatted}</Typography>
    </Stack>
  );
};

const TargetGroupInfo = ({ targetGroup }: { targetGroup: string }) => {
  return (
    <Stack direction="row" spacing={1} data-cy="info-targetgroup">
      <GroupsIcon color="secondary" />
      <Typography>{targetGroup}</Typography>
    </Stack>
  );
};

type Props = Omit<
  GenericContentSectionProps,
  'content' | 'placeholder' | 'empty'
> & {
  offer?: EntityModelOffer;
};

const AdditionalInformationSection = ({ offer, ...sectionProps }: Props) => {
  const { project } = useProject();
  const hasLocation =
    offer?.mobileLocation || offer?.fixedLocation || offer?.onlineLocation;
  const hasTime = offer?.startTime;
  const isEmpty = !hasLocation && !hasTime;

  return (
    <GenericContentSection
      content={
        <Grid container gap={4} width="auto">
          <Grid item xs={12} sm>
            <Stack direction="column" spacing={2}>
              {offer?.fixedLocation && (
                <FixedLocationInfo fixedLocation={offer.fixedLocation} />
              )}
              {offer?.mobileLocation && (
                <MobileLocationInfo mobileLocation={offer.mobileLocation} />
              )}
              {offer?.onlineLocation && (
                <OnlineLocationInfo onlineLocation={offer.onlineLocation} />
              )}
              {offer?.targetGroup && project?.features?.targetGroups && (
                <TargetGroupInfo targetGroup={offer.targetGroup.name} />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm>
            <Stack direction="column" spacing={2}>
              {offer?.startTime && !offer?.endTime && (
                <FixedTimeInfo
                  startTime={offer.startTime}
                  recurrenceRule={offer.recurrenceRule}
                />
              )}
              {offer?.startTime && offer?.endTime && (
                <>
                  <TimeRangeDateInfo
                    startTime={offer.startTime}
                    endTime={offer.endTime}
                    recurrenceRule={offer.recurrenceRule}
                  />
                  <TimeRangeTimeInfo
                    startTime={offer.startTime}
                    endTime={offer.endTime}
                  />
                </>
              )}
              {!offer?.startTime && !offer?.endTime && <BusinessTimeInfo />}
              {offer?.duration && <DurationInfo duration={offer.duration} />}
            </Stack>
          </Grid>
        </Grid>
      }
      placeholder={<ParagraphSkeleton lines={3} pulse />}
      empty={isEmpty}
      {...sectionProps}
    />
  );
};

export default AdditionalInformationSection;
