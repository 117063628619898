import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, hash]);

  return null;
};

type ScrollToIdProps = {
  element: React.MutableRefObject<HTMLHeadingElement | null>;
};

const ScrollToId = ({ element: refElement }: ScrollToIdProps) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (refElement.current?.id === hash.replace('#', '')) {
      const headerHeight =
        document.getElementsByTagName('header')[0]?.offsetHeight ?? 148;

      window.scrollTo({
        top: refElement.current?.offsetTop - headerHeight,
        behavior: 'smooth'
      });
    }
  }, [hash, refElement]);

  return null;
};

export { ScrollToTop, ScrollToId };
