import { useGetProjectId } from 'context/project';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useOrganizationsApi from 'context/user/hooks/useOrganizationsApi';

const useDeleteOrganization = () => {
  const queryClient = useQueryClient();
  const projectId = useGetProjectId() ?? '';
  const { deleteOrganization } = useOrganizationsApi();

  return useMutation<void, Error, string, () => void>(
    (organizationId: string) =>
      deleteOrganization({
        projectId,
        organizationId
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'participantsInfo',
          {
            projectId
          }
        ]);
        queryClient.invalidateQueries([
          'currentUsersOrganizations',
          {
            projectId
          }
        ]);
      }
    }
  );
};

export default useDeleteOrganization;
