import { Visibility } from '@SLR/solution3-sdk';
import { useKeycloakAuth } from 'auth';
import { PropsWithChildren } from 'react';

export const useIsVisible = (visibility?: Visibility) => {
  const { isAuthenticated } = useKeycloakAuth();
  return visibility === Visibility.Everyone || isAuthenticated;
};

interface Props {
  visibility?: Visibility;
  placeholder?: React.ReactNode;
}

const RestrictedVisibility = ({
  children,
  visibility,
  placeholder = <></>
}: PropsWithChildren<Props>) => {
  const isVisible = useIsVisible(visibility);
  return isVisible ? <>{children}</> : <>{placeholder}</>;
};

export default RestrictedVisibility;
