import { Typography } from '@mui/material';
import { ContentWrapper } from 'feature';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { getTextIn } from 'localization';
import ContactForm from './contact-form';
import { useCallback } from 'react';
import { useUser } from 'context/user';
import { useCreateAdminMessage } from 'feature/hooks';
import { type AnonymousMessage } from '@SLR/solution3-sdk';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import useProjectNavigate from 'utils/useProjectNavigate';
import { useSetDocumentTitle } from 'context/project';

const getContactText = getTextIn('contact');

const ContactPage = ({
  subject,
  content,
  title = getContactText('contact')
}: {
  subject?: string;
  content?: string;
  title?: string;
}) => {
  const { navigateToHome } = useProjectNavigate();

  const { currentUser } = useUser();
  const createAdminMessage = useCreateAdminMessage();

  const handleConfirm = useCallback(
    (anonymousMessage: AnonymousMessage) => {
      createAdminMessage.mutate(anonymousMessage, {
        onSuccess: () => {
          notifyMutationSuccess(getContactText('successMessage'));
          navigateToHome();
        },
        onError: () => {
          notifyMutationError(getContactText('errorMessage'));
        }
      });
    },
    [createAdminMessage, navigateToHome]
  );

  useSetDocumentTitle(title);

  return (
    <ContentWrapper breadcrumbOnly>
      <BreadcrumbNavigation />
      <Typography variant="h1" align="center">
        {title}
      </Typography>
      <ContactForm
        currentUser={currentUser}
        isLoading={createAdminMessage.isLoading}
        subject={subject}
        content={content}
        onConfirm={handleConfirm}
      />
    </ContentWrapper>
  );
};

export default ContactPage;
