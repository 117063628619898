import { getPath } from 'localization';

// get the provider profile url for the passed providerId
const getProviderProfileUrl = (providerId?: string) =>
  providerId
    ? getPath('getProviderProfile', {
        providerId
      })
    : '';

const getProviderProfileEditUrl = (providerId?: string) =>
  providerId
    ? getPath('getProviderProfileEdit', {
        providerId
      })
    : '';

// get the seeker profile url for the passed seekerId
const getSeekerProfileUrl = (seekerId?: string) =>
  seekerId
    ? getPath('getSeekerProfile', {
        seekerId
      })
    : '';

const getSeekerProfileEditUrl = (seekerId?: string) =>
  seekerId
    ? getPath('getSeekerProfileEdit', {
        seekerId
      })
    : '';

export {
  getProviderProfileUrl,
  getProviderProfileEditUrl,
  getSeekerProfileUrl,
  getSeekerProfileEditUrl
};
