import { FormControlLabel, Switch } from '@mui/material';
import { useIsAdmin, useUser } from 'context/user';
import { getTextIn } from 'localization';

const getEditText = getTextIn('edit');

const EditToggle = () => {
  const { isEditModeOn, toggleEditMode } = useUser();
  const isAdmin = useIsAdmin();

  return isAdmin ? (
    <FormControlLabel
      control={
        <Switch
          checked={isEditModeOn}
          onChange={toggleEditMode}
          inputProps={{
            'aria-label': getEditText('editToggleAriaLabel')
          }}
          data-cy="toggle-edit-mode"
        />
      }
      label={getEditText('editToggleLabel')}
    />
  ) : null;
};

export default EditToggle;
