import { type InvitationAccept } from '@SLR/solution3-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useInvitationsApi from './useInvitationsApi';

type AcceptType = {
  invitationId: string;
  organizationId?: string;
};

const useCreateInvitationAcceptance = () => {
  const queryClient = useQueryClient();
  const { createInvitationAcceptance } = useInvitationsApi();
  const projectId = useGetProjectId() ?? '';

  return useMutation<InvitationAccept, Response, AcceptType>(
    ({ invitationId, organizationId = undefined }: AcceptType) =>
      createInvitationAcceptance({
        projectId,
        invitationId,
        organizationId
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          'currentUsersOrganizations',
          { projectId }
        ]);
      }
    }
  );
};

export default useCreateInvitationAcceptance;
