import { CSSProperties } from 'react';
import { getText } from 'localization';
import PlaceholderImage from 'image-placeholder.svg';

const ImagePlaceholder = ({ style }: { style?: CSSProperties }) => {
  return (
    <img
      style={style}
      src={PlaceholderImage}
      alt={getText('placeholder', 'image')}
    />
  );
};

export default ImagePlaceholder;
