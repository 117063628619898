import {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  ChangeEvent
} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import type { EntityModelArticleCategory, Image } from '@SLR/solution3-sdk';
import { DialogGeneric, ImageUpdateLogic } from 'feature';
import PlaceholderImage from '../../image-placeholder.svg';
import { useValidation } from 'feature/hooks';
import {
  useArticleCategories,
  useCreateArticleCategory,
  useUpdateArticleCategory
} from 'feature/hooks/compass';
import { notifyMutationSuccess } from 'feature/error';
import { findCategory } from './utils';
import { getText, getTextIn } from 'localization';

const getEditText = getTextIn('edit');
const getEditModalText = getTextIn('edit-editHeroImage');
const getTextCompass = getTextIn('compass');

type CompassCategoryDialogProps = {
  isOpen: boolean;
  categoryId?: string;
  data?: EntityModelArticleCategory;
  handleClose: VoidFunction;
  title?: string;
  setTitle?: Dispatch<SetStateAction<string>>;
  confirmText?: string;
};

const CompassCategoryDialog = ({
  isOpen,
  categoryId,
  data,
  title = getTextCompass('createCompassCategory'),
  setTitle,
  confirmText = getTextCompass('create'),
  handleClose
}: CompassCategoryDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { articleCategories = [] } = useArticleCategories();
  const createArticleCategory = useCreateArticleCategory();
  const updateArticleCategory = useUpdateArticleCategory();
  const [updatedHeroImage, setUpdatedHeroImage] = useState<Image | null>(null);
  const [categoryName, setCategoryName] = useState(data?.name ?? '');
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [deleteFired, setDeleteFired] = useState(false);

  const dataPicture = data?.picture;
  const isEditMode = !!categoryId;

  const handleAddImage = (imageToAdd: Image) => setUpdatedHeroImage(imageToAdd);

  const resetFields = () => {
    setUpdatedHeroImage(null);
    setCategoryName('');
  };

  const handleCancel = () => {
    handleClose();
    resetFields();
  };

  useEffect(() => {
    if (dataPicture) {
      setUpdatedHeroImage(dataPicture);
    }
  }, [dataPicture]);

  const handleConfirm = () => {
    const picture = updatedHeroImage
      ? {
          imageId: updatedHeroImage.id,
          alternativeText: updatedHeroImage.alternativeText ?? ''
        }
      : categoryId
      ? // necessary for deleting an image on update - requires the ts-ignores in the mutates
        null
      : undefined;

    if (categoryName) {
      if (categoryId) {
        updateArticleCategory.mutate(
          {
            categoryId,
            articleCategoryUpdate: {
              name: categoryName,
              // eslint-disable-next-line
              // @ts-ignore
              picture
            }
          },
          {
            onSuccess: () => {
              notifyMutationSuccess();
              setTitle?.(categoryName);
              handleCancel();
            },
            onError: () => {
              enqueueSnackbar(getEditText('editError'), {
                variant: 'error',
                persist: true
              });
            }
          }
        );
      } else {
        createArticleCategory.mutate(
          {
            name: categoryName,
            // eslint-disable-next-line
            // @ts-ignore
            picture
          },
          {
            onSuccess: () => {
              notifyMutationSuccess(getTextCompass('createCategorySuccess'));
              handleCancel();
            },
            onError: () => {
              enqueueSnackbar(getTextCompass('createCategoryError'), {
                variant: 'error',
                persist: true
              });
            }
          }
        );
      }
    }
  };

  const handleAltTextChange = (text: string) => {
    setUpdatedHeroImage((prevImage) => {
      const img = prevImage ?? (dataPicture as Image);
      return img ? { ...img, alternativeText: text } : null;
    });
  };

  const handleNameChange = (newName: string) => setCategoryName(newName);

  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    handleNameChange?.(event.target.value);
  };

  const currentImage = updatedHeroImage ?? dataPicture;

  const TITLE = 'title';
  const ALTERNATIVE_TEXT = 'alternativeText';

  const checkForNotValidHash = () => {
    const notValidHash: { [key: string]: boolean } = {};

    notValidHash[TITLE] =
      !categoryName ||
      (!!findCategory(articleCategories, categoryName, 'name') &&
        (!isEditMode ||
          findCategory(articleCategories, categoryName, 'name')?.id !==
            categoryId));

    // eslint-disable-next-line no-negated-condition
    notValidHash[ALTERNATIVE_TEXT] = !!updatedHeroImage
      ? !!updatedHeroImage.id && !updatedHeroImage.alternativeText?.trim()
      : false;

    return notValidHash;
  };

  const { isNotValidFor, onChange, onConfirm, onCancel } = useValidation({
    handleChange: handleAltTextChange,
    handleConfirm,
    handleCancel: () => {
      setDeleteFired(false);
      handleCancel();
    },
    checkForNotValidHash
  });

  return (
    <DialogGeneric
      open={isOpen}
      confirmText={confirmText}
      onClose={onCancel}
      onConfirm={onConfirm}
      fullWidth
      isLoading={
        (isEditMode ? updateArticleCategory : createArticleCategory).isLoading
      }
      title={title}
      prefix="compass-category-dialog"
      disableEscapeKeyDown
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems="start"
        spacing={2}
        sx={{ pt: 1, pb: 4 }}
      >
        <TextField
          fullWidth
          required
          label={getTextCompass('title')}
          value={categoryName ?? data?.name}
          onChange={onChangeName}
          inputProps={{
            maxLength: 40
          }}
          data-cy="compass-category-title"
          error={isNotValidFor(TITLE)}
          helperText={
            (isNotValidFor(TITLE) &&
              (categoryName
                ? getTextCompass('titleExistsHelperError')
                : getTextCompass('titleHelperError'))) ||
            getTextCompass('titleHelperText')
          }
        />
      </Stack>

      <Box
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          bgcolor: 'primary.background',
          backgroundImage: `url(${
            updatedHeroImage?.urls?.medium ?? PlaceholderImage
          })`,
          backgroundSize: 'cover',
          display: 'flex',
          minHeight: {
            xs: '20vh',
            md: '24vh'
          },
          backgroundPosition: 'center',
          p: 2,
          gap: 2
        }}
      >
        <Button
          variant="outlined"
          size="small"
          color="error"
          disabled={isLoadingImage || !Boolean(currentImage)}
          onClick={() => {
            setDeleteFired(true);
            setUpdatedHeroImage(null);
          }}
          startIcon={<DeleteIcon />}
          data-cy="delete-image"
        >
          {getText('delete')}
        </Button>

        <ImageUpdateLogic
          handleAddImage={handleAddImage}
          setIsLoadingImage={setIsLoadingImage}
          inputDataCy="upload-compass-category-image-input"
          label={getTextCompass('uploadImage')}
          dataCy="upload-compass-category-image-button"
          additionalOnClick={() => setDeleteFired(false)}
        />
      </Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems="start"
        spacing={2}
        sx={{ pt: 4 }}
      >
        <TextField
          fullWidth
          required
          label={getEditModalText('textField')}
          disabled={isLoadingImage || !Boolean(currentImage)}
          value={(!deleteFired && currentImage?.alternativeText) || ''}
          onChange={onChange}
          inputProps={{
            maxLength: 255
          }}
          data-cy="compass-category-alternativeText"
          error={isNotValidFor(ALTERNATIVE_TEXT)}
          helperText={
            isNotValidFor(ALTERNATIVE_TEXT) && getEditModalText('helperError')
          }
        />
      </Stack>
    </DialogGeneric>
  );
};

export default CompassCategoryDialog;
