import { ListItemIcon, MenuItem, Stack } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { ItemText } from 'feature';
import { useKeycloakAuth } from 'auth';
import { getText } from 'localization';

const Logout = () => {
  const { logout } = useKeycloakAuth();

  return (
    <MenuItem
      key="logout"
      sx={{
        height: { xs: 50, sm: 60 },
        p: 0
      }}
      onClick={logout}
      data-cy="logout-button"
    >
      <Stack
        direction="row"
        sx={{
          px: 2.5,
          py: 2
        }}
      >
        <ListItemIcon sx={{ color: 'primary.main' }}>
          <LogoutIcon />
        </ListItemIcon>
        <ItemText text={getText('logout')} />
      </Stack>
    </MenuItem>
  );
};
export default Logout;
