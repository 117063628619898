import { getTextPreProject } from 'localization';
import { ReactNode } from 'react';
import config, { LOCALE_DEFAULT } from './config';
import type { Config } from './types';

const globalConfigKey = 'globalConfig' as keyof typeof globalThis;

type GetTextFxParams = { [key: string]: unknown };
type GetTextFx = (params: GetTextFxParams) => string;
type GetNode = (params: GetTextFxParams) => ReactNode;

function getTextFrom<T = ''>(localization: object, defaultValue?: T) {
  return (key: string): T => {
    return localization[key as keyof typeof localization] ?? defaultValue ?? '';
  };
}

/**
 *
 * @param localizationConfig The localized texts from the config
 * @param key The key name in a localization object
 * @param path The path to the localization object:
 *              - no path: the texts object
 *              - path: a string with the object key to walk inside, e.g. 'role'
 *                      for a higher level structure: separate the object keys by a '-', e.g. 'xxx-yyy'
 * @returns The localized text
 */
const getTextGeneric = (
  localizationConfig: Config,
  key: string,
  path?: string
): string | GetTextFx => {
  const pathParts = path ? path.split('-') : [];

  const leavesObj = pathParts.reduce((acc, part) => {
    return getTextFrom(acc, {})(part);
  }, localizationConfig);

  const text = getTextFrom(leavesObj)(key);
  if (text == null) {
    new Error(`No translation for key ${key}`);
  }
  return text;
};

const getErrorText = (key: string) => {
  // eslint-disable-next-line import/no-named-as-default-member
  return getTextPreProject(config[LOCALE_DEFAULT] as Config, key, 'error');
};

// for the moment only a check for: „s“, „ss“, „ß“, „tz“, „z“, „x“, „ce“
const generateGenitive = (name = '') => {
  const addGenitiveS = !/^.*([sßzx]|ce)$/.test(name);
  return addGenitiveS ? `${name}s` : `${name}'`;
};

export { getErrorText, getTextGeneric, generateGenitive, globalConfigKey };

export type { GetTextFx, GetNode, GetTextFxParams };
