import { useCallback, useMemo } from 'react';
import {
  type GetCurrentUserRequest,
  type ListCurrentUserOrganizationsRequest,
  type RequestCurrentUserDataRequest,
  UsersApi,
  type DeleteUserRequest
} from '@SLR/solution3-sdk';
import { APINames, getApiInstance } from 'utils/api-utils';

const useUsersApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.users) as UsersApi;
  }, []);

  const getCurrentUser = useCallback(
    async (requestParameters: GetCurrentUserRequest) => {
      try {
        return await apiInstance.getCurrentUser(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getCurrentUserOrganizations = useCallback(
    async (payload: ListCurrentUserOrganizationsRequest) => {
      try {
        return await apiInstance.listCurrentUserOrganizations(payload);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const requestUserData = useCallback(
    async (requestParameters: RequestCurrentUserDataRequest) => {
      try {
        return await apiInstance.requestCurrentUserData(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const deleteUser = useCallback(
    async (requestParameters: DeleteUserRequest) => {
      try {
        return await apiInstance.deleteUser(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getCurrentUser,
    getCurrentUserOrganizations,
    requestUserData,
    deleteUser
  };
};

export default useUsersApi;
