import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { CustomCard, CardDescription } from 'feature/cards';
import { Loadable } from 'types';

type ArticleCardProps = {
  title: string;
  content: string;
  url: string;
};

const ArticleCard = ({
  title,
  content,
  loading,
  url
}: Loadable<ArticleCardProps>) => {
  return (
    <CustomCard href={loading ? '' : url} minWidthPercent={31}>
      <CardHeader
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pb: 0,
          '& .MuiCardHeader-content': {
            width: '100%'
          }
        }}
        title={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {loading ? (
              <Skeleton animation="wave" />
            ) : (
              <Typography
                variant="customCardTitle"
                sx={{
                  fontSize: 24,
                  mb: 0,
                  minHeight: 'unset'
                }}
              >
                {title}
              </Typography>
            )}
          </Box>
        }
      />

      <CardContent key="content" sx={{ pt: 1, px: 2 }}>
        <CardDescription html={content} loading={loading} />
      </CardContent>
    </CustomCard>
  );
};

export default ArticleCard;
