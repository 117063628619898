import type {
  OrganizationProfileUpdate,
  EntityModelOrganizationProfile
} from '@SLR/solution3-sdk';
import useOrganizationProfilesApi from './useOrganizationProfilesApi';
import { useGetProjectId } from 'context/project';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UpdateOrganizationProfileType = {
  organizationId: string;
  organizationProfileUpdate: OrganizationProfileUpdate;
};

const useUpdateOrganizationProfile = () => {
  const projectId = useGetProjectId();
  const queryClient = useQueryClient();
  const { updateOrganizationProfile } = useOrganizationProfilesApi();
  return useMutation<
    EntityModelOrganizationProfile,
    Error,
    UpdateOrganizationProfileType
  >(
    ({
      organizationId,
      organizationProfileUpdate
    }: UpdateOrganizationProfileType) => {
      return updateOrganizationProfile({
        projectId: projectId ?? '',
        organizationId,
        organizationProfileUpdate
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          'organization profile',
          { projectId, organizationId: data.organizationId }
        ]);
        queryClient.invalidateQueries(['participantsInfo', { projectId }]);
      }
    }
  );
};

export default useUpdateOrganizationProfile;
