import { Box, useTheme } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { PLUGINS } from './tinymce';
import { customStyle } from './text-editor';

export type TextEditorProps = {
  content: string;
  dataCy?: string;
};

const ReadOnlyTextEditor = ({ content, dataCy }: TextEditorProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        textAlign: { xs: 'left', sm: 'justify' },
        hyphens: 'auto',
        fontSize: {
          xs: 16,
          md: 18
        }
      }}
      data-cy={dataCy}
    >
      <Editor
        plugins={PLUGINS}
        toolbar={undefined}
        init={{
          skin: false,
          inline: true,
          menubar: false,
          statusbar: false,
          content_style: customStyle(theme)
        }}
        initialValue={content}
        disabled
      />
    </Box>
  );
};

export default ReadOnlyTextEditor;
