import { useSchemaValidationFormContext } from 'feature/forms';
import { Controller, useFieldArray } from 'react-hook-form';
import { Offer } from './model';
import { useMemo } from 'react';
import { Chip, FormLabel, Stack } from '@mui/material';
import { GeoAreaSelector } from 'components';
import map from 'lodash/map';
import compact from 'lodash/compact';
import { getText, getTextIn, required } from 'localization';

const getMobileLocationText = getTextIn('offer-details-mobileLocation');

const MobileLocationForm = () => {
  const { control } = useSchemaValidationFormContext<Offer>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'mobileLocation.locations'
  });

  const geoAreaChips = useMemo(() => {
    return map(fields, (item, index) => {
      return (
        <Controller
          key={item.id}
          control={control}
          name={`mobileLocation.locations.${index}`}
          render={({ field }) => {
            const label = field.value.geoArea?.name ?? field.value.geoAreaId;
            return (
              <Chip
                key={index}
                label={label}
                color="primary"
                variant="filled"
                onDelete={() => {
                  remove(index);
                }}
                data-cy={`geoArea-chip-${label}`}
              />
            );
          }}
        />
      );
    });
  }, [control, fields, remove]);

  return (
    <Stack spacing={2}>
      <FormLabel>{getMobileLocationText('hint')}</FormLabel>
      <Controller
        control={control}
        name="mobileLocation.locations"
        render={({ field, fieldState }) => {
          const selectedGeoAreas = compact(map(field.value, 'geoArea'));
          const error = !!fieldState.error;
          const errorMessage = fieldState?.error?.message;

          return (
            <GeoAreaSelector
              label={`${required(getMobileLocationText('availableLocations'))}`}
              selectCaption={getText('add')}
              error={error}
              errorMessage={errorMessage}
              excludedGeoAreas={selectedGeoAreas}
              onGeoAreaSelected={(geoArea) => {
                append({ geoAreaId: geoArea.id, geoArea });
              }}
            />
          );
        }}
      />

      <Stack direction="row" flexWrap="wrap" useFlexGap spacing={1}>
        {geoAreaChips}
      </Stack>
    </Stack>
  );
};

export default MobileLocationForm;
