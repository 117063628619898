import { Box, List, ListItem, Typography } from '@mui/material';
import FooterItem from '../footer-item';
import { getTextIn } from 'localization';
import { useProject } from 'context/project';

const getFooterText = getTextIn('footer');

const Contacts = () => {
  const { project } = useProject();
  const {
    operatorZipCode,
    operatorCity,
    operatorStreet,
    operatorHouseNumber,
    operatorLogo
  } = project ?? {};

  return (
    <FooterItem title={getFooterText('contactTitle')}>
      <List dense disablePadding>
        {project?.phone && (
          <ListItem key="phone" disableGutters>
            <Typography component="address" variant="footer">
              {getFooterText('phone')}:{' '}
              <a href={`tel:${project.phone}`}>{project.phone}</a>
            </Typography>
          </ListItem>
        )}
        {operatorZipCode &&
          operatorCity &&
          operatorStreet &&
          operatorHouseNumber && (
            <ListItem key="address" disableGutters>
              <Typography component="address" variant="footer">
                {getFooterText('address')}:{` ${operatorStreet}`}&nbsp;
                {`${operatorHouseNumber}`},{` ${operatorZipCode}`}&nbsp;
                {`${operatorCity}`}
              </Typography>
            </ListItem>
          )}
        {project?.email && (
          <ListItem key="email" disableGutters>
            <Typography component="address" variant="footer">
              {getFooterText('email')}:{' '}
              <a href={`mailto:${project.email}`}>{project.email}</a>
            </Typography>
          </ListItem>
        )}
      </List>
      {operatorLogo ? (
        <Box
          key="logo"
          sx={{
            backgroundColor: 'white',
            mt: 2,
            p: 1,
            width: 'fit-content'
          }}
        >
          <img
            width="auto"
            height={60}
            src={operatorLogo.urls?.original}
            alt={operatorLogo.alternativeText}
          />
        </Box>
      ) : (
        <></>
      )}
    </FooterItem>
  );
};

export default Contacts;
