import { Fragment } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ShowProtectedLink, useRouteLinks } from 'routes';

const SideMenu = ({ routeLinksName }: { routeLinksName: string }) => {
  const location = useLocation();
  const subRoute = location.pathname.split('/')[2];
  const navigationLinks = useRouteLinks(routeLinksName);

  const list = () => (
    <Box role="presentation">
      <List sx={{ p: 0 }}>
        {navigationLinks.map(
          ({
            path,
            title,
            protect
          }: {
            path: string;
            title: string;
            protect: string;
          }) => {
            const isSelected: boolean = subRoute === path;
            const color = isSelected ? 'secondary.main' : 'primary.main';
            const showProtectedLink = ShowProtectedLink({
              protect
            });

            return (
              showProtectedLink && (
                <Fragment key={`${title}-${path}-sidebar`}>
                  <ListItem
                    disablePadding
                    sx={{
                      px: 2,
                      '&:hover': {
                        bgcolor: 'hover.main'
                      }
                    }}
                  >
                    <Link
                      data-cy={`settings-links-${path}`}
                      component={RouterLink}
                      to={`../${path}`}
                      sx={{
                        textDecoration: 'none',
                        width: '100%',
                        height: '100%',
                        pr: { md: 3, lg: 9 }
                      }}
                    >
                      <ListItemText
                        primary={title}
                        primaryTypographyProps={{
                          sx: {
                            color,
                            py: 0.5,
                            width: 'fit-content',
                            borderBottom: isSelected ? '6px solid' : 'unset',
                            borderBottomColor: isSelected
                              ? 'secondary.main'
                              : 'unset'
                          }
                        }}
                      />
                    </Link>
                  </ListItem>
                </Fragment>
              )
            );
          }
        )}
      </List>
    </Box>
  );

  return list();
};

export default SideMenu;
