import { getTextIn } from 'localization';
import { Typography } from '@mui/material';
import { Loadable } from 'types';
import { HtmlContentSection, TextContentSection } from 'feature';
import { type EntityModelOffer } from '@SLR/solution3-sdk';
import AdditionalInformationSection from './additional-information-section';

const getOfferText = getTextIn('offer-details');

interface Props {
  offer?: EntityModelOffer;
}

const OfferView = ({ offer, loading = false }: Loadable<Props>) => {
  return (
    <>
      <HtmlContentSection
        title={
          <Typography component="h2" variant="sectionTitle" color="secondary">
            {getOfferText('description')}
          </Typography>
        }
        content={offer?.description}
        loading={loading}
        dataCyPostfix="description"
      />

      <TextContentSection
        title={
          <Typography
            component="h2"
            variant="sectionTitle"
            color="secondary"
            sx={{ mt: { xs: 2, sm: 4, md: 6 } }}
          >
            {getOfferText('requirements')}
          </Typography>
        }
        content={offer?.requirements}
        hideEmpty
        loading={loading}
        dataCyPostfix="requirements"
      />

      <AdditionalInformationSection
        title={
          <Typography
            component="h2"
            variant="sectionTitle"
            color="secondary"
            sx={{ mt: { xs: 2, sm: 4, md: 6 } }}
          >
            {getOfferText('additionalInformation')}
          </Typography>
        }
        offer={offer}
        hideEmpty
        loading={loading}
      />
    </>
  );
};

export default OfferView;
