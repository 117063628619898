import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentWrapper } from 'feature';
import {
  EditableOfferForm,
  type Offer,
  EMPTY_OFFER,
  toCreateRepresentation
} from 'feature/offers';
import { notifyMutationError, notifyMutationSuccess } from 'feature/error';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { Typography } from '@mui/material';
import { useCreateOffer } from 'feature/hooks';
import { useUser } from 'context/user';
import { useSetDocumentTitle } from 'context/project';
import { useOnboardingLogic, OFFER } from 'feature/onboarding';
import { getTextIn, getPath } from 'localization';
import { Restricted } from 'routes';
import { useBooking } from 'context/booking';

const getOfferText = getTextIn('offer');

const OfferCreatePage = () => {
  useSetDocumentTitle(getOfferText('createOffer'));
  const navigate = useNavigate();
  const createOffer = useCreateOffer();
  const { mayCreateOffers } = useUser();
  const { addFlag } = useOnboardingLogic();
  const { isOffersBookable } = useBooking();

  const handleConfirm = useCallback(
    (offer: Offer) => {
      createOffer.mutate(toCreateRepresentation(offer, isOffersBookable), {
        onSuccess: (data) => {
          notifyMutationSuccess();
          addFlag(OFFER);

          navigate(
            getPath('getOffer', {
              category: data?.category?.name,
              offerId: data.id
            })
          );
        },
        onError: () => {
          notifyMutationError();
        }
      });
    },
    [addFlag, createOffer, navigate, isOffersBookable]
  );

  return (
    <Restricted isVisible={mayCreateOffers} forceLogin>
      <ContentWrapper breadcrumbOnly>
        <BreadcrumbNavigation />

        <Typography variant="h1" align="center">
          {getOfferText('createOffer')}
        </Typography>
      </ContentWrapper>

      <EditableOfferForm
        defaultOffer={EMPTY_OFFER}
        inProgress={createOffer.isLoading}
        onConfirm={handleConfirm}
        onCancel={() => navigate(`/${getPath('offers')}`)}
      />
    </Restricted>
  );
};

export default OfferCreatePage;
