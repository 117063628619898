import { useNavigate } from 'react-router-dom';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import { useDeleteArticle } from 'feature/hooks/compass';
import { getTextIn, getPath } from 'localization';

const getTextCompass = getTextIn('compass');

const useDeleteArticleMutate = ({
  id,
  handleClose,
  categoryId
}: {
  id: string;
  handleClose: VoidFunction;
  categoryId: string;
}) => {
  const navigate = useNavigate();
  const deleteArticle = useDeleteArticle();

  const onDeleteConfirm = () => {
    if (id) {
      deleteArticle.mutate(id, {
        onSuccess: () => {
          notifyMutationSuccess(getTextCompass('deleteArticleSuccess'));
          navigate(`/${getPath('compass')}/${categoryId}`, {
            replace: true
          });
        },
        onError: () => {
          notifyMutationError(getTextCompass('deleteArticleError'));
        }
      });
    }

    handleClose();
  };

  return { onDeleteConfirm };
};

export default useDeleteArticleMutate;
