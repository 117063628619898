import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';

const INIT = 'start';
const PROFILE = 'profile';
const KEY = 'key';
const OFFER = 'offer';

const icons = {
  [PROFILE]: PersonOutlineOutlinedIcon,
  [KEY]: VerifiedUserOutlinedIcon,
  [OFFER]: MedicalInformationOutlinedIcon
};

type StepType = typeof PROFILE | typeof KEY | typeof OFFER | typeof INIT;

type SetType = React.Dispatch<React.SetStateAction<string>>;

export { INIT, PROFILE, KEY, OFFER, icons };
export type { StepType, SetType };
