import { Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import EmojiEmotionsTwoToneIcon from '@mui/icons-material/EmojiEmotionsTwoTone';
import { getNode, getTextIn } from 'localization';
import FeedbackScreen from './feedback-screen';
import { useGetPortalOrganizationProfileHref } from 'context/user';
import { useProject } from 'context/project';

const getInvitationText = getTextIn('invitation');

export type OrganizationScreenProps = {
  organizationName: string;
  organizationId: string;
};

const WaitOrganizationApprovalScreen = ({
  organizationName,
  organizationId
}: OrganizationScreenProps) => {
  const { project } = useProject();
  const icon = <EmojiEmotionsTwoToneIcon sx={{ flexGrow: 1 }} />;
  const text = (
    <Typography component="span">
      {getNode(
        'getNodeWaitText',
        'invitation'
      )({
        link: (
          <Link
            to={useGetPortalOrganizationProfileHref(organizationId)}
            component={RouterLink}
            sx={{
              fontWeight: 600
            }}
          >
            {organizationName}
          </Link>
        ),
        projectName: project?.name
      })}
    </Typography>
  );

  return (
    <FeedbackScreen
      title={getInvitationText('soon')}
      icon={icon}
      text={text}
      hasHomePageButton={false}
      dataCy="waitfororganizationapprovalscreen"
    />
  );
};

export default WaitOrganizationApprovalScreen;
