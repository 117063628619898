import Stack from '@mui/material/Stack';
import { ConfigurableDatePicker } from 'feature/forms';
import { dateToDayjs } from 'utils/date';
import { getTextIn } from 'localization';
import { type Dayjs } from 'dayjs';
import { useProject } from 'context/project';

const getTextInFilterTime = getTextIn('filter-time');

const TimeFilterForm = ({
  disabled,
  minPointInTime
}: {
  disabled?: boolean;
  minPointInTime?: Dayjs;
}) => {
  const { maxDateFromNow } = useProject();
  return (
    <Stack gap={4} sx={{ mt: 2, mb: 1.5 }} width={{ xs: '100%', md: 'unset' }}>
      <ConfigurableDatePicker
        name="time"
        label={getTextInFilterTime('pointInTime')}
        disabled={disabled}
        maxDateTime={dateToDayjs(maxDateFromNow)}
        minDateTime={minPointInTime}
        inputProps={{
          'data-cy': 'filter-time',
          sx: { fontSize: 16 }
        }}
        // width: equals width of TimeFilterForm DatePickers (2 x 212) + gaps and m-dash
        pickerSx={{ width: { xs: 'auto', md: 2 * 212 + 42 } }}
      />
    </Stack>
  );
};

export default TimeFilterForm;
