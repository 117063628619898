import { useProject } from 'context/project';
import ProcessElement from './process-element';
import ProcessViz from './process-viz';
import ProcessWrapper from './process-wrapper';
import { getText, getTextFx, getTextIn, getPath } from 'localization';
import { transformToFragment } from 'utils/fragments-utils';

const useVizLabels = (headlines: string[], path: string) => {
  const { project, isProjectNew } = useProject();

  return [
    {
      text: isProjectNew
        ? getText('patientInfo', 'info')
        : getTextFx(
            'info',
            'process'
          )({ projectName: project?.name ?? getText('solutionName') }),
      linkTo: '/'
    }
  ].concat(
    headlines.map((h) => ({
      text: h,
      linkTo: `/${path}#${transformToFragment(h)}`
    }))
  );
};

const useVizLabelsPatient = () => {
  const getInfoText = getTextIn('info');

  const PATIENT_HEADLINES = [
    getInfoText('patientHelpTitle'),
    getInfoText('patientServicesTitle'),
    getInfoText('patientProvidersText'),
    getInfoText('patientParticipate'),
    getInfoText('patientMore')
  ];

  return useVizLabels(PATIENT_HEADLINES, getPath('patientInfo'));
};

const useVizLabelsProvider = (path: string) => {
  const getInfoText = getTextIn('info');

  const PROVIDER_HEADLINES = [
    getInfoText('servicesTitle'),
    getInfoText('howToParticipateTitle'),
    getText('members'),
    getText('additionalInformation', 'offer-details'),
    getInfoText('helpAndContact')
  ];

  return useVizLabels(PROVIDER_HEADLINES, path);
};

export {
  ProcessViz,
  ProcessElement,
  ProcessWrapper,
  useVizLabels,
  useVizLabelsPatient,
  useVizLabelsProvider
};
