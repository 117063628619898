import { useQuery } from '@tanstack/react-query';
import { type ListGeoAreasRequest } from '@SLR/solution3-sdk';
import useGeoAreasApi from './use-geo-areas-api';

const useListGeoAreas = (query: string) => {
  const { listGeoAreas } = useGeoAreasApi();
  const requestParameters: ListGeoAreasRequest = {
    query
  };

  return useQuery(
    ['geoAreas', { query }],
    () => {
      return listGeoAreas(requestParameters);
    },
    {
      staleTime: Infinity,
      enabled: query.length > 2
    }
  );
};

export default useListGeoAreas;
