import Button from '@mui/material/Button';
import { useBooking } from 'context/booking';
import { useIsSeeker } from 'context/user';
import { RemoveFilterDialog } from 'feature/filter';
import { getText, getTextIn } from 'localization';
import { useState } from 'react';

const getFilterText = getTextIn('filter');

type RemoveFilterButtonProps = {
  dataCy?: string;
  text?: string;
  onClick: VoidFunction;
};

const RemoveFilterButton = ({
  dataCy,
  text = getText('cancel'),
  onClick
}: RemoveFilterButtonProps) => {
  const isSeeker = useIsSeeker();
  const { offers: bookinglist } = useBooking();
  const [showAlert, setShowAlert] = useState(false);

  return (
    <>
      <Button
        data-cy={dataCy}
        variant="text"
        sx={{
          textTransform: 'none',
          whiteSpace: 'nowrap'
        }}
        onClick={
          bookinglist.length > 0 && isSeeker
            ? () => setShowAlert(true)
            : onClick
        }
      >
        {text}
      </Button>

      <RemoveFilterDialog
        showDialog={showAlert}
        onClose={() => setShowAlert(false)}
        removeFilters={onClick}
        bodyText={getFilterText('removeAllFiltersDialogText')}
      />
    </>
  );
};

export default RemoveFilterButton;
