import { ElementType } from 'react';
import { Button, CircularProgress, lighten, useTheme } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button';

type LoadingButtonProps = {
  dataCy: string;
  label: string;
  loading?: boolean;
  component?: ElementType;
};

const LoadingButton = ({
  dataCy,
  label,
  variant,
  size = 'medium',
  color = 'primary',
  loading = false,
  disabled = false,
  startIcon,
  endIcon,
  sx,
  onClick,
  component = 'button'
}: ButtonProps & LoadingButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      data-cy={`${dataCy}-loadingbutton`}
      variant={variant}
      size={size}
      color={color}
      disabled={disabled || loading}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={[
        {
          bgcolor:
            disabled || loading
              ? `${lighten(theme.palette.primary.main, 0.9)} !important`
              : !variant /* `contained` is the default value */ ||
                variant === 'contained'
              ? color
              : 'unset'
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      component={component}
    >
      {label}
      {loading && (
        <CircularProgress
          data-cy={`${dataCy}-loadingbutton-progress`}
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            mt: '-12px',
            ml: '-12px'
          }}
        />
      )}
    </Button>
  );
};

export default LoadingButton;
