/* eslint-disable @typescript-eslint/no-namespace */
import { Locales } from './types';

type GlobalConfig = { [key: string]: Locales };

// eslint-disable-next-line @typescript-eslint/prefer-namespace-keyword
declare module globalThis {
  // eslint-disable-next-line no-var
  var globalConfig: GlobalConfig;
}

globalThis.globalConfig = {};
