import type { EntityModelParticipantInfo } from '@SLR/solution3-sdk';
import { Stack } from '@mui/material';
import { useProject } from 'context/project';
import useDeleteUser from 'context/user/hooks/useDeleteUser';
import { DialogGeneric } from 'feature';
import { notifyMutationError, notifyMutationSuccess } from 'feature/error';
import { useDeleteOrganization } from 'feature/hooks';
import { getNode, getText, getTextIn } from 'localization';

const getParticipantsText = getTextIn('settings-participants');

const prefix = 'delete-participant-alert-dialog';

export type AdministerParticipantProps = {
  open: boolean;
  onClose: () => void;
  participantSelected?: EntityModelParticipantInfo;
};

const DeleteParticipantDialog = ({
  open,
  onClose,
  participantSelected
}: AdministerParticipantProps) => {
  const { project } = useProject();
  const deleteOrganization = useDeleteOrganization();
  const deleteUser = useDeleteUser();

  const participantType = participantSelected?.user
    ? getParticipantsText('user')
    : getText('organization', 'role');

  const responseReaction = {
    onSuccess: () => {
      notifyMutationSuccess(
        `${participantType} ${getParticipantsText('deleteSuccess')}`
      );
      onClose();
    },
    onError: () => {
      notifyMutationError(
        `${participantType} ${getParticipantsText('deleteFailure')}`
      );
    }
  };

  const handleDelete = () => {
    if (participantSelected?.user) {
      deleteUser.mutate(participantSelected.participantId, responseReaction);
    } else if (participantSelected?.user === false) {
      deleteOrganization.mutate(
        participantSelected.participantId,
        responseReaction
      );
    }
  };

  return (
    <DialogGeneric
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      title={getParticipantsText('removeParticipant')}
      confirmText={getParticipantsText('removeParticipant')}
      prefix={prefix}
      confirmColor="error"
      fullWidth
    >
      <Stack gap={2}>
        {getNode(
          'getNodeDeleteHint',
          'settings-participants'
        )({
          isUser: participantSelected?.user,
          participantType,
          name: participantSelected?.name,
          projectName: project?.name
        })}
      </Stack>
    </DialogGeneric>
  );
};

export default DeleteParticipantDialog;
