import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { SidebarPageWrapper } from 'feature';
import { useSnackbar } from 'notistack';
import CtrlButtons from './ctrl-buttons';
import { useProject } from 'context/project';
import { useUpdateProject } from 'context/project/hooks';

import {
  type UpdateProjectRequest,
  Visibility,
  Registration
} from '@SLR/solution3-sdk';
import { getTextIn } from 'localization';

const getSettingsText = getTextIn('settings-visibility');

type VisibilitySettingsSectionProps = {
  visibility?: Visibility;
  onChange: (p1: React.ChangeEvent<HTMLInputElement>) => void;
  helperTextLocalizationKey: string;
};

const VisibilitySettingsSection = ({
  visibility,
  onChange,
  helperTextLocalizationKey
}: VisibilitySettingsSectionProps) => {
  return (
    <>
      <Typography variant="formLabel">
        {getSettingsText(helperTextLocalizationKey)}
      </Typography>

      <FormControl sx={{ mb: 3 }}>
        <RadioGroup
          value={visibility}
          onChange={onChange}
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row'
            }
          }}
        >
          <FormControlLabel
            value={Visibility.Everyone}
            control={<Radio />}
            label={getSettingsText('everyone')}
          />
          <FormControlLabel
            value={Visibility.OnlyRegistered}
            control={<Radio />}
            label={getSettingsText('onlyRegistered')}
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

const SettingsVisibility = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { project } = useProject();
  const updateProject = useUpdateProject();

  const [providerOrganizationsVisibility, setProviderOrganizationsVisibility] =
    useState(project?.providerOrganizationsVisibility);
  const onProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProviderOrganizationsVisibility(
      (event.target as HTMLInputElement).value as Visibility
    );
  };

  const [seekerOrganizationsVisibility, setSeekerOrganizationsVisibility] =
    useState(project?.seekerOrganizationsVisibility);
  const onSeekerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeekerOrganizationsVisibility(
      (event.target as HTMLInputElement).value as Visibility
    );
  };

  const [offersVisibility, setOffersVisibility] = useState(
    project?.offersVisibility
  );
  const onOffersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOffersVisibility((event.target as HTMLInputElement).value as Visibility);
  };

  const isValid =
    providerOrganizationsVisibility !==
      project?.providerOrganizationsVisibility ||
    seekerOrganizationsVisibility !== project?.seekerOrganizationsVisibility ||
    offersVisibility !== project?.offersVisibility;

  const onConfirmClick = () => {
    const projectUpdate: UpdateProjectRequest = {
      projectId: project?.id ?? '',
      projectUpdate: {
        providerOrganizationsVisibility,
        seekerOrganizationsVisibility,
        offersVisibility
      }
    };

    if (isValid) {
      updateProject.mutate(projectUpdate, {
        onSuccess: () => {
          enqueueSnackbar(getSettingsText('changeVisibilitySuccess'), {
            variant: 'success'
          });
        },
        onError: () => {
          enqueueSnackbar(getSettingsText('changeVisibilityError'), {
            variant: 'error'
          });
        }
      });
    }
  };

  const onCancelClick = () => {
    setProviderOrganizationsVisibility(
      project?.providerOrganizationsVisibility
    );
    setSeekerOrganizationsVisibility(project?.seekerOrganizationsVisibility);
    setOffersVisibility(project?.offersVisibility);
  };

  return (
    <SidebarPageWrapper>
      <>
        <Typography variant="h1">{getSettingsText('settings')}</Typography>

        <VisibilitySettingsSection
          onChange={onProviderChange}
          helperTextLocalizationKey="helperTextProviders"
          visibility={providerOrganizationsVisibility}
        />

        {project?.seekerOrganizationsRegistration !== Registration.None && (
          <VisibilitySettingsSection
            onChange={onSeekerChange}
            helperTextLocalizationKey="helperTextSeekers"
            visibility={seekerOrganizationsVisibility}
          />
        )}

        <VisibilitySettingsSection
          onChange={onOffersChange}
          helperTextLocalizationKey="helperTextOffers"
          visibility={offersVisibility}
        />

        <CtrlButtons
          disabled={!isValid}
          onConfirm={onConfirmClick}
          onCancel={onCancelClick}
          isLoading={updateProject.isLoading}
        />
      </>
    </SidebarPageWrapper>
  );
};

export default SettingsVisibility;
