import { type ReactElement } from 'react';
import { Button, Box, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps
} from 'react-router-dom';

type ShowMoreButtonConfig = {
  text: string;
  to: RouterLinkProps['to'];
};

type CardPreviewSectionProps = {
  children: ReactElement[] | ReactElement;
  title?: string;
  showMoreButtonConfig?: ShowMoreButtonConfig;
  coloredBackground?: boolean; // Necessary for drilling of backgroundColor in the process wrapper
};

const CardPreviewSection = ({
  title,
  children,
  showMoreButtonConfig
}: CardPreviewSectionProps) => {
  return (
    <Box>
      {title && (
        <Typography variant="h2" align="left">
          {title}
        </Typography>
      )}
      {children}
      {showMoreButtonConfig && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'center',
              sm: 'flex-end'
            }
          }}
        >
          <Button
            endIcon={<NavigateNextIcon />}
            component={RouterLink}
            to={showMoreButtonConfig.to}
            data-cy="cardpreviewsection-more-button"
          >
            {showMoreButtonConfig.text}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CardPreviewSection;
