import TextField from '@mui/material/TextField';
import { ValidatedTextField } from 'feature/forms';

const FormTimesDuration = ({
  name = 'duration',
  label,
  helperText,
  dataCy
}: {
  name?: string;
  label?: string;
  helperText?: string;
  dataCy?: string;
}) => {
  return (
    <ValidatedTextField
      name={name}
      render={({ field, props }) => {
        const { errorMessage, ...fieldProps } = props;

        return (
          <TextField
            label={label}
            helperText={errorMessage ?? helperText}
            placeholder="hh:mm"
            fullWidth
            inputProps={{ 'data-cy': dataCy }}
            {...field}
            {...fieldProps}
          />
        );
      }}
    />
  );
};

export default FormTimesDuration;
