import { Box, Button, Stack } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
import {
  type ImageCreate,
  type ImageUpdate,
  type Image,
  type ImageUrls
} from '@SLR/solution3-sdk';
import PlaceholderImage from '../../image-placeholder.svg';
import { getText } from 'localization';
import { useState } from 'react';
import { ObjectSchema, mixed, object, string } from 'yup';
import HeroImageDialog from 'feature/info/hero-image-dialog';

const DELETE_ALTERNATIVE_TEXT = '_';

function convertDeleteValue<T, U extends T[keyof T]>(
  imageObject: T,
  imageKey: string,
  imageId = 'imageId',
  alternativeText = 'alternativeText'
): void {
  const imageKeyTyped = imageKey as keyof T;
  const imageParams = imageObject[imageKeyTyped];

  // the signal for a deleted image due to the types (no null, alternativeText required, etc.)
  if (imageParams) {
    const imageIdTyped =
      imageId as keyof (typeof imageObject)[typeof imageKeyTyped];

    const alternativeTextTyped =
      alternativeText as keyof (typeof imageObject)[typeof imageKeyTyped];

    if (
      !imageParams[imageIdTyped] &&
      imageParams[alternativeTextTyped] === DELETE_ALTERNATIVE_TEXT
    ) {
      imageObject[imageKeyTyped] = null as unknown as U;
    }
  }
}

type DialogImage = Partial<Image & ImageCreate & ImageUpdate>;

const DialogImageSchema: ObjectSchema<DialogImage> = object().shape({
  id: string(),
  imageId: string(),
  alternativeText: string().required().max(255),
  urls: mixed<ImageUrls>().default(undefined)
});

interface ImageDialogWrapperProps {
  image?: DialogImage | null;
  editTitleText?: string;
  titleElement: JSX.Element;
  buttonText?: string;
  dataCyPrefix: string;
  isUpdateLoading?: boolean;
  onChange: (image?: DialogImage | null) => void;
}

const ImageDialogWrapper = ({
  image,
  editTitleText,
  buttonText,
  titleElement,
  dataCyPrefix,
  isUpdateLoading = false,
  onChange
}: ImageDialogWrapperProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [resetImage, setResetImage] = useState<DialogImage | null>();
  const openPictureDialog = () => {
    setIsDialogOpen(true);
    setResetImage(image);
  };
  const closePictureDialog = () => setIsDialogOpen(false);

  const handleClose = () => {
    onChange(resetImage);
    closePictureDialog();
  };

  const handleChange = (newImage?: DialogImage) => {
    onChange({ ...newImage, imageId: newImage?.id });
  };

  const handleDelete = () => {
    // this is the signal for a deleted image due to the types (no null, alternativeText required, etc.)
    onChange({
      imageId: undefined,
      alternativeText: DELETE_ALTERNATIVE_TEXT
    });
  };

  return (
    <>
      <HeroImageDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        image={image as Image}
        handleConfirm={closePictureDialog}
        handleAddImage={handleChange}
        handleAltTextChange={(alternativeText) => {
          handleChange({ ...image, alternativeText });
        }}
        handleDelete={handleDelete}
        isUpdateLoading={isUpdateLoading}
        title={editTitleText}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        {titleElement}
        {image?.urls ? (
          <img
            data-cy={`${dataCyPrefix}-image`}
            key={`${dataCyPrefix}-image`}
            style={{ maxHeight: 200, objectFit: 'cover' }}
            src={image?.urls?.small}
            alt={image?.alternativeText}
          />
        ) : (
          <img
            style={{
              maxHeight: 200,
              objectFit: 'cover',
              backgroundColor: 'placeholder.main',
              display: 'inherit'
            }}
            src={PlaceholderImage}
            alt={getText('placeholderImage', 'edit-editHeroImage')}
          />
        )}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={{ xs: 2, sm: 7.25 }}
          sx={{
            flexWrap: 'wrap'
          }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={openPictureDialog}
            sx={{ alignSelf: 'center', minWidth: 120 }}
            startIcon={<CameraAlt />}
            data-cy={`${dataCyPrefix}-edit-image`}
          >
            {`${
              image?.urls
                ? `${buttonText ? `${buttonText} ` : `Bild `}${getText(
                    'edit',
                    'edit'
                  )}`
                : `${buttonText ? `${buttonText} ` : `Bild `}${getText(
                    'uploadImage',
                    'offer-details'
                  )}`
            }`}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export { ImageDialogWrapper, DialogImageSchema, convertDeleteValue };
export type { DialogImage };
