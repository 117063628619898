import { FormControlLabel, Stack, Switch } from '@mui/material';
import { InfoSymbol } from 'feature/forms';
import SubFormWrapper from './subform-wrapper';

type SwitchSubFormProps = {
  checked: boolean;
  handleChange: (value: boolean) => void;
  title: string;
  info: string;
  switchDataCy: string;
  children: React.ReactNode;
};

const SwitchSubForm = ({
  checked,
  handleChange,
  title,
  info,
  switchDataCy,
  children
}: SwitchSubFormProps) => {
  return (
    <>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={(event) => handleChange(event.target.checked)}
              data-cy={switchDataCy}
            />
          }
          label={title}
        />
        <InfoSymbol info={info} />
      </Stack>

      {children && checked && <SubFormWrapper>{children}</SubFormWrapper>}
    </>
  );
};

export default SwitchSubForm;
