import {
  SchemaValidationFormProvider,
  useSchemaValidationForm
} from 'feature/forms';
import { ReactElement } from 'react';
import {
  type FieldValues,
  type DefaultValues,
  type UseFormHandleSubmit,
  type UseFormGetValues,
  type UseFormSetValue,
  type Control
} from 'react-hook-form';

import { ObjectSchema } from 'yup';
import { UseFormSetValueValidating } from './use-form';

type SchemaProviderOptions = { [key: string]: boolean | undefined };
interface Props<T> {
  defaultValues: T;
  values?: T;
  schema:
    | ObjectSchema<T & FieldValues>
    | ((options?: SchemaProviderOptions) => ObjectSchema<T & FieldValues>);
  options?: SchemaProviderOptions;
  children: ({
    handleSubmit,
    getValues,
    setValue,
    setValueValidating,
    control
  }: {
    handleSubmit: UseFormHandleSubmit<T & FieldValues>;
    getValues: UseFormGetValues<T & FieldValues>;
    setValue: UseFormSetValue<T & FieldValues>;
    setValueValidating: UseFormSetValueValidating<T & FieldValues>;
    control: Control<T & FieldValues>;
  }) => ReactElement | ReactElement[];
}

function Fx<T>({
  defaultValues,
  values,
  schema,
  options,
  children
}: Props<T & FieldValues>): ReactElement {
  const form = useSchemaValidationForm<T & FieldValues>(
    defaultValues as DefaultValues<T & FieldValues>,
    values,
    typeof schema === 'function' ? schema(options) : schema
  );

  return (
    <SchemaValidationFormProvider {...form}>
      {children({
        handleSubmit: form.handleSubmit,
        getValues: form.getValues,
        setValue: form.setValue,
        setValueValidating: form.setValueValidating,
        control: form.control
      })}
    </SchemaValidationFormProvider>
  );
}

// a schema independent schema provider
const SchemaProvider: <T>({
  defaultValues,
  values,
  schema,
  options,
  children
}: Props<T & FieldValues>) => ReactElement = Fx;

export default SchemaProvider;
export type { SchemaProviderOptions };
