import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import { useBooking } from 'context/booking';
import { useIsSeeker } from 'context/user';
import {
  TIME_RANGE,
  TIME,
  GEO_AREA,
  LOCATION_TYPES,
  getFilterButtonLabel,
  useCtrlButtonsToShow,
  useGetFilterButtons,
  type UrlParams,
  type FilterParamsKeyType,
  type SetFilterButtonsCount,
  SearchParams
} from 'utils/url-param';
import RemoveFilterDialog from './remove-filter-dialog';
import { useGetVariableCheckboxValues } from './hooks';
import { DispatchBooleanStateAction } from 'types';
import { getTextIn } from 'localization';

const getFilterText = getTextIn('filter');

const FilterChip = ({
  label,
  onDelete,
  setDropDownsShouldClose,
  shouldProtectBookingList = true
}: {
  label: string;
  onDelete: VoidFunction;
  setDropDownsShouldClose: DispatchBooleanStateAction;
  shouldProtectBookingList?: boolean;
}) => {
  const isSeeker = useIsSeeker();
  const { offers: bookinglist } = useBooking();
  const [showAlert, setShowAlert] = useState(false);

  const allowDialog =
    bookinglist.length > 0 && isSeeker && shouldProtectBookingList;

  const handleDelete = allowDialog ? () => setShowAlert(true) : onDelete;

  return (
    <Box
      sx={{
        display: 'inline-block',
        verticalAlign: { xs: 'text-top', sm: 'unset', md: 'middle' },
        height: { xs: 'unset', md: 30 },
        mx: { xs: 0.25, sm: 0.625, md: 1 },
        my: { xs: 0.625, sm: 1, md: 1.25 }
      }}
    >
      {label ? (
        <Chip
          label={label}
          variant="outlined"
          onClick={handleDelete}
          onDelete={() => {
            setDropDownsShouldClose(true);
            handleDelete();
          }}
          sx={{
            color: 'white',
            bgcolor: 'primary.main',
            height: 24,
            '&.MuiChip-root:hover': {
              bgcolor: 'primary.dark'
            },
            '& .MuiChip-deleteIcon': {
              color: 'neutral.main',
              height: 16,
              width: 16
            },
            '&:hover .MuiChip-deleteIcon': {
              color: 'neutral.dark'
            }
          }}
        />
      ) : (
        <Skeleton
          variant="rounded"
          width={100}
          height={32}
          sx={{ borderRadius: 16 }}
        />
      )}
      <RemoveFilterDialog
        showDialog={showAlert}
        onClose={() => setShowAlert(false)}
        removeFilters={() => {
          onDelete();
          setShowAlert(false);
        }}
        bodyText={getFilterText('removeFilterDialogText')}
      />
    </Box>
  );
};

const PROTECTED_BOOKING_LIST = [TIME_RANGE, TIME, GEO_AREA, LOCATION_TYPES];

const RemoveButtons = ({
  search,
  onRemoveFilter,
  numberToShow,
  setFilterButtonsCount,
  setDropDownsShouldClose
}: {
  search: UrlParams;
  onRemoveFilter: (key: FilterParamsKeyType, name: string) => VoidFunction;
  numberToShow?: number;
  setFilterButtonsCount: SetFilterButtonsCount;
  setDropDownsShouldClose: DispatchBooleanStateAction;
}) => {
  const { setIsFixedTime } = useBooking();
  const filterButtons = useGetFilterButtons(search);

  const filterButtonsToShow = useCtrlButtonsToShow({
    filterButtons,
    numberToShow,
    setFilterButtonsCount
  });

  const variableCheckboxValues = useGetVariableCheckboxValues();
  const getLabel = getFilterButtonLabel(variableCheckboxValues);

  const hasTimeFilter = !!search[SearchParams.time];

  useEffect(() => {
    setIsFixedTime(hasTimeFilter);
  }, [hasTimeFilter, setIsFixedTime]);

  return (
    <>
      {filterButtonsToShow.map((keyName: string) => {
        const [key, name] = keyName.split('_');
        const typedKey = key as FilterParamsKeyType;
        const onDelete = onRemoveFilter(typedKey, name);

        const label = getLabel(typedKey, name) ?? '';

        return (
          <FilterChip
            key={keyName}
            label={label}
            onDelete={onDelete}
            setDropDownsShouldClose={setDropDownsShouldClose}
            shouldProtectBookingList={PROTECTED_BOOKING_LIST.includes(key)}
          />
        );
      })}
    </>
  );
};

export default RemoveButtons;
