import { ReactElement } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import { ContentWrapper } from 'feature';
import { isObject } from 'utils/helper';

type DetailPageWrapperProps = {
  overlayBox: ReactElement;
  maxWidth?: BoxProps['maxWidth'];
  flexDirection?: BoxProps['flexDirection'];
  mt?: BoxProps['mt'];
  children: ReactElement;
};

const DetailPageWrapper = ({
  overlayBox,
  maxWidth = { md: 350 },
  flexDirection = 'column',
  mt,
  children
}: DetailPageWrapperProps) => {
  let mtObject = {};

  if (mt !== undefined && isObject(mt)) {
    mtObject = mt;
  }

  return (
    <ContentWrapper>
      <Box
        sx={{
          display: { xs: 'flex', md: 'unset' },
          flexDirection
        }}
      >
        <Box
          sx={{
            float: { xs: 'none', md: 'right' },
            alignSelf: { xs: 'center', md: 'unset' },

            position: 'relative',
            zIndex: 10,
            ml: { xs: 0, md: 5, lg: 12 },
            mb: { xs: 2, md: 5 },
            mt: {
              ...{ xs: -19, sm: -20, md: -24, lg: -16 },
              ...mtObject
            },
            maxWidth
          }}
        >
          {overlayBox}
        </Box>
        {children}
      </Box>
    </ContentWrapper>
  );
};

export default DetailPageWrapper;
