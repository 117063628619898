import { TextField } from '@mui/material';
import { getTextIn } from 'localization';
import { ValidatedTextField } from 'feature/forms';

const getOnlineLocationText = getTextIn('offer-details-onlineLocation');

const OnlineLocationForm = () => {
  return (
    <ValidatedTextField
      name="onlineLocation.websiteUrl"
      render={({ field, props }) => {
        const { errorMessage, ...fieldProps } = props;
        return (
          <TextField
            fullWidth
            label={getOnlineLocationText('url')}
            helperText={errorMessage ?? getOnlineLocationText('urlHelperText')}
            inputProps={{
              maxLength: 255,
              'data-cy': 'offerform-onlinelocation-url'
            }}
            {...field}
            {...fieldProps}
          />
        );
      }}
    />
  );
};

export default OnlineLocationForm;
