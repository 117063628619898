import { useQuery } from '@tanstack/react-query';
import useProjectSectionsApi from './useProjectSectionsApi';
import { useGetProjectId } from 'context/project';
import { Section } from '@SLR/solution3-sdk';

const useGetSectionText = (section: Section) => {
  const { getProjectSectionText } = useProjectSectionsApi();
  const projectId = useGetProjectId();

  return useQuery(
    ['sectionText', { projectId, section }],
    () => {
      return getProjectSectionText({ projectId: projectId ?? '', section });
    },

    {
      staleTime: Infinity,
      enabled: !!projectId
    }
  );
};

export default useGetSectionText;
