import { useMutation } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import { useArticleCategoriesApi } from './use-compass-api';

const useDeleteArticleCategory = () => {
  const projectId = useGetProjectId();
  const { deleteArticleCategory } = useArticleCategoriesApi();

  return useMutation((categoryId: string) => {
    return deleteArticleCategory({
      projectId: projectId ?? '',
      categoryId
    });
  });
};

export default useDeleteArticleCategory;
