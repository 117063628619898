import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ArticleCategoryCreate } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { useArticleCategoriesApi } from './use-compass-api';
import { LIST_ARTICLE_CATEGORIES } from './use-list-article-categories';

const useCreateArticleCategory = () => {
  const projectId = useGetProjectId();
  const queryClient = useQueryClient();
  const { createArticleCategory } = useArticleCategoriesApi();

  return useMutation(
    (articleCategoryCreate: ArticleCategoryCreate) => {
      return createArticleCategory({
        projectId: projectId ?? '',
        articleCategoryCreate
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([LIST_ARTICLE_CATEGORIES]);
      }
    }
  );
};

export default useCreateArticleCategory;
