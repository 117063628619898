import {
  type ControllerRenderProps,
  type UseFormStateReturn,
  type FieldValues,
  type Path,
  type PathValue,
  Controller
} from 'react-hook-form';
import { useCallback } from 'react';
import { useSchemaValidationFormContext } from './form-provider';

interface RenderProps<T extends FieldValues = FieldValues> {
  field: ControllerRenderProps<T, Path<T>>;
  formState: UseFormStateReturn<T>;
  props: {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error: boolean;
    errorMessage?: string;
  };
}

interface Props<T extends FieldValues = FieldValues> {
  name: Path<T>;
  render: (props: RenderProps<T>) => React.ReactElement;
  checkedValue: PathValue<T, Path<T>>;
  uncheckedValue: PathValue<T, Path<T>>;
}

/**
 * Note: The component can only be used within an {@link SchemaValidationFormProvider}.
 */
const ValidatedSwitch = <T extends FieldValues = FieldValues>({
  name,
  render,
  checkedValue,
  uncheckedValue
}: Props<T>) => {
  const { control, setValueValidating } = useSchemaValidationFormContext<T>();

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked ? checkedValue : uncheckedValue;
      setValueValidating(name, value);
    },
    [name, setValueValidating, checkedValue, uncheckedValue]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) => {
        const props = {
          onChange: handleOnChange,
          error: !!fieldState.error,
          errorMessage: fieldState.error?.message
        };
        return render({ field, props, formState });
      }}
    />
  );
};

export default ValidatedSwitch;
