import { type EntityModelParticipantInfo } from '@SLR/solution3-sdk';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ParticipantCard, useCardPreviewNumber } from 'feature/cards';
import { getText } from 'localization';
import { Role } from 'utils/roles';

const ParticipantGrid = ({
  participantInfos,
  isLoading,
  isPreview = false,
  role
}: {
  participantInfos?: EntityModelParticipantInfo[];
  isLoading: boolean;
  isPreview?: boolean;
  role: Role;
}) => {
  const previewNumber = useCardPreviewNumber();

  if (!isLoading && participantInfos === undefined) {
    return <Typography>{getText('noParticipantsYet')}</Typography>;
  }

  return (
    <Grid
      container
      spacing={2}
      sx={[
        {
          mb: 2
        }
      ]}
    >
      {(participantInfos ?? Array(previewNumber).fill(null))?.map(
        (participant: EntityModelParticipantInfo, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              xl={isPreview ? 3 : 4}
              key={index}
              sx={{ justifyContent: 'center', display: 'flex' }}
            >
              <ParticipantCard
                key={index}
                participantInfo={participant}
                loading={isLoading}
                role={role}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default ParticipantGrid;
