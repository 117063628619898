import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';
import {
  TargetGroupsApi,
  type ListTargetGroupsRequest,
  type GetTargetGroupRequest,
  type CreateTargetGroupRequest,
  type UpdateTargetGroupRequest,
  type DeleteTargetGroupRequest
} from '@SLR/solution3-sdk';

const useTargetGroupsApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.targetGroups) as TargetGroupsApi;
  }, []);

  const listTargetGroups = useCallback(
    async (requestParameters: ListTargetGroupsRequest) => {
      try {
        return await apiInstance.listTargetGroups(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getTargetGroup = useCallback(
    async (requestParameters: GetTargetGroupRequest) => {
      try {
        return await apiInstance.getTargetGroup(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createTargetGroup = useCallback(
    async (requestParameters: CreateTargetGroupRequest) => {
      try {
        return await apiInstance.createTargetGroup(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const updateTargetGroup = useCallback(
    async (requestParameters: UpdateTargetGroupRequest) => {
      try {
        return await apiInstance.updateTargetGroup(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const deleteTargetGroup = useCallback(
    async (requestParameters: DeleteTargetGroupRequest) => {
      try {
        return await apiInstance.deleteTargetGroup(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );
  return {
    listTargetGroups,
    createTargetGroup,
    getTargetGroup,
    deleteTargetGroup,
    updateTargetGroup
  };
};

export default useTargetGroupsApi;
