import { useState } from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TuneIcon from '@mui/icons-material/Tune';
import FilterMenuMobile from './filter-menu-mobile';
import type {
  UrlParams,
  FilterHandler,
  FilterGeoAreaCtrl,
  OnRemoveFilter
} from 'utils/url-param';
import { getText } from 'localization';

type FilterBlockMobileProps = FilterHandler &
  FilterGeoAreaCtrl & {
    search: UrlParams;
    totalElements?: number;
    hideCategoryFilter?: boolean;
    hasTargetGroup?: boolean;
    onRemoveFilter: OnRemoveFilter;
    onRemoveAllFilters: VoidFunction;
  };

const FilterBlockMobile = ({
  search,
  totalElements,
  geoAreaData,
  hideCategoryFilter,
  hasTargetGroup,
  onRemoveFilter,
  onRemoveAllFilters,
  onClickApplyCheckbox,
  onClickApplyGeoArea,
  onClickApplyDate,
  onClickApplyDuration,
  onChangeSort
}: FilterBlockMobileProps) => {
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const title = getText('filterAndSort', 'filter');

  return (
    <>
      {/* filter button and menu */}
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        width="100%"
      >
        <Container
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            p: '0 !important',
            justifyContent: { xs: 'center', sm: 'flex-end' },
            mt: 0.5,
            mb: 1
          }}
        >
          <Button
            data-cy="offerspage-createoffer-button"
            variant="outlined"
            startIcon={<TuneIcon />}
            onClick={() => setOpenMobileFilter(true)}
            sx={{
              mt: { xs: 'unset', sm: 3.25 }
            }}
          >
            {title}
          </Button>
        </Container>
      </Stack>

      <FilterMenuMobile
        open={openMobileFilter}
        onClose={() => setOpenMobileFilter(false)}
        search={search}
        totalElements={totalElements}
        title={title}
        geoAreaData={geoAreaData}
        hideCategoryFilter={hideCategoryFilter}
        hasTargetGroup={hasTargetGroup}
        onRemoveFilter={onRemoveFilter}
        onRemoveAllFilters={onRemoveAllFilters}
        onClickApplyCheckbox={onClickApplyCheckbox}
        onClickApplyGeoArea={onClickApplyGeoArea}
        onClickApplyDate={onClickApplyDate}
        onClickApplyDuration={onClickApplyDuration}
        onChangeSort={onChangeSort}
      />
    </>
  );
};

export default FilterBlockMobile;
