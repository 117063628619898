import React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import { useUser } from 'context/user';
import { icons, type SetType } from './config';
import { useOnboardingDone, type BitMaskOnboardingType } from './logic';
import { getTextIn } from 'localization';

const getTextOnboarding = getTextIn('onboarding');

const UNSET = 'unset';

const IconItem = ({
  name,
  setType,
  small = false,
  success = false,
  noFixWidth = false,
  disabled = false
}: {
  name: string;
  setType?: SetType;
  small?: boolean;
  success?: boolean;
  noFixWidth?: boolean;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  const { perspective } = useUser();
  const isSeeker = perspective.roles?.isSeeker;

  const done = useOnboardingDone(name as BitMaskOnboardingType);

  const Icon = icons[name as keyof typeof icons] as React.ElementType;

  const fabSize = small ? 50 : success ? 82 : { xs: 82, sm: 116 };

  return (
    <Stack
      spacing={success ? 4 : 1}
      sx={{
        fontSize: small ? 12 : UNSET,
        color: 'primary.main',
        alignItems: 'center',
        textAlign: 'center',
        px: small ? UNSET : 2,
        width: noFixWidth || small ? UNSET : { xs: UNSET, sm: 142 }
      }}
    >
      <Fab
        disabled={disabled || done}
        disableFocusRipple
        sx={{
          boxShadow: 'none',
          bgcolor: done ? 'primary.main' : 'primary.background',
          '& svg': {
            fontSize: small
              ? '2rem'
              : success
              ? '3.5rem'
              : { xs: '3.5rem', sm: '4.75rem' }
          },
          '&.Mui-disabled': {
            bgcolor: disabled
              ? done
                ? 'primary.main'
                : 'primary.background'
              : 'primary.main'
          },
          width: fabSize,
          height: fabSize
        }}
        onClick={() => {
          setType?.(name);
        }}
      >
        {done ? (
          <CheckIcon sx={{ color: 'common.white' }} />
        ) : (
          <Icon
            sx={{
              color: 'primary.main',
              stroke: theme.palette.primary.background,
              strokeWidth: 1
            }}
          />
        )}
      </Fab>
      <Box sx={{ fontWeight: 'bold' }}>
        {getTextOnboarding(
          `${name}${isSeeker ? 'Seeker' : ''}${success ? 'Success' : ''}`
        )}
      </Box>
    </Stack>
  );
};

export default IconItem;
