import { Schema, type TestConfig } from 'yup';
import { isNotEmptyHtml } from 'utils/editor';

export const DEFAULT_MAX_CLOB_LENGTH = 10_000;

export const NON_EMPTY_HTML: TestConfig<Schema['__outputType']> = {
  name: 'non-empty-html',
  test: isNotEmptyHtml,
  message: '${path} darf nicht leer sein'
};
