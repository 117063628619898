import { Visibility } from '@SLR/solution3-sdk';
import RestrictedVisibility, { useIsVisible } from './restricted-visibility';
import compact from 'lodash/compact';
import minBy from 'lodash/minBy';

const restrictivity = (visibility: Visibility): number => {
  switch (visibility) {
    default:
    case Visibility.OnlyRegistered:
      return 2;
    case Visibility.Everyone:
      return 1;
  }
};

const leastRestrictiveVisibility = (
  ...visibilities: Array<Visibility | undefined>
) => {
  return minBy(compact(visibilities), restrictivity);
};

export { RestrictedVisibility, leastRestrictiveVisibility, useIsVisible };
