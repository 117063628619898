import Box from '@mui/material/Box';

const NdashBox = () => {
  return (
    <Box height={56} sx={{ display: 'flex', alignItems: 'center' }}>
      &ndash;
    </Box>
  );
};

export default NdashBox;
