import { useEffect, useState, ChangeEvent } from 'react';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';

type OnChangeRadio = (key: string, value?: string) => void;

type RadioOption = {
  key: string;
  title?: string;
  options: Array<{ value: string; label: string }>;
};

type RadioBlockProps = {
  radioOption: RadioOption;
  initialValue?: string;
  controlledValue?: string;
  shouldReset?: boolean;
  row?: boolean;
  onValueChange: OnChangeRadio;
  disableTypography?: boolean;
};

const RadioBlock = ({
  radioOption,
  initialValue = '',
  controlledValue,
  shouldReset,
  row = true,
  onValueChange,
  disableTypography = false
}: RadioBlockProps) => {
  const [currentValue, setCurrentValue] = useState<string>(initialValue);

  /**
   * Update current value when initial value or shouldReset is changed
   * Note: shouldReset is just used to pass down the reset click of the parent view, its value is not important.
   */
  useEffect(() => {
    if (initialValue) {
      setCurrentValue(initialValue);
    }
  }, [initialValue, shouldReset]);

  /**
   * Handle value changes
   * @param event HTMLInputElement
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;
    setCurrentValue(newValue);
    onValueChange(radioOption.key, newValue);
  };

  return (
    <FormControl fullWidth>
      {radioOption.title && (
        <FormLabel sx={{ pb: 1, fontWeight: 500 }}>
          {radioOption.title}
        </FormLabel>
      )}
      <RadioGroup
        row={row}
        sx={{ pl: 1 }}
        value={controlledValue ?? currentValue}
        onChange={handleChange}
        data-cy={`radiosetting-${radioOption.key}`}
      >
        {radioOption.options.map(({ value, label }) => (
          <FormControlLabel
            data-cy={`radiosetting-${radioOption.key}-${value}`}
            key={label}
            value={value}
            control={<Radio />}
            label={label}
            disableTypography={disableTypography}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioBlock;

export type { RadioOption, OnChangeRadio };
