import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';
import {
  CategoriesApi,
  type CreateCategoryRequest,
  type DeleteCategoryRequest,
  type GetCategoryRequest,
  type ListCategoriesRequest,
  type UpdateCategoryRequest
} from '@SLR/solution3-sdk';

const useCategoriesApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.categories) as CategoriesApi;
  }, []);

  const listCategories = useCallback(
    async (requestParameters: ListCategoriesRequest) => {
      try {
        return await apiInstance.listCategories(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getCategory = useCallback(
    async (requestParameters: GetCategoryRequest) => {
      try {
        return await apiInstance.getCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createCategory = useCallback(
    async (requestParameters: CreateCategoryRequest) => {
      try {
        return await apiInstance.createCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const updateCategory = useCallback(
    async (requestParameters: UpdateCategoryRequest) => {
      try {
        return await apiInstance.updateCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const deleteCategory = useCallback(
    async (requestParameters: DeleteCategoryRequest) => {
      try {
        return await apiInstance.deleteCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );
  return {
    listCategories,
    createCategory,
    getCategory,
    deleteCategory,
    updateCategory
  };
};

export default useCategoriesApi;
