import { ContentWrapper, OfferGrid } from 'feature';
import { PreviewSection, useCardPreviewNumber } from 'feature/cards';
import { useGetOffers } from 'feature/hooks';
import { getTextIn, getPath } from 'localization';

const getTextOffer = getTextIn('offer');

const SORT_BY = ['desc'];

const PreviewOffers = ({ keywords }: { keywords: string[] }) => {
  const maxPreviewNumber = 4;

  const previewNumber = useCardPreviewNumber(maxPreviewNumber);
  const query = keywords.join(' OR ');

  const { data, isLoading, isError } = useGetOffers({
    // preview number + 1 to get the information, if there are more offers than the preview number (for more button)
    size: previewNumber + 1,
    sort: SORT_BY,
    query
  });

  // more button only, if more than 3 (but size is set to 3) ==> set it to 4 and use the max in previewNumber??
  const allOffers = data?.embedded?.offers;
  const offers = allOffers?.slice(0, previewNumber);

  return offers ? (
    <ContentWrapper backgroundColor="primary.background">
      <PreviewSection
        title={getTextOffer('matchingOffers')}
        showMoreButtonConfig={
          allOffers && allOffers.length > previewNumber
            ? {
                to: `/${getPath('offers')}?query=${query}`,
                text: getTextOffer('furtherOffers')
              }
            : undefined
        }
        coloredBackground={true}
      >
        <OfferGrid
          isError={isError}
          isLoading={isLoading}
          offers={offers}
          isPreview={true}
        />
      </PreviewSection>
    </ContentWrapper>
  ) : null;
};

export default PreviewOffers;
