import { useQuery } from '@tanstack/react-query';
import {
  type ListArticleCategoriesRequest,
  type GetArticleCategoryRequest,
  type EntityModelArticleCategory
} from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { useArticleCategoriesApi } from './use-compass-api';

type ListArticleCategories = Omit<ListArticleCategoriesRequest, 'projectId'>;

type GetArticleCategory = Omit<GetArticleCategoryRequest, 'projectId'>;

const LIST_ARTICLE_CATEGORIES = 'listArticleCategories';
const GET_ARTICLE_CATEGORY = 'getArticleCategory';

const useListArticleCategories = (params?: ListArticleCategories) => {
  const projectId = useGetProjectId();
  const { listArticleCategories } = useArticleCategoriesApi();

  const requestParameters: ListArticleCategoriesRequest = {
    ...params,
    projectId: projectId ?? ''
  };

  return useQuery(
    [LIST_ARTICLE_CATEGORIES, requestParameters],
    () => {
      return listArticleCategories(requestParameters);
    },
    {
      enabled: !!projectId
    }
  );
};

const useArticleCategories = (params?: ListArticleCategories) => {
  const defaultListParameters = { sort: ['name,asc'] };

  const { data, isLoading } =
    useListArticleCategories(params ?? defaultListParameters) ?? {};

  const articleCategories = data?.embedded?.categories;

  return { articleCategories, isLoading };
};

const useGetArticleCategory = ({ categoryId }: GetArticleCategory) => {
  const projectId = useGetProjectId();
  const { getArticleCategory } = useArticleCategoriesApi();

  const requestParameters: GetArticleCategoryRequest = {
    projectId: projectId ?? '',
    categoryId
  };

  return useQuery<EntityModelArticleCategory, Response>(
    [GET_ARTICLE_CATEGORY, requestParameters],
    () => {
      return getArticleCategory(requestParameters);
    },
    {
      enabled: !!projectId && !!categoryId
    }
  );
};

export {
  useGetArticleCategory,
  useArticleCategories,
  LIST_ARTICLE_CATEGORIES,
  GET_ARTICLE_CATEGORY
};
