import Typography from '@mui/material/Typography';
import { SidebarPageWrapper } from 'feature';
import ChangeData from './change-data';
import RequestUserData from './request-user-data';
import DeleteAccount from './delete-account';
import { getText } from 'localization';
import { useUser } from 'context/user';
import { Restricted } from 'routes';
import { Stack } from '@mui/material';

const ManageData = () => {
  const { perspective } = useUser();
  return (
    <Restricted isVisible={perspective.roles.isSolutionUser} forceLogin>
      <SidebarPageWrapper routeLinksName="accountLinks">
        <>
          <Typography variant="h1">
            {getText('manageData', 'account-data')}
          </Typography>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={{ xs: 2, sm: 4, md: 6 }}
          >
            <ChangeData />
            <RequestUserData />
            <DeleteAccount />
          </Stack>
        </>
      </SidebarPageWrapper>
    </Restricted>
  );
};

export default ManageData;
