import { useGetProjectId } from 'context/project';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useUsersApi from './useUsersApi';

const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const projectId = useGetProjectId() ?? '';
  const { deleteUser } = useUsersApi();

  return useMutation<void, Error, string, () => void>(
    (userId = 'current') =>
      deleteUser({
        projectId,
        userId
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'participantsInfo',
          {
            projectId
          }
        ]);
      }
    }
  );
};

export default useDeleteUser;
