import { useCallback, useState } from 'react';
import { type UseQueryResult } from '@tanstack/react-query';
import { Button, Stack } from '@mui/material';
import {
  type EntityModelGeoArea,
  type PagedModelEntityModelGeoArea
} from '@SLR/solution3-sdk';
import { useListGeoAreas, GeoArea } from 'feature/hooks';
import SearchFieldAutocomplete from 'components/search-field-autocomplete';

const getSelectableGeoAreas = (
  listGeoAreas: UseQueryResult<PagedModelEntityModelGeoArea, unknown>,
  excludedGeoAreas: GeoArea[]
) =>
  (listGeoAreas?.data?.embedded?.geoAreas ?? []).filter(
    (area: EntityModelGeoArea) =>
      !excludedGeoAreas.some((ignoredGeoArea) => ignoredGeoArea.id === area.id)
  );

interface Props {
  label: string;
  selectCaption?: string;
  excludedGeoAreas?: GeoArea[];
  error?: boolean;
  errorMessage?: string;
  onGeoAreaSelected: (geoArea: GeoArea) => void;
}

const GeoAreaSelector = ({
  label,
  selectCaption,
  error,
  errorMessage,
  excludedGeoAreas = [],
  onGeoAreaSelected
}: Props) => {
  const [query, setQuery] = useState<string>('');
  const listGeoAreas = useListGeoAreas(query);

  const [selectedGeoArea, setSelectedGeoArea] = useState<EntityModelGeoArea>();

  const selectableOptions = getSelectableGeoAreas(
    listGeoAreas,
    excludedGeoAreas
  );

  const { isFetching } = listGeoAreas;

  const handleSelect = useCallback(() => {
    if (selectedGeoArea) {
      onGeoAreaSelected(selectedGeoArea);
    }
    // Reset search field
    setQuery('');
    setSelectedGeoArea(undefined);
  }, [onGeoAreaSelected, selectedGeoArea]);

  return (
    <Stack direction="row" spacing={2} alignItems="stretch">
      {SearchFieldAutocomplete<EntityModelGeoArea>({
        label,
        query,
        selectableOptions,
        isFetching,
        error,
        errorMessage,
        onQueryChanged: setQuery,
        selectedOptionName: selectedGeoArea?.name ?? '',
        onOptionSelected: setSelectedGeoArea,
        onAccept: handleSelect
      })}

      <Button
        variant="outlined"
        disabled={!selectedGeoArea}
        onClick={handleSelect}
        sx={{ height: 56 }}
        data-cy="geoareaselector-select-button"
      >
        {selectCaption}
      </Button>
    </Stack>
  );
};

export default GeoAreaSelector;
