import dayjs, { type Dayjs } from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de');

const dateToDayjs = (date?: Date | null): Dayjs | undefined =>
  date ? dayjs(date) : undefined;

const dayjsToDate = (date: Dayjs | null): Date | undefined =>
  date ? date.toDate() : undefined;

const formatAsDate = (date: Date): string => dayjs(date).format('DD.MM.YYYY');

const formatAsDateTime = (dateTime: Date): string =>
  dayjs(dateTime).format('DD.MM.YYYY, H:mm');

const formatAsDateTimePlain = (dateTime: Date): string =>
  dayjs(dateTime).format('DD.MM.YYYY H:mm');

const formatAsDateTimeExtended = (dateTime: Date): string =>
  dayjs(dateTime).format('dddd, DD.MM.YYYY; H:mm');

const formatAsTime = (time: Date): string => dayjs(time).format('H:mm');

const RANGE_STRING_SEPARATOR = '\u00A0\u2013 ';

// connect start and end value of a date/time range with a no breaking space and n-dash
// s. https://www.annika-lamer.de/so-schreiben-sie-datum-und-uhrzeit-richtig/
// s. https://de.wikipedia.org/wiki/Typografische_Zeichen_in_XML_und_HTML
const getFormattedRangeString = (start = '', end = '') =>
  `${start}${RANGE_STRING_SEPARATOR}${end}`;

/**
 * Convert a zero based weekday index from SUN-SAT to MON-SUN
 */
const sunSatWeekdayToMonSun = (index: number): number => {
  /*
  SUN: 0 -> 6
  MON: 1 -> 0
  TUE: 2 -> 1
  WED: 3 -> 2
  THU: 4 -> 3
  FRI: 5 -> 4
  SAT: 6 -> 5
   */
  const monSun = index - 1;
  return monSun < 0 ? 6 : monSun;
};

const getWeekInMonth = (dayOfMonth: number): number =>
  Math.floor(dayOfMonth / 7.0);

const addNumberOfDays = (numberOfDays: number) => {
  const newDate = new Date();
  newDate.setDate(newDate.getDate() + numberOfDays);
  return newDate;
};

const addHours = (numberOfHours: number) => {
  const newDate = new Date();
  newDate.setHours(newDate.getHours() + numberOfHours);
  return newDate;
};

export {
  dateToDayjs,
  dayjsToDate,
  formatAsDate,
  formatAsDateTime,
  formatAsTime,
  formatAsDateTimePlain,
  sunSatWeekdayToMonSun,
  getWeekInMonth,
  addNumberOfDays,
  addHours,
  formatAsDateTimeExtended,
  getFormattedRangeString,
  RANGE_STRING_SEPARATOR
};
