import { Button, Stack, Typography } from '@mui/material';
import { useCrypto } from 'context/crypto/crypto';
import { useGetPortalOrganizationProfileHref } from 'context/user';
import { DialogGeneric } from 'feature';
import { useOnboardingLogic, KEY } from 'feature/onboarding';
import { getTextIn } from 'localization';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { readPrivateKey } from 'openpgp';

const getCryptoText = getTextIn('crypto');

export type PrivateKeyDialogProps = {
  showDialog: boolean;
  closeDialog: () => void;
  organizationId: string;
  isOrganizationAdmin: boolean;
};

const PrivateKeyDialog = ({
  showDialog,
  closeDialog,
  organizationId,
  isOrganizationAdmin
}: PrivateKeyDialogProps) => {
  const { addPrivateKey } = useCrypto();
  const { addFlag } = useOnboardingLogic();
  const userPortalHref = useGetPortalOrganizationProfileHref(
    organizationId,
    '&showEncryption'
  );
  const [newKeyFile, setNewKeyFile] = useState<File>();

  const receivePrivateKey: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget
  }) => {
    if (currentTarget?.files) {
      setNewKeyFile(currentTarget?.files[0]);
    }
  };

  const reader = new FileReader();
  reader.addEventListener(
    'load',
    () => {
      const result = reader.result as string;
      readPrivateKey({
        armoredKey: result
      })
        .then(() => {
          addPrivateKey(result);
          enqueueSnackbar(getCryptoText('keyAdded'), {
            variant: 'success'
          });
          addFlag(KEY);
        })
        .catch(() =>
          enqueueSnackbar(getCryptoText('NotAKeyError'), {
            variant: 'error'
          })
        );
    },
    false
  );

  useEffect(() => {
    if (newKeyFile) {
      reader.readAsText(newKeyFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newKeyFile]);

  return (
    <DialogGeneric
      open={showDialog}
      title={getCryptoText('secureEncryption')}
      onClose={closeDialog}
      prefix="provide-private-key"
      hasActionButtons={false}
    >
      <Stack
        direction="column"
        data-cy="event-details"
        spacing={2}
        sx={{ py: 2 }}
      >
        <Typography>{getCryptoText('encryptionIntro')}</Typography>
        <Typography>
          {isOrganizationAdmin
            ? getCryptoText('addPrivateKeyDialogAdmin')
            : getCryptoText('addPrivateKeyDialogMember')}
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <label htmlFor="private-key-upload-input">
            <input
              data-cy="private-key-upload-input"
              accept=".asc"
              id="private-key-upload-input"
              onChange={receivePrivateKey}
              type="file"
              hidden
            />
            <Button
              data-cy="private-key-upload-button"
              component="div"
              variant="outlined"
              size="small"
            >
              {getCryptoText('chooseKey')}
            </Button>
          </label>
          {isOrganizationAdmin && (
            <Button
              variant="outlined"
              size="small"
              target="_blank"
              href={userPortalHref}
            >
              {getCryptoText('toUserPortal')}
            </Button>
          )}
        </Stack>
      </Stack>
    </DialogGeneric>
  );
};

export default PrivateKeyDialog;
