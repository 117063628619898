import { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useKeycloakAuth } from 'auth';
import { useUser } from 'context/user';
import { useProject } from 'context/project';
import { DialogGeneric } from 'feature';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import useProjectNavigate from 'utils/useProjectNavigate';
import { getTextIn, getTextFx, getNode } from 'localization';
import { useDeleteOrganization } from 'feature/hooks';

const getTextDeleteAccount = getTextIn('account-data-deleteAccount');

const DeleteAccountDialog = ({
  open,
  onClose,
  onConfirm
}: {
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}) => {
  const { perspective } = useUser();
  const deleteActionText = getTextDeleteAccount('action');

  return (
    <DialogGeneric
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={deleteActionText}
      confirmText={deleteActionText}
      confirmColor="error"
      maxWidth="sm"
      prefix="delete-account-dialog"
    >
      {getNode(
        'getDialogQuestion',
        'account-data-deleteAccount'
      )({
        children: (
          <Typography variant="formLabel" color="primary">
            {perspective?.label ?? ''}
          </Typography>
        )
      })}
    </DialogGeneric>
  );
};

const DeleteAccount = () => {
  const [open, setOpen] = useState(false);
  const { perspective } = useUser();
  const { project } = useProject();
  const { logout } = useKeycloakAuth();
  const { navigateToHome } = useProjectNavigate();

  const projectName = project?.name ?? '';
  const organizationId = perspective?.id ?? '';

  const deleteOrganization = useDeleteOrganization();
  const deleteActionText = getTextDeleteAccount('action');

  const handleConfirm = useCallback(() => {
    if (organizationId) {
      deleteOrganization.mutate(organizationId, {
        onSuccess: () => {
          notifyMutationSuccess(
            getTextFx(
              'getSuccess',
              'account-data-deleteAccount'
            )({ projectName })
          );
          navigateToHome();
          logout();
        },
        onError: () => {
          notifyMutationError(getTextDeleteAccount('error'));
        }
      });
    }

    setOpen(false);
  }, [deleteOrganization, logout, navigateToHome, organizationId, projectName]);

  return (
    <>
      <Grid item>
        <Typography component="div" variant="sectionTitle" sx={{ mb: 0.75 }}>
          {deleteActionText}
        </Typography>

        <Typography>
          {getTextFx('getInfo', 'account-data-deleteAccount')({ projectName })}
        </Typography>

        <Button
          variant="outlined"
          sx={{ mt: 2.5 }}
          onClick={() => setOpen(true)}
        >
          {deleteActionText}
        </Button>
      </Grid>

      <DeleteAccountDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default DeleteAccount;
