import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useKeycloakAuth } from 'context/auth';
import { useUser } from 'context/user';
import { useOnboarding } from 'context/onboarding';
import OnboardingDialog from './onboarding-dialog';
import OnboardingPanel from './onboarding-panel';
import { useOnboardingLogic } from './logic';
import { INIT, PROFILE, KEY, type StepType } from './config';
import { getPath } from 'localization';
import { useBooking } from 'context/booking';

const Onboarding = ({
  children
}: {
  children: React.ReactElement[] | React.ReactElement;
}) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useKeycloakAuth();
  const { perspective } = useUser();

  const { successMode, setSuccessMode, type, setType } = useOnboarding();
  const { onboardingDone, getAllStepsDone } = useOnboardingLogic();

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (successMode) {
      setOpen(true);
    }
  }, [successMode]);

  const { isOffersBookable: showKeyStep } = useBooking();
  const allStepsDone = getAllStepsDone(showKeyStep ? undefined : KEY);

  const getsOnboarding = isAuthenticated && perspective.isOrganization;
  const isInvitationScreen = pathname.split('/')[1] === getPath('invitation');

  const showDialog =
    getsOnboarding &&
    !isInvitationScreen &&
    (type === INIT ? !allStepsDone : !onboardingDone) &&
    (type !== KEY || showKeyStep);

  const showPanel =
    getsOnboarding && !isInvitationScreen && !onboardingDone && type !== INIT;

  const setTypeAndOpen = (name: StepType) => {
    setType(name);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);

    setTimeout(() => {
      setSuccessMode(false);
    }, 100);

    // set a different type than the INIT type, for showing up the panel
    if (type === INIT) {
      setType(PROFILE);
    }
  };

  return (
    <>
      {children}
      {showDialog && (
        <OnboardingDialog
          open={open}
          type={type}
          onClose={onClose}
          showKeyStep={showKeyStep}
          setType={setType}
        />
      )}
      {showPanel && (
        <OnboardingPanel
          setType={setTypeAndOpen}
          showKeyStep={showKeyStep}
          allStepsDone={allStepsDone}
        />
      )}
    </>
  );
};

export default Onboarding;
