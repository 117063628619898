import { Stack } from '@mui/material';
import NavigationLinks from './nav-links';
import { NavLinksBasicProps } from './types';

const NavTopbarLinks = ({ mainRoute, navigationLinks }: NavLinksBasicProps) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        height: '100%',
        font: '16px/20px Roboto',
        display: { xs: 'none', sm: 'flex' }
      }}
    >
      <NavigationLinks
        navigationLinks={navigationLinks}
        mainRoute={mainRoute}
        variant="topbar"
      />
    </Stack>
  );
};

export default NavTopbarLinks;
