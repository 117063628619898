import { ReactNode } from 'react';
import { Reason } from '@SLR/solution3-sdk';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  formatAsDateTimePlain,
  formatAsTime,
  getFormattedRangeString
} from 'utils/date';
import { transformToFragment } from 'utils/fragments-utils';
import { Locales } from './types';
import { getPath } from 'localization';

const LOCALE_DEFAULT = 'de-DE';

/**
 * Config file
 *
 *    - standard localization with the LOCALE_DEFAULT
 *    - custom localizations with the project locale and the different custom values for the specific branch
 *
 *  for a better and fast identification of the different value types:
 *    - functions with key prefix 'get'
 *    - react nodes with key prefix 'getNode'
 *    - standard text without key prefix
 */
const config: Locales = {
  [LOCALE_DEFAULT]: {
    solutionName: 'Lösung 3', // exists also as error text (necessary)
    home: 'Startseite',
    members: 'Wer ist dabei?',
    allProviders: 'Alle Anbieter',
    allSeekers: 'Alle Nachfrager',
    participate: 'Mitmachen',
    organizations: 'Organisationen',
    profilePage: 'Profilseite',
    organizationProfile: 'Organisationsprofil',
    noParticipantsYet: 'Es gibt noch keine Beteiligten.',
    editorButtonH2: 'Überschrift',
    contact: {
      contact: 'Kontakt',
      title: 'Kontaktiere uns',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail Adresse',
      subject: 'Betreff',
      content: 'Nachricht',
      privacyPolicy: 'Datenschutzerklärung',
      acceptPrivacyPolicy: 'Ich akzeptiere die Bestimmungen der',
      send: 'Senden',
      successMessage:
        'Deine Nachricht wurde erfolgreich an die Projektverwalter verschickt.',
      errorMessage: 'Nachricht konnte nicht gesendet werden.',
      reportAccessibility: 'Barrierefreiheitsproblem melden',
      getAccessibilityParams: ({
        pageTitle,
        url
      }: {
        pageTitle: string;
        url: string;
      }) => `?subject=Barrierefreiheitsproblem auf "${pageTitle}"&body=${url}`,
      getAccessibilityBody: ({ url }: { url: string }) =>
        `Hallo, ich habe ein Barrierefreiheitsproblem auf ${url} gefunden.`
    },
    terms: 'Nutzungsbedingungen',
    imprint: 'Impressum',
    accessibility: 'Barrierefreiheitserklärung',
    login: 'Anmelden',
    logout: 'Abmelden',
    datenschutzInfo: 'Datenschutzinformationen',
    privacy: 'Datenschutz',
    privacyPolicy: 'Datenschutzerklärung',
    apply: 'Anwenden',
    save: 'Speichern',
    cancel: 'Abbrechen',
    delete: 'Löschen',
    removeAll: 'Alles löschen',
    back: 'Zurück',
    forward: 'Weiter',
    send: 'Senden',
    add: 'Hinzufügen',
    navBarSearchBarPlaceholder: 'Suche…',
    projectFallback: 'Das Projekt',
    offers: 'Angebote',
    events: 'Termine',
    more: 'Mehr ERFAHREN',
    requiredField: '* Pflichtfeld',
    requiredFields: '* Pflichtfelder',
    numberPerPage: 'Anzahl pro Seite',
    noMatchingOptions: 'Keine passenden Vorschläge gefunden',
    footer: {
      contactTitle: 'Adresse und Kontakt',
      hintTitle: 'Förderhinweis',
      hintIESE:
        ' wird vom Fraunhofer IESE entwickelt und betrieben. Das Fraunhofer IESE gehört zu den weltweit führenden Forschungs\u00ADeinrichtungen auf dem Gebiet der Software- und System\u00ADentwicklungs\u00ADmethoden.',
      hintSLR:
        'Es ist Teil des Projektes Smarte.Land.Regionen. Mit dem Modellvorhaben unterstützt das Bundesministerium für Ernährung und Landwirtschaft (BMEL) sieben einzelne Landkreise bei der Entwicklung und Umsetzung digitaler Lösungen im ländlichen Raum.',
      linksTitle: 'Nützliche Links',
      support: 'Support',
      imgAltText:
        'Das Logo vom Bundesministerium für Ernährung und Landwirtschaft und das Logo vom Projektträger Bundesanstalt für Landwirtschaft und Ernährung',
      phone: 'Telefon',
      email: 'E-Mail',
      address: 'Adresse'
    },
    user: {
      myProfile: 'Mein Profil',
      manageAccount: 'Konto verwalten',
      usePortalAs: 'Portal nutzen als',
      changed: 'Du agierst nun als'
    },
    image: {
      placeholder: 'Bild Platzhalter'
    },
    edit: {
      addURL: 'Bitte URL des Link eingeben',
      edit: 'Bearbeiten',
      editToggleLabel: 'Bearbeitungsmodus',
      editToggleAriaLabel: 'Bearbeitungsmodus aktivieren oder deaktivieren',
      editorPlaceholder: 'Hier ist Platz für euren Text',
      editSuccess: 'Änderungen wurden gespeichert.',
      editTextError: 'Änderungen des Texts fehlgeschlagen.',
      editError: 'Änderungen fehlgeschlagen',
      projectSloganLabel: 'Titel der Startseite',
      projectSloganHelpText: 'Maximal 150 Zeichen',
      projectSloganError: 'Bitte einen Titel eingeben.',
      noTextYet: 'Es gibt noch keine Beschreibung',
      editHeroImage: {
        title: 'Titelbild bearbeiten',
        prefix: 'edit-hero-image-modal',
        textField: 'Alternativtext',
        helperAdd:
          'Füge durch Klicken auf den Button "Bild hochladen" ein neues Bild hinzu.\nDie Datei darf eine Größe von 5 MB nicht überschreiten.',
        helperUpdate:
          'Aktualisiere das Bild durch Klicken auf den Button "Ändern".\nDie Datei darf eine Größe von 5 MB nicht überschreiten.',
        bannerHelperAddition:
          'Das ideale Seitenverhältnis für das Titelbild ist 16:9.',
        helperError: 'Bitte einen Alternativtext eingeben.',
        helperText: 'Max. 255 Zeichen',
        upload: 'Bild hochladen',
        change: ' ändern',
        savingSuccess: 'Bild wurde gespeichert',
        savingFail: 'Bild konnte nicht gespeichert werden',
        uploadFail: 'Das Bild konnte nicht hochgeladen werden.',
        fileTooLargeError: 'Die Datei ist zu groß.',
        badFormatError: 'Das Dateiformat wird nicht unterstützt.',
        altTextFallback: 'Dekoratives Bild',
        uploadAltText: 'Neu hochgeladenes Bild',
        placeholderImage: 'Bild Platzhalter',
        maxSize: 'Maximale Dateigröße: 5MB'
      }
    },
    role: {
      user: 'Privatperson',
      users: 'Privatpersonen',
      organization: 'Organisation',
      organizations: 'Organisationen',
      provider: 'Anbieter',
      providers: 'Anbieter',
      seeker: 'Nachfrager',
      seekers: 'Nachfrager'
    },
    info: {
      patientInfo: 'Informationen für Patienten',
      howItWorksSectionTitle: 'Wie funktioniert es?',
      whatToAchieveSectionTitle: 'Was wir erreichen wollen',
      patientInfoShort: 'Info für Patienten',
      doctorInfoShort: 'Info für Hausärzte',
      providerInfoShort: 'Info für Anbieter',
      providerInfo: 'Informationen für Anbieter',
      doctorInfo: 'Informationen für Hausärzte',
      servicesTitle: 'Was sind meine Mehrwerte?',
      patientServicesTitle: 'Welche Angebote gibt es?',
      patientHelpTitle: 'Wie wird mir geholfen?',
      howToParticipateTitle: 'Wie kann ich mitmachen?',
      moreProviders: 'Unsere Anbieter',
      patientProvidersText: 'Welche Anbieter gibt es?',
      patientParticipate: 'Wie finde ich ein Angebot?',
      patientMore: 'Was ist sonst noch wichtig?',
      helpAndContact: 'Hilfe und Kontakt',
      helpAndContactInfo:
        'Wenn Sie Fragen haben oder Hilfe benötigen, gelangen Sie über den Button "Kontakt" zu unserem Kontaktformular. Hier können Sie eine generelle Anfrage stellen, oder sich bei Fragen mit uns in Verbindung setzen. Unser Team steht Ihnen zur Verfügung, um Ihnen bei allen Problemen zu helfen, die bei der Nutzung der Anwendung auftreten können. Wir schätzen Ihr Feedback und setzen uns dafür ein, dass unsere Anwendung sowohl für Anbieter als auch für Nutzer die bestmögliche Erfahrung bietet.'
    },
    process: {
      info: ({ projectName }: { projectName: string }) =>
        `Was ist ${projectName}?`,
      diagnose: 'Medizinischer Befund',
      contact: 'Hilfe / Kontakt',
      searchOffer: 'Suche von Angeboten',
      searchProvider: 'Suche von Anbietern',
      request: 'Leistung anfragen',
      treatment: 'Leistung wahrnehmen'
    },
    profile: {
      hint: 'Hinweis',
      titleImage: 'Titelbild',
      profileImage: 'Profilbild',
      changeProfileSuccess: 'Profil erfolgreich gespeichert.',
      changeProfileError: 'Speichern des Profils fehlgeschlagen.',
      editProfile: 'Profil bearbeiten',
      saveProfile: 'Profil speichern',
      address: 'Anschrift',
      street: 'Straße',
      houseNumber: 'Hausnummer',
      zipCode: 'Postleitzahl',
      city: 'Ort',
      about: 'Informationen über uns',
      aboutPlaceholder: 'Beschreiben Sie Ihre Organisation',
      qualification: 'Qualifikationen',
      qualificationPlaceholder: 'Beschreiben Sie Ihre Qualifikationen',
      focus: 'Behandlungsschwerpunkte',
      focusPlaceholder: 'Beschreiben Sie Ihre Behandlungsschwerpunkte',
      businessHours: 'Behandlungszeiten',
      changeBusinessHoursSuccess: 'Die Behandlungszeiten wurden geändert.',
      changeBusinessHoursError:
        'Änderung der Behandlungszeiten fehlgeschlagen.',
      imageGallery: 'Bildergalerie',
      editImageGallery: 'Bildergalerie bearbeiten',
      imageGalleryMore: 'Weitere Bilder',
      deleteImage: 'Bild löschen',
      uploadImage: 'Bild hochladen',
      maxUploadSize: '(Max. 5MB)',
      galleryUpdateError: 'Gallery update error',
      description: 'Beschreibung',
      userPortalHint:
        'Die Stammdaten wie Name, Webseite, E-Mail, Telefon und Profilbild kannst du im Nutzerportal ändern.',
      userPortalCaption: 'Zum Nutzerportal',
      ourOffers: 'Unsere Angebote',
      moreOffers: 'Weitere Angebote'
    },
    offer: {
      offer: 'Angebot',
      offers: 'Angebote',
      offersFrom: 'Angebote von',
      newOffer: 'Neues Angebot',
      newOffers: 'Neue Angebote',
      moreOffers: 'Mehr Angebote',
      matchingOffers: 'Passende Angebote',
      furtherOffers: 'Weitere Angebote',
      createOffer: 'Angebot erstellen',
      editOffer: 'Angebot bearbeiten',
      deleteOffer: 'Angebot löschen',
      expired: 'Beendet',
      searchBarPlaceholder:
        'Nach welchem Angebot suchst du? Gebe hier einen Suchbegriff ein.',
      noOfferResults:
        'Leider konnte für deine Eingabe kein Angebot gefunden werden.',
      noOfferResultsHint:
        'Versuche es mit einem anderen Suchbegriff oder einem anderen Filter.',
      deleteSuccess: 'Angebot wurde gelöscht',
      deleteError:
        'Angebot konnte nicht gelöscht werden, da noch Termine in der Zukunft bestehen',
      bookingHint:
        'Wähle Zeit, Ort und Angebotstyp, um Angebote buchen zu können',
      createOffersHint:
        'Um Leistungen erstellen zu können, muss ein Schlüssel erstellt werden. ',
      createOffersHintAdmin: 'Schlüssel im Nutzerportal erstellen.',
      createOffersHintMember:
        'Bitte den Administrator deiner Organisation, um einen Schlüssel zu erstellen.',
      details: {
        saveOffer: 'Angebot speichern',
        uploadImage: 'Auswählen',
        title: 'Angebotstitel',
        titlePlaceholder: 'Gib deinem Angebot einen aussagekräftigen Titel.',
        titleHelperText: 'max. 100 Zeichen',
        description: 'Beschreibung',
        descriptionLabel: 'Beschreibung des Angebots',
        descriptionPlaceholder: 'Hier kannst du dein Angebot beschreiben.',
        requirements: 'Voraussetzungen',
        requirementsPlaceholder:
          'Hier kannst du Voraussetzungen für dein Angebot nennen.',
        location: 'Ort des Angebots',
        mobileLocation: {
          title: 'Hausbesuch',
          info: 'Das Angebot wird mobil durchgeführt, d.h. du kommst zu der Person, die das Angebot in Anspruch nimmt.',
          hint: 'In welchen Ortschaften möchtest du das Angebot anbieten?',
          locations: 'Orte',
          availableLocations: 'Verfügbare Ortschaften',
          availableLocationsText: 'In folgenden Orten verfügbar: ',
          atLeastOne: 'Es muss mindestens eine Ortschaft ausgewählt werden.'
        },
        fixedLocation: {
          title: 'An einem festen Ort durchführbares Angebot',
          info: 'Das Angebot wird an einem festen Ort durchgeführt, d.h. die Person, die das Angebot in Anspruch nimmt, kommt zu diesem Ort.',
          street: 'Straße',
          houseNumber: 'Hausnummer',
          city: 'Ort',
          zipCode: 'Postleitzahl',
          description: 'Ortsbeschreibung'
        },
        onlineLocation: {
          title: 'Online durchführbares Angebot',
          info: 'Das Angebot wird online durchgeführt. Es besteht die Möglichkeit direkt einen Zugangslink zu hinterlegen, über den das Angebot in Anspruch genommen wird.',
          url: 'Zugangslink',
          urlHelperText: 'z.B. ein Link zu einem Teamsmeeting'
        },
        atLeastOneLocation: 'Es muss mindestens ein Ort angegeben werden',
        additionalInformation: 'Weitere Informationen',
        time: 'Zeitangabe des Angebots',
        duration: 'Dauer',
        durationHelperText: 'Dauer der Durchführung',
        datetime: 'Datum & Uhrzeit',
        startTime: 'Von',
        endTime: 'Bis',
        times: 'Zeiten',
        fixedTime: {
          title: 'Fester Termin',
          info: 'Das Angebot findet an einem festen Tag zu einer festen Uhrzeit statt. Damit die Termine genauer angefragt werden können, muss auch die Dauer angegeben werden.'
        },
        timeRange: {
          title: 'Zeitfenster',
          info: 'Das Angebot wird in einem festgelegten Zeitfenster angeboten.'
        },
        businessTime: {
          title: 'Zu den Behandlungszeiten',
          info: 'Das Angebot orientiert sich an den Behandlungszeiten, die im Profil eingetragen sind. Damit die Termine genauer angefragt werden können, muss auch die Dauer angegeben werden.'
        },
        online: 'Online',
        toOnlineLocation: 'Zur Online Veranstaltung',
        noOnlineLocation:
          'Um Zugriff auf das Angebot zu erhalten, setze dich mit dem Anbieter in Verbindung.',
        getNextEvent: ({ date }: { date: string }) => `Nächster Termin ${date}`,
        getLastEvent: ({ date }: { date: string }) => `Letzter Termin ${date}`,
        getEventDuration: ({ duration }: { duration: string }) =>
          `ca. ${duration} h`,
        getEventTimeRange: ({ from, to }: { from: string; to: string }) =>
          `${getFormattedRangeString(from, to)} Uhr`,
        getEventTime: ({ from }: { from: string }) => `${from} Uhr`,
        getRecurrenceError: ({ from, to }: { from?: string; to?: string }) =>
          `Enddatum kann nicht ${to ? 'nach' : 'vor'} dem ${
            to || from
          } liegen.`,
        durationFormatError: '${path} muss eine gültige Zeitdauer sein',
        durationRangeError: '${path} darf maximal 8 Stunden betragen',
        delete: {
          question: 'Bist du sicher, dass du dieses Angebot löschen möchtest?',
          adminQuestion:
            'Bist du dir sicher, dass du dieses Angebot endgültig löschen möchtest?',
          adminReasonPlaceholder: 'Begründung (optional)',
          adminReasonHelperText:
            'Hier kannst du eine Begründung angeben, weshalb dieser Inhalt gelöscht wird.'
        },
        pictureTitle: 'Angebotsbild',
        editPictureTitle: 'Angebotsbild bearbeiten',
        category: 'Kategorie',
        pleaseSelect: 'Bitte wählen…',
        targetGroup: 'Zielgruppe'
      },
      booking: {
        book: 'Buchen',
        toBookingList: 'Zur Liste',
        added: 'hinzugefügt',
        bookingList: 'Behandlungsliste',
        successTitle: 'Termin bestätigt',
        errorMessage: 'Buchung fehlgeschlagen!',
        timeslots: {
          requestNext: 'Neue Termine anfordern',
          requestPrev: 'Zurück zu den ersten Terminen',
          noAppointments: 'Keine Termine verfügbar',
          noAppointmentsAnymore:
            'Es sind leider keine Terminvorschläge mehr für deine Anfrage vorhanden. Bitte starte eine neue Anfrage mit einer anderen Zeitangabe oder wähle andere Angebote.',
          fetchTimeslotsError: 'Termine konnten nicht abgerufen werden.'
        },
        firstName: 'Vorname',
        lastName: 'Nachname',
        providerLocation: 'Von Anbieter festgelegter Ort',
        confirm: 'Termin buchen',
        chooseTimeslot: 'Termin wählen',
        overview: 'Zusammenfassung',
        offers: 'Leistungen',
        offer: 'Leistung',
        patientData: 'Patientendaten',
        location: 'Behandlungsort',
        videoParticipation: 'Videoteilnahme der Ärztin oder des Arztes',
        bookingListEmpty: 'Deine Behandlungsliste ist leer.',
        withVideo: 'Mit Videoteilnahme',
        mobileLocation: 'Hausbesuch',
        personLabel: 'Medizinische Fachkraft:',
        hour: 'Stunde',
        hours: 'Stunden',
        minute: 'Minute',
        minutes: 'Minuten',
        getNodeSuccessDescription: ({
          date,
          time,
          isHomevisit,
          location
        }: {
          date: string;
          time: string;
          isHomevisit: boolean;
          location: string;
        }) => {
          return (
            <Typography>
              Dein Termin am <b>{date}</b> um <b>{time} Uhr</b>{' '}
              {isHomevisit ? 'als' : 'an der Adresse'}{' '}
              <b>{isHomevisit ? 'Hausbesuch' : location}</b> wurde erfolgreich
              gebucht.
            </Typography>
          );
        }
      }
    },
    compass: {
      listTitle: 'Kompass',
      category: 'Rubrik',
      categoryDefaultTitle: 'Kompass Rubrik',
      noCategoriesYet: 'Es gibt noch keine Rubriken.',
      createCategory: 'Rubrik erstellen',
      createCompassCategory: 'Kompass Rubrik erstellen',
      editCategory: 'Rubrik bearbeiten',
      editCompassCategory: 'Kompass Rubrik bearbeiten',
      deleteCategory: 'Rubrik löschen',
      deleteCompassCategory: 'Kompass Rubrik löschen',
      deleteCompassCategorySuccess: 'Kompass Rubrik wurde gelöscht',
      deleteCompassCategoryError: 'Löschen der Kompass Rubrik fehlgeschlagen',
      getNodeDeleteQuestion: ({ categoryName }: { categoryName: string }) => (
        <Box>
          Bist du dir sicher, dass du die Rubrik
          <strong> {categoryName} </strong>löschen möchtest? Alle Artikel dieser
          Rubrik werden gelöscht. Diese Aktion kann nicht rückgängig gemacht
          werden.
        </Box>
      ),

      create: 'Erstellen',
      createCategorySuccess: 'Rubrik wurde erstellt.',
      createCategoryError: 'Rubrik konnte nicht erstellt werden.',
      uploadImage: 'Bild hochladen',
      title: 'Titel',
      titleHelperText: 'Maximal 40 Zeichen',
      titleHelperError: 'Bitte einen Titel eingeben.',
      titleExistsHelperError:
        'Diese Rubrik existiert bereits. Bitte einen anderen Titel eingeben.',
      createArticle: 'Artikel erstellen',
      createArticleSuccess: 'Der Artikel wurde erfolgreich erstellt',
      createArticleError: 'Erstellen des Artikels fehlgeschlagen',
      editArticle: 'Artikel bearbeiten',
      deleteArticle: 'Artikel löschen',
      deleteCompassArticle: 'Kompass Artikel löschen',
      noArticlesYet: 'Es gibt noch keine Artikel.',
      articleTitle: 'Titel des Artikels',
      articleTitleHelperText: 'Maximal 100 Zeichen',
      articleContent: 'Beschreibung des Artikels',
      articleContentPlaceholder:
        'Hier können Sie ihren Kompass Artikel schreiben.',
      keywords: 'Schlagwörter',
      articleKeywords: 'Passende Angebote verlinken',
      articleKeywordsDescription:
        'Gib passende Schlagwörter an, um geeignete Angebote zu diesem Artikel zu verlinken',
      articleKeywordsPlaceholder: 'Schlagwörter',
      deleteArticleSuccess: 'Der Artikel wurde erfolgreich gelöscht',
      deleteArticleError: 'Löschen des Artikels fehlgeschlagen',
      getNodeDeleteArticleQuestion: ({
        articleName
      }: {
        articleName: string;
      }) => (
        <Box>
          Bist du dir sicher, dass du den Artikel
          <strong> {articleName} </strong>löschen möchtest? Diese Aktion kann
          nicht rückgängig gemacht werden.
        </Box>
      )
    },
    report: {
      report: 'Melden',
      description: 'Beschreibung',
      descriptionHelpertext:
        'Du kannst deiner Meldung optional eine Beschreibung anhängen',
      emailRequired: 'E-Mail Adresse *',
      emailInquiryHelpertext:
        'Bitte gib deine E-Mail Adresse für Rückfragen an',
      error: 'Melden fehlgeschlagen.',

      offer: {
        report: 'Angebot melden',
        successful: 'Angebot erfolgreich gemeldet.',
        why: 'Warum möchtest du dieses Angebot melden?',
        reasonWhy: 'Du meldest dieses Angebot aus folgendem Grund:'
      },

      profile: {
        report: 'Profil melden',
        successful: 'Profil erfolgreich gemeldet.',
        why: 'Warum möchtest du dieses Profil melden?',
        reasonWhy: 'Du meldest dieses Profil aus folgendem Grund:'
      },

      reasons: {
        [Reason.Offense]: 'Beleidigung',
        [Reason.IllegalContent]: 'Illegale Inhalte',
        [Reason.ViolationOfTermsOfUse]: 'Verstoß gegen die Nutzungsbedingungen',
        [Reason.PersonalData]: 'Persönliche Daten',
        [Reason.Other]: 'Sonstiges'
      }
    },

    filter: {
      filterAndSort: 'Filtern & Sortieren',
      selected: 'Ausgewählte Filter',
      delete: 'Filter löschen',
      alter: 'Filter ändern',
      sortBy: 'Sortieren nach',
      timeRange: 'Zeitraum',
      duration: 'Dauer',
      location: 'Ort',
      locationType: 'Angebotstyp',
      fixed: 'Vor-Ort Angebot',
      online: 'Online Angebot',
      mobile: 'Hausbesuch', // TODO
      withoutLocationType: 'Ohne Typangabe',
      category: 'Kategorie',
      targetGroup: 'Zielgruppe',
      provider: 'Ausgewählter Anbieter',
      removeFilterDialogTitle: 'Filter löschen und Behandlungsliste leeren',
      removeFilterDialogText:
        'Möchtest du wirklich den Filter löschen? Dadurch wird die Behandlungsliste geleert.',
      removeAllFiltersDialogText:
        'Möchtest du wirklich alle Filter löschen? Dadurch wird auch die Behandlungsliste geleert.',
      alterFilterDialogTitle: 'Filter ändern und Behandlungsliste leeren',
      alterFiltersDialogText:
        'Möchtest du wirklich den Filter ändern? Dadurch wird die Behandlungsliste geleert.',
      geoArea: {
        sliderInfo: 'Umkreis über den Slider oder Eingabe einer Zahl ändern',
        area: 'Umkreis in km',
        areaPlaceholder: 'Ganzer Ort',
        errorInputNecessary: 'Bitte einen Ort wählen.',
        getSelectedGeoArea: ({
          location,
          distance
        }: {
          location: string;
          distance?: string;
        }) => {
          return `${location}${distance ? ` (+${distance}km)` : ''}`;
        }
      },
      time: {
        asOf: 'ab',
        until: 'bis',
        from: 'Von',
        to: 'Bis',
        range: 'Zeitraum',
        rangeFrom: 'Zeitraum von',
        rangeTo: 'Zeitraum bis',
        pointInTime: 'Zeitpunkt',
        // error helper texts
        validCustomDuration: 'h:mm, max: 08:00',
        validCustomDurationMin: 'h:mm, min: 00:10',
        insertValidValue: 'Bitte einen gültigen Wert eingeben.',
        maxValidRange: 'Zeitraum max. 90 Tage.',
        valueDependsOnStartValue: 'Wert hängt von gültigem Startwert ab.',
        getTimeValueFrom: ({
          min,
          onlyTime = false
        }: {
          min: string | Date;
          onlyTime: boolean;
        }) => {
          return `Wert ab ${
            typeof min === 'string'
              ? min
              : (onlyTime ? formatAsTime : formatAsDateTimePlain)(min)
          }${onlyTime ? ' h' : ''} eingeben.`;
        },
        empty: 'Bitte einen Wert eingeben'
      }
    },
    settings: {
      manage: 'Lösung verwalten',
      menu: 'MENÜ',
      roles: 'Rollen',
      huhu: 'huhu PLATTFORM',
      general: {
        general: 'Allgemein',
        generalSettings: 'Allgemeine Einstellungen',
        title: 'Titel',
        changeTitleSuccess: 'Titel wurde geändert.',
        changeTitleError: 'Änderung des Titels fehlgeschlagen.',
        hideTitleToggleLabel: 'Titel verbergen und nur das Logo anzeigen',
        titleHelperText: 'Max. 50 Zeichen',
        titleHelperError: 'Bitte einen Titel eingeben.'
      },
      appearance: {
        appearance: 'Erscheinungsbild',
        appearanceSettings: 'Erscheinungsbild anpassen',
        changeAppearanceSuccess: 'Erscheinungsbild wurde erfolgreich angepasst',
        changeAppearanceError: 'Erscheinungsbild anpassen fehlgeschlagen',
        choosePrimaryColor: 'Projektfarbe festlegen',
        chooseSecondaryColor: 'Akzentfarbe festlegen',
        colorHelperText:
          'Bitte Farbe als 6-stelligen Hexadezimalcode eingeben, z.B: #27537A oder #CD8E7B',
        logo: 'Logo'
      },
      visibility: {
        visibilities: 'Sichtbarkeiten',
        settings: 'Sichtbarkeiten einstellen',
        helperTextProviders: 'Wie sollen die Anbieter gelistet werden?',
        helperTextSeekers: 'Wie sollen die Nachfrager gelistet werden?',
        helperTextOffers: 'Wie sollen die Angebote gelistet werden?',
        everyone: 'Öffentlich',
        onlyRegistered: 'Nur für registrierte Nutzer',
        changeVisibilitySuccess: 'Sichtbarkeiten wurden geändert.',
        changeVisbilityError: 'Änderung der Sichtbarkeiten fehlgeschlagen.'
      },
      participants: {
        participants: 'Beteiligte',
        allParticipants: 'Alle Projektbeteiligten',
        inviteParticipants: 'Beteiligte einladen',
        inviteOrganization: 'Organisation einladen',
        invite: 'einladen',
        email: 'E-Mail Adresse des Ansprechpartners',
        success: 'Beteiligter wurde eingeladen',
        failure: 'Einladung fehlgeschlagen',
        chooseRole: 'Rolle wählen',
        searchOrganization: 'Nach vorhandener Organisation suchen',
        moreInfo: 'Weitere Informationen',
        organizationSearchLabel: 'Suche nach dem Namen der Organisation',
        organizationSearchNoResults: 'Keine bestehende Organisation gefunden.',
        organizationSearchFailed:
          'Es konnte keine bestehende Organisation gefunden werden. Möchtest du eine Organisation mit diesem Namen gründen?',
        getInviteWithName: ({ name }: { name: string }) => {
          return `Ja, ich möchte die Organisation "${name}" gründen`;
        },
        organizationName: 'Name der neuen Organisation',
        organizationNameError:
          'Es muss ein Name oder eine bestehende Organisation angegeben werden.',
        all: 'Alle Projektbeteiligten',
        user: 'Nutzer',
        name: 'Name',
        type: 'Beteiligtentyp',
        roles: 'Rollen',
        created: 'Dabei seit',
        actions: 'Aktionen',
        removeParticipant: 'Beteiligten entfernen',
        editParticipant: 'Beteiligten bearbeiten',
        deleteSuccess: 'wurde gelöscht.',
        deleteFailure: 'konnte nicht gelöscht werden.',
        changeRole: 'Rolle ändern',
        offerLostWarning:
          'Durch den Wechsel der Rolle zu Nachfrager werden alle bestehenden Angebote des Nutzers entfernt.',
        getNodeDeleteHint: ({
          isUser,
          participantType,
          name,
          projectName
        }: {
          isUser: boolean;
          participantType: string;
          name: string;
          projectName?: string;
        }) => {
          return (
            <>
              <Typography>
                Bist du dir sicher, dass du {isUser ? 'den' : 'die'}{' '}
                {participantType} <b>{name}</b> entfernen möchtest?
              </Typography>
              <Typography>
                Alle Angebote {isUser ? 'des' : 'der'} {participantType} werden
                dadurch aus dem Projekt {projectName} entfernt.
              </Typography>
            </>
          );
        }
      },
      segments: {
        actions: 'Aktionen',
        other: 'Sonstiges',
        getAdd: ({ singular }: { singular: string }) => {
          return `${singular} hinzufügen`;
        },
        getEmpty: ({ singular }: { singular: string }) => {
          return `Noch wurde keine ${singular} hinzugefügt`;
        },
        getNotEmpty: ({ singular }: { singular: string }) => {
          return `${singular} kann nicht leer sein`;
        },
        getAlreadyExists: ({ singular }: { singular: string }) => {
          return `${singular} ist bereits vorhanden`;
        },
        getSuccessAction: ({
          singular,
          action
        }: {
          singular: string;
          action: string;
        }) => {
          return `${singular} wurde erfolgreich ${action}`;
        },
        getErrorAction: ({
          singular,
          action
        }: {
          singular: string;
          action: string;
        }) => {
          return `${singular} konnte nicht ${action} werden`;
        },
        create: 'hinzugefügt',
        update: 'bearbeitet',
        delete: 'gelöscht',
        getDelete: ({ singular }: { singular: string }) => {
          return `${singular} löschen`;
        },
        getNodeDeleteQuestion: ({
          segment,
          singular
        }: {
          segment: string;
          singular: string;
        }) => (
          <Stack spacing={3}>
            <Typography>
              Bist du dir sicher, dass du die {singular}
              <strong> {segment} </strong> löschen möchtest?
            </Typography>

            <Typography>
              Alle Angebote, die dieser {singular} zugeordnet sind, werden der{' '}
              {singular}
              <strong> Sonstiges </strong> zugeordnet.
            </Typography>
          </Stack>
        )
      },
      categories: {
        title: 'Kategorien',
        manageTitle: 'Angebotskategorien verwalten',
        segment: 'Kategorie'
      },
      targetGroups: {
        title: 'Zielgruppen',
        manageTitle: 'Zielgruppen verwalten',
        segment: 'Zielgruppe'
      }
    },
    invitation: {
      invitation: 'Einladung',
      alreadyAccepted: 'Einladung wurde bereits bestätigt.',
      createOrganization: 'Organisation anlegen',
      getNodeRegisterText: ({
        orgName,
        projectName
      }: {
        orgName: ReactNode;
        projectName?: string;
      }) => {
        return (
          <>
            Deine Organisation {orgName} wurde zum Projekt {projectName}{' '}
            eingeladen. Damit du loslegen kannst, musst du zunächst deine
            Organisation anlegen.
          </>
        );
      },
      contact: 'Kontakt aufnehmen',
      noOrgRights:
        'Du versuchst auf eine Organisation zuzugreifen, für die du keine Rechte hast. Überprüfe, ob du dich mit dem richtigen Account angemeldet hast und wende dich an die Verwalter des Projekts.',
      congratulation: 'Herzlichen Glückwunsch!',
      getNodeSuccessText: ({
        link,
        projectName
      }: {
        link: ReactNode;
        projectName?: string;
      }) => {
        return (
          <>
            Deine Organisation {link} wurde erfolgreich zum Projekt{' '}
            {projectName} hinzugefügt!
          </>
        );
      },
      getNodeWaitText: ({
        link,
        projectName
      }: {
        link: ReactNode;
        projectName?: string;
      }) => {
        return (
          <>
            Vielen Dank für dein Interesse mit deiner Organisation {link} an dem
            Projekt {projectName} teilzunehmen. Wir prüfen deine Anfrage und
            schalten deine Organisation so schnell wie möglich frei.
          </>
        );
      },
      soon: 'Bald kann es losgehen!',
      acceptanceError: 'Bestätigung konnte nicht gesendet werden.',
      success: 'Herzlich Willkommen!',
      approveTitle: 'Organisation Genehmigung',
      approveSuccess: 'Organisation wurde genehmigt.',
      approveFail: 'Genehmigung fehlgeschlagen'
    },
    account: {
      profile: {
        editProfile: 'Profil bearbeiten'
      },
      offers: {
        offers: 'Angebote'
      },
      data: {
        manageData: 'Daten verwalten',
        changeData: {
          action: 'Daten ändern',
          info: 'Sie können Ihre Daten wie Name, E-Mail Adresse etc. im huhu-Portal abändern.'
        },
        requestData: {
          action: 'Daten anfordern',
          requestCopy: 'Kopien der Daten anfordern',
          info: 'Erhalten Sie eine Kopie der Daten, die wir von Ihnen gespeichert haben. Sie erhalten von uns eine E-Mail mit den gesammelten Daten.',
          getDialogQuestion: ({ children }: { children: ReactNode }) => {
            return (
              <>
                Möchten Sie eine Kopie Ihrer gespeicherten Daten für das unter{' '}
                {children} registrierte Konto anfordern?
              </>
            );
          },
          success: 'Deine Daten wurden angefordert.',
          error: 'Deine Anfrage war nicht erfolgreich.'
        },
        deleteAccount: {
          action: 'Konto löschen',
          getInfo: ({ projectName }: { projectName: string }) => {
            return `Löschen Sie Ihr Konto im ${projectName} endgültig. Durch Löschen Ihres Kontos werden alle erstellten Inhalte und Angebote unwiderruflich gelöscht.`;
          },
          getDialogQuestion: ({ children }: { children: ReactNode }) => {
            return (
              <>
                Möchten Sie wirklich das Konto von {children} und die damit
                verbundenen Inhalte löschen?
              </>
            );
          },
          getSuccess: ({ projectName }: { projectName: string }) => {
            return `Das Konto wurde aus ${projectName} gelöscht.`;
          },
          error: 'Das Konto konnte nicht gelöscht werden.'
        }
      }
    },
    onboarding: {
      profile: 'Dein Profil ausfüllen',
      key: 'Daten schützen',
      offer: 'Dein erstes Angebot',

      profileSeeker: 'Dein Profil ausfüllen',
      keySeeker: 'Daten schützen',
      offerSeeker: 'Ein Angebot finden',

      profileSuccess: 'Du hast dein Profil erfolgreich ausgefüllt',
      keySuccess: 'Du hast deinen digitalen Schlüssel erfolgreich hinterlegt!',
      offerSuccess: 'Du hast dein erstes Angebot erstellt!',

      profileSeekerSuccess: 'Du hast dein Profil erfolgreich ausgefüllt',
      keySeekerSuccess:
        'Du hast deinen digitalen Schlüssel erfolgreich hinterlegt!',
      offerSeekerSuccess: 'Du hast dein erstes Angebot gefunden!',

      success: 'Du hast das Onboarding erfolgreich abgeschlossen!',
      successful: 'Schritt abgeschlossen',
      error: 'Beim Speichern des Onboarding-Status ist ein Fehler aufgetreten.',
      close: 'Schliessen',

      dialog: {
        titleStart: ({ projectName }: { projectName: string }) => {
          return `Willkommen bei ${projectName}`;
        },
        stepsNumber2: 'Zwei',
        stepsNumber3: 'Drei',
        getStepsDescriptionStart: ({
          stepsNumber
        }: {
          stepsNumber: string;
        }) => {
          return `${stepsNumber} einfache Schritte für deinen Einstieg:`;
        },
        getNodeProfile: ({
          profileLink
        }: {
          profileLink: (text: string) => ReactNode;
        }) => {
          return (
            <>
              Fülle dein Profil aus, damit andere Nutzer sich über dich
              informieren können. Ein gepflegtes Profil schafft Vertrauen.
              <br />
              <br /> Dein Profil findest du im Account-Menü. Klicke dafür rechts
              oben auf deinen Namen und wähle "Profil" aus. Dort kannst du über
              "Profil bearbeiten" alle Informationen über dich, deine
              Qualifikationen und Behandlungsschwerpunkte einfügen sowie eine
              Bildergalerie anlegen.
              <br />
              <br /> Hier kommst du direkt zu deinem {profileLink('Profil')}.
            </>
          );
        },
        getNodeKeyAdmin: ({
          projectName,
          portalLink,
          keyLink
        }: {
          projectName: string;
          portalLink: (text: string) => ReactNode;
          keyLink: (text: string) => ReactNode;
        }) => {
          return (
            <>
              Im medizinischen Umfeld ist ein besonderer Schutz sensibler Daten
              notwendig. Dazu nutzt {projectName} digitale Schlüssel um den
              Zugriff auf medizinische Daten nur auf autorisierte Personen zu
              beschränken. Funktionen wie die Terminverwaltung und Buchung sind
              ohne einen Schlüssel nicht möglich.
              <br />
              <br /> Deinen Schlüssel kannst du im
              <span> {portalLink('Nutzerportal')}</span> erstellen und
              verwalten.
              <br />
              <br /> Wenn du einen Schlüssel erstellt hast, kannst du ihn
              <span> {keyLink('hier')}</span> für diese Sitzung hinterlegen.
            </>
          );
        },
        getNodeKey: ({
          projectName,
          keyLink
        }: {
          projectName: string;
          keyLink: (text: string) => ReactNode;
        }) => {
          return (
            <>
              Im medizinischen Umfeld ist ein besonderer Schutz sensibler Daten
              notwendig. Dazu nutzt {projectName} digitale Schlüssel um den
              Zugriff auf medizinische Daten nur auf autorisierte Personen zu
              beschränken. Funktionen wie die Terminverwaltung und Buchung sind
              ohne einen Schlüssel nicht möglich.
              <br />
              <br /> Wende dich an den Administrator deiner Organisation um den
              digitalen Schlüssel zu erhalten.
              <br />
              <br /> Wenn du einen Schlüssel erhalten hast, kannst du ihn
              <span> {keyLink('hier')}</span> für diese Sitzung hinterlegen.
            </>
          );
        },
        getNodeOffer: ({
          offerLink
        }: {
          offerLink: (text: string) => ReactNode;
        }) => {
          return (
            <>
              Erstelle dein erstes Angebot und mache dich mit unserem flexiblen
              Angebots-Editor vertraut. Gib ausreichende Informationen an, damit
              Arztpraxen wissen, welche Leistung dein Angebot umfasst.
              <br />
              <br /> Gehe zu <span>{offerLink('Angebote')}</span> und wähle
              wähle "Angebot erstellen".
            </>
          );
        },
        getNodeOfferSeeker: ({
          offerLink
        }: {
          offerLink: (text: string) => ReactNode;
        }) => {
          return (
            <>
              Sieh dir die Liste der Angebote an und mache dich mit der Suche
              und den Filtermöglichkeiten vertraut.
              <br />
              <br /> Gehe zu den <span>{offerLink('Angebote')}</span>.
            </>
          );
        }
      }
    },
    recurrence: {
      series: 'Serie',
      endDate: 'Enddatum der Serie',
      once: 'Einmalig',
      daily: 'Täglich',
      weekly: 'Wöchentlich',
      everySecondWeek: 'Jede zweite Woche',
      monthly: 'Monatlich'
    },
    calendar: {
      date: 'Datum',
      time: 'Uhrzeit',
      event: 'Termin',
      week: 'Woche',
      work_week: 'Arbeitswoche',
      day: 'Tag',
      month: 'Monat',
      yesterday: 'Gestern',
      tomorrow: 'Morgen',
      today: 'Heute',
      agenda: 'Agenda',
      more: 'mehr',
      noEventsInRange: 'Keine Termine in der Auswahl',
      allDay: 'ganztägig',
      address: 'Adresse',
      patient: 'Patient:in',
      bookedBy: 'gebucht durch',
      offeredBy: 'angeboten durch',
      requestedTimeframe: 'Angefragter Zeitraum',
      oClock: 'Uhr',
      withVideo: 'Mit Videoteilnahme',
      cancelAppointment: 'Termin absagen',
      on: 'am',
      providerNotification:
        'Der Nachfrager wird per Mail darüber benachrichtigt.',
      seekerNotification: 'Der Anbieter wird per Mail darüber benachrichtigt.',
      getNodeDeleteConfirmation: ({ children }: { children: ReactNode }) => {
        return (
          <>
            Möchten Sie wirklich den gebuchten Termin {children}
            absagen?
          </>
        );
      },
      deleteSuccess: 'Termin wurde abgesagt.',
      fetchEventsError:
        'Fehler beim Abrufen der Termine. Vielleicht werden nicht alle Termine angezeigt.'
    },
    crypto: {
      secureEncryption: 'Sichere Verschlüsselung',
      encryptionIntro:
        'Um den Schutz sensibler Daten sicherzustellen benötigst du für diese Funktion einen digitalen Schlüssel. Diesen Schlüssel musst du für jede Sitzung einmalig hinzufügen.',
      addPrivateKeyDialogAdmin:
        'Wähle den vorhandenen Organisations-Schlüssel aus oder gehe zum Nutzerportal um einen Schlüssel für deine Organisation zu erstellen.',
      addPrivateKeyDialogMember:
        'Wähle den vorhandenen Organisations-Schlüssel aus oder wende dich an den Administrator deiner Organisation.',
      chooseKey: 'Schlüssel auswählen',
      toUserPortal: 'Zum Nutzerportal',
      keySendFail: 'Ein Schlüssel konnte nicht gesendet werden!',
      encryptSymMsgKeyFail:
        'Fehler bei der Verschüsselung eines Symmetric Message Keys.',
      publicKeyMissing: 'Ein öffentlicher Schlüssel fehlt!',
      decryptionError: 'Fehler bei der Entschlüsselung',
      keyAdded: 'Schlüssel hinzugefügt.',
      NotAKeyError: 'Ausgewählte Datei enthält keinen validen Schlüssel'
    },
    weekDay: {
      MONDAY: 'Montag',
      TUESDAY: 'Dienstag',
      WEDNESDAY: 'Mittwoch',
      THURSDAY: 'Donnerstag',
      FRIDAY: 'Freitag',
      SATURDAY: 'Samstag',
      SUNDAY: 'Sonntag'
    },
    generic: {
      modificationSuccess: 'Änderungen wurden gespeichert',
      modificationFailure: 'Änderungen fehlgeschlagen',
      select: 'Auswählen'
    },
    // Note: for the error texts exists a special getter function (getErrorText)
    //    that does not wait for the project to be loaded (available without delays)
    error: {
      solutionName: 'Lösung 3',
      oops: 'Huch!',
      someError: 'Ups ein Fehler ist aufgetreten…',
      somethingWentWrong: 'Leider ist etwas schief gelaufen',
      errorOnPage:
        'Auf der Seite, die du besuchen möchtest, ist ein Fehler aufgetreten.',
      pageNotFound: 'Seite nicht gefunden',
      pageNotFoundDescription:
        'Die Seite, die du aufgerufen hast, konnte nicht gefunden werden.',
      invitationNotFound: 'Einladung nicht gefunden',
      invitationNotFoundDescription:
        'Die Einladung, die du aufgerufen hast, konnte nicht gefunden werden.',
      tryAgain: 'Versuche es bitte später erneut.',
      errorId: 'Fehler ID',
      toHome: 'Zur Startseite',
      back: 'Zurück',
      requiredError: 'Dies ist ein Pflichtfeld und darf nicht leer sein.',
      emailFormatError: 'Bitte eine gültige E-Mail Adresse angeben',
      zipFormatError: 'Bitte eine gültige Postleitzahl angeben',
      twofaTitle: 'Fehlende Zwei-Faktor-Authentifizierung',
      twofaLoading: 'Status der Zwei-Faktor-Authentifzierung wird abgerufen…',
      userPortalButton: 'huhu NUTZERPORTAL', // Schreibweise wichtig, da huhu immer klein geschrieben werden soll, auch im Button
      logout: 'Ausloggen',
      getNodeKey2FADescription: ({
        portalLink
      }: {
        portalLink: (text: string) => ReactNode;
      }) => {
        return (
          <>
            Zur Verwendung der Anwendung musst du eine
            Zwei-Faktor-Authentifzierung aktivieren. Diese kannst du im{' '}
            <span> {portalLink('huhu Nutzerportal aktivieren')}</span>.
          </>
        );
      },
      dateFormatError:
        'Datum nicht korrekt. Bitte gib ein Datum ein wie z.B. 20.09.2024 19:00'
    },
    // path elements not prefixed with '/' within the cascading/nesting route/link system;
    //  if slashes will be necessary, to make a route directly after the domain, prepend a slash for that case
    paths: {
      home: '/',
      patientInfo: 'patienten',
      doctorInfo: 'hausaerzte-info',
      settings: 'einstellungen',
      general: 'allgemein',
      appearance: 'erscheinungsbild',
      visibility: 'sichtbarkeiten',
      participants: 'beteiligte',
      categories: 'kategorien',
      targetGroups: 'zielgruppen',
      role: 'rollen',
      account: 'konto',
      data: 'daten',
      contact: 'kontakt',
      reportAccessibility: 'barrierefreiheitsproblem',
      accessibility: 'barrierefreiheitserklaerung',
      privacy: 'datenschutz',
      terms: 'nutzungsbedingungen',
      imprint: 'impressum',
      events: 'termine',
      offers: 'angebote',
      providers: 'anbieter',
      seekers: 'nachfrager',
      compass: 'kompass',
      information: 'informationen',
      providerInfo: 'anbieter/informationen',
      create: 'erstellen',
      edit: 'bearbeiten',
      invitation: 'invitations',
      approve: 'approve',
      booking: 'behandlungsliste',
      getProviderProfile: ({ providerId }: { providerId: string }) =>
        `/${getPath('providers')}/${providerId}`,
      getProviderProfileEdit: ({ providerId }: { providerId: string }) =>
        `/${getPath('providers')}/${providerId}/${getPath('edit')}`,
      getSeekerProfile: ({ seekerId }: { seekerId: string }) =>
        `/${getPath('seekers')}/${seekerId}`,
      getSeekerProfileEdit: ({ seekerId }: { seekerId: string }) =>
        `/${getPath('seekers')}/${seekerId}/${getPath('edit')}`,
      // Note: update 'sonstiges' here in case otherCategory is changed
      getOffer: ({
        category = 'sonstiges',
        offerId
      }: {
        category?: string;
        offerId: string;
      }) => `/${getPath('offers')}/${transformToFragment(category)}/${offerId}`,
      getOfferEdit: ({ offerId }: { offerId: string }) =>
        `/${getPath('offers')}/${offerId}/${getPath('edit')}`,
      getSettingSubpagePath: ({ subpage }: { subpage: string }) =>
        `/${getPath('settings')}/${getPath(subpage)}`,
      getCompassCategory: ({ categoryId }: { categoryId: string }) =>
        `/${getPath('compass')}/${categoryId}`
    }
  },
  // specific NEW texts - follow the branches and add the custom text to the leaves
  'de-DE-neustadt': {
    allProviders: 'Medizinisches Fachpersonal',
    allSeekers: 'Alle Hausärzte',
    offers: 'Leistungen',
    role: {
      provider: 'Medizinisches Fachpersonal',
      providers: 'Medizinisches Fachpersonal',
      seeker: 'Hausarzt',
      seekers: 'Hausärzte'
    },
    participants: {
      offerLostWarning:
        'Durch den Wechsel der Rolle zu Hausarzt werden alle bestehenden Leistungen des Nutzers entfernt.'
    },
    info: {
      providerInfoShort: 'Info für medizinisches Fachpersonal',
      providerInfo: 'Informationen für medizinisches Fachpersonal',
      moreProviders: 'Unser medizinisches Fachpersonal',
      helpAndContactInfo:
        'Wenn Sie Fragen haben oder Hilfe benötigen, gelangen Sie über den Button "Kontakt" zu unserem Kontaktformular. Hier können Sie eine generelle Anfrage stellen, oder sich bei Fragen mit uns in Verbindung setzen. Unser Team steht Ihnen zur Verfügung, um Ihnen bei allen Problemen zu helfen, die bei der Nutzung der Anwendung auftreten können. Wir schätzen Ihr Feedback und setzen uns dafür ein, dass unsere Anwendung sowohl für medizinisches Fachpersonal als auch für Hausärzte die bestmögliche Erfahrung bietet.',
      patientInfo: 'Was ist analog-digitale Versorgung?',
      patientHelpTitle: 'Unsere Leistungen',
      patientServicesTitle: 'Unsere Praxen',
      patientProvidersText: 'Unser medizinisches Fachpersonal',
      patientParticipate: 'Unsere Standorte',
      patientMore: 'Wie kann ich teilnehmen?'
    },
    process: {
      searchOffer: 'Suche von Leistungen',
      searchProvider: 'Suche von medizinischem Fachpersonal'
    },
    profile: {
      ourOffers: 'Unsere Leistungen',
      moreOffers: 'Weitere Leistungen'
    },
    offer: {
      offer: 'Leistung',
      offers: 'Leistungen',
      offersFrom: 'Leistungen von',
      newOffer: 'Neue Leistung',
      newOffers: 'Neue Leistungen',
      moreOffers: 'Mehr Leistungen',
      matchingOffers: 'Passende Leistungen',
      furtherOffers: 'Weitere Leistungen',
      createOffer: 'Leistung erstellen',
      editOffer: 'Leistung bearbeiten',
      deleteOffer: 'Leistung löschen',
      bookingHint:
        'Wähle Zeit, Ort und Leistungstyp um Leistungen buchen zu können',
      searchBarPlaceholder:
        'Nach welcher Leistung suchst du? Gebe hier einen Suchbegriff ein.',
      noOfferResults:
        'Leider konnte für deine Eingabe keine Leistungen gefunden werden.',
      deleteSuccess: 'Leistung wurde gelöscht',
      deleteError:
        'Leistung konnte nicht gelöscht werden, da noch Termine in der Zukunft bestehen',
      details: {
        saveOffer: 'Leistung speichern',
        title: 'Leistungstitel',
        titlePlaceholder: 'Gib deiner Leistung einen aussagekräftigen Titel.',
        descriptionLabel: 'Beschreibung der Leistung',
        descriptionPlaceholder: 'Hier kannst du deine Leistung beschreiben.',
        requirementsPlaceholder:
          'Hier kannst du Voraussetzungen für deine Leistung nennen.',
        location: 'Ort der Leistung',
        mobileLocation: {
          title: 'Hausbesuch',
          info: 'Die Leistung wird mobil durchgeführt, d.h. du kommst zu der Person, welche die Leistung in Anspruch nimmt.',
          hint: 'In welchen Ortschaften möchtest du die Leistung anbieten?'
        },
        fixedLocation: {
          title: 'An einem festen Ort durchführbare Leistung',
          info: 'Die Leistung wird an einem festen Ort durchgeführt, d.h. die Person, welche die Leistung in Anspruch nimmt, kommt zu diesem Ort.'
        },
        onlineLocation: {
          title: 'Online durchführbare Leistung',
          info: 'Die Leistung wird online durchgeführt. Es besteht die Möglichkeit direkt einen Zugangslink zu hinterlegen, über den die Leistung in Anspruch genommen wird.'
        },
        time: 'Zeitangabe der Leistung',
        fixedTime: {
          info: 'Die Leistung findet an einem festen Tag zu einer festen Uhrzeit statt. Damit die Termine genauer angefragt werden können, muss auch die Dauer angegeben werden.'
        },
        timeRange: {
          info: 'Die Leistung wird in einem festgelegten Zeitfenster angeboten.'
        },
        businessTime: {
          title: 'Zu den Behandlungszeiten',
          info: 'Die Leistung orientiert sich an den Behandlungszeiten, die im Profil eingetragen sind. Damit die Termine genauer angefragt werden können, muss auch die Dauer angegeben werden.'
        },
        delete: {
          question: 'Bist du sicher, dass du diese Leistung löschen möchtest?',
          adminQuestion:
            'Bist du dir sicher, dass du diese Leistung endgültig löschen möchtest?'
        },
        pictureTitle: 'Leistungsbild',
        editPictureTitle: 'Leistungsbild bearbeiten'
      },
      booking: {
        timeslots: {
          noAppointmentsAnymore:
            'Es sind leider keine Terminvorschläge mehr für deine Anfrage vorhanden. Bitte starte eine neue Anfrage mit einer anderen Zeitangabe oder wähle andere Leistungen.'
        }
      }
    },
    report: {
      offer: {
        report: 'Leistung melden',
        successful: 'Leistung erfolgreich gemeldet.',
        why: 'Warum möchtest du diese Leistung melden?',
        reasonWhy: 'Du meldest diese Leistung aus folgendem Grund:'
      }
    },
    filter: {
      locationType: 'Leistungstyp *',
      fixed: 'Vor-Ort Leistung',
      online: 'Online Leistung',
      timeRange: 'Zeit *',
      location: 'Ort *',
      provider: 'Ausgewähltes Fachpersonal',
      time: {
        pointInTimeTitle: 'Zeitpunkt *'
      }
    },
    settings: {
      visibility: {
        helperTextProviders:
          'Wie soll das medizinische Fachpersonal gelistet werden?',
        helperTextSeekers: 'Wie sollen die Hausärzte gelistet werden?',
        helperTextOffers: 'Wie sollen die Leistungen gelistet werden?'
      },
      category: { manageCategories: 'Leistungskategorien verwalten' }
    },
    account: {
      offers: {
        offers: 'Leistungen'
      },
      data: {
        deleteAccount: {
          getInfo: ({ projectName }: { projectName: string }) => {
            return `Löschen Sie Ihr Konto im ${projectName} endgültig. Durch Löschen Ihres Kontos werden alle erstellten Inhalte und Leistungen unwiderruflich gelöscht.`;
          }
        }
      }
    },
    onboarding: {
      offer: 'Deine erste Leistung',
      offerSeeker: 'Eine Leistung finden',
      offerSuccess: 'Du hast deine erste Leistung erstellt!',
      offerSeekerSuccess: 'Du hast deine erste Leistung gefunden!',

      dialog: {
        getNodeOffer: ({
          offerLink
        }: {
          offerLink: (text: string) => ReactNode;
        }) => {
          return (
            <>
              Erstelle deine erste Leistung und mache dich mit unserem flexiblen
              Leistungs-Editor vertraut. Gib ausreichende Informationen an,
              damit Arztpraxen wissen, welche Leistung dein Angebot umfasst.
              <br />
              <br /> Gehe zu <span>{offerLink('Leistungen')}</span> und wähle
              "Leistung erstellen".
              <br />
              <br /> Um Leistungen erstellen zu können, muss deine Organisation
              ein Schlüsselpaar erstellt haben. Dies wird im Schritt "Daten
              schützen" erklärt.
            </>
          );
        },
        getNodeOfferSeeker: ({
          offerLink
        }: {
          offerLink: (text: string) => ReactNode;
        }) => {
          return (
            <>
              Sieh dir die Liste der angebotenen Leistungen an und mache dich
              mit der Suche und den Filtermöglichkeiten vertraut.
              <br />
              <br /> Gehe zu den <span>{offerLink('Leistungen')}</span>.
            </>
          );
        }
      }
    },
    calendar: {
      providerNotification:
        'Die Person, die die Leistung gebucht hat, wird per Mail darüber benachrichtigt.',
      seekerNotification: 'Die Fachperson wird per Mail darüber benachrichtigt.'
    },

    // specific path names
    paths: {
      offers: 'leistungen',
      providers: 'fachpersonal',
      providerInfo: 'fachpersonal/informationen',
      seekers: 'hausaerzte'
    }
  },
  'de-DE-loerrach': {
    info: {
      doctorInfoShort: 'Info für Fachpersonen',
      doctorInfo: 'Informationen für Fachpersonen',
      patientInfo: 'Informationen für Betroffene und Angehörige',
      patientInfoShort: 'Info für Betroffene und Angehörige'
    },
    participants: {
      offerLostWarning:
        'Durch den Wechsel der Rolle zu Fachperson werden alle bestehenden Angebote der Organisation entfernt.'
    },
    paths: {
      doctorInfo: 'fachpersonen',
      patientInfo: 'betroffene'
    }
  },
  'de-DE-loeFH': {
    info: {
      howItWorksSectionTitle: 'Wie funktioniert HalLÖchen?',
      whatToAchieveSectionTitle: 'HalLÖchen - Familienapp Landkreis Lörrach',
      patientMore: 'HalLÖchen - Familienapp Landkreis Lörrach',
      patientServicesTitle: 'Welche Angebote gibt es für dich und mich?',
      doctorInfo: 'Informationen für Fachleute',
      patientInfo: 'Informationen für Dich und Mich',
      patientInfoShort: 'Infos für Dich und Mich',
      doctorInfoShort: 'Infos für Fachleute',
      providerInfoShort: 'Infos für Anbieter'
    },
    profile: {
      businessHours: 'Sprechzeiten',
      changeBusinessHoursSuccess: 'Die Sprechzeiten wurden geändert.',
      changeBusinessHoursError: 'Änderung der Sprechzeiten fehlgeschlagen.',
      focus: 'Angebotsschwerpunkte',
      focusPlaceholder: 'Beschreiben Sie Ihre Angebotsschwerpunkte'
    },
    offer: {
      details: {
        businessTime: {
          title: 'Zu den Sprechzeiten',
          info: 'Das Angebot orientiert sich an den Sprechzeiten, die im Profil eingetragen sind. Damit die Termine genauer angefragt werden können, muss auch die Dauer angegeben werden.'
        }
      }
    },
    onboarding: {
      dialog: {
        getNodeProfile: ({
          profileLink
        }: {
          profileLink: (text: string) => ReactNode;
        }) => {
          return (
            <>
              Fülle dein Profil aus, damit andere Nutzer sich über dich
              informieren können. Ein gepflegtes Profil schafft Vertrauen.
              <br />
              <br /> Dein Profil findest du im Account-Menü. Klicke dafür rechts
              oben auf deinen Namen und wähle "Profil" aus. Dort kannst du über
              "Profil bearbeiten" alle Informationen über dich, deine
              Qualifikationen und Angebotsschwerpunkte einfügen sowie eine
              Bildergalerie anlegen.
              <br />
              <br /> Hier kommst du direkt zu deinem {profileLink('Profil')}.
            </>
          );
        }
      }
    },
    participants: {
      offerLostWarning:
        'Durch den Wechsel zur Rolle Fachleute werden alle bestehenden Angebote der Organisation entfernt.'
    },
    paths: {
      doctorInfo: 'fachleute',
      patientInfo: 'dich-und-mich'
    }
  }
};

export default config;
export { LOCALE_DEFAULT };
