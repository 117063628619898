import { USERPORTAL_URL } from 'auth/settings';
import { useProject } from 'context/project';
import { getText } from 'localization';

const useGetPortalOrganizationProfileHref = (
  organizationId?: string,
  additionalQueryParameter?: string
) => {
  const { project, primaryColor } = useProject();

  return `${USERPORTAL_URL}organizations/${organizationId}?solution_uri=${
    window.location.href
  }&solution_name=${
    project?.name ?? getText('solutionName')
  }&solution_color=${primaryColor.replace('#', '')}${
    additionalQueryParameter ? additionalQueryParameter : ''
  }`;
};

const useGetPortalOrganizationsListHref = () => {
  const { project, primaryColor } = useProject();

  return `${USERPORTAL_URL}organizations?solution_uri=${
    window.location.href
  }&solution_name=${
    project?.name ?? getText('solutionName')
  }&solution_color=${primaryColor.replace('#', '')}`;
};

const useGetPortalAddOrganizationHref = (organizationName?: string) => {
  const { project, primaryColor } = useProject();

  return `${USERPORTAL_URL}choose/organization?redirect_uri=${
    window.location.href
  }?solution_uri=${window.location.href}&solution_name=${
    project?.name ?? getText('solutionName')
  }&solution_color=${primaryColor.replace(
    '#',
    ''
  )}&organization_name=${organizationName}&types[]=user`;
};

const useGetPortalOrganizationSecurityHref = () => {
  const { project, primaryColor } = useProject();

  return `${USERPORTAL_URL}profile/security?solution_uri=${
    window.location.href
  }&solution_name=${
    project?.name ?? getText('solutionName')
  }&solution_color=${primaryColor.replace('#', '')}`;
};

export {
  useGetPortalOrganizationProfileHref,
  useGetPortalOrganizationsListHref,
  useGetPortalAddOrganizationHref,
  useGetPortalOrganizationSecurityHref
};
