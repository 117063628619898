import { InvitationApprovePage, InvitationLandingPage } from 'pages';
import { Routes, Route, Outlet } from 'react-router-dom';
import { ForceAuth, Restricted } from './restricted-route';
import { getPath } from 'localization';
import { useIsAdmin } from 'context/user';

const InvitationRoutes = () => {
  const isAdmin = useIsAdmin();

  return (
    <>
      <Routes>
        <Route
          path={`:invitationId`}
          element={
            <ForceAuth>
              <InvitationLandingPage />
            </ForceAuth>
          }
        />
        <Route
          path={`:invitationId/${getPath('approve')}`}
          element={
            <Restricted isVisible={isAdmin} forceLogin={true}>
              <InvitationApprovePage />
            </Restricted>
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};

export default InvitationRoutes;
