import { Box, Stack, Typography } from '@mui/material';
import { useProject } from 'context/project';
import ColorPicker, { hexFormat } from 'feature/color-picker';
import { getTextIn } from 'localization';

const getSettingsText = getTextIn('settings-appearance');

type ColorBoxProps = {
  title: string;
  color: string;
  handleColorChange: (newValue: string) => void;
  dataCyPrefix: string;
  resetClicked?: boolean;
};

const ColorBox = ({
  title,
  color,
  handleColorChange,
  dataCyPrefix,
  resetClicked
}: ColorBoxProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="formLabel">{title}</Typography>
      <ColorPicker
        globalColor={color}
        handleGlobalColorChange={handleColorChange}
        resetClicked={resetClicked}
        dataCyPrefix={dataCyPrefix}
      />
    </Box>
  );
};

type ColorSettingsProps = {
  resetClicked?: boolean;
};

const ColorSettings = ({ resetClicked }: ColorSettingsProps) => {
  const { primaryColor, secondaryColor, setPrimaryColor, setSecondaryColor } =
    useProject();

  const handlePrimaryChange = (newValue: string) => {
    if (hexFormat.test(newValue)) {
      setPrimaryColor(newValue);
    }
  };

  const handleSecondaryChange = (newValue: string) => {
    if (hexFormat.test(newValue)) {
      setSecondaryColor(newValue);
    }
  };
  return (
    <Stack
      sx={{ pt: 7 }}
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 8, sm: 4, lg: 20 }}
    >
      <ColorBox
        title={getSettingsText('choosePrimaryColor')}
        color={primaryColor}
        handleColorChange={handlePrimaryChange}
        resetClicked={resetClicked}
        dataCyPrefix="primary"
      />
      <ColorBox
        title={getSettingsText('chooseSecondaryColor')}
        color={secondaryColor}
        handleColorChange={handleSecondaryChange}
        resetClicked={resetClicked}
        dataCyPrefix="secondary"
      />
    </Stack>
  );
};

export default ColorSettings;
